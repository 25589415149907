import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { CardBody, Button, FormGroup, Label, ButtonGroup } from "reactstrap";
import { adminRequestPost, requestGet, requestPost } from "../../apis/request";
import { useTranslation } from "react-i18next";
import SensorRow from "./SensorRow";
import SimpleBarReact from "simplebar-react";
import Sensorchart from "../Charts/Sensorchart";
import "simplebar/dist/simplebar.min.css";
import moment from "moment";
import Switch from "@mui/material/Switch";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./sensorrow.scss";
import { ReactComponent as Calendar } from "../../assets/images/chart/fi-rr-calendar.svg";
import { ReactComponent as Smallup } from "../../assets/images/chart/fi-rr-angle-small-up.svg";
import { ReactComponent as Smalldown } from "../../assets/images/chart/fi-rr-angle-small-down.svg";
import { ReactComponent as Left } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-left.svg";
import { ReactComponent as Right } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-right.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-arrow-right.svg";
import { ReactComponent as Database } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-database.svg";
import { ReactComponent as Thermometer } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-thermometer-half.svg";
import { ReactComponent as Raindrops } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { ReactComponent as Sun } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-sun.svg";
import { ReactComponent as Drainagetemperature } from "../../assets/icons/drainagetemperature.svg";
import { ReactComponent as Co2 } from "../../assets/icons/co2.svg";
import { ReactComponent as Drainagewaterflow } from "../../assets/icons/drainagewaterflow.svg";
import { ReactComponent as Fullwifi } from "../../assets/icons/fullwifi.svg";
import { ReactComponent as Nutrienttemperature } from "../../assets/icons/nutrienttemperature.svg";
import { ReactComponent as Phtemperature } from "../../assets/icons/phtemperature.svg";
import { ReactComponent as Temperature } from "../../assets/icons/temperature.svg";
import { ReactComponent as Speed } from "../../assets/icons/speed.svg";
import { ReactComponent as Flow } from "../../assets/icons/flow.svg";
import Battery from "../../assets/icons/001-empty-battery_2021-11-05/001-empty-battery.png";
import Energy from "../../assets/icons/002-renewable-energy_2021-11-05/002-renewable-energy.png";
import Pump from "../../assets/icons/pumpicon.png";
import Temp from "../../assets/icons/temp.png";
import Hum from "../../assets/icons/hum.png";
import DeviceHum from "../../assets/icons/devicehum.png";
import { useHistory } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import CotRow from "./CotRow";
import dayjs from "dayjs";
import CotRowDetail from "./CotRowDetail";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FormControlLabel, Stack, TextField, Typography } from "@mui/material";
const COTsensorWrap = (props) => {
  const [selects, setSelects] = useState([]);
  const [nowData, setNowData] = useState({});
  const [autoWater, setAutoWater] = useState("");
  const [autoLowMoi, setAutoLowMoi] = useState("");
  const [autoHighMoi, setAutoHighMoi] = useState("");
  const [reWater, setReWater] = useState("");
  const [chnum, setchnum] = useState(0);
  const [chOnOff, setChOnOff] = useState("");
  const [reOnOff, setReOnOff] = useState(false);
  const [perWater, setPerWater] = useState("");
  const [perMinute, setPerMinute] = useState("");
  const [perOnOff, setPerOnOff] = useState(false);
  //   const [props?.ch[props.dataListId]?.ch, setChannel] = useState("1");
  const [value, setValue] = useState(dayjs());
  const [customDate, setCustomDate] = useState(false);
  const [autoView, setAutoView] = useState(false);
  const [reserView, setReserView] = useState(false);
  const [perView, setPerView] = useState(false);
  const [status, setStatus] = useState(null);

  const history = useHistory();
  const { t } = useTranslation("main");

  useEffect(() => {
    if (props?.dataListId !== undefined) {
      setSelects([props?.dataListId]);
    }
  }, [props?.dataListId]);

  const onAutoWater = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "automatic/watering",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: autoWater,
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setAutoWater("");
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const callChOnOff = async (ch) => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "onoff",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: Number(ch),
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onAutoLow = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "automatic/low",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: autoLowMoi,
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setAutoLowMoi("");
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onAutoHigh = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "automatic/high",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: autoHighMoi,
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setAutoHighMoi("");
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onAutoReset = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "automatic/reset",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: "1",
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setAutoHighMoi("");
      setAutoLowMoi("");
      setAutoWater("");
      setTimeout(() => {
        props?.callMqttsensors();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const onReWater = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "reservation/watering",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: reWater,
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setReWater("");
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onReTimeH = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "reservation/time",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: moment(value.$d).format("HH:mm"),
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setValue(dayjs());
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onReRepeat = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "reservation/repeat",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: reOnOff ? "1" : "2",
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setReOnOff(false);
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onReReset = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "reservation/reset",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: "1",
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setReWater("");
      setValue(dayjs());
      setReOnOff(false);
      setTimeout(() => {
        props?.callMqttsensors();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const onPerWater = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "period/watering",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: perWater,
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setPerWater("");
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onPerTimeM = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "period/time/m",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: perMinute,
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setPerMinute("");
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onPerRepeat = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "period/repeat",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: perOnOff ? "1" : "2",
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setPerOnOff(false);
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onPerReset = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "period/reset",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: "1",
      };
      await adminRequestPost(`/api/v1/sensor/cmd`, body);
      setPerWater("");
      setPerMinute("");
      setPerOnOff(false);
      setTimeout(() => {
        props?.callMqttsensors();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChOnOff = (value) => {
    setChOnOff(value);
  };
  const handleReOnOff = (e) => {
    setReOnOff(!reOnOff);
  };
  const handlePerOnOff = (e) => {
    setPerOnOff(!perOnOff);
  };
  const onReset = (e) => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (props?.mqttData?.length > 0) {
      for (let i = 0; i < props?.mqttData?.length; i++) {
        if (
          props?.mqttData[i]?.ch === Number(props?.ch[props.dataListId]?.ch)
        ) {
          setNowData(props?.mqttData[i]);
        }
      }
    }
  }, [props?.ch[props.dataListId]?.ch, props?.mqttData]);

  useEffect(() => {
    if (props.data) {
      setNowData({
        automatic: props.data?.automatic,
        automaticHighMoisture: props.data?.automaticHighMoisture,
        automaticLowMoisture: props.data?.automaticLowMoisture,
        automaticWatering: props.data?.automaticWatering,
        onoff: props.data?.onoff,
        period: props.data?.period,
        periodRepeat: props.data?.periodRepeat,
        periodTime: props.data?.periodTime,
        periodWatering: props.data?.periodWatering,
        reservation: props.data?.reservation,
        reservationRepeat: props.data?.reservationRepeat,
        reservationTime: props.data?.reservationTime,
        reservationWatering: props.data?.reservationWatering,
        serial: props.data?.serial,
      });
      setChOnOff(String(props.data.onoff));
      if (props.data.automatic === 1) {
        setAutoView(true);
      } else {
        setAutoView(false);
      }
      if (props.data.period === 1) {
        setPerView(true);
      } else {
        setPerView(false);
      }
      if (props.data.reservation === 1) {
        setReserView(true);
      } else {
        setReserView(false);
      }
    } else {
      setNowData({});
    }
  }, [props.data]);

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col xs="auto">
            <ToggleButtonGroup
              type="checkbox"
              value={props?.ch[props.dataListId]?.ch}
              className="chbtnWrap"
            >
              {props?.chLength[props.dataListId]?.chleng?.map((el, i) => {
                return (
                  <ToggleButton
                    id={`tbg-btn-${el}`}
                    value={`${el}`}
                    key={i}
                    onClick={() => {
                      setchnum(Number(i));
                      props.setCh((prevState) => ({
                        ...prevState,
                        [`${props.dataListId}`]: {
                          ...prevState.props?.dataListId,
                          ch: `${el}`,
                        },
                      }));
                    }}
                  >
                    {`${el} CH`}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <div
              className="bbadge"
              style={{
                marginRight: "20px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Raindrops className="sensorsListIcon" />
              {!props.nowMoi ? "--" : props.nowMoi === 0 ? "00" : props.nowMoi}%
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs="auto"
            className="d-flex align-items-center"
            style={{ marginTop: "15px" }}
          >
            <ButtonGroup type="checkbox" value={chOnOff}>
              <Button
                id="noClor"
                value="1"
                style={{ marginBottom: "0px" }}
                onClick={() => callChOnOff("1")}
              >
                On
              </Button>
              <Button
                id="noClor"
                value="2"
                style={{ marginBottom: "0px" }}
                onClick={() => callChOnOff("2")}
              >
                Off
              </Button>
            </ButtonGroup>
            <Label
              style={{
                color: "#000",
                fontSize: "18px",
                fontWeight: "bold",
                marginLeft: "20px",
                marginBottom: "0px",
              }}
            >
              {t("current")} :{" "}
              {nowData?.onoff === 1
                ? "ON"
                : nowData?.onoff === 2
                ? "OFF"
                : "--"}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col
            xs="12"
            className="controlSensorNameWrap"
            style={{ marginTop: "48px", display: "flex", alignItems: "center" }}
          >
            <span className="controlSensorName">Automatic</span>
            <button
              className="controlSensorResetBtn"
              onClick={() => onAutoReset()}
            >
              reset
            </button>
            <input
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              checked={autoView}
              value={autoView}
              onClick={(e) => {
                e.stopPropagation();
                setAutoView(!autoView);
              }}
            ></input>
          </Col>
          {autoView ? (
            <>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("watering")}{" "}
                    {nowData?.automaticWatering ||
                    nowData?.automaticWatering === 0
                      ? nowData.automaticWatering
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Watering Value"
                      value={autoWater}
                      className="controlSensorTextInput"
                      onChange={(e) => setAutoWater(e.target.value)}
                    />
                    <button
                      onClick={() => onAutoWater()}
                      className="controlSensorTextInputBtn"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("lowmoi")}{" "}
                    {nowData?.automaticLowMoisture ||
                    nowData?.automaticLowMoisture === 0
                      ? nowData.automaticLowMoisture
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Low Moisture Value"
                      value={autoLowMoi}
                      className="controlSensorTextInput"
                      onChange={(e) => setAutoLowMoi(e.target.value)}
                    />
                    <button
                      onClick={() => onAutoLow()}
                      className="controlSensorTextInputBtn"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("highmoi")}{" "}
                    {nowData?.automaticHighMoisture ||
                    nowData?.automaticHighMoisture === 0
                      ? nowData.automaticHighMoisture
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="HIGH Moisture Value"
                      value={autoHighMoi}
                      className="controlSensorTextInput"
                      onChange={(e) => setAutoHighMoi(e.target.value)}
                    />
                    <button
                      onClick={() => onAutoHigh()}
                      className="controlSensorTextInputBtn"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>
        <Row>
          <Col
            xs="12"
            className="controlSensorNameWrap"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="controlSensorName">Reservation</span>
            <button
              className="controlSensorResetBtn"
              onClick={() => onReReset()}
            >
              reset
            </button>
            <input
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              checked={reserView}
              value={reserView}
              onClick={(e) => {
                e.stopPropagation();
                setReserView(!reserView);
              }}
            ></input>
          </Col>
          {reserView ? (
            <>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("watering")}{" "}
                    {nowData?.reservationWatering ||
                    nowData?.reservationWatering === 0
                      ? nowData.reservationWatering
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Watering Value"
                      value={reWater}
                      className="controlSensorTextInput"
                      onChange={(e) => setReWater(e.target.value)}
                    />
                    <button
                      onClick={() => onReWater()}
                      className="controlSensorTextInputBtn"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("currenttime")}{" "}
                    {(nowData?.reservationTime ||
                      nowData?.reservationTime === 0) &&
                    nowData?.reservationTime !== "string"
                      ? nowData.reservationTime
                      : "--"}
                  </Label>
                  <div className="d-flex">
                    <div className="timepickerWrap">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={value}
                          onChange={setValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              style={{
                                height: "33.5px",
                                display: "flex",
                                justifyContent: "center",
                                paddingLeft: "10px",
                                maxWidth: "197px",
                              }}
                              className="controlSensorTextInput"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <button
                      onClick={() => onReTimeH()}
                      className="controlSensorTextInputBtn"
                      style={{ minWidth: "53px" }}
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("repeat")}{" "}
                    {nowData?.reservationRepeat === 0
                      ? "OFF"
                      : nowData.reservationRepeat === 1
                      ? "ON"
                      : "--"}
                  </Label>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={reOnOff}
                          onClick={handleReOnOff}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Repeat"
                      labelPlacement="start"
                      style={{ marginBottom: "0px" }}
                    />
                    <button
                      onClick={() => onReRepeat()}
                      className="controlSensorTextInputBtn"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col
            xs="12"
            className="controlSensorNameWrap"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="controlSensorName">Period</span>
            <button
              className="controlSensorResetBtn"
              onClick={(e) => onPerReset()}
            >
              reset
            </button>
            <input
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              checked={perView}
              value={perView}
              onClick={(e) => {
                e.stopPropagation();
                setPerView(!perView);
              }}
            ></input>
          </Col>
          {perView ? (
            <>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("watering")}{" "}
                    {nowData?.periodWatering || nowData?.periodWatering === 0
                      ? nowData.periodWatering
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Watering Value"
                      value={perWater}
                      className="controlSensorTextInput"
                      onChange={(e) => setPerWater(e.target.value)}
                    />
                    <button
                      onClick={() => onPerWater()}
                      className="controlSensorTextInputBtn"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("pertime")}{" "}
                    {(nowData?.periodTime || nowData?.periodTime === 0) &&
                    nowData?.periodTime !== "string"
                      ? nowData.periodTime
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Time Minute Value"
                      value={perMinute}
                      className="controlSensorTextInput"
                      onChange={(e) => setPerMinute(e.target.value)}
                    />
                    <button
                      onClick={() => onPerTimeM()}
                      className="controlSensorTextInputBtn"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("repeat")}{" "}
                    {nowData?.periodRepeat === 0
                      ? "OFF"
                      : nowData.periodRepeat === 1
                      ? "ON"
                      : "--"}
                  </Label>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch checked={perOnOff} onClick={handlePerOnOff} />
                      }
                      label="Repeat"
                      labelPlacement="start"
                      style={{ marginBottom: "0px" }}
                    />
                    <button
                      onClick={() => onPerRepeat()}
                      className="controlSensorTextInputBtn"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default COTsensorWrap;
