import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { requestGet } from "../../../apis/request";
import moment from "moment";
import "moment/locale/ko";
import excel from "../../../assets/icons/xls-file.svg";
import Calendar from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-calendar.svg";
import Pencil from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-pencil.svg";

const WATERLEVELSplineArea = ({
  setMenu,
  userDevice,
  min,
  max,
  data,
  typeKey,
}) => {
  const [series, setSeries] = useState([]);
  const [inMin, setInMin] = useState();
  const [inMax, setInMax] = useState();
  const getLog = async (userDevice) => {
    try {
      if (userDevice) {
        return {
          name: userDevice?.name,
          data: data[userDevice?.name]?.map((sensorItem, j) => {
            return [
              new Date(sensorItem.createdDate.replace(/-/g, "/")).getTime(),
              Number(sensorItem[typeKey]).toFixed(0),
            ];
          }),
        };
      } else {
        return {
          name: userDevice?.name,
          data: [],
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callChartApi = async () => {
    if (Array.isArray(userDevice) && userDevice.length > 0) {
      const arr = [];
      if (userDevice.length > 0) {
        for (let value of userDevice) {
          const result = await getLog(value);
          arr.push(result);
        }
      }
      setSeries(arr);
    }
  };

  useEffect(() => {
    callChartApi();
  }, [userDevice, data]);

  useEffect(() => {
    if (!min && !max) {
      return;
    } else {
      if (parseInt(min, 10) >= 0) {
        setInMin(parseInt(min, 10));
      } else if (parseInt(min, 10) <= 0) {
        setInMin(parseInt(min, 10));
      } else {
        setInMin(0);
      }
      if (parseInt(max, 10) >= 0) {
        setInMax(parseInt(max, 10));
      } else if (parseInt(max, 10) <= 0) {
        setInMax(parseInt(max, 10));
      } else {
        setInMax(0);
      }
    }
  }, [min, max]);

  const onMenu = () => {
    setMenu((menu) => ({
      ...menu,
      [typeKey]: true,
    }));
  };

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    // colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
        format: "yyyy/MM/dd",
      },
    },
    yaxis: {
      min: inMin,
      max: inMax,
    },
    chart: {
      toolbar: {
        tools: {
          download: `<img src=${excel} width="20" height="20" />`,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          customIcons: [
            {
              icon: `<img src=${Pencil} width="20" height="20" />`,
              index: 2,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onMenu();
              },
            },
          ],
        },
        export: {
          csv: {
            filename: typeKey,
            columnDelimiter: ",",
            headerCategory: `${typeKey} Date`,
            headerValue: "value",
            dateFormatter(timestamp) {
              return moment(timestamp).format("YYYY/MM/DD HH:mm:ss");
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      followCursor: true,
      x: {
        format: "yyyy/MM/dd HH:mm",
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
      className="apex-charts"
      dir="ltr"
    />
  );
};

export default WATERLEVELSplineArea;
