import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { CardBody, Button } from "reactstrap";
import { requestGet } from "../../apis/request";
import { useTranslation } from "react-i18next";
import SensorRow from "./SensorRow";
import SimpleBarReact from "simplebar-react";
import Sensorchart from "../Charts/Sensorchart";
import "simplebar/dist/simplebar.min.css";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./sensorrow.scss";
import { ReactComponent as Left } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-left.svg";
import { ReactComponent as Right } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-right.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-arrow-right.svg";
import { useHistory } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SensorDate from "./SensorDate";
import SensorCheaps from "./SensorCheaps";
import { ko } from "date-fns/esm/locale";

const SensorListType = (props) => {
  const [startDate, setStartDate] = useState(
    moment().format("YYYY-MM-DD 00:00")
  );
  const [endDate, setEndDate] = useState(moment());
  const [teststartDate, settestStartDate] = useState(new Date());
  const [testendDate, settestEndDate] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const [type] = useState(props.location.state.deviceType);
  const [dataListId] = useState(props.location.state.id);
  const [selects, setSelects] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [sensorLog, setSensorLog] = useState({});
  const [usercluster, setUserCluster] = useState([]);
  const [sideswicth, setSideswitch] = useState(false);
  const [dayOpen, setDayOpen] = useState(false);
  const [activeDay, setActiveDay] = useState("");
  const [topDeviceTypes, setTopDeviceTypes] = useState("");
  const [lastidx, setLastidx] = useState(0);
  const history = useHistory();
  const [customDate, setCustomDate] = useState(false);
  const { t } = useTranslation("main");

  const getsensor = async (value) => {
    try {
      const sensor = await requestGet(`/api/v1/sensor/list/${value}`);
      return sensor;
    } catch (error) {
      alert("데이터 불러오는데 실패하였습니다.");
    }
  };

  const sensors = async () => {
    try {
      let arr = [];
      if (typeof type === "string") {
        const sensor = await requestGet(`/api/v1/sensor/list/${type}`);
        return setUserInfo(sensor);
      } else {
        for (let value of type) {
          const result = await getsensor(value);
          if (result) {
            arr.push(...result);
          }
        }
      }
      setUserInfo(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sensors();
  }, [type]);

  const clu = async () => {
    const clusterList = await requestGet(`/api/v1/cluster/list`);
    setUserCluster(clusterList);
  };

  useEffect(() => {
    clu();
  }, []);

  useEffect(() => {
    if (dataListId !== undefined) {
      setSelects([dataListId]);
    }
  }, [dataListId]);

  const callsensors = async () => {
    if (selects[lastidx] !== undefined) {
      const log = await requestGet(
        `/api/v1/sensor/read/log/${selects[lastidx]}/latest`
      );
      setSensorLog(log);
    }
  };

  useEffect(() => {
    setLastidx(selects.length - 1);
    if (selects.length >= 0) {
      callsensors();
    } else {
      return;
    }
  }, [selects, lastidx]);

  const headersideswitch = () => {
    setSideswitch(!sideswicth);
  };

  const isOutsideRange = (day) => {
    return moment().diff(day) < 0;
  };

  const closeall = () => {
    setCustomDate(false);
    setDayOpen(!dayOpen);
  };

  const goDataList = () => {
    history.push({
      pathname: "/sensors/datalist",
      state: {
        userInfo: userInfo,
        usercluster: usercluster,
        types: type,
      },
    });
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    settestStartDate(start);
    settestEndDate(end);
    if (start && end) {
      setStartDate(moment(start).format("YYYY-MM-DD 00:00"));
      setEndDate(moment(end).format("YYYY-MM-DD 23:59"));
      setCustomDate(false);
      setDayOpen(!dayOpen);
    }
  };

  const sidebarheighy = $(".colwrap").height();

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensors List | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap1">
        <div className="container p-0 m-0" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <Row
              className="mobilewidth justify-content-center"
              style={{ height: "100%", width: "100%" }}
            >
              <Col
                md={3}
                className={sideswicth ? "switchcardwrap" : "colwrap"}
                style={
                  sideswicth ? { height: sidebarheighy } : { height: "100%" }
                }
              >
                <Card className="cardwrap m-0">
                  <span className="irrigationtitle">{t("sensortext")}</span>
                  <div className="sensorsListDiv">
                    <span className="sensorsListSpan">Sensor Data List</span>
                    <ArrowRight
                      className="gosensorsListIcon"
                      onClick={goDataList}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <CardBody
                    className="cardBodyWrap"
                    style={{ height: "100%", overflow: "hidden" }}
                  >
                    <div
                      className="table-responsive"
                      style={{ height: "100%", overflow: "hidden" }}
                    >
                      <SimpleBarReact
                        className="listtypesimplebar"
                        autoHide={false}
                        style={{ maxHeight: "100%" }}
                      >
                        <Table className="table mb-0">
                          <tbody className="tbodystyle1">
                            <SensorRow
                              allSelected={allSelected}
                              selects={selects}
                              setSelects={setSelects}
                              userInfo={userInfo}
                              setSideswitch={setSideswitch}
                              setAllSelected={setAllSelected}
                              isOutsideRange={isOutsideRange}
                              endDate={endDate}
                              usercluster={usercluster}
                              setTopDeviceTypes={setTopDeviceTypes}
                            />
                          </tbody>
                        </Table>
                      </SimpleBarReact>
                    </div>
                  </CardBody>
                  <Button
                    className="selectSuccesBtn"
                    color="success"
                    onClick={() => setSideswitch(true)}
                  >
                    선택완료
                  </Button>
                </Card>
              </Col>
              <div className="sideMark" onClick={() => headersideswitch()}>
                {sideswicth ? (
                  <Right className="sideIcon" />
                ) : (
                  <Left className="sideIcon" />
                )}
              </div>
              <Col md={9} className="chartwrap" style={{ height: "100%" }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                  }}
                >
                  <div className="div354">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="div354Name">
                        {t(
                          `${
                            typeof type === "string"
                              ? type === "SSH"
                                ? "SSH01"
                                : type
                              : type[0]
                          }`
                        )}
                      </span>
                      <span
                        className="div354dateName"
                        style={{ marginLeft: "70px" }}
                      >
                        {sensorLog.createdDate?.split(" ").map((line) => (
                          <div className="createddate">{line}</div>
                        ))}
                      </span>
                    </div>
                    <SensorDate
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      type={type}
                      dataListId={dataListId}
                      selects={selects}
                      setSelects={setSelects}
                      setUserInfo={setUserInfo}
                      setSensorLog={setSensorLog}
                      setUserCluster={setUserCluster}
                      dayOpen={dayOpen}
                      setDayOpen={setDayOpen}
                      activeDay={activeDay}
                      setActiveDay={setActiveDay}
                      lastidx={lastidx}
                      setLastidx={setLastidx}
                      setCustomDate={setCustomDate}
                    />
                  </div>
                  {customDate ? (
                    <>
                      <div
                        className="chartOutside"
                        onClick={() => closeall()}
                      />
                      <DatePicker
                        selected={teststartDate}
                        onChange={onChange}
                        startDate={teststartDate}
                        endDate={testendDate}
                        dateFormat="yyyy년 MM월 dd일"
                        selectsRange
                        inline
                        locale={ko}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {selects.length !== 0 ? (
                    <SensorCheaps
                      type={type}
                      dataListId={dataListId}
                      selects={selects}
                      setSelects={setSelects}
                      setUserInfo={setUserInfo}
                      sensorLog={sensorLog}
                      setSensorLog={setSensorLog}
                      setUserCluster={setUserCluster}
                      topDeviceTypes={topDeviceTypes}
                      lastidx={lastidx}
                      setLastidx={setLastidx}
                    />
                  ) : (
                    ""
                  )}
                  {selects.length !== 0 ? (
                    <div style={{ height: "100%", overflowX: "inherit" }}>
                      <Sensorchart
                        selects={selects}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  ) : (
                    <div className="insideText">SENSORS</div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SensorListType;
