import { Card, CardBody, Table } from "reactstrap";
import SimpleBarReact from "simplebar-react";
import Farmlistrow from "./farmlistrow";
import { useTranslation } from "react-i18next";
import "simplebar/src/simplebar.css";
const Farmlist = ({ userInfo, setOpenFarm, setFarmId, setFarmName }) => {
  const { t } = useTranslation("main");
  return (
    <Card className="m-0 irrigationcard">
      <span className="irrigationtitle">{t("irrigationtext")}</span>
      <div style={{ height: "100%", overflow: "hidden" }}>
        <SimpleBarReact style={{ maxHeight: "100%" }} autoHide={false}>
          <CardBody className="cardBodyWrap">
            <div className="table-responsive">
              <Table className="table mb-0">
                <tbody className="tbodystyle">
                  <Farmlistrow
                    userInfo={userInfo}
                    setOpenFarm={setOpenFarm}
                    setFarmId={setFarmId}
                    setFarmName={setFarmName}
                  />
                </tbody>
              </Table>
            </div>
          </CardBody>
        </SimpleBarReact>
      </div>
    </Card>
  );
};

export default Farmlist;
