import { Card, CardBody, Table } from "reactstrap";
import SimpleBarReact from "simplebar-react";
import MapTypelistrow from "./MapTypelistrow";
import "simplebar/src/simplebar.css";
const MapTypelist = ({ setOpenType, setTypeName }) => {
  return (
    <Card className="m-0 irrigationcard">
      <SimpleBarReact style={{ maxHeight: "85vh" }}>
        <CardBody className="cardBodyWrap">
          <div className="table-responsive">
            <Table className="table mb-0">
              <tbody className="tbodystyle">
                <MapTypelistrow
                  setOpenType={setOpenType}
                  setTypeName={setTypeName}
                />
              </tbody>
            </Table>
          </div>
        </CardBody>
      </SimpleBarReact>
    </Card>
  );
};

export default MapTypelist;
