import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { requestGet } from "../../apis/request";
const MapTypelistrow = () => {
  const [typeList, setTypeList] = useState([]);
  const [typeEditList, setTypeEditList] = useState([]);
  const history = useHistory();
  const { t } = useTranslation("main");
  const clicksoil = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: ["SOIL", "SMS", "SSH"],
      },
    });
  };
  const clickco2 = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "CO2",
      },
    });
  };
  const clickat = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "AT",
      },
    });
  };
  const clickwaterflow = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "WATERFLOW",
      },
    });
  };
  const clicknutrient = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "NUTRIENT",
      },
    });
  };
  const clickdrainage = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "DRAINAGE",
      },
    });
  };
  const clickFMS = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "FMS",
      },
    });
  };
  const clickFMS02 = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "FMS02",
      },
    });
  };
  const clickcompost = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "COMPOST",
      },
    });
  };
  const clickssh01 = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "SSH",
      },
    });
  };

  const clickats = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "ATS02",
      },
    });
  };
  const clickats03 = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "LAT",
      },
    });
  };
  const clickwaterlevel = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "WATERLEVEL",
      },
    });
  };
  const clickses = () => {
    history.push({
      pathname: "/maps/type",
      state: {
        deviceType: "SES",
      },
    });
  };

  const clickcot = () => {
    history.push({
      pathname: "/maps/cot",
      state: {
        deviceType: "COT",
      },
    });
  };

  const apicall = async () => {
    try {
      const sensorlist = await requestGet(`/api/v1/sensor/list`);
      setTypeList(sensorlist);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    apicall();
  }, []);

  useEffect(() => {
    if (typeList.length > 0) {
      let arr = [];
      for (let i = 0; i < typeList.length; i++) {
        arr.push(
          typeList[i].deviceType === "SMS" || typeList[i].deviceType === "SSH"
            ? "SOIL"
            : typeList[i].deviceType
        );
      }
      let resultArr = [...new Set(arr)];
      setTypeEditList(resultArr);
    }
  }, [typeList]);

  return (
    <>
      {typeEditList?.map((el, i) => {
        if (el === "SOIL") {
          return (
            <tr onClick={clicksoil} className="trstyle" key={i}>
              <td className="tdnoneborder"> {t("SOIL")}</td>
            </tr>
          );
        } else if (el === "CO2") {
          return (
            <tr onClick={clickco2} className="trstyle" key={i}>
              <td className="tdnoneborder">{t("CO2")}</td>
            </tr>
          );
        } else if (el === "AT") {
          return (
            <tr onClick={clickat} className="trstyle" key={i}>
              <td className="tdnoneborder">{t("AT")}</td>
            </tr>
          );
        } else if (el === "WATERFLOW") {
          return (
            <tr onClick={clickwaterflow} className="trstyle" key={i}>
              <td className="tdnoneborder"> {t("WATERFLOW")}</td>
            </tr>
          );
        } else if (el === "NUTRIENT") {
          return (
            <tr onClick={clicknutrient} className="trstyle" key={i}>
              <td className="tdnoneborder">{t("NUTRIENT")}</td>
            </tr>
          );
        } else if (el === "DRAINAGE") {
          return (
            <tr onClick={clickdrainage} className="trstyle" key={i}>
              <td className="tdnoneborder"> {t("DRAINAGE")}</td>
            </tr>
          );
        } else if (el === "FMS") {
          return (
            <tr onClick={clickFMS} className="trstyle" key={i}>
              <td className="tdnoneborder">{t("FMS")}</td>
            </tr>
          );
        } else if (el === "FMS02") {
          return (
            <tr onClick={clickFMS02} className="trstyle" key={i}>
              <td className="tdnoneborder">{t("FMS02")}</td>
            </tr>
          );
        } else if (el === "ATS02") {
          return (
            <tr onClick={clickats} className="trstyle" key={i}>
              <td className="tdnoneborder"> {t("ATS02")}</td>
            </tr>
          );
        } else if (el === "LAT") {
          return (
            <tr onClick={clickats03} className="trstyle" key={i}>
              <td className="tdnoneborder"> {t("LAT")}</td>
            </tr>
          );
        } else if (el === "COMPOST") {
          return (
            <tr onClick={clickcompost} className="trstyle" key={i}>
              <td className="tdnoneborder"> {t("COMPOST")}</td>
            </tr>
          );
        } else if (el === "WATERLEVEL") {
          return (
            <tr onClick={clickwaterlevel} className="trstyle" key={i}>
              <td className="tdnoneborder"> {t("WATERLEVEL")}</td>
            </tr>
          );
        }
        //  else if (el === "SES") {
        //   return (
        //     <tr onClick={clickses} className="trstyle" key={i}>
        //       <td className="tdnoneborder"> {t("SES")}</td>
        //     </tr>
        //   );
        // }
        else if (el === "COT") {
          return (
            <tr onClick={clickcot} className="trstyle" key={i}>
              <td className="tdnoneborder"> {t("COT")}</td>
            </tr>
          );
        }
      })}
    </>
  );
};

export default MapTypelistrow;
