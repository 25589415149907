import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "simplebar/src/simplebar.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import AdminSensorEdit from "./AdminSensorEdit";
import { MetaTags } from "react-meta-tags";

const AdminSensorsList = (props) => {
  const [id] = useState(props.location.state.id);
  const [name] = useState(props.location.state.name);
  const [deviceType] = useState(props.location.state.deviceType);
  const [serial] = useState(props.location.state.serial);
  const [description] = useState(props.location.state.description);
  const [latitude] = useState(props.location.state.latitude);
  const [longitude] = useState(props.location.state.longitude);
  const [imagePath] = useState(props.location.state.imagePath);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    const sensors = async () => {
      setEditData({
        id: id,
        name: name,
        deviceType: deviceType,
        serial: serial,
        description: description,
        latitude: latitude,
        longitude: longitude,
        imagePath: imagePath,
      });
    };
    sensors();
  }, [
    description,
    deviceType,
    id,
    imagePath,
    latitude,
    longitude,
    name,
    serial,
  ]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin Page Sensor Details | FarmCLO</title>
      </MetaTags>
      <div className="accountpageTotalWrap11">
        <div className="accountpage-content">
          <div className="accountWrap">
            <div className="accountPageCardWrap">
              <AdminSensorEdit editData={editData} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminSensorsList;
