import React from "react";
import { withRouter, useHistory } from "react-router-dom";
//i18n
import backImg from "../../assets/images/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds_2021-10-21/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds.png";
import "../Listadd/farmadd.scss";
import { MetaTags } from "react-meta-tags";
const Admin = (props) => {
  const history = useHistory();

  return (
    <React.Fragment>
       <MetaTags>
        <title>Admin Page | FarmCLO</title>
      </MetaTags>
      <img src={backImg} alt="backImg" className="backgroundimg" />
      <div className="dashText">ADMIN PAGE</div>
      <div className="addpage-content">
        <div className="addWrap">
          <div className="addPageCard">
            <div className="addPageTitle">관리자 페이지</div>
            <div className="addPageSubTitle">
              회원정보나 센서를 수정 할 수 있습니다
            </div>
            <button
              className="addPageBtn"
              onClick={() => history.push("/adminpage/users")}
            >
              회원 관리하기
            </button>
            <button
              className="addPageBtn"
              onClick={() => history.push("/adminpage/sensors")}
            >
              센서 관리하기
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Admin);
