import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { requestGet } from "../../apis/request";
import "./maps.scss";
import { useGeoLocation } from "./useGeoLocation";

function GoogleSensorMap({ selects }) {
  const [mapCenter, setMapCenter] = useState({
    lat: 37.3614483,
    lng: 127.1114883,
  });
  const geolocationOptions = {
    enableHighAccuracy: true,
  };
  const [marker, setMarker] = useState(null);
  const { location, error } = useGeoLocation(geolocationOptions);
  const myStyles = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ];

  useEffect(() => {
    if (location) {
      setMapCenter({
        lat: location.latitude,
        lng: location.longitude,
      });
    }
  }, [location]);

  useEffect(() => {
    if (selects === undefined || selects.length === 0) {
      return;
    }
    const asmap = async () => {
      const device = await requestGet(`/api/v1/sensor/read/${selects}`);
      if (device.latitude === null || device.longitude === null) {
        return;
      }
      if (device.latitude !== null && device.longitude !== null) {
        return (
          setMapCenter({
            lat: device.latitude,
            lng: device.longitude,
          }),
          setMarker({
            lat: device.latitude,
            lng: device.longitude,
          })
        );
      }
    };
    asmap();
  }, [selects]);

  const handleRightClick = (e) => {
    const newMarker = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setMarker(newMarker);
  };

  return (
    <Wrapper className="mapwrap">
      <LoadScriptNext
        googleMapsApiKey={`AIzaSyCEQYNmgWlfSpVbpv2F1ccTOxto8wU6hps`}
      >
        <GoogleMap
          zoom={marker ? 18 : 8}
          center={mapCenter}
          mapContainerClassName="map-container"
          options={{ styles: myStyles }}
          onRightClick={handleRightClick}
        >
          {marker ? <Marker position={marker} /> : null}
        </GoogleMap>
      </LoadScriptNext>
    </Wrapper>
  );
}
export default GoogleSensorMap;

const Wrapper = styled.div`
  .map-container {
    width: 100%;
    height: 100%;
  }
`;
