import React, { useEffect, useState } from "react";
import { NaverMap, Marker } from "react-naver-maps";
import { RenderAfterNavermapsLoaded } from "react-naver-maps";
import { requestGet } from "../../apis/request";

function Maps({ markers, latitude, longitude, setLatitude, setLongitude }) {
  const [dumMapCenter, setDumMapCenter] = useState({
    lat: 37.3614483,
    lng: 127.1114883,
  });
  const [mapCenter, setMapCenter] = useState({
    lat: null,
    lng: null,
  });
  const [markCenter, setMarkCenter] = useState({
    lat: null,
    lng: null,
  });
  const [mark, setMark] = useState(false);
  setTimeout(function () {
    window.dispatchEvent(new Event("resize"));
  }, 600);

  // useEffect(() => {
  //   if (selects === undefined || selects.length === 0) {
  //     return;
  //   }
  //   const asmap = async () => {
  //     const device = await requestGet(`/api/v1/sensor/read/${selects}`);
  //     if (device.latitude === null || device.longitude === null) {
  //       return;
  //     }
  //     if (device.latitude !== null && device.longitude !== null) {
  //       return (
  //         setMapCenter({
  //           lat: device.latitude,
  //           lng: device.longitude,
  //         }),
  //         setMarkCenter({
  //           lat: device.latitude,
  //           lng: device.longitude,
  //         }),
  //         setMark(true)
  //       );
  //     }
  //   };
  //   asmap();
  // }, [selects]);

  return (
    <React.Fragment>
      <RenderAfterNavermapsLoaded // Render후 Navermap로드
        ncpClientId={process.env.REACT_APP_APIKEY} // 자신의 네이버 계정에서 발급받은 Client ID
        error={<p>Maps Load Error</p>}
        loading={<p>Maps Loading...</p>}
      >
        <NaverMap
          mapDivId={"maps-getting-started-uncontrolled"}
          style={{ width: "100%", height: "86vh" }}
          center={
            mapCenter.lat === null || mapCenter.lng === null
              ? dumMapCenter
              : mapCenter
          }
          // onCenterChanged={(center) => setLet(center.lat)}
          zoom={mapCenter.lat === null || mapCenter.lng === null ? 5 : 15}
        >
          {/* {mark ? <Marker position={markCenter} /> : <></>} */}
          {markers?.map((marker) => (
            <Marker
              key={marker.id}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          ))}
        </NaverMap>
      </RenderAfterNavermapsLoaded>
    </React.Fragment>
  );
}

export default Maps;
