import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as Database } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-database.svg";
import { ReactComponent as Thermometer } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-thermometer-half.svg";
import { ReactComponent as Raindrops } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { ReactComponent as Sun } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-sun.svg";
import { ReactComponent as Drainagetemperature } from "../../assets/icons/drainagetemperature.svg";
import { ReactComponent as Co2 } from "../../assets/icons/co2.svg";
import { ReactComponent as Drainagewaterflow } from "../../assets/icons/drainagewaterflow.svg";
import { ReactComponent as Fullwifi } from "../../assets/icons/fullwifi.svg";
import { ReactComponent as Nutrienttemperature } from "../../assets/icons/nutrienttemperature.svg";
import { ReactComponent as Phtemperature } from "../../assets/icons/phtemperature.svg";
import { ReactComponent as Temperature } from "../../assets/icons/temperature.svg";
import { ReactComponent as Speed } from "../../assets/icons/speed.svg";
import { ReactComponent as Flow } from "../../assets/icons/flow.svg";
import { ReactComponent as WindD } from "../../assets/icons/winD.svg";
import { ReactComponent as WindS } from "../../assets/icons/winS.svg";
import { ReactComponent as Rainhum } from "../../assets/icons/rainhum.svg";
import { ReactComponent as Hpa } from "../../assets/icons/ph.svg";
import { ReactComponent as Acc } from "../../assets/icons/acceleration.svg";
import { ReactComponent as Gyro } from "../../assets/icons/gyro.svg";
import Battery from "../../assets/icons/001-empty-battery_2021-11-05/001-empty-battery.png";
import { ReactComponent as Energy } from "../../assets/icons/circle-bolt.svg";
import Pump from "../../assets/icons/pumpicon.png";
import Temp from "../../assets/icons/temp.png";
import Hum from "../../assets/icons/hum.png";
import DeviceHum from "../../assets/icons/devicehum.png";
import "./sensorCard.scss";

const SensorCard = (props) => {
  const [latestLog, setLatestLog] = useState([]);
  const { t } = useTranslation("main");

  useEffect(() => {
    if (props.datas.latestLog !== null) {
      let arr = [];
      Object.entries(props.datas.latestLog).map((entrie) => {
        return arr.unshift(entrie);
      });
      setLatestLog(arr);
    }
  }, []);

  return (
    <React.Fragment>
      <Row className="w-100 mx-0">
        <Card className="sonsorCardWrap flex-row">
          <Col className="d-flex flex-column justify-content-center align-items-center cardBorder">
            <span
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                width: "max-content",
              }}
            >
              {t(`${props.datas?.deviceType}`)}.
            </span>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                width: "max-content",
              }}
            >
              {props.datas?.name}
            </span>
            <span
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                width: "max-content",
              }}
            >
              {props.datas?.serial}
            </span>
          </Col>
          <Col className="d-flex justify-content-center align-items-center cardDataDiv">
            {latestLog
              ? latestLog.map((item, i) => {
                  if (
                    // item[0] !== "ec" &&
                    item[0] !== "sensorId" &&
                    item[0] !== "fwversion" &&
                    item[0] !== "rssi" &&
                    item[0] !== "createdDate" &&
                    item[0] !== "modifiedDate" &&
                    item[0] !== "svp" &&
                    !(
                      props.datas?.deviceType === "FMS" &&
                      props.datas?.deviceType === "FMS02" &&
                      item[0] === "t"
                    ) &&
                    !(
                      props.datas?.deviceType !== "FMS" &&
                      props.datas?.deviceType !== "FMS02" &&
                      props.datas?.deviceType !== "NUTRIENT" &&
                      item[0] === "ec"
                    )
                  ) {
                    return (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center sensorCardsMargin"
                        key={i}
                      >
                        {item[0] === "temp" ||
                        item[0] === "soilTemperature" ||
                        item[0] === "temperature" ? (
                          <Thermometer className="widgetSensorListIcon" />
                        ) : item[0] === "mois" ||
                          item[0] === "moisture" ||
                          item[0] === "humidity" ? (
                          <Raindrops className="widgetSensorListIcon" />
                        ) : item[0] === "battery" ? (
                          <i
                            class="fas fa-battery-half"
                            style={{ fontSize: "22px" }}
                          ></i>
                        ) : item[0] === "windD" ? (
                          <WindD className="widgetSensorListIcon" />
                        ) : item[0] === "windS" ? (
                          <WindS className="widgetSensorListIcon" />
                        ) : item[0] === "gyro" ? (
                          <Gyro className="widgetSensorListIcon" />
                        ) : item[0] === "acceleration" ? (
                          <Acc className="widgetSensorListIcon" />
                        ) : item[0] === "rain" ? (
                          <Rainhum className="widgetSensorListIcon" />
                        ) : item[0] === "illuminance" ||
                          item[0] === "solar" ||
                          item[0] === "lux" ? (
                          <Sun className="widgetSensorListIcon" />
                        ) : item[0] === "temperature" ? (
                          <Drainagetemperature className="widgetSensorListIcon" />
                        ) : item[0] === "pressure" ? (
                          <Hpa className="widgetSensorListIcon" />
                        ) : item[0] === "co2" ? (
                          <Co2 className="widgetSensorListIcon" />
                        ) : item[0] === "vpd" ? (
                          <span>VPD </span>
                        ) : item[0] === "td" ? (
                          <span>TD </span>
                        ) : item[0] === "flowmeter" || item[0] === "l" ? (
                          <Flow className="widgetSensorListIcon" />
                        ) : item[0] === "speed" ? (
                          <Speed className="widgetSensorListIcon" />
                        ) : item[0] === "pumpopencount" ? (
                          <img
                            src={Pump}
                            alt="Pump"
                            className="widgetSensorListIcon"
                          />
                        ) : item[0] === "ph" ? (
                          <Phtemperature className="widgetSensorListIcon" />
                        ) : item[0] === "waterlevel" ? (
                          <Drainagewaterflow className="widgetSensorListIcon" />
                        ) : item[0] === "t15" ? (
                          <span
                            className="widgetSensorListIcon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Thermometer className="widgetSensorListIcon" />
                            15
                          </span>
                        ) : item[0] === "t30" ? (
                          <span
                            className="widgetSensorListIcon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Thermometer className="widgetSensorListIcon" />
                            30
                          </span>
                        ) : item[0] === "m15" ? (
                          <span
                            className="widgetSensorListIcon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Raindrops className="widgetSensorListIcon" />
                            15
                          </span>
                        ) : item[0] === "m30" ? (
                          <span
                            className="widgetSensorListIcon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Raindrops className="widgetSensorListIcon" />
                            30
                          </span>
                        ) : item[0] === "rh" ? (
                          <span
                            className="widgetSensorListIcon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={DeviceHum}
                              alt="DeviceHum"
                              className="widgetSensorListIcon"
                            />
                          </span>
                        ) : item[0] === "ec" ? (
                          <span
                            className="widgetSensorListIcon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Energy className="widgetSensorListIcon" />
                          </span>
                        ) : item[0] === "gdo" ? (
                          <span
                            className="widgetSensorListIcon"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "21px",
                              fontWeight: "800",
                            }}
                          >
                            <span>DO</span>
                          </span>
                        ) : null}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {item[0] === "co2" ? "CO2 (ppm)" : t(`${item[0]}`)}
                        </span>
                        <div>
                          <span
                            style={{
                              fontSize: "21px",
                              fontWeight: "800",
                            }}
                          >
                            {item[0] === "vpd" || item[0] === "td"
                              ? item[1].toFixed(1)
                              : item[1]}
                          </span>
                        </div>
                      </div>
                    );
                  }
                })
              : null}
          </Col>
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default SensorCard;
