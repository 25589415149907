import React, { useEffect, useState } from "react";
import { Col, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Button, FormGroup, Label, ButtonGroup } from "reactstrap";
import { adminRequestPost, adminUserPut, requestGet } from "../../apis/request";
import { useTranslation } from "react-i18next";
import "simplebar/dist/simplebar.min.css";
import moment from "moment";
import Switch from "@mui/material/Switch";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./sensorrow.scss";
import { ReactComponent as Raindrops } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FormControlLabel, TextField } from "@mui/material";
const COTsensorWrap = (props) => {
  const [selects, setSelects] = useState([]);
  const [nowData, setNowData] = useState({});
  const [autoWater, setAutoWater] = useState("");
  const [autoLowMoi, setAutoLowMoi] = useState("");
  const [autoHighMoi, setAutoHighMoi] = useState("");
  const [reWater, setReWater] = useState("");
  const [chnum, setchnum] = useState(0);
  const [chOnOff, setChOnOff] = useState("");
  const [reOnOff, setReOnOff] = useState(false);
  const [perWater, setPerWater] = useState("");
  const [perMinute, setPerMinute] = useState("");
  const [perOnOff, setPerOnOff] = useState(false);
  const [value, setValue] = useState(dayjs());
  const [trigger, setTrigger] = useState(false);
  const [triggerData, setTriggerData] = useState({});
  const [reservation, setReservation] = useState(false);
  const [reservationData, setReservationData] = useState({});
  const [period, setPeriod] = useState(false);
  const [periodData, setPeriodData] = useState({});
  const [viewOpen, setViewOpen] = useState(0);

  const history = useHistory();
  const { t } = useTranslation("main");
  // console.log(dayjs("2024-05-02 15:30"));

  const getTrigger = async () => {
    try {
      const response = await requestGet(
        `/api/v1/controller/channel/${props.nowCh}/trigger`
      );
      if (response) {
        setTriggerData(response);
        setTrigger(true);
        setNowData((prevData) => ({
          ...prevData,
          lowerBound: response?.lowerBound,
          upperBound: response?.upperBound,
          wateringDuration: response?.wateringDuration,
        }));
        setAutoHighMoi(
          response?.upperBound ? String(response?.upperBound) : ""
        );
        setAutoLowMoi(response?.lowerBound ? String(response?.lowerBound) : "");
        setAutoWater(
          response?.wateringDuration ? String(response.wateringDuration) : ""
        );
      } else {
        setTrigger(false);
        setNowData((prevData) => ({
          ...prevData,
          lowerBound: "",
          upperBound: "",
          wateringDuration: "",
        }));
        setAutoHighMoi("");
        setAutoLowMoi("");
        setAutoWater("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReservation = async () => {
    try {
      const currentDate = dayjs().format("YYYY-MM-DD");
      const response = await requestGet(
        `/api/v1/controller/channel/${props.nowCh}/reservation`
      );
      if (response) {
        setReservationData(response);
        setReservation(true);
        setNowData((prevData) => ({
          ...prevData,
          reservationWatering: response?.wateringDuration,
          reservationRepeat: response?.repeat === true ? 1 : 2,
          reservationTime: response?.onTime
            ? dayjs(`${currentDate}T${response?.onTime}`).format("HH:mm")
            : "--",
        }));
        setValue(
          response?.onTime
            ? dayjs(`${currentDate}T${response?.onTime}`)
            : dayjs()
        );
        setReWater(response?.wateringDuration);
        setReOnOff(response?.repeat);
      } else {
        setReservation(false);
        setNowData((prevData) => ({
          ...prevData,
          reservationWatering: "",
          reservationRepeat: "",
          reservationTime: "",
        }));
        setReWater("");
        setValue(dayjs());
        setReOnOff(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPeriod = async () => {
    try {
      const response = await requestGet(
        `/api/v1/controller/channel/${props.nowCh}/period`
      );
      if (response) {
        setPeriodData(response);
        setPeriod(true);
        setNowData((prevData) => ({
          ...prevData,
          periodRepeat: response.repeat === true ? 1 : 2,
          periodTime: response.wateringInterval,
          periodWatering: response.wateringDuration,
        }));
        setPerMinute(
          response.wateringInterval ? String(response.wateringInterval) : ""
        );
        setPerWater(
          response.wateringDuration ? String(response.wateringDuration) : ""
        );
        setPerOnOff(response.repeat);
      } else {
        setPeriod(false);
        setNowData((prevData) => ({
          ...prevData,
          periodRepeat: "",
          periodTime: "",
          periodWatering: "",
        }));
        setPerMinute("");
        setPerWater("");
        setPerOnOff(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetAll = async () => {
    await setTrigger(false);
    await setReservation(false);
    await setPeriod(false);
    await setNowData({
      automatic: "",
      upperBound: "",
      lowerBound: "",
      wateringDuration: "",
      onoff: "",
      period: "",
      periodRepeat: "",
      periodTime: "",
      periodWatering: "",
      reservation: "",
      reservationRepeat: "",
      reservationTime: "",
      reservationWatering: "",
      serial: "",
    });
    await setAutoHighMoi("");
    await setAutoLowMoi("");
    await setAutoWater("");
    await setReWater("");
    await setValue(dayjs());
    await setReOnOff(false);
    await setPerMinute("");
    await setPerWater("");
    await setPerOnOff(false);
    setTimeout(() => {
      getTrigger();
      getReservation();
      getPeriod();
    }, 500);
  };

  useEffect(() => {
    if (props.nowCh) {
      resetAll();
    }
  }, [props.nowCh]);

  useEffect(() => {
    if (props?.dataListId !== undefined) {
      setSelects([props?.dataListId]);
    }
  }, [props?.dataListId]);

  const callChOnOff = async (ch) => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "onoff",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: Number(ch),
      };
      await adminRequestPost(`/api/v1/controller/cmd`, body);
      // setTimeout(() => {
      //   props?.callMqttsensors();
      // }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const onAutoTrigger = async () => {
    try {
      let body = {};
      if (autoWater) {
        body.wateringDuration = autoWater;
      } else if (!autoWater) {
        return alert("물주는 시간은 필수 입력입니다.");
      }
      if (autoHighMoi) {
        body.upperBound = autoHighMoi;
      }
      if (autoLowMoi) {
        body.lowerBound = autoLowMoi;
      }
      if (!autoHighMoi && !autoLowMoi) {
        return alert(
          "최소수분량 혹은 최대수분량 중에 하나는 필수 입력입니다.."
        );
      }
      if (!trigger) {
        await adminRequestPost(
          `/api/v1/controller/channel/${props.nowCh}/trigger`,
          body
        );
      } else {
        await adminUserPut(
          `/api/v1/controller/channel/${props.nowCh}/trigger/${triggerData.id}`,
          body
        );
      }
      alert("설정 완료 하였습니다.");
      getTrigger();
    } catch (error) {
      alert("설정 실패 하였습니다.");
      console.log(error);
    }
  };

  const onReTrigger = async () => {
    try {
      let body = {};
      if (reWater) {
        body.offDelayMinutes = reWater;
      } else if (!reWater) {
        return alert("물주는 시간은 필수 입력입니다.");
      }
      if (value.$d) {
        body.onTime = moment(value.$d).format("HH:mm");
      }
      body.repeat = reOnOff;
      if (!reservation) {
        await adminRequestPost(
          `/api/v1/controller/channel/${props.nowCh}/reservation`,
          body
        );
      } else {
        await adminUserPut(
          `/api/v1/controller/channel/${props.nowCh}/reservation/${reservationData.id}`,
          body
        );
      }
      alert("설정 완료 하였습니다.");
      getReservation();
    } catch (error) {
      alert("설정 실패 하였습니다.");
      console.log(error);
    }
  };

  const onPerTrigger = async () => {
    try {
      let body = {};
      if (perWater) {
        body.wateringDuration = perWater;
      } else if (!perWater) {
        return alert("물주는 시간은 필수 입력입니다.");
      }
      if (perMinute) {
        body.wateringInterval = perMinute;
      }
      if (perOnOff) {
        body.repeat = perOnOff;
      }
      if (!period) {
        await adminRequestPost(
          `/api/v1/controller/channel/${props.nowCh}/period`,
          body
        );
      } else {
        await adminUserPut(
          `/api/v1/controller/channel/${props.nowCh}/period/${periodData.id}`,
          body
        );
      }
      alert("설정 완료 하였습니다.");
      getPeriod();
    } catch (error) {
      alert("설정 실패 하였습니다.");
      console.log(error);
    }
  };

  const onAutoReset = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "automatic/reset",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: "1",
      };
      await adminRequestPost(`/api/v1/controller/cmd`, body);
      setAutoHighMoi("");
      setAutoLowMoi("");
      setAutoWater("");
    } catch (error) {
      console.log(error);
    }
  };

  const onReReset = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "reservation/reset",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: "1",
      };
      await adminRequestPost(`/api/v1/controller/cmd`, body);
      setReWater("");
      setValue(dayjs());
      setReOnOff(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onPerReset = async () => {
    try {
      let body = {
        uuid: `${props?.dataListId}`,
        command: "period/reset",
        deviceType: "controller",
        channel: `${props?.ch[props.dataListId]?.ch}`,
        payload: "1",
      };
      await adminRequestPost(`/api/v1/controller/cmd`, body);
      setPerWater("");
      setPerMinute("");
      setPerOnOff(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReOnOff = (e) => {
    setReOnOff(!reOnOff);
  };
  const handlePerOnOff = (e) => {
    setPerOnOff(!perOnOff);
  };

  useEffect(() => {
    if (props.data) {
      setNowData({
        automatic: props.data?.automatic,
        upperBound: props.data?.automaticHighMoisture,
        lowerBound: props.data?.automaticLowMoisture,
        wateringDuration: props.data?.automaticWatering,
        onoff: props.data?.onoff,
        period: props.data?.period,
        periodRepeat: props.data?.periodRepeat,
        periodTime: props.data?.periodTime,
        periodWatering: props.data?.periodWatering,
        reservation: props.data?.reservation,
        reservationRepeat: props.data?.reservationRepeat,
        reservationTime: props.data?.reservationTime,
        reservationWatering: props.data?.reservationWatering,
        serial: props.data?.serial,
      });
      if (props?.data?.reservationTime) {
        const [hour, minute] = props?.data?.reservationTime
          ?.split(":")
          ?.map(Number);
        setValue(dayjs().hour(hour).minute(minute));
      }
      setChOnOff(String(props.data.onoff));
      setAutoHighMoi(
        props.data?.automaticHighMoisture
          ? String(props.data?.automaticHighMoisture)
          : ""
      );
      setAutoLowMoi(
        props.data?.automaticLowMoisture
          ? String(props.data?.automaticLowMoisture)
          : ""
      );
      setAutoWater(
        props.data?.automaticWatering
          ? String(props.data?.automaticWatering)
          : ""
      );
      setReWater(
        props.data?.reservationWatering
          ? String(props.data?.reservationWatering)
          : ""
      );
      setReOnOff(props.data?.reservationRepeat === 1 ? true : false);
      setPerWater(
        props.data?.periodWatering ? String(props.data?.periodWatering) : ""
      );
      setPerMinute(
        props.data?.periodTime ? String(props.data?.periodTime) : ""
      );
      setPerOnOff(props.data?.periodRepeat === 1 ? true : false);
      if (props.data.automatic === 1) {
        setViewOpen(1);
      }
      if (props.data.period === 1) {
        setViewOpen(2);
      }
      if (props.data.reservation === 1) {
        setViewOpen(3);
      }
    }
  }, [props.data]);

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col xs="auto">
            <ToggleButtonGroup
              type="checkbox"
              value={props?.ch[props.dataListId]?.ch}
              className="chbtnWrap"
            >
              {props?.chLength[props.dataListId]?.chleng?.map((el, i) => {
                return (
                  <ToggleButton
                    id={`tbg-btn-${el}`}
                    value={`${el}`}
                    key={i}
                    onClick={() => {
                      setchnum(Number(i));
                      props.setCh((prevState) => ({
                        ...prevState,
                        [`${props.dataListId}`]: {
                          ...prevState.props?.dataListId,
                          ch: `${el}`,
                        },
                      }));
                    }}
                  >
                    {`${el} CH`}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <div
              className="bbadge"
              style={{
                marginRight: "20px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Raindrops className="sensorsListIcon" />
              {!props.nowMoi ? "--" : props.nowMoi === 0 ? "00" : props.nowMoi}%
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs="auto"
            className="d-flex align-items-center"
            style={{ marginTop: "15px" }}
          >
            <ButtonGroup type="checkbox" value={chOnOff}>
              <Button
                id="noClor"
                value="1"
                style={{ marginBottom: "0px" }}
                onClick={() => callChOnOff("1")}
              >
                On
              </Button>
              <Button
                id="noClor"
                value="2"
                style={{ marginBottom: "0px" }}
                onClick={() => callChOnOff("2")}
              >
                Off
              </Button>
            </ButtonGroup>
            <Label
              style={{
                color: "#000",
                fontSize: "18px",
                fontWeight: "bold",
                marginLeft: "20px",
                marginBottom: "0px",
              }}
            >
              {t("current")} :{" "}
              {nowData?.onoff === 1
                ? "ON"
                : nowData?.onoff === 2
                ? "OFF"
                : "--"}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col
            xs="12"
            className="controlSensorNameWrap"
            style={{ marginTop: "48px", display: "flex", alignItems: "center" }}
          >
            <span className="controlSensorName">Automatic</span>
            <button
              className="controlSensorResetBtn"
              onClick={() => onAutoReset()}
            >
              reset
            </button>
            <input
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              checked={viewOpen === 1}
              value={1}
              onClick={(e) => {
                e.stopPropagation();
                setViewOpen(viewOpen !== 1 ? 1 : 0);
              }}
            ></input>
          </Col>
          {viewOpen === 1 ? (
            <>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("watering")}{" "}
                    {nowData?.wateringDuration ||
                    nowData?.wateringDuration === 0
                      ? nowData.wateringDuration
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Watering Value"
                      value={autoWater}
                      className="controlSensorTextInput"
                      onChange={(e) => setAutoWater(e.target.value)}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("lowmoi")}{" "}
                    {nowData?.lowerBound || nowData?.lowerBound === 0
                      ? nowData.lowerBound
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Low Moisture Value"
                      value={autoLowMoi}
                      className="controlSensorTextInput"
                      onChange={(e) => setAutoLowMoi(e.target.value)}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("highmoi")}{" "}
                    {nowData?.upperBound || nowData?.upperBound === 0
                      ? nowData.upperBound
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="HIGH Moisture Value"
                      value={autoHighMoi}
                      className="controlSensorTextInput"
                      onChange={(e) => setAutoHighMoi(e.target.value)}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                      visibility: "hidden",
                    }}
                  >
                    {"d"}
                  </Label>
                  <div>
                    <button
                      onClick={() => onAutoTrigger()}
                      className="controlSensorTextInputBtn m-0"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>
        <Row>
          <Col
            xs="12"
            className="controlSensorNameWrap"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="controlSensorName">Reservation</span>
            <button
              className="controlSensorResetBtn"
              onClick={() => onReReset()}
            >
              reset
            </button>
            <input
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              checked={viewOpen === 2}
              value={2}
              onClick={(e) => {
                e.stopPropagation();
                setViewOpen(viewOpen !== 2 ? 2 : 0);
              }}
            ></input>
          </Col>
          {viewOpen === 2 ? (
            <>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("watering")}{" "}
                    {nowData?.reservationWatering ||
                    nowData?.reservationWatering === 0
                      ? nowData.reservationWatering
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Watering Value"
                      value={reWater}
                      className="controlSensorTextInput"
                      onChange={(e) => setReWater(e.target.value)}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("currenttime")}{" "}
                    {(nowData?.reservationTime ||
                      nowData?.reservationTime === 0) &&
                    nowData?.reservationTime !== "string"
                      ? nowData.reservationTime
                      : "--"}
                  </Label>
                  <div className="d-flex">
                    <div className="timepickerWrap">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          value={value}
                          onChange={setValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              style={{
                                height: "33.5px",
                                display: "flex",
                                justifyContent: "center",
                                paddingLeft: "10px",
                                maxWidth: "197px",
                              }}
                              className="controlSensorTextInput"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("repeat")}{" "}
                    {nowData?.reservationRepeat === 2
                      ? "OFF"
                      : nowData.reservationRepeat === 1
                      ? "ON"
                      : "--"}
                  </Label>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={reOnOff}
                          onClick={handleReOnOff}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Repeat"
                      labelPlacement="start"
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                      visibility: "hidden",
                    }}
                  >
                    {"d"}
                  </Label>
                  <div>
                    <button
                      onClick={() => onReTrigger()}
                      className="controlSensorTextInputBtn m-0"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col
            xs="12"
            className="controlSensorNameWrap"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="controlSensorName">Period</span>
            <button
              className="controlSensorResetBtn"
              onClick={(e) => onPerReset()}
            >
              reset
            </button>
            <input
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              checked={viewOpen === 3}
              value={3}
              onClick={(e) => {
                e.stopPropagation();
                setViewOpen(viewOpen !== 3 ? 3 : 0);
              }}
            ></input>
          </Col>
          {viewOpen === 3 ? (
            <>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("watering")}{" "}
                    {nowData?.periodWatering || nowData?.periodWatering === 0
                      ? nowData.periodWatering
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Watering Value"
                      value={perWater}
                      className="controlSensorTextInput"
                      onChange={(e) => setPerWater(e.target.value)}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("pertime")}{" "}
                    {(nowData?.periodTime || nowData?.periodTime === 0) &&
                    nowData?.periodTime !== "string"
                      ? nowData.periodTime
                      : "--"}
                  </Label>
                  <div>
                    <input
                      type="text"
                      placeholder="Time Minute Value"
                      value={perMinute}
                      className="controlSensorTextInput"
                      onChange={(e) => setPerMinute(e.target.value)}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("repeat")}{" "}
                    {nowData?.periodRepeat === 2
                      ? "OFF"
                      : nowData.periodRepeat === 1
                      ? "ON"
                      : "--"}
                  </Label>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch checked={perOnOff} onClick={handlePerOnOff} />
                      }
                      label="Repeat"
                      labelPlacement="start"
                      style={{ marginBottom: "0px" }}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <FormGroup>
                  <Label
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "bold",
                      visibility: "hidden",
                    }}
                  >
                    {"d"}
                  </Label>
                  <div>
                    <button
                      onClick={() => onPerTrigger()}
                      className="controlSensorTextInputBtn m-0"
                    >
                      {t("setting")}
                    </button>
                  </div>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default COTsensorWrap;
