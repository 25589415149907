import { Card, CardBody, Table } from "reactstrap";
import SimpleBarReact from "simplebar-react";
import Typelistrow from "./Typelistrow";
import { useTranslation } from "react-i18next";
import "simplebar/src/simplebar.css";
const Typelist = () => {
  const { t } = useTranslation("main");

  return (
    <Card className="m-0 irrigationcard">
      <span className="irrigationtitle">{t("sensortext")}</span>
      <div style={{ height: "100%", overflow: "hidden" }}>
        <SimpleBarReact style={{ maxHeight: "100%" }}>
          <CardBody className="cardBodyWrap">
            <div className="table-responsive">
              <Table className="table mb-0">
                <tbody className="tbodystyle">
                  <Typelistrow />
                </tbody>
              </Table>
            </div>
          </CardBody>
        </SimpleBarReact>
      </div>
    </Card>
  );
};

export default Typelist;
