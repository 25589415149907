import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { requestGet } from "../../../apis/request";
import moment from "moment";
import "moment/locale/ko";
import excel from "../../../assets/icons/xls-file.svg";
import Pencil from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-pencil.svg";
import Hpa from "../../../assets/icons/ph.svg";
import Thermometer from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-thermometer-half.svg";
import Sun from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-sun.svg";
import Raindrops from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import Co2 from "../../../assets/icons/co2.svg";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../Dashboard/LoadingScreen";

const ATSplineArea = ({
  setMenu,
  userDevice,
  apistartdate,
  apienddate,
  min,
  max,
  hpamin,
  hpamax,
  tempmin,
  tempmax,
  vpdmin,
  vpdmax,
  sunmin,
  sunmax,
  hummin,
  hummax,
  co2min,
  co2max,
  tdmin,
  tdmax,
  setTableData,
  typeKey,
}) => {
  const [respon, setRespon] = useState([]);
  const [series, setSeries] = useState([]);
  const [inMin, setInMin] = useState();
  const [inMax, setInMax] = useState();
  const [hpaInMin, setHpaInMin] = useState();
  const [hpaInMax, setHpaInMax] = useState();
  const [tempInMin, setTempInMin] = useState();
  const [tempInMax, setTempInMax] = useState();
  const [vpdInMin, setVpdInMin] = useState();
  const [vpdInMax, setVpdInMax] = useState();
  const [sunInMin, setSunInMin] = useState();
  const [sunInMax, setSunInMax] = useState();
  const [humInMin, setHumInMin] = useState();
  const [humInMax, setHumInMax] = useState();
  const [co2InMin, setCo2InMin] = useState();
  const [co2InMax, setCo2InMax] = useState();
  const [tdInMin, setTdInMin] = useState();
  const [tdInMax, setTdInMax] = useState();
  const [highName, setHighName] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("main");

  const sensors = [
    "co2",
    "humidity",
    "pressure",
    "solar",
    "temperature",
    "vpd",
    "td",
  ];

  const callapi = async () => {
    try {
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/${userDevice[0].id}/${apistartdate}/${apienddate}`
      );
      setRespon(response);
      setTableData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setRespon([]);
      setTableData({});
      setLoading(false);
    }
  };

  useEffect(() => {
    callapi();
  }, [apistartdate, apienddate, userDevice]);

  const getLog = async (sens) => {
    if (respon.length > 0) {
      return {
        name: t(sens === "co2" ? "co2eng" : sens),
        data: respon.map((sensorItem, j) => {
          return [
            new Date(sensorItem.createdDate.replace(/-/g, "/")).getTime(),
            sensorItem[sens]?.toFixed(1),
          ];
        }),
      };
    } else {
      return {
        name: t(sens === "co2" ? "co2eng" : sens),
        data: [],
      };
    }
  };

  const callChartApi = async () => {
    if (
      Array.isArray(userDevice) &&
      userDevice.length > 0 &&
      respon.length > 0
    ) {
      const arr = [];
      if (userDevice.length > 0) {
        for (let value of sensors) {
          const result = await getLog(value);
          arr.push(result);
        }

        arr.reduce((indices, series, currentIndex) => {
          const maxCurrentValue = Math.max(
            ...series.data.map((data) => data[1])
          );
          const maxPrevValue =
            indices[0] !== undefined
              ? Math.max(...arr[indices[0]].data.map((data) => data[1]))
              : -Infinity;

          if (maxCurrentValue > maxPrevValue) {
            indices.push(currentIndex);
            if (series.name !== t("pressure") && series.name !== t("solar")) {
              setHighName(series.name);
            }
          }
          return indices;
        }, []);
        setSeries(arr);
      }
    } else {
      setSeries([]);
    }
  };

  useEffect(() => {
    callChartApi();
  }, [userDevice, respon]);

  useEffect(() => {
    if (!min && !max) {
      return;
    } else {
      if (parseInt(min, 10) >= 0) {
        setInMin(parseInt(min, 10));
      } else if (parseInt(min, 10) <= 0) {
        setInMin(parseInt(min, 10));
      } else {
        setInMin(0);
      }
      if (parseInt(max, 10) >= 0) {
        setInMax(parseInt(max, 10));
      } else if (parseInt(max, 10) <= 0) {
        setInMax(parseInt(max, 10));
      } else {
        setInMax(0);
      }
    }
  }, [min, max]);

  useEffect(() => {
    if (!hpamin && !hpamax) {
      setHpaInMin(undefined);
      setHpaInMax(undefined);
    } else {
      if (parseInt(hpamin, 10) >= 0) {
        setHpaInMin(parseInt(hpamin, 10));
      } else if (parseInt(hpamin, 10) <= 0) {
        setHpaInMin(parseInt(hpamin, 10));
      } else {
        setHpaInMin(0);
      }
      if (parseInt(hpamax, 10) >= 0) {
        setHpaInMax(parseInt(hpamax, 10));
      } else if (parseInt(hpamax, 10) <= 0) {
        setHpaInMax(parseInt(hpamax, 10));
      } else {
        setHpaInMax(0);
      }
    }
  }, [hpamin, hpamax]);

  useEffect(() => {
    if (!tempmin && !tempmax) {
      setTempInMin(undefined);
      setTempInMax(undefined);
    } else {
      if (parseInt(tempmin, 10) >= 0) {
        setTempInMin(parseInt(tempmin, 10));
      } else if (parseInt(tempmin, 10) <= 0) {
        setTempInMin(parseInt(tempmin, 10));
      } else {
        setTempInMin(0);
      }
      if (parseInt(tempmax, 10) >= 0) {
        setTempInMax(parseInt(tempmax, 10));
      } else if (parseInt(tempmax, 10) <= 0) {
        setTempInMax(parseInt(tempmax, 10));
      } else {
        setTempInMax(0);
      }
    }
  }, [tempmin, tempmax]);

  useEffect(() => {
    if (!vpdmin && !vpdmax) {
      setVpdInMin(undefined);
      setVpdInMax(undefined);
    } else {
      if (parseInt(vpdmin, 10) >= 0) {
        setVpdInMin(parseInt(vpdmin, 10));
      } else if (parseInt(vpdmin, 10) <= 0) {
        setVpdInMin(parseInt(vpdmin, 10));
      } else {
        setVpdInMin(0);
      }
      if (parseInt(vpdmax, 10) >= 0) {
        setVpdInMax(parseInt(vpdmax, 10));
      } else if (parseInt(vpdmax, 10) <= 0) {
        setVpdInMax(parseInt(vpdmax, 10));
      } else {
        setVpdInMax(0);
      }
    }
  }, [vpdmin, vpdmax]);

  useEffect(() => {
    if (!sunmin && !sunmax) {
      setSunInMin(undefined);
      setSunInMax(undefined);
    } else {
      if (parseInt(sunmin, 10) >= 0) {
        setSunInMin(parseInt(sunmin, 10));
      } else if (parseInt(sunmin, 10) <= 0) {
        setSunInMin(parseInt(sunmin, 10));
      } else {
        setSunInMin(0);
      }
      if (parseInt(sunmax, 10) >= 0) {
        setSunInMax(parseInt(sunmax, 10));
      } else if (parseInt(sunmax, 10) <= 0) {
        setSunInMax(parseInt(sunmax, 10));
      } else {
        setSunInMax(0);
      }
    }
  }, [sunmin, sunmax]);

  useEffect(() => {
    if (!hummin && !hummax) {
      setHumInMin(undefined);
      setHumInMax(undefined);
    } else {
      if (parseInt(hummin, 10) >= 0) {
        setHumInMin(parseInt(hummin, 10));
      } else if (parseInt(hummin, 10) <= 0) {
        setHumInMin(parseInt(hummin, 10));
      } else {
        setHumInMin(0);
      }
      if (parseInt(hummax, 10) >= 0) {
        setHumInMax(parseInt(hummax, 10));
      } else if (parseInt(hummax, 10) <= 0) {
        setHumInMax(parseInt(hummax, 10));
      } else {
        setHumInMax(0);
      }
    }
  }, [hummin, hummax]);

  useEffect(() => {
    if (!co2min && !co2max) {
      setCo2InMin(undefined);
      setCo2InMax(undefined);
    } else {
      if (parseInt(co2min, 10) >= 0) {
        setCo2InMin(parseInt(co2min, 10));
      } else if (parseInt(co2min, 10) <= 0) {
        setCo2InMin(parseInt(co2min, 10));
      } else {
        setCo2InMin(0);
      }
      if (parseInt(co2max, 10) >= 0) {
        setCo2InMax(parseInt(co2max, 10));
      } else if (parseInt(co2max, 10) <= 0) {
        setCo2InMax(parseInt(co2max, 10));
      } else {
        setCo2InMax(0);
      }
    }
  }, [co2min, co2max]);

  useEffect(() => {
    if (!tdmin && !tdmax) {
      setTdInMin(undefined);
      setTdInMax(undefined);
    } else {
      if (parseInt(tdmin, 10) >= 0) {
        setTdInMin(parseInt(tdmin, 10));
      } else if (parseInt(tdmin, 10) <= 0) {
        setTdInMin(parseInt(tdmin, 10));
      } else {
        setTdInMin(0);
      }
      if (parseInt(tdmax, 10) >= 0) {
        setTdInMax(parseInt(tdmax, 10));
      } else if (parseInt(tdmax, 10) <= 0) {
        setTdInMax(parseInt(tdmax, 10));
      } else {
        setTdInMax(0);
      }
    }
  }, [tdmin, tdmax]);

  const onMenu = () => {
    setMenu((menu) => ({
      ...menu,
      [typeKey]: true,
    }));
  };
  const onHpaMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATHpa: true,
    }));
  };
  const onTempMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATTemp: true,
    }));
  };
  const onVpdMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATVpd: true,
    }));
  };
  const onSunMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATSun: true,
    }));
  };
  const onHumMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATHum: true,
    }));
  };
  const onCo2Menu = () => {
    setMenu((menu) => ({
      ...menu,
      ATCo2: true,
    }));
  };
  const onTdMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATTd: true,
    }));
  };

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
        format: "yyyy/MM/dd",
      },
    },
    yaxis: [
      {
        show: false,
        seriesName: t("co2"),
        min: co2InMin,
        max: co2InMax,
      },
      {
        show: false,
        seriesName: t("humidity"),
        min: humInMin,
        max: humInMax,
      },
      {
        show: false,
        seriesName: t("pressure"),
        min: hpaInMin,
        max: hpaInMax,
      },
      {
        show: false,
        seriesName: t("solar"),
        min: sunInMin,
        max: sunInMax,
      },
      {
        show: false,
        seriesName: t("temperature"),
        min: tempInMin,
        max: tempInMax,
      },
      {
        show: false,
        seriesName: t("vpd"),
        min: vpdInMin,
        max: vpdInMax,
      },
      {
        show: false,
        seriesName: t("td"),
        min: tdInMin,
        max: tdInMax,
      },
    ],
    // yaxis: {
    //   min: inMin,
    //   max: inMax,
    // },
    chart: {
      toolbar: {
        tools: {
          download: `<img src=${excel} width="20" height="20" />`,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          customIcons: [
            {
              icon: `<img src=${Co2} width="20" height="20" />`,
              index: 2,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onCo2Menu();
              },
            },
            {
              icon: `<img src=${Raindrops} width="20" height="20" />`,
              index: 3,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onHumMenu();
              },
            },
            {
              icon: `<img src=${Hpa} width="20" height="20" />`,
              index: 4,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onHpaMenu();
              },
            },
            {
              icon: `<img src=${Sun} width="20" height="20" />`,
              index: 5,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onSunMenu();
              },
            },
            {
              icon: `<img src=${Thermometer} width="20" height="20" />`,
              index: 6,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onTempMenu();
              },
            },
            {
              icon: `<span>VPD</span>`,
              index: 7,
              title: "tooltip of the icon",
              class: "custom-icon custom-span",
              click: function (chart, options, e) {
                onVpdMenu();
              },
            },
            {
              icon: `<span>TD</span>`,
              index: 7,
              title: "tooltip of the icon",
              class: "custom-icon custom-span",
              click: function (chart, options, e) {
                onTdMenu();
              },
            },
          ],
        },
        export: {
          csv: {
            filename: "AT",
            columnDelimiter: ",",
            headerCategory: "AT Date",
            headerValue: "value",
            dateFormatter(timestamp) {
              return moment(timestamp).format("YYYY/MM/DD HH:mm:ss");
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      followCursor: true,
      x: {
        format: "yyyy/MM/dd HH:mm",
      },
    },
  };
  console.log("ddddd");
  return loading ? (
    <div
      style={{
        height: "350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "150px" }}>
        <LoadingScreen />
      </div>
    </div>
  ) : (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="400px"
      className="apex-charts"
      dir="ltr"
    />
  );
};

export default ATSplineArea;
