import { Link, useHistory } from "react-router-dom";
import CheckComponent from "./CheckComponent";
import { ReactComponent as Sensorlogo } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-chart-histogram.svg";
import { ReactComponent as Settings } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-settings.svg";
import moment from "moment";
import "moment/locale/ko";

const SensorDataListRow = ({
  type,
  currentPosts,
  usercluster,
  setAllSelected,
}) => {
  const history = useHistory();

  return currentPosts.map((data, i) => {
    const goAdminEdit = (data) => {
      history.push({
        pathname: `/sensors/edit`,
        state: {
          editData: data,
          usercluster: usercluster,
        },
      });
    };
    const goSensorList = (data) => {
      history.push({
        pathname: `/sensors/type`,
        state: {
          id: data.id,
          deviceType: data.deviceType,
        },
      });
    };

    const timeForToday = (value) => {
      const today = new Date();
      const timeValue = new Date(value);

      const betweenTime = Math.floor(
        (today.getTime() - timeValue.getTime()) / 1000 / 60
      );
      if (betweenTime < 1) return "방금전";
      if (betweenTime < 60) {
        return `${betweenTime}분전`;
      }
      const betweenTimeHour = Math.floor(betweenTime / 60);
      if (betweenTimeHour < 24) {
        return `${betweenTimeHour}시간전`;
      }
      const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
      if (betweenTimeDay < 365) {
        return `${betweenTimeDay}일전`;
      }
      return `${Math.floor(betweenTimeDay / 365)}년전`;
    };
    return type === "SOIL" || type === "SMS" ? (
      <tr className="tableHover" key={i}>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.id}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.name ? data.name : data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.soilTemperature
            ? data.latestLog.soilTemperature
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.moisture ? data.latestLog.moisture : "--"}
        </td>
        {/* <td style={{ border: "1px solid #000", textAlign: "center" }}>
              {data.latestLog?.ec ? data.latestLog.ec : "--"}
            </td> */}
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.battery ? data.latestLog.battery : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.fwversion ? data.latestLog.fwversion : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {moment(data.latestLog?.createdDate).diff(moment(), "minute") > -60
            ? "Online"
            : "Offline"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.createdDate
            ? timeForToday(data.latestLog?.createdDate)
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          <Sensorlogo
            className="datalistSensorLogo"
            onClick={() => goSensorList(data)}
          />
          <Settings
            className="datalistSettings"
            onClick={() => goAdminEdit(data)}
          />
        </td>
      </tr>
    ) : type === "CO2" || type === "AT" ? (
      <tr className="tableHover" key={i}>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.id}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.name ? data.name : data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.co2 ? data.latestLog.co2 : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.temperature ? data.latestLog.temperature : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.humidity ? data.latestLog.humidity : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.pressure ? data.latestLog.pressure : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.battery ? data.latestLog.battery : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.fwversion ? data.latestLog.fwversion : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {moment(data.latestLog?.createdDate).diff(moment(), "minute") > -60
            ? "Online"
            : "Offline"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.createdDate
            ? timeForToday(data.latestLog?.createdDate)
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          <Sensorlogo
            className="datalistSensorLogo"
            onClick={() => goSensorList(data)}
          />
          <Settings
            className="datalistSettings"
            onClick={() => goAdminEdit(data)}
          />
        </td>
      </tr>
    ) : type === "WATERFLOW" ? (
      <tr className="tableHover" key={i}>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.id}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.name ? data.name : data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.speed ? data.latestLog.speed : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.flowmeter ? data.latestLog.flowmeter : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.fwversion ? data.latestLog.fwversion : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {moment(data.latestLog?.createdDate).diff(moment(), "minute") > -60
            ? "Online"
            : "Offline"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.createdDate
            ? timeForToday(data.latestLog?.createdDate)
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          <Sensorlogo
            className="datalistSensorLogo"
            onClick={() => goSensorList(data)}
          />
          <Settings
            className="datalistSettings"
            onClick={() => goAdminEdit(data)}
          />
        </td>
      </tr>
    ) : type === "NUTRIENT" ? (
      <tr className="tableHover" key={i}>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.id}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.name ? data.name : data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.ph ? data.latestLog.ph : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.temperature ? data.latestLog.temperature : "--"}
        </td>
        {/* <td style={{ border: "1px solid #000", textAlign: "center" }}>
              {data.latestLog?.ec ? data.latestLog.ec : "--"}
            </td> */}
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.fwversion ? data.latestLog.fwversion : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {moment(data.latestLog?.createdDate).diff(moment(), "minute") > -60
            ? "Online"
            : "Offline"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.createdDate
            ? timeForToday(data.latestLog?.createdDate)
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          <Sensorlogo
            className="datalistSensorLogo"
            onClick={() => goSensorList(data)}
          />
          <Settings
            className="datalistSettings"
            onClick={() => goAdminEdit(data)}
          />
        </td>
      </tr>
    ) : type === "DRAINAGE" ? (
      <tr className="tableHover" key={i}>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.id}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.name ? data.name : data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.flowmeter ? data.latestLog.flowmeter : "--"}
        </td>
        {/* <td style={{ border: "1px solid #000", textAlign: "center" }}>
              {data.latestLog?.ec ? data.latestLog.ec : "--"}
            </td> */}
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.temperature ? data.latestLog.temperature : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.battery ? data.latestLog.battery : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.fwversion ? data.latestLog.fwversion : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {moment(data.latestLog?.createdDate).diff(moment(), "minute") > -60
            ? "Online"
            : "Offline"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.createdDate
            ? timeForToday(data.latestLog?.createdDate)
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          <Sensorlogo
            className="datalistSensorLogo"
            onClick={() => goSensorList(data)}
          />
          <Settings
            className="datalistSettings"
            onClick={() => goAdminEdit(data)}
          />
        </td>
      </tr>
    ) : type === "FMS" ? (
      <tr className="tableHover" key={i}>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.id}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.name ? data.name : data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.t ? data.latestLog.t : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.rh ? data.latestLog.rh : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.ec ? data.latestLog.ec : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.fwversion ? data.latestLog.fwversion : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {moment(data.latestLog?.createdDate).diff(moment(), "minute") > -60
            ? "Online"
            : "Offline"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.createdDate
            ? timeForToday(data.latestLog?.createdDate)
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          <Sensorlogo
            className="datalistSensorLogo"
            onClick={() => goSensorList(data)}
          />
          <Settings
            className="datalistSettings"
            onClick={() => goAdminEdit(data)}
          />
        </td>
      </tr>
    ) : type === "COMPOST" ? (
      <tr className="tableHover" key={i}>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.id}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.name ? data.name : data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.t ? data.latestLog.t : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.m15 ? data.latestLog.m15 : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.m30 ? data.latestLog.m30 : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.battery ? data.latestLog.battery : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.fwversion ? data.latestLog.fwversion : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {moment(data.latestLog?.createdDate).diff(moment(), "minute") > -60
            ? "Online"
            : "Offline"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.createdDate
            ? timeForToday(data.latestLog?.createdDate)
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          <Sensorlogo
            className="datalistSensorLogo"
            onClick={() => goSensorList(data)}
          />
          <Settings
            className="datalistSettings"
            onClick={() => goAdminEdit(data)}
          />
        </td>
      </tr>
    ) : type === "ATS02" ? (
      <tr className="tableHover" key={i}>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.id}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.name ? data.name : data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.serial}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.windD ? data.latestLog.windD : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.windS ? data.latestLog.windS : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.rain ? data.latestLog.rain : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.illuminance ? data.latestLog.illuminance : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.temperature ? data.latestLog.temperature : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.humidity ? data.latestLog.humidity : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.pressure ? data.latestLog.pressure : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.battery ? data.latestLog.battery : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.fwversion ? data.latestLog.fwversion : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {moment(data.latestLog?.createdDate).diff(moment(), "minute") > -60
            ? "Online"
            : "Offline"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          {data.latestLog?.createdDate
            ? timeForToday(data.latestLog?.createdDate)
            : "--"}
        </td>
        <td style={{ border: "1px solid #000", textAlign: "center" }}>
          <Sensorlogo
            className="datalistSensorLogo"
            onClick={() => goSensorList(data)}
          />
          <Settings
            className="datalistSettings"
            onClick={() => goAdminEdit(data)}
          />
        </td>
      </tr>
    ) : null;
  });
};

export default SensorDataListRow;
