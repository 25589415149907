import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap";
import { requestGet } from "../../apis/request";
import moment from "moment";
import "moment/locale/ko";
import "./chart.scss";
import { useTranslation } from "react-i18next";
const SensorTable = ({ type, tableData }) => {
  const [header, setHeader] = useState([]);
  const [body, setBody] = useState(["Max", "Min", "Avg", "Var"]);
  const [data, setData] = useState({});
  const { t } = useTranslation("main");

  useEffect(() => {
    if (type === "AT") {
      setHeader(["co2", "humidity", "pressure", "solar", "temperature", "vpd"]);
    }
    if (type === "ATS02") {
      setHeader([
        "humidity",
        "illuminance",
        "pressure",
        "rain",
        "temperature",
        "windD",
        "windS",
      ]);
    }
    if (type === "SSH") {
      setHeader(["moisture", "temperature", "ecno", "phno", "n", "p", "k"]);
    }
  }, [type]);

  useEffect(() => {
    if (Object.keys(tableData).length > 0) {
      if (type === "AT") {
        setData({
          Max: {
            co2:
              tableData?.maxCo2 !== "NaN"
                ? tableData?.maxCo2?.toFixed(1)
                : "0.0",
            humidity:
              tableData?.maxHumidity !== "NaN"
                ? tableData?.maxHumidity?.toFixed(1)
                : "0.0",
            pressure:
              tableData?.maxPressure !== "NaN"
                ? tableData?.maxPressure?.toFixed(1)
                : "0.0",
            solar:
              tableData?.maxSolar !== "NaN"
                ? tableData?.maxSolar?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.maxTemperature !== "NaN"
                ? tableData?.maxTemperature?.toFixed(1)
                : "0.0",
            vpd: (tableData?.maxVpd / 1000)?.toFixed(1),
          },
          Min: {
            co2:
              tableData?.minCo2 !== "NaN"
                ? tableData?.minCo2?.toFixed(1)
                : "0.0",
            humidity:
              tableData?.minHumidity !== "NaN"
                ? tableData?.minHumidity?.toFixed(1)
                : "0.0",
            pressure:
              tableData?.minPressure !== "NaN"
                ? tableData?.minPressure?.toFixed(1)
                : "0.0",
            solar:
              tableData?.minSolar !== "NaN"
                ? tableData?.minSolar?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.minTemperature !== "NaN"
                ? tableData?.minTemperature?.toFixed(1)
                : "0.0",
            vpd: (tableData?.minVpd / 1000)?.toFixed(1),
          },
          Avg: {
            co2:
              tableData?.avgCo2 !== "NaN"
                ? tableData?.avgCo2?.toFixed(1)
                : "0.0",
            humidity:
              tableData?.avgHumidity !== "NaN"
                ? tableData?.avgHumidity?.toFixed(1)
                : "0.0",
            pressure:
              tableData?.avgPressure !== "NaN"
                ? tableData?.avgPressure?.toFixed(1)
                : "0.0",
            solar:
              tableData?.avgSolar !== "NaN"
                ? tableData?.avgSolar?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.avgTemperature !== "NaN"
                ? tableData?.avgTemperature?.toFixed(1)
                : "0.0",
            vpd: (tableData?.avgVpd / 1000)?.toFixed(1),
          },
          Var: {
            co2:
              tableData?.co2Variation !== "NaN"
                ? tableData?.co2Variation?.toFixed(1)
                : "0.0",
            humidity:
              tableData?.humidityVariation !== "NaN"
                ? tableData?.humidityVariation?.toFixed(1)
                : "0.0",
            pressure:
              tableData?.pressureVariation !== "NaN"
                ? tableData?.pressureVariation?.toFixed(1)
                : "0.0",
            solar:
              tableData?.solarVariation !== "NaN"
                ? tableData?.solarVariation?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.temperatureVariation !== "NaN"
                ? tableData?.temperatureVariation?.toFixed(1)
                : "0.0",
            vpd: (tableData?.vpdVariation / 1000)?.toFixed(1),
          },
        });
      }
      if (type === "ATS02") {
        setData({
          Max: {
            illuminance:
              tableData?.maxIlluminance !== "NaN"
                ? tableData?.maxIlluminance?.toFixed(1)
                : "0.0",
            humidity:
              tableData?.maxHumidity !== "NaN"
                ? tableData?.maxHumidity?.toFixed(1)
                : "0.0",
            pressure:
              tableData?.maxPressure !== "NaN"
                ? tableData?.maxPressure?.toFixed(1)
                : "0.0",
            rain:
              tableData?.maxRain !== "NaN"
                ? tableData?.maxRain?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.maxTemperature !== "NaN"
                ? tableData?.maxTemperature?.toFixed(1)
                : "0.0",
            windD:
              tableData?.maxWindD !== "NaN"
                ? tableData?.maxWindD?.toFixed(1)
                : "0.0",
            windS:
              tableData?.maxWindS !== "NaN"
                ? tableData?.maxWindS?.toFixed(1)
                : "0.0",
          },
          Min: {
            illuminance:
              tableData?.minIlluminance !== "NaN"
                ? tableData?.minIlluminance?.toFixed(1)
                : "0.0",
            humidity:
              tableData?.minHumidity !== "NaN"
                ? tableData?.minHumidity?.toFixed(1)
                : "0.0",
            pressure:
              tableData?.minPressure !== "NaN"
                ? tableData?.minPressure?.toFixed(1)
                : "0.0",
            rain:
              tableData?.minRain !== "NaN"
                ? tableData?.minRain?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.minTemperature !== "NaN"
                ? tableData?.minTemperature?.toFixed(1)
                : "0.0",
            windD:
              tableData?.minWindD !== "NaN"
                ? tableData?.minWindD?.toFixed(1)
                : "0.0",
            windS:
              tableData?.minWindS !== "NaN"
                ? tableData?.minWindS?.toFixed(1)
                : "0.0",
          },
          Avg: {
            illuminance:
              tableData?.avgIlluminance !== "NaN"
                ? tableData?.avgIlluminance?.toFixed(1)
                : "0.0",
            humidity:
              tableData?.avgHumidity !== "NaN"
                ? tableData?.avgHumidity?.toFixed(1)
                : "0.0",
            pressure:
              tableData?.avgPressure !== "NaN"
                ? tableData?.avgPressure?.toFixed(1)
                : "0.0",
            rain:
              tableData?.avgRain !== "NaN"
                ? tableData?.avgRain?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.avgTemperature !== "NaN"
                ? tableData?.avgTemperature?.toFixed(1)
                : "0.0",
            windD:
              tableData?.avgWindD !== "NaN"
                ? tableData?.avgWindD?.toFixed(1)
                : "0.0",
            windS:
              tableData?.avgWindS !== "NaN"
                ? tableData?.avgWindS?.toFixed(1)
                : "0.0",
          },
          Var: {
            illuminance:
              tableData?.illuminanceVariation !== "NaN"
                ? tableData?.illuminanceVariation?.toFixed(1)
                : "0.0",
            humidity:
              tableData?.humidityVariation !== "NaN"
                ? tableData?.humidityVariation?.toFixed(1)
                : "0.0",
            pressure:
              tableData?.pressureVariation !== "NaN"
                ? tableData?.pressureVariation?.toFixed(1)
                : "0.0",
            rain:
              tableData?.rainVariation !== "NaN"
                ? tableData?.rainVariation?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.temperatureVariation !== "NaN"
                ? tableData?.temperatureVariation?.toFixed(1)
                : "0.0",
            windD:
              tableData?.windDVariation !== "NaN"
                ? tableData?.windDVariation?.toFixed(1)
                : "0.0",
            windS:
              tableData?.windSVariation !== "NaN"
                ? tableData?.windSVariation?.toFixed(1)
                : "0.0",
          },
        });
      }
      if (type === "SSH") {
        setData({
          Max: {
            moisture:
              tableData?.maxMoisture !== "NaN"
                ? tableData?.maxMoisture?.toFixed(1)
                : "0.0",
            ecno:
              tableData?.maxEc !== "NaN" ? tableData?.maxEc?.toFixed(1) : "0.0",
            phno:
              tableData?.maxPh !== "NaN" ? tableData?.maxPh?.toFixed(1) : "0.0",
            n: tableData?.maxN !== "NaN" ? tableData?.maxN?.toFixed(1) : "0.0",
            temperature:
              tableData?.maxTemperature !== "NaN"
                ? tableData?.maxTemperature?.toFixed(1)
                : "0.0",
            p: tableData?.maxP !== "NaN" ? tableData?.maxP?.toFixed(1) : "0.0",
            k: tableData?.maxK !== "NaN" ? tableData?.maxK?.toFixed(1) : "0.0",
          },
          Min: {
            moisture:
              tableData?.minMoisture !== "NaN"
                ? tableData?.minMoisture?.toFixed(1)
                : "0.0",
            ecno:
              tableData?.minEc !== "NaN" ? tableData?.minEc?.toFixed(1) : "0.0",
            phno:
              tableData?.minPh !== "NaN" ? tableData?.minPh?.toFixed(1) : "0.0",
            n: tableData?.minN !== "NaN" ? tableData?.minN?.toFixed(1) : "0.0",
            temperature:
              tableData?.minTemperature !== "NaN"
                ? tableData?.minTemperature?.toFixed(1)
                : "0.0",
            p: tableData?.minP !== "NaN" ? tableData?.minP?.toFixed(1) : "0.0",
            k: tableData?.minK !== "NaN" ? tableData?.minK?.toFixed(1) : "0.0",
          },
          Avg: {
            moisture:
              tableData?.avgMoisture !== "NaN"
                ? tableData?.avgMoisture?.toFixed(1)
                : "0.0",
            ecno:
              tableData?.avgEc !== "NaN" ? tableData?.avgEc?.toFixed(1) : "0.0",
            phno:
              tableData?.avgPh !== "NaN" ? tableData?.avgPh?.toFixed(1) : "0.0",
            n: tableData?.avgN !== "NaN" ? tableData?.avgN?.toFixed(1) : "0.0",
            temperature:
              tableData?.avgTemperature !== "NaN"
                ? tableData?.avgTemperature?.toFixed(1)
                : "0.0",
            p: tableData?.avgP !== "NaN" ? tableData?.avgP?.toFixed(1) : "0.0",
            k: tableData?.avgK !== "NaN" ? tableData?.avgK?.toFixed(1) : "0.0",
          },
          Var: {
            moisture:
              tableData?.moistureVariation !== "NaN"
                ? tableData?.moistureVariation?.toFixed(1)
                : "0.0",
            ecno:
              tableData?.ecVariation !== "NaN"
                ? tableData?.ecVariation?.toFixed(1)
                : "0.0",
            phno:
              tableData?.phVariation !== "NaN"
                ? tableData?.phVariation?.toFixed(1)
                : "0.0",
            n:
              tableData?.nvariation !== "NaN"
                ? tableData?.nvariation?.toFixed(1)
                : "0.0",
            temperature:
              tableData?.temperatureVariation !== "NaN"
                ? tableData?.temperatureVariation?.toFixed(1)
                : "0.0",
            p:
              tableData?.pvariation !== "NaN"
                ? tableData?.pvariation?.toFixed(1)
                : "0.0",
            k:
              tableData?.kvariation !== "NaN"
                ? tableData?.kvariation?.toFixed(1)
                : "0.0",
          },
        });
      }
    } else {
      setData({});
    }
  }, [type, tableData]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th></th>
                {header?.map((item, i) => (
                  <th style={{ textAlign: "center" }} key={i}>
                    {t(item === "co2" ? "co2eng" : item)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {body?.map((item, i) => (
                <tr key={i}>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: "8px 0px",
                    }}
                  >
                    {item}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data[item] ? data[item][header[0]] : null}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data[item] ? data[item][header[1]] : null}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data[item] ? data[item][header[2]] : null}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data[item] ? data[item][header[3]] : null}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data[item] ? data[item][header[4]] : null}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {data[item] ? data[item][header[5]] : null}
                  </td>
                  {type === "ATS02" || type === "SSH" ? (
                    <td style={{ textAlign: "center" }}>
                      {data[item] ? data[item][header[6]] : null}
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SensorTable;
