import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input, FormFeedback, FormGroup, Row } from "reactstrap";
import { loginUser } from "../../store/auth/login/actions";
import { loginPost, requestGet } from "../../apis/request";
import { useTranslation } from "react-i18next";
import logoSm from "../../assets/images/logo68.svg";
import backimg from "../../assets/images/large-green-rice-field-with-green-rice-plants-in-rows-at-sunset_2021-10-13/large-green-rice-field-with-green-rice-plants-in-rows-at-sunset.png";
import backimg2x from "../../assets/images/large-green-rice-field-with-green-rice-plants-in-rows-at-sunset_2021-10-13/large-green-rice-field-with-green-rice-plants-in-rows-at-sunset@2x.png";
import backimg3x from "../../assets/images/large-green-rice-field-with-green-rice-plants-in-rows-at-sunset_2021-10-13/large-green-rice-field-with-green-rice-plants-in-rows-at-sunset@3x.png";
import "./login.scss";
import Langchange from "../../locales/langchange";
import { MetaTags } from "react-meta-tags";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("main");

  const handleEmail = (e) => {
    setUsername(e.target.value);
  };

  const handlePwd = (e) => {
    setPassword(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      const params = new URLSearchParams();
      params.append("username", username);
      params.append("password", password);

      dispatch(loginUser());
      const res = await loginPost(`/api/account/login?${params}`);
      // sessionStorage.setItem("username", username);
      // sessionStorage.setItem("password", password);
      sessionStorage.setItem("authUser", res.accessToken);
      localStorage.setItem("long", res.refeshToken);
      setLogin(!login);
      getuserinfo();
    } catch (error) {}
  };

  const getuserinfo = async () => {
    const userinfo = await requestGet(`/api/account/info`);
    history.push("/dashboard");
  };
  const regType1 = /^[A-Za-z0-9_-]{5,16}$/;
  const regType2 = /^[A-Za-z0-9~!@#$%^&*()+_|<>?:{}]{8,16}$/;

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | FarmCLO</title>
      </MetaTags>
      <div className="KR-000-">
        <img
          src={backimg}
          alt="backimg"
          srcSet={(backimg2x, backimg3x)}
          className="backgroundimg"
        />
        <div className="log-in">LOG IN</div>
        <div className="loginheaderline" />
        <img src={logoSm} alt="logo" className="lo368" />
        <div className="headerLang">
          <Langchange />
        </div>
        <div className="LoginWrap">
          <div className="logincard">
            <div className="titleWrap">
              <span className="title">{t("logintitle")}</span>
            </div>
            <div className="subtitle">
              <span className="Already-have-an-account-Login-below">
                {t("loginsub")}
              </span>
            </div>

            <Form className="loginCardInputWrap" onSubmit={onSubmitHandler}>
              <FormGroup className="IdWrap">
                <Row>
                  <span className="idspan ms-2 mb-1">{t("id")}</span>
                </Row>
                <div className="mobilediv">
                  <Input
                    type="text"
                    className="input325"
                    maxlength="16"
                    name="_id"
                    value={username}
                    onChange={handleEmail}
                    invalid={!regType1.test(username)}
                    required
                  />
                  <FormFeedback className="loginFormFeed ms-2">
                    {t("pwrq")}
                  </FormFeedback>
                </div>
              </FormGroup>
              <div className="PwWrap">
                <Row>
                  <span className="pwspan ms-2 mb-1">{t("pw")}</span>
                </Row>
                <div className="mobilediv">
                  <Input
                    type="password"
                    className="inputpw325"
                    maxlength="16"
                    name="_pwd"
                    value={password}
                    onChange={handlePwd}
                    invalid={!regType2.test(password)}
                    required
                  />
                  <FormFeedback className="loginFormFeed ms-2">
                    {t("pwrq1")}
                  </FormFeedback>
                </div>
              </div>

              <button className="btn326" type="submit">
                <span>{t("loginbutton")}</span>
              </button>

              <div className="mobilemarginbottom">
                <Link to="/pages-recoverpw" className="forgotpw">
                  {t("forgot")}
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(Login);
