import { ADD_LIST_SUCCESSFUL } from "./actionTypes";

const initialState = {
  list: null,
};

const addli = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LIST_SUCCESSFUL:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};

export default addli;
