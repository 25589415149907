import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { requestGet } from "../../../apis/request";
import moment from "moment";
import "moment/locale/ko";
import excel from "../../../assets/icons/xls-file.svg";
import Pencil from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-pencil.svg";
import Hpa from "../../../assets/icons/ph.svg";
import Thermometer from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-thermometer-half.svg";
import Sun from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-sun.svg";
import Raindrops from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import RainHum from "../../../assets/icons/rainhum.svg";
import WindD from "../../../assets/icons/winD.svg";
import WindS from "../../../assets/icons/winS.svg";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../Dashboard/LoadingScreen";

const ATS02SplineArea = ({
  setMenu,
  userDevice,
  apistartdate,
  apienddate,
  min,
  max,
  hummin,
  hummax,
  sunmin,
  sunmax,
  hpamin,
  hpamax,
  rainmin,
  rainmax,
  tempmin,
  tempmax,
  winddmin,
  winddmax,
  windsmin,
  windsmax,
  setTableData,
  typeKey,
}) => {
  const [respon, setRespon] = useState([]);
  const [series, setSeries] = useState([]);
  const [inMin, setInMin] = useState();
  const [inMax, setInMax] = useState();
  const [humInMin, setHumInMin] = useState();
  const [humInMax, setHumInMax] = useState();
  const [sunInMin, setSunInMin] = useState();
  const [sunInMax, setSunInMax] = useState();
  const [hpaInMin, setHpaInMin] = useState();
  const [hpaInMax, setHpaInMax] = useState();
  const [rainInMin, setRainInMin] = useState();
  const [rainInMax, setRainInMax] = useState();
  const [tempInMin, setTempInMin] = useState();
  const [tempInMax, setTempInMax] = useState();
  const [windDInMin, setWindDInMin] = useState();
  const [windDInMax, setWindDInMax] = useState();
  const [windSInMin, setWindSInMin] = useState();
  const [windSInMax, setWindSInMax] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("main");

  const sensors = [
    "humidity",
    "illuminance",
    "pressure",
    "rain",
    "temperature",
    "windD",
    "windS",
  ];

  const callapi = async () => {
    try {
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/${userDevice[0].id}/${apistartdate}/${apienddate}`
      );
      setRespon(response.data);
      setTableData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setRespon([]);
      setTableData({});
      setLoading(false);
    }
  };

  useEffect(() => {
    callapi();
  }, [apistartdate, apienddate, userDevice]);

  const getLog = async (sens) => {
    if (respon.length > 0) {
      return {
        name: t(sens),
        data: respon.map((sensorItem, j) => {
          return [
            new Date(sensorItem.createdDate.replace(/-/g, "/")).getTime(),
            sensorItem[sens].toFixed(1),
          ];
        }),
      };
    } else {
      return {
        name: t(sens),
        data: [],
      };
    }
  };

  const callChartApi = async () => {
    if (
      Array.isArray(userDevice) &&
      userDevice.length > 0 &&
      respon.length > 0
    ) {
      const arr = [];
      if (userDevice.length > 0) {
        for (let value of sensors) {
          const result = await getLog(value);
          arr.push(result);
        }
      }
      setSeries(arr);
    } else {
      setSeries([]);
    }
  };

  useEffect(() => {
    callChartApi();
  }, [userDevice, respon]);

  useEffect(() => {
    if (!min && !max) {
      return;
    } else {
      if (parseInt(min, 10) >= 0) {
        setInMin(parseInt(min, 10));
      } else if (parseInt(min, 10) <= 0) {
        setInMin(parseInt(min, 10));
      } else {
        setInMin(0);
      }
      if (parseInt(max, 10) >= 0) {
        setInMax(parseInt(max, 10));
      } else if (parseInt(max, 10) <= 0) {
        setInMax(parseInt(max, 10));
      } else {
        setInMax(0);
      }
    }
  }, [min, max]);

  const onMenu = () => {
    setMenu((menu) => ({
      ...menu,
      [typeKey]: true,
    }));
  };

  useEffect(() => {
    if (!hummin && !hummax) {
      setHumInMin(undefined);
      setHumInMax(undefined);
    } else {
      if (parseInt(hummin, 10) >= 0) {
        setHumInMin(parseInt(hummin, 10));
      } else if (parseInt(hummin, 10) <= 0) {
        setHumInMin(parseInt(hummin, 10));
      } else {
        setHumInMin(0);
      }
      if (parseInt(hummax, 10) >= 0) {
        setHumInMax(parseInt(hummax, 10));
      } else if (parseInt(hummax, 10) <= 0) {
        setHumInMax(parseInt(hummax, 10));
      } else {
        setHumInMax(0);
      }
    }
  }, [hummin, hummax]);

  const onHumMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATS02Hum: true,
    }));
  };

  useEffect(() => {
    if (!sunmin && !sunmax) {
      setSunInMin(undefined);
      setSunInMax(undefined);
    } else {
      if (parseInt(sunmin, 10) >= 0) {
        setSunInMin(parseInt(sunmin, 10));
      } else if (parseInt(sunmin, 10) <= 0) {
        setSunInMin(parseInt(sunmin, 10));
      } else {
        setSunInMin(0);
      }
      if (parseInt(sunmax, 10) >= 0) {
        setSunInMax(parseInt(sunmax, 10));
      } else if (parseInt(sunmax, 10) <= 0) {
        setSunInMax(parseInt(sunmax, 10));
      } else {
        setSunInMax(0);
      }
    }
  }, [sunmin, sunmax]);

  const onSunMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATS02Sun: true,
    }));
  };

  useEffect(() => {
    if (!hpamin && !hpamax) {
      setHpaInMin(undefined);
      setHpaInMax(undefined);
    } else {
      if (parseInt(hpamin, 10) >= 0) {
        setHpaInMin(parseInt(hpamin, 10));
      } else if (parseInt(hpamin, 10) <= 0) {
        setHpaInMin(parseInt(hpamin, 10));
      } else {
        setHpaInMin(0);
      }
      if (parseInt(hpamax, 10) >= 0) {
        setHpaInMax(parseInt(hpamax, 10));
      } else if (parseInt(hpamax, 10) <= 0) {
        setHpaInMax(parseInt(hpamax, 10));
      } else {
        setHpaInMax(0);
      }
    }
  }, [hpamin, hpamax]);

  const onHpaMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATS02Hpa: true,
    }));
  };

  useEffect(() => {
    if (!rainmin && !rainmax) {
      setRainInMin(undefined);
      setRainInMax(undefined);
    } else {
      if (parseInt(rainmin, 10) >= 0) {
        setRainInMin(parseInt(rainmin, 10));
      } else if (parseInt(rainmin, 10) <= 0) {
        setRainInMin(parseInt(rainmin, 10));
      } else {
        setRainInMin(0);
      }
      if (parseInt(rainmax, 10) >= 0) {
        setRainInMax(parseInt(rainmax, 10));
      } else if (parseInt(rainmax, 10) <= 0) {
        setRainInMax(parseInt(rainmax, 10));
      } else {
        setRainInMax(0);
      }
    }
  }, [rainmin, rainmax]);

  const onRainMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATS02Rain: true,
    }));
  };

  useEffect(() => {
    if (!tempmin && !tempmax) {
      setTempInMin(undefined);
      setTempInMax(undefined);
    } else {
      if (parseInt(tempmin, 10) >= 0) {
        setTempInMin(parseInt(tempmin, 10));
      } else if (parseInt(tempmin, 10) <= 0) {
        setTempInMin(parseInt(tempmin, 10));
      } else {
        setTempInMin(0);
      }
      if (parseInt(tempmax, 10) >= 0) {
        setTempInMax(parseInt(tempmax, 10));
      } else if (parseInt(tempmax, 10) <= 0) {
        setTempInMax(parseInt(tempmax, 10));
      } else {
        setTempInMax(0);
      }
    }
  }, [tempmin, tempmax]);

  const onTempMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATS02Temp: true,
    }));
  };

  useEffect(() => {
    if (!winddmin && !winddmax) {
      setWindDInMin(undefined);
      setWindDInMax(undefined);
    } else {
      if (parseInt(winddmin, 10) >= 0) {
        setWindDInMin(parseInt(winddmin, 10));
      } else if (parseInt(winddmin, 10) <= 0) {
        setWindDInMin(parseInt(winddmin, 10));
      } else {
        setWindDInMin(0);
      }
      if (parseInt(winddmax, 10) >= 0) {
        setWindDInMax(parseInt(winddmax, 10));
      } else if (parseInt(winddmax, 10) <= 0) {
        setWindDInMax(parseInt(winddmax, 10));
      } else {
        setWindDInMax(0);
      }
    }
  }, [winddmin, winddmax]);

  const onWindDMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATS02WindD: true,
    }));
  };

  useEffect(() => {
    if (!windsmin && !windsmax) {
      setWindSInMin(undefined);
      setWindSInMax(undefined);
    } else {
      if (parseInt(windsmin, 10) >= 0) {
        setWindSInMin(parseInt(windsmin, 10));
      } else if (parseInt(windsmin, 10) <= 0) {
        setWindSInMin(parseInt(windsmin, 10));
      } else {
        setWindSInMin(0);
      }
      if (parseInt(windsmax, 10) >= 0) {
        setWindSInMax(parseInt(windsmax, 10));
      } else if (parseInt(windsmax, 10) <= 0) {
        setWindSInMax(parseInt(windsmax, 10));
      } else {
        setWindSInMax(0);
      }
    }
  }, [windsmin, windsmax]);

  const onWindSMenu = () => {
    setMenu((menu) => ({
      ...menu,
      ATS02WindS: true,
    }));
  };

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    // colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
        format: "yyyy/MM/dd",
      },
    },
    yaxis: [
      {
        show: false,
        seriesName: t("humidity"),
        min: humInMin,
        max: humInMax,
      },
      {
        show: false,
        seriesName: t("illuminance"),
        min: sunInMin,
        max: sunInMax,
      },
      {
        show: false,
        seriesName: t("pressure"),
        min: hpaInMin,
        max: hpaInMax,
      },
      {
        show: false,
        seriesName: t("rain"),
        min: rainInMin,
        max: rainInMax,
      },
      {
        show: false,
        seriesName: t("temperature"),
        min: tempInMin,
        max: tempInMax,
      },
      {
        show: false,
        seriesName: t("windD"),
        min: windDInMin,
        max: windDInMax,
      },
      {
        show: false,
        seriesName: t("windS"),
        min: windSInMin,
        max: windSInMax,
      },
    ],
    chart: {
      toolbar: {
        tools: {
          download: `<img src=${excel} width="20" height="20" />`,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          customIcons: [
            {
              icon: `<img src=${Raindrops} width="20" height="20" />`,
              index: 2,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onHumMenu();
              },
            },
            {
              icon: `<img src=${Sun} width="20" height="20" />`,
              index: 3,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onSunMenu();
              },
            },
            {
              icon: `<img src=${Hpa} width="20" height="20" />`,
              index: 4,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onHpaMenu();
              },
            },
            {
              icon: `<img src=${RainHum} width="20" height="20" />`,
              index: 5,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onRainMenu();
              },
            },
            {
              icon: `<img src=${Thermometer} width="20" height="20" />`,
              index: 6,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onTempMenu();
              },
            },
            {
              icon: `<img src=${WindD} width="20" height="20" />`,
              index: 7,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onWindDMenu();
              },
            },
            {
              icon: `<img src=${WindS} width="20" height="20" />`,
              index: 8,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onWindSMenu();
              },
            },
          ],
        },
        export: {
          csv: {
            filename: "ATS02",
            columnDelimiter: ",",
            headerCategory: "ATS02 Date",
            headerValue: "value",
            dateFormatter(timestamp) {
              return moment(timestamp).format("YYYY/MM/DD HH:mm:ss");
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      followCursor: true,
      x: {
        format: "yyyy/MM/dd HH:mm",
      },
    },
  };

  return loading ? (
    <div
      style={{
        height: "350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "150px" }}>
        <LoadingScreen />
      </div>
    </div>
  ) : (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
      className="apex-charts"
      dir="ltr"
    />
  );
};

export default ATS02SplineArea;
