import CheckComponent from "./CheckComponent";
import "./sensorrow.scss";
import { useHistory } from "react-router-dom";
import { ReactComponent as Settings } from "../../assets/images/chart/fi-rr-settings.svg";
import { useState } from "react";
import { apiUrl } from "../../apis/request";
import { Col, Row } from "reactstrap";
const CotRow = ({
  allSelected,
  selects,
  setSelects,
  userInfo,
  setAllSelected,
  endDate,
  usercluster,
  setSideswitch,
}) => {
  const [imgBase64, setImgBase64] = useState([]);
  const history = useHistory();
  const detailBtn = (e, data) => {
    e.stopPropagation();
    history.push({
      pathname: "/sensors/cot/detail",
      state: {
        deviceType: data.deviceType,
        serial: data.serial,
        id: data.id,
        name: data.name,
      },
    });
  };
  const editBtn = (e, data) => {
    e.stopPropagation();
    history.push({
      pathname: "/controller/edit",
      state: {
        cotId: data.id,
      },
    });
  };
  return userInfo.map((data, i) => {
    const imgurl = apiUrl + data.imagePath;
    return (
      <div
        className="sensorsrow"
        style={{ cursor: "pointer" }}
        onClick={(e) => detailBtn(e, data)}
      >
        <Row
          className="d-flex align-items-center"
          style={{ height: "100%", width: "100%", margin: "0px" }}
        >
          <Col
            className="col-auto"
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            {!data.imagePath ? (
              <div className="photoImage rounded-circle avatar-md" />
            ) : (
              <img
                src={imgurl}
                alt="photo"
                className="photoImage rounded-circle avatar-md"
              />
            )}
          </Col>
          <Col className="rowWrap col-5">
            <div>{data.name ? data.name : data.serial}</div>
            <div>{data.serial}</div>
            <div>{data.description}</div>
          </Col>

          <Col className="settingWrap col-auto">
            <button onClick={(e) => editBtn(e, data)} className="settingBtn">
              <Settings />
            </button>
            {/* <CheckComponent
            className="settingCheck"
            allSelected={allSelected}
            selects={selects}
            sensorName={"ㅇㅁㄴ"}
            // sensorName={data.name}
            setSideswitch={setSideswitch}
            setSelects={setSelects}
            // id={data.id}
            id={"data.id"}
            setAllSelected={setAllSelected}
            endDate={endDate}
          /> */}
          </Col>
        </Row>
      </div>
    );
  });
};
export default CotRow;
