import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Label,
} from "reactstrap";
import backImg from "../../assets/images/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds_2021-10-21/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds.png";
import { addList } from "../../utils/addList";
import { requestGet, requestPost } from "../../apis/request";
import ListaddMap from "../Maps/ListaddMap";
import { useTranslation } from "react-i18next";
import "./listadd.scss";
const Listadd = () => {
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [deviceType, setDeviceType] = useState("SMS");
  const [serial, setSerial] = useState("");
  const [description, setDescription] = useState("");
  const [crops, setCrops] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [channel, setChannel] = useState("2");
  const [latitudeData, setLatitudeData] = useState(null);
  const [longitudeData, setLongitudeData] = useState(null);
  const [clusterId, setClusterId] = useState("");
  const [clusterList, setClusterList] = useState([]);
  const history = useHistory();
  const { t } = useTranslation("main");
  const dispatch = useDispatch();

  const clusters = async () => {
    const cluster = await requestGet(`/api/v1/cluster/list`);
    setClusterList(cluster);
  };

  useEffect(() => {
    clusters();
  }, []);

  useEffect(() => {
    numberCheck(serial);
  }, [serial]);

  const handleCrops = (e) => {
    setCrops(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handledevice = (e) => {
    setDeviceType(e.target.value);
  };

  const handleChennel = (e) => {
    setChannel(e.target.value);
  };

  const handleserial = (e) => {
    numberCheck(e.target.value);
  };
  const handledescription = (e) => {
    setDescription(e.target.value);
  };

  const handlelatitude = (e) => {
    setLatitude(e.target.value);
    setLatitudeData(e.target.value);
  };

  const handlelongitude = (e) => {
    setLongitude(e.target.value);
    setLongitudeData(e.target.value);
  };

  const handlecluster = (e) => {
    setClusterId(e.target.value);
  };

  const numberCheck = (value) => {
    let num = value;
    if (!isFinite(num)) return;
    num = num.toString();
    num = num.replace(/^0+/, "");
    setSerial(num);
  };

  const OnSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      const params = new URLSearchParams();
      params.append("name", name);
      params.append("deviceType", deviceType);
      params.append("serial", serial);
      params.append("description", description);
      params.append("crops", crops);
      params.append("latitude", latitude);
      params.append("longitude", longitude);
      params.append("clusterId", clusterId);
      params.append("chNums", channel);
      await requestPost("/api/v1/sensor/add", params);
      history.push("/dashboard");
      setName("");
      setDeviceType("SMS");
      setSerial("");
      setDescription("");
      setCrops("");
      setLatitude("");
      setLongitude("");
      setClusterId(0);
    } catch (error) {
      alert("등록 실패하였습니다.");
    }
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensor Add | FarmCLO</title>
      </MetaTags>
      <img src={backImg} className="backgroundimg" />
      <div className="dashText">
        ADD
        <br />
        SENSOR
      </div>
      <div className="listAddCardTotalWrap">
        <div className="listAddCardWrap">
          <Container className="h-100">
            <Row className="h-100">
              <Col className="listAddDisplay h-100">
                <Card className="listAddCard m-0">
                  <Row>
                    <Col>
                      <div>
                        <div>
                          <Form onSubmit={OnSubmitHandler}>
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                {t("name")}
                              </label>
                              <Input
                                type="text"
                                name="_name"
                                className="listAddInput"
                                value={name}
                                onChange={handleName}
                                placeholder="name"
                                required
                              />
                            </div>

                            <FormGroup className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_clusterId"
                                className="listAddName"
                              >
                                {t("devicetype")}
                              </label>
                              <Input
                                type="select"
                                className="form-select listAddInput"
                                onChange={handledevice}
                              >
                                <option value="SMS">{t("SMS")}</option>
                                <option value="SSH">{t("SSH01")}</option>
                                <option value="AT">{t("AT")}</option>
                                <option value="WATERFLOW">
                                  {t("WATERFLOW")}
                                </option>
                                <option value="NUTRIENT">
                                  {t("NUTRIENT")}
                                </option>
                                <option value="DRAINAGE">
                                  {t("DRAINAGE")}
                                </option>
                                <option value="FMS">{t("FMS")}</option>
                                <option value="FMS02">{t("FMS02")}</option>
                                <option value="COMPOST">{t("COMPOST")}</option>
                                <option value="SOIL">{t("SOIL")}</option>
                                <option value="CO2">{t("CO2")}</option>
                                <option value="ATS02">{t("ATS02")}</option>
                                <option value="LAT">{t("LAT")}</option>
                                <option value="WATERLEVEL">
                                  {t("WATERLEVEL")}
                                </option>
                                {/* <option value="SES">{t("SES")}</option> */}
                                <option value="COT">{t("COT")}</option>
                              </Input>
                            </FormGroup>
                            {deviceType === "COT" ? (
                              <FormGroup className="listAddRow d-block d-md-flex">
                                <label
                                  htmlFor="_clusterId"
                                  className="listAddName"
                                >
                                  {t("ch")}
                                </label>
                                <Input
                                  type="select"
                                  className="form-select listAddInput"
                                  onChange={handleChennel}
                                >
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                </Input>
                              </FormGroup>
                            ) : null}
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_serial" className="listAddName">
                                {t("serial")}
                              </label>
                              <Input
                                type="number"
                                name="_serial"
                                className="listAddInput"
                                value={serial}
                                onChange={handleserial}
                                placeholder="serial"
                                required
                              />
                            </div>
                            <div className="listAddRow d-block d-md-flex">
                              <Label className="listAddName">
                                {t("crops")}
                              </Label>
                              <Input
                                type="text"
                                className="listAddInput"
                                value={crops}
                                onChange={handleCrops}
                                placeholder="crops"
                                id="description"
                              />
                            </div>
                            <FormGroup className="listAddRow d-block d-md-flex">
                              <Label className="listAddName">
                                {t("farmid")}
                              </Label>
                              <Input
                                type="select"
                                className="form-select listAddInput"
                                onChange={handlecluster}
                                value={clusterId}
                              >
                                <option value={null}>없음</option>
                                {clusterList.map((data, i) => {
                                  return (
                                    <option value={data.id}>{data.name}</option>
                                  );
                                })}
                              </Input>
                            </FormGroup>

                            <div className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_description"
                                className="listAddName"
                              >
                                {t("description")}
                              </label>
                              <Input
                                type="text"
                                name="_description"
                                className="listAddInput"
                                value={description}
                                onChange={handledescription}
                                placeholder="description"
                              />
                            </div>

                            <div className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_latitude"
                                className="listAddName"
                              >
                                {t("latitude")}
                              </label>
                              <Input
                                type="text"
                                name="_latitude"
                                className="listAddInput"
                                value={latitude}
                                onChange={handlelatitude}
                                placeholder="latitude"
                              />
                            </div>

                            <div className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_longitude"
                                className="listAddName"
                              >
                                {t("longitude")}
                              </label>
                              <Input
                                type="text"
                                name="_longitude"
                                className="listAddInput"
                                value={longitude}
                                onChange={handlelongitude}
                                placeholder="longitude"
                              />
                            </div>
                            <div className="listAddRow d-block d-md-flex align-items-start">
                              <label
                                htmlFor="_longitude"
                                className="listAddName mt-2"
                              >
                                {t("map")}
                              </label>
                              <div className="listAddMapWrap">
                                {/* <ListaddMap
                                latitude={latitudeData}
                                longitude={longitudeData}
                                setLatitude={setLatitude}
                                setLongitude={setLongitude}
                              /> */}
                              </div>
                            </div>
                            <Col>
                              <div>
                                <button className="listAddBtn" type="submit">
                                  {t("save")}
                                </button>
                              </div>
                            </Col>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(Listadd);
