const UserCheckComponent = ({ setAllSelected, selects, setSelects, id }) => {
  return (
    <input
      type="checkbox"
      checked={selects?.includes(id)}
      style={{ width: "20px", height: "20px" }}
      onClick={(e) => {
        e.stopPropagation();
        if (selects?.includes(id)) {
          setSelects(selects.filter((_id) => _id !== id));
          setAllSelected(false);
        } else if (!selects?.includes(id)) {
          setSelects([...selects, id]);
        }
      }}
      readOnly
    ></input>
  );
};

export default UserCheckComponent;
