import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard";

import Admin from "../pages/Admin/Admin";
import AdminSensorsList from "../pages/Admin/AdminSensorsList";
import AdminSensor from "../pages/Tables/AdminSensor";
import AdminUsers from "../pages/Tables/AdminUsers";
import AdminUserEdit from "../pages/Admin/AdminUserEdit";
import AdminListadd from "../pages/Listadd/AdminListadd";
import AdminUseradd from "../pages/Listadd/AdminUseradd";

// Maps
import MapSensorList from "../pages/Maps/MapSensorList";
import MapSensorTypeList from "../pages/Maps/MapSensorTypeList";

//Tables
import SensorList from "../pages/Tables/SensorList";
import SensorListType from "../pages/Tables/SensorListType";
import SensorListCot from "../pages/Tables/SensorListCot";
import SensorListCotDetail from "../pages/Tables/SensorListCotDetailcopy";
import IrrigationList from "../pages/Tables/IrrigationList";
import IrrigationListFarm from "../pages/Tables/IrrigationListFarm";
import SensorDataList from "../pages/Tables/SensorDataList";

import AddPage from "../pages/Listadd/AddIndex";

import Listadd from "../pages/Listadd/Listadd";
import Cotadd from "../pages/Listadd/Cotadd";
import Farmadd from "../pages/Listadd/Farmadd";
import FarmDelete from "../pages/Listadd/FarmDelete";
//Pages

import Account from "../pages/Utility/Profile/Account";
import IrrigationEdit from "../pages/Utility/Profile/IrrigationEdit";
import IrrigationNameEdit from "../pages/Utility/Profile/IrrigationNameEdit";
import SensorEdit from "../pages/Utility/Profile/SensorEdit";
import MapEdit from "../pages/Utility/Profile/MapEdit";
import ControllerEdit from "../pages/Utility/Profile/ControllerEdit";
const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/adminpage", component: Admin },
  { path: "/adminpage/sensor", component: AdminSensorsList },
  { path: "/adminpage/sensors", component: AdminSensor },
  { path: "/adminpage/sensor/add", component: AdminListadd },
  { path: "/adminpage/users", component: AdminUsers },
  { path: "/adminpage/user", component: AdminUserEdit },
  { path: "/adminpage/user/add", component: AdminUseradd },
  // //profile
  { path: "/account", component: Account },
  { path: "/account", component: Account },

  // Tables
  { path: "/irrigation", component: IrrigationList },
  { path: "/sensors", component: SensorList },
  { path: "/sensors/type", component: SensorListType },
  { path: "/sensors/cot", component: SensorListCot },
  { path: "/sensors/cot/detail", component: SensorListCotDetail },
  { path: "/irrigation/farm", component: IrrigationListFarm },
  { path: "/sensors/datalist", component: SensorDataList },
  // Maps
  { path: "/maps", component: MapSensorList },
  { path: "/maps/type", component: MapSensorTypeList },

  { path: "/addpage", component: AddPage },
  { path: "/listadd", component: Listadd },
  { path: "/cotadd", component: Cotadd },
  { path: "/farmadd", component: Farmadd },
  { path: "/farmdelete", component: FarmDelete },
  //Utility

  { path: "/irrigation/edit", component: IrrigationEdit },
  { path: "/irrigation/name/edit", component: IrrigationNameEdit },
  { path: "/sensors/edit", component: SensorEdit },
  { path: "/maps/edit", component: MapEdit },
  { path: "/controller/edit", component: ControllerEdit },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
];

export { userRoutes, authRoutes };
