import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Form,
  Input,
} from "reactstrap";
import { ReactComponent as Envelope } from "../../../assets/images/fi-rr-envelope.svg";
import "./sendmail.scss";
//i18n

import { useTranslation } from "react-i18next";
import { requestPost } from "../../../apis/request";

const SendMail = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation("main");
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const OnSubmitHandler = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append("name", name);
    params.append("title", email);
    params.append("message", message);
    await requestPost("/api/account/contact", params);
    setMenu(!menu);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
        id="bellBtnWrap"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id={
            menu
              ? "sendMailToggle"
              : window.location.pathname === "/login" ||
                window.location.pathname === "/dashboard" ||
                window.location.pathname === "/addpage" ||
                window.location.pathname === "/farmadd" ||
                window.location.pathname === "/listadd" ||
                window.location.pathname === "/cotadd" ||
                window.location.pathname === "/account" ||
                window.location.pathname === "/adminpage" ||
                window.location.pathname === "/farmdelete"
              ? "page-header-notifications-dropdown"
              : "page-header-notifications-dropdown-nodash"
          }
        >
          <Envelope className="fi-rr-bell" />
          <span className="badge bg-danger rounded-pill"></span>
        </DropdownToggle>

        <DropdownMenu className="sendMailWrap p-0">
          <div className="sendMailTitleRow">
            <Row>
              <Col>
                <span className="sendMailTitle">{t("contactus")}</span>
              </Col>
            </Row>
          </div>

          <Form onSubmit={OnSubmitHandler}>
            <Row className="m-0 p-0">
              <Col className="m-0 p-0">
                <div className="sendMailInside">
                  <div>
                    <label className="sendMailName">{t("name")}</label>
                    <Input
                      type="text"
                      name="_name"
                      className="sendMailInput"
                      value={name}
                      onChange={handleName}
                      placeholder={t("nameplaceholder")}
                      required
                    />
                  </div>
                  <div>
                    <label className="sendMailName">{t("email")}</label>
                    <Input
                      type="email"
                      name="_email"
                      className="sendMailInput"
                      value={email}
                      onChange={handleEmail}
                      placeholder={t("emailplaceholder")}
                    />
                  </div>
                  <div>
                    <label className="sendMailName">{t("message")}</label>
                    <Input
                      type="textarea"
                      name="_messege"
                      className="sendMailMessage"
                      value={message}
                      onChange={handleMessage}
                      placeholder={t("messageplaceholder")}
                    />
                  </div>
                  <Row className="sendMailBtnWrap">
                    <Col>
                      <button type="submit" className="sendMailBtn">
                        {t("send")}
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default SendMail;

SendMail.propTypes = {
  t: PropTypes.any,
};
