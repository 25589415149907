import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { CardBody } from "reactstrap";
import PaginationWrap from "./PaginationWrap";

import Profile from "../Utility/Profile/IrrigationEdit";
import { useTranslation } from "react-i18next";

import "simplebar/src/simplebar.css";
import "react-dates/initialize";
import { useHistory } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import SensorDataListRow from "./SensorDataListRow";
import { ReactComponent as ArrowLeft } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-arrow-left.svg";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./table.scss";

const SensorDataList = (props) => {
  const [allSelected, setAllSelected] = useState(false);
  const [selects, setSelects] = useState([]);
  const [userInfo, setUserInfo] = useState(props.location.state.userInfo);
  const [types] = useState(props.location.state.types);
  const [page, setPage] = useState(1);
  const [userId, setUserId] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [usercluster, setUserCluster] = useState(
    props.location.state.usercluster
  );
  const [sideswicth, setSideswitch] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState([]);
  const [type, setType] = useState("");
  const offset = 50;
  const indexOfLastPost = page * offset;
  const indexOfFirstPost = indexOfLastPost - offset;
  const history = useHistory();
  const [pageNumber, setpageNumber] = useState([]);
  const { t } = useTranslation("main");

  const outsideClick = (e) => {
    e.preventDefault();
    setEditOpen(false);
  };

  useEffect(() => {
    setType(userInfo[0]?.deviceType);
    if (userInfo?.length > 0 && Array.isArray(userInfo)) {
      setUserId(userInfo.map((data) => data.id));
      setCurrentPosts(userInfo.slice(indexOfFirstPost, indexOfLastPost));
    }
  }, [userInfo, indexOfFirstPost, indexOfLastPost]);

  useEffect(() => {
    if (userInfo?.length > 0 && Array.isArray(userInfo)) {
      setCurrentPosts(userInfo.slice(indexOfFirstPost, indexOfLastPost));
    }
  }, [page, userInfo, indexOfFirstPost, indexOfLastPost]);

  const paginate = (pageNumber) => setPage(pageNumber);

  const goSensors = () => {
    history.push({
      pathname: "/sensors/type",
      state: {
        deviceType: types,
      },
    });
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensors Data List | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap11">
        <div className="container">
          <div>
            <Row className="d-flex justify-content-center">
              <Col md={9} className="chartwrap">
                {!editOpen ? (
                  <Card className="dataListCardWrap">
                    <CardBody>
                      <div
                        className="d-flex"
                        style={{ marginBottom: "20px", alignItems: "center" }}
                      >
                        <ArrowLeft
                          className="gosensorsListIcon"
                          onClick={goSensors}
                          style={{ cursor: "pointer", margin: "0 30px 0 0" }}
                        />
                        <h4 className="card-title m-0">Sensors Data List</h4>
                      </div>
                      <div className="table-responsive">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button"
                          table="table-to-xls"
                          filename="tablexls"
                          sheet="tablexls"
                          buttonText="Download as XLS"
                        />
                        <table
                          id="table-to-xls"
                          className="table"
                          style={{ marginBottom: "10px" }}
                        >
                          <thead>
                            {type === "SOIL" || type === "SMS" ? (
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  UID
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Temp
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  HUM
                                </th>
                                {/* <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  EC
                                </th> */}
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Battery
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Firmware Version
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Live
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            ) : type === "CO2" || type === "AT" ? (
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  UID
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  CO2 농도
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  대기온도
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  대기습도
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  기압
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  광량
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Firmware Version
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Live
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            ) : type === "WATERFLOW" ? (
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  UID
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Flow Rate
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Total Flow
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Firmware Version
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Live
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            ) : type === "NUTRIENT" ? (
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  UID
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  pH
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  온도
                                </th>
                                {/* <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  EC
                                </th> */}
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Firmware Version
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Live
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            ) : type === "DRAINAGE" ? (
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  UID
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  배액량
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  pH
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  온도
                                </th>
                                {/* <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  EC
                                </th> */}
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Firmware Version
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Live
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            ) : type === "FMS" ? (
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  UID
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  온도
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  습도
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  EC
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Firmware Version
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Live
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            ) : type === "COMPOST" ? (
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  UID
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Temp
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Moisture(15)
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Moisture(30)
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Battery
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Firmware Version
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Live
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            ) : type === "ATS02" ? (
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  UID
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  풍향
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  풍속
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  강수량
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  광량
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  온도
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  습도
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  압력
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Battery
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Firmware Version
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Live
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Time
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #000",
                                    textAlign: "center",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </thead>
                          <tbody>
                            <SensorDataListRow
                              type={type}
                              allSelected={allSelected}
                              selects={selects}
                              setSelects={setSelects}
                              userInfo={userInfo}
                              setUserInfo={setUserInfo}
                              currentPosts={currentPosts}
                              setAllSelected={setAllSelected}
                              usercluster={usercluster}
                            />
                          </tbody>
                        </table>
                        <PaginationWrap
                          pageNumber={pageNumber}
                          setpageNumber={setpageNumber}
                          lastpage={Math.ceil(userInfo.length / 5)}
                          setActiveNum={setPage}
                          activeNum={page}
                        />
                      </div>
                    </CardBody>
                  </Card>
                ) : editOpen ? (
                  <>
                    <div className="outside" onClick={(e) => outsideClick(e)} />
                    <Profile
                      editData={editData}
                      setEditOpen={setEditOpen}
                      usercluster={usercluster}
                    />
                  </>
                ) : (
                  <div className="insideText">SENSORS</div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SensorDataList;
