import React, { useEffect } from "react";
import { requestGet } from "../../apis/request";
import SimpleBarReact from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./sensorrow.scss";
import { ReactComponent as Database } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-database.svg";
import { ReactComponent as Thermometer } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-thermometer-half.svg";
import { ReactComponent as Raindrops } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { ReactComponent as Sun } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-sun.svg";
import { ReactComponent as Drainagetemperature } from "../../assets/icons/drainagetemperature.svg";
import { ReactComponent as Co2 } from "../../assets/icons/co2.svg";
import { ReactComponent as Drainagewaterflow } from "../../assets/icons/drainagewaterflow.svg";
import { ReactComponent as Fullwifi } from "../../assets/icons/fullwifi.svg";
import { ReactComponent as Nutrienttemperature } from "../../assets/icons/nutrienttemperature.svg";
import { ReactComponent as Phtemperature } from "../../assets/icons/phtemperature.svg";
import { ReactComponent as Temperature } from "../../assets/icons/temperature.svg";
import { ReactComponent as Speed } from "../../assets/icons/speed.svg";
import { ReactComponent as Flow } from "../../assets/icons/flow.svg";
import { ReactComponent as WindD } from "../../assets/icons/winD.svg";
import { ReactComponent as WindS } from "../../assets/icons/winS.svg";
import { ReactComponent as Angry } from "../../assets/icons/uicons-regular-rounded/svg/face-angry.svg";
import { ReactComponent as RainHum } from "../../assets/icons/rainhum.svg";
import { ReactComponent as Hpa } from "../../assets/icons/ph.svg";
import { ReactComponent as Acc } from "../../assets/icons/acceleration.svg";
import { ReactComponent as Dacc } from "../../assets/icons/dacceleration.svg";
import { ReactComponent as Gyro } from "../../assets/icons/gyro.svg";
import { ReactComponent as Dgyro } from "../../assets/icons/dgyro.svg";
import { ReactComponent as Altitude } from "../../assets/icons/svgs/light/line-height.svg";
import Battery from "../../assets/icons/001-empty-battery_2021-11-05/001-empty-battery.png";
import Energy from "../../assets/icons/002-renewable-energy_2021-11-05/002-renewable-energy.png";
import Pump from "../../assets/icons/pumpicon.png";
import Temp from "../../assets/icons/temp.png";
import Hum from "../../assets/icons/hum.png";
import DeviceHum from "../../assets/icons/devicehum.png";
import "react-datepicker/dist/react-datepicker.css";

const SensorCheaps = ({
  type,
  dataListId,
  selects,
  setSelects,
  setUserInfo,
  sensorLog,
  setSensorLog,
  setUserCluster,
  topDeviceTypes,
  lastidx,
  setLastidx,
}) => {
  const getsensor = async (value) => {
    try {
      const sensor = await requestGet(`/api/v1/sensor/list/${value}`);
      return sensor;
    } catch (error) {
      alert("데이터 불러오는데 실패하였습니다.");
    }
  };

  const sensors = async () => {
    try {
      let arr = [];
      if (typeof type === "string") {
        const sensor = await requestGet(`/api/v1/sensor/list/${type}`);
        return setUserInfo(sensor);
      } else {
        for (let value of type) {
          const result = await getsensor(value);
          if (result) {
            arr.push(...result);
          }
        }
      }
      setUserInfo(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sensors();
  }, [type]);

  const clu = async () => {
    const clusterList = await requestGet(`/api/v1/cluster/list`);
    setUserCluster(clusterList);
  };

  useEffect(() => {
    clu();
  }, []);

  useEffect(() => {
    if (dataListId !== undefined) {
      setSelects([dataListId]);
    }
  }, [dataListId]);

  const callsensors = async () => {
    if (selects[lastidx] !== undefined) {
      const log = await requestGet(
        `/api/v1/sensor/read/log/${selects[lastidx]}/latest`
      );
      setSensorLog(log);
      // setEndDate(moment(log.createdDate));
    }
  };

  useEffect(() => {
    setLastidx(selects.length - 1);
    if (selects.length >= 0) {
      callsensors();
    } else {
      return;
    }
  }, [selects, lastidx]);

  return (
    <SimpleBarReact style={{ maxHeight: "66vh" }}>
      <div style={{ display: "flex" }}>
        <div className="bbadge" style={{ backgroundColor: "#939393" }}>
          <span
            className="sensorsListIcon"
            style={{ display: "flex", alignItems: "center" }}
          >
            S/N
          </span>
          {sensorLog.sensorId ? sensorLog.sensorId : selects[lastidx]}
        </div>
        <div className="bbadge">
          <Database
            className="sensorsListIcon"
            style={{ marginRight: "15px" }}
          />
          V{sensorLog.fwversion ? sensorLog.fwversion : "1.0"}
        </div>
        <div className="bbadge">
          <Fullwifi className="sensorsListIcon" />
          {sensorLog.rssi ? sensorLog.rssi : sensorLog.rssi === 0 ? "00" : "--"}
        </div>
        {topDeviceTypes === "SMS" ? (
          <>
            <div className="bbadge">
              <Thermometer className="sensorsListIcon" />
              {sensorLog.soilTemperature
                ? sensorLog.soilTemperature
                : sensorLog.soilTemperature === 0
                ? "00"
                : "--"}
              °C
            </div>
            <div className="bbadge">
              <Raindrops className="sensorsListIcon" />
              {sensorLog.moisture
                ? sensorLog.moisture
                : sensorLog.moisture === 0
                ? "00"
                : "--"}
              %
            </div>
            <div className="bbadge">
              <img src={Battery} alt="battert" className="sensorsListIcon" />
              {sensorLog.battery
                ? sensorLog.battery
                : sensorLog.battery === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : type === "ATS02" ? (
          <>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <WindD className="sensorsListIcon" />
              </span>
              {sensorLog.windD
                ? sensorLog.windD
                : sensorLog.windD === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <WindS className="sensorsListIcon" />
              </span>
              {sensorLog.windS
                ? sensorLog.windS
                : sensorLog.windS === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RainHum className="sensorsListIcon" />
              </span>
              {sensorLog.rain
                ? sensorLog.rain
                : sensorLog.rain === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <Sun className="sensorsListIcon" />
              {sensorLog.illuminance
                ? sensorLog.illuminance
                : sensorLog.illuminance === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <Drainagetemperature className="sensorsListIcon" />
              {sensorLog.temperature
                ? sensorLog.temperature
                : sensorLog.temperature === 0
                ? "00"
                : "--"}
              °C
            </div>
            <div className="bbadge">
              <Raindrops className="sensorsListIcon" />
              {sensorLog.humidity
                ? sensorLog.humidity
                : sensorLog.humidity === 0
                ? "00"
                : "--"}
              %
            </div>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Hpa className="sensorsListIcon" />
              </span>
              {sensorLog.pressure
                ? sensorLog.pressure
                : sensorLog.pressure === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : topDeviceTypes === "AT" ? (
          <>
            <div className="bbadge">
              <Co2 className="sensorsListIcon" />
              {sensorLog.co2
                ? sensorLog.co2
                : sensorLog.co2 === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <Raindrops className="sensorsListIcon" />
              {sensorLog.humidity
                ? sensorLog.humidity
                : sensorLog.humidity === 0
                ? "00"
                : "--"}
              %
            </div>
            <div className="bbadge">
              <span>
                <Hpa className="sensorsListIcon" />
              </span>
              {sensorLog.pressure
                ? sensorLog.pressure
                : sensorLog.pressure === 0
                ? "00"
                : "--"}
              hPa
            </div>
            <div className="bbadge">
              <Sun className="sensorsListIcon" />
              {sensorLog.solar
                ? sensorLog.solar
                : sensorLog.solar === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <Thermometer className="sensorsListIcon" />
              {sensorLog.temperature
                ? sensorLog.temperature
                : sensorLog.temperature === 0
                ? "00"
                : "--"}
              °C
            </div>
            <div className="bbadge">
              <span>TD </span>
              {sensorLog.td ? sensorLog.td : sensorLog.td === 0 ? "00" : "--"}
            </div>
            <div className="bbadge">
              <span>VPD </span>
              {sensorLog.vpd
                ? sensorLog.vpd
                : sensorLog.vpd === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : type === "WATERFLOW" ? (
          <>
            <div className="bbadge">
              <Flow className="sensorsListIcon" />
              {sensorLog.flowmeter
                ? sensorLog.flowmeter
                : sensorLog.flowmeter === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <Speed className="sensorsListIcon" />
              {sensorLog.speed
                ? sensorLog.speed
                : sensorLog.speed === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <img src={Pump} alt="Pump" className="sensorsListIcon" />
              {sensorLog.pumpopencount
                ? sensorLog.pumpopencount
                : sensorLog.pumpopencount === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : type === "NUTRIENT" ? (
          <>
            <div className="bbadge">
              <Nutrienttemperature className="sensorsListIcon" />
              {sensorLog.temperature
                ? sensorLog.temperature
                : sensorLog.temperature === 0
                ? "00"
                : "--"}
              °C
            </div>
            <div className="bbadge">
              <Phtemperature className="sensorsListIcon" />
              {sensorLog.ph ? sensorLog.ph : sensorLog.ph === 0 ? "00" : "--"}
            </div>
            <div className="bbadge">
              <img src={Energy} alt="energy" className="sensorsListIcon" />
              {sensorLog.ec ? sensorLog.ec : sensorLog.ec === 0 ? "00" : "--"}
            </div>
            <div className="bbadge">
              <span>DO</span>
              {sensorLog.gdo
                ? sensorLog.gdo
                : sensorLog.gdo === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <img src={Battery} alt="battert" className="sensorsListIcon" />
              {sensorLog.battery
                ? sensorLog.battery
                : sensorLog.battery === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : type === "DRAINAGE" ? (
          <>
            <div className="bbadge">
              <Drainagewaterflow className="sensorsListIcon" />
              {sensorLog.waterlevel
                ? sensorLog.waterlevel
                : sensorLog.waterlevel === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <Drainagetemperature className="sensorsListIcon" />
              {sensorLog.temperature
                ? sensorLog.temperature
                : sensorLog.temperature === 0
                ? "00"
                : "--"}
              °C
            </div>
          </>
        ) : topDeviceTypes === "FMS" ? (
          <>
            <div className="bbadge">
              <span>
                <Temperature className="sensorsListIcon" />
              </span>
              {sensorLog.temperature
                ? sensorLog.temperature
                : sensorLog.temperature === 0
                ? "00"
                : "--"}
              °C
            </div>
            <div className="bbadge">
              <Raindrops className="sensorsListIcon" />
              {sensorLog.moisture
                ? sensorLog.moisture
                : sensorLog.moisture === 0
                ? "00"
                : "--"}
              %
            </div>
            <div className="bbadge">
              <Acc className="sensorsListIcon" />
              {sensorLog.acceleration
                ? sensorLog.acceleration
                : sensorLog.acceleration === 0
                ? "00"
                : "--"}
            </div>
            {/* <div className="bbadge">
              <Dacc className="sensorsListIcon" />
              {sensorLog.dacceleration
                ? sensorLog.dacceleration
                : sensorLog.dacceleration === 0
                ? "00"
                : "--"}
            </div> */}
            <div className="bbadge">
              <Gyro className="sensorsListIcon" />
              {sensorLog.gyroActivity
                ? sensorLog.gyroActivity
                : sensorLog.gyroActivity === 0
                ? "00"
                : "--"}
            </div>
            {/* <div className="bbadge">
              <Dgyro className="sensorsListIcon" />
              {sensorLog.dgyro
                ? sensorLog.dgyro
                : sensorLog.dgyro === 0
                ? "00"
                : "--"}
            </div> */}
            <div className="bbadge">
              <Altitude className="sensorsListIcon" />
              {sensorLog.altitude
                ? sensorLog.altitude
                : sensorLog.altitude === 0
                ? "00"
                : "--"}
              m
            </div>
            <div className="bbadge">
              <Sun className="sensorsListIcon" />
              {sensorLog.lux
                ? sensorLog.lux
                : sensorLog.lux === 0
                ? "00"
                : "--"}
              lx
            </div>
            <div className="bbadge">
              <Hpa className="sensorsListIcon" />
              {sensorLog.press
                ? sensorLog.press
                : sensorLog.press === 0
                ? "00"
                : "--"}
              hpa
            </div>
            <div className="bbadge">
              <img src={Battery} alt="battert" className="sensorsListIcon" />
              {sensorLog.battery
                ? sensorLog.battery
                : sensorLog.battery === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : topDeviceTypes === "FMS02" ? (
          <>
            <div className="bbadge">
              <span>
                <Temperature className="sensorsListIcon" />
              </span>
              {sensorLog.temperature
                ? sensorLog.temperature
                : sensorLog.temperature === 0
                ? "00"
                : "--"}
              °C
            </div>
            <div className="bbadge">
              <Raindrops className="sensorsListIcon" />
              {sensorLog.moisture
                ? sensorLog.moisture
                : sensorLog.moisture === 0
                ? "00"
                : "--"}
              %
            </div>
            <div className="bbadge">
              <Acc className="sensorsListIcon" />
              {sensorLog.acceleration
                ? sensorLog.acceleration
                : sensorLog.acceleration === 0
                ? "00"
                : "--"}
            </div>
            {/* <div className="bbadge">
              <Dacc className="sensorsListIcon" />
              {sensorLog.dacceleration
                ? sensorLog.dacceleration
                : sensorLog.dacceleration === 0
                ? "00"
                : "--"}
            </div> */}
            <div className="bbadge">
              <Gyro className="sensorsListIcon" />
              {sensorLog.gyroActivity
                ? sensorLog.gyroActivity
                : sensorLog.gyroActivity === 0
                ? "00"
                : "--"}
            </div>
            {/* <div className="bbadge">
              <Dgyro className="sensorsListIcon" />
              {sensorLog.dgyro
                ? sensorLog.dgyro
                : sensorLog.dgyro === 0
                ? "00"
                : "--"}
            </div> */}
            <div className="bbadge">
              <Hpa className="sensorsListIcon" />
              {sensorLog.press
                ? sensorLog.press
                : sensorLog.press === 0
                ? "00"
                : "--"}
              hpa
            </div>
            <div className="bbadge">
              <img src={Battery} alt="battert" className="sensorsListIcon" />
              {sensorLog.battery
                ? sensorLog.battery
                : sensorLog.battery === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : type === "LAT" ? (
          <>
            <div className="bbadge">
              <span>
                <Raindrops className="sensorsListIcon" />
              </span>
              {sensorLog.humidity
                ? sensorLog.humidity
                : sensorLog.humidity === 0
                ? "00"
                : "--"}
              %
            </div>
            <div className="bbadge">
              <span>
                <Hpa className="sensorsListIcon" />
              </span>
              {sensorLog.pressure
                ? sensorLog.pressure
                : sensorLog.pressure === 0
                ? "00"
                : "--"}
              hPa
            </div>
            <div className="bbadge">
              <Thermometer className="sensorsListIcon" />
              {sensorLog.temperature
                ? sensorLog.temperature
                : sensorLog.temperature === 0
                ? "00"
                : "--"}
              °C
            </div>
            <div className="bbadge">
              <Angry className="sensorsListIcon" />
              {sensorLog.stress
                ? sensorLog.stress
                : sensorLog.stress === 0
                ? "00"
                : "--"}
              %
            </div>
            <div className="bbadge">
              <img src={Battery} alt="battert" className="sensorsListIcon" />
              {sensorLog.battery
                ? sensorLog.battery
                : sensorLog.battery === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : type === "COMPOST" ? (
          <>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={Temp} alt="temp" className="sensorsListIcon" />
                15
              </span>
              {sensorLog.t15
                ? sensorLog.t15
                : sensorLog.t15 === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={Temp} alt="temp" className="sensorsListIcon" />
                30
              </span>
              {sensorLog.t30
                ? sensorLog.t30
                : sensorLog.t30 === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={Hum} alt="hum" className="sensorsListIcon" />
                15
              </span>
              {sensorLog.m15
                ? sensorLog.m15
                : sensorLog.m15 === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={Hum} alt="hum" className="sensorsListIcon" />
                30
              </span>
              {sensorLog.m30
                ? sensorLog.m30
                : sensorLog.m30 === 0
                ? "00"
                : "--"}
            </div>
            <div className="bbadge">
              <span
                className="sensorsListIcon"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={DeviceHum}
                  alt="DeviceHum"
                  className="sensorsListIcon"
                />
              </span>
              {sensorLog.rh ? sensorLog.rh : sensorLog.rh === 0 ? "00" : "--"}
            </div>
          </>
        ) : type === "WATERLEVEL" ? (
          <>
            <div className="bbadge">
              <Flow className="sensorsListIcon" />
              {sensorLog.l ? sensorLog.l : sensorLog.l === 0 ? "00" : "--"}
            </div>
            <div className="bbadge">
              <img src={Battery} alt="battert" className="sensorsListIcon" />
              {sensorLog.battery
                ? sensorLog.battery
                : sensorLog.battery === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : // : type === "SES" ? (
        //   <>
        //     <div className="bbadge">
        //       <Thermometer className="sensorsListIcon" />
        //       {sensorLog.temperature
        //         ? sensorLog.temperature
        //         : sensorLog.temperature === 0
        //         ? "00"
        //         : "--"}
        //       °C
        //     </div>
        //     <div className="bbadge">
        //       <Phtemperature className="sensorsListIcon" />
        //       {sensorLog.ph ? sensorLog.ph : sensorLog.ph === 0 ? "00" : "--"}
        //     </div>
        //     <div className="bbadge">
        //       <img src={Energy} alt="energy" className="sensorsListIcon" />
        //       {sensorLog.ec ? sensorLog.ec : sensorLog.ec === 0 ? "00" : "--"}
        //     </div>
        //     <div className="bbadge">
        //       <span>DO</span>
        //       {sensorLog.gdo
        //         ? sensorLog.gdo
        //         : sensorLog.gdo === 0
        //         ? "00"
        //         : "--"}
        //     </div>
        //     <div className="bbadge">
        //       <img src={Battery} alt="battert" className="sensorsListIcon" />
        //       {sensorLog.battery
        //         ? sensorLog.battery
        //         : sensorLog.battery === 0
        //         ? "00"
        //         : "--"}
        //     </div>
        //   </>
        // )
        topDeviceTypes === "SSH" ? (
          <>
            <div className="bbadge">
              <Raindrops className="sensorsListIcon" />
              {sensorLog.moisture
                ? sensorLog.moisture
                : sensorLog.moisture === 0
                ? "00"
                : "--"}
              %
            </div>
            <div className="bbadge">
              <Thermometer className="sensorsListIcon" />
              {sensorLog.temperature
                ? sensorLog.temperature
                : sensorLog.temperature === 0
                ? "00"
                : "--"}
              °C
            </div>
            <div className="bbadge">
              <img src={Energy} alt="energy" className="sensorsListIcon" />
              {sensorLog.ec ? sensorLog.ec : sensorLog.ec === 0 ? "00" : "--"}
            </div>
            <div className="bbadge">
              <span>N</span>
              {sensorLog.n ? sensorLog.n : sensorLog.n === 0 ? "00" : "--"}
            </div>
            <div className="bbadge">
              <span>P</span>
              {sensorLog.p ? sensorLog.p : sensorLog.p === 0 ? "00" : "--"}
            </div>
            <div className="bbadge">
              <span>K</span>
              {sensorLog.k ? sensorLog.k : sensorLog.k === 0 ? "00" : "--"}
            </div>
            <div className="bbadge">
              <img src={Battery} alt="battert" className="sensorsListIcon" />
              {sensorLog.battery
                ? sensorLog.battery
                : sensorLog.battery === 0
                ? "00"
                : "--"}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </SimpleBarReact>
  );
};

export default SensorCheaps;
