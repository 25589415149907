import CheckComponent from "./CheckComponent";
import { ReactComponent as Settings } from "../../assets/images/chart/fi-rr-settings.svg";
import "./sensorrow.scss";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Col, Row } from "reactstrap";
import { apiUrl } from "../../apis/request";
import CheckComponentCOT from "./CheckComponentCOT";
const IrrigationRow = ({
  allSelected,
  selects,
  setSelects,
  farmData,
  setAllSelected,
  farmId,
  endDate,
  userInfo,
  setSideswitch,
  cotIds,
  setCotIds,
  setTopDeviceTypes,
}) => {
  const [deviceTypes, setDeviceTypes] = useState("");
  const history = useHistory();
  const editBtn = (e, sensor) => {
    e.preventDefault();
    history.push({
      pathname: "/irrigation/edit",
      state: {
        editData: sensor,
        usercluster: userInfo,
        farmId: farmId,
      },
    });
  };
  return farmData.map((sensor, i) => {
    const imgurl = apiUrl + sensor.imagePath;
    return (
      <tr key={i} className="sensorsrow">
        <td style={{ width: "100%", border: "none" }}>
          <Row
            className="d-flex align-items-center"
            style={{ height: "100%", width: "100%", margin: "0px" }}
          >
            <Col
              className="col-auto"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!sensor.imagePath ? (
                <div className="photoImage rounded-circle avatar-md" />
              ) : (
                <img
                  src={imgurl}
                  alt="photo"
                  className="photoImage rounded-circle avatar-md"
                />
              )}
            </Col>
            <Col className="rowWrap col-5">
              <div>{sensor.name ? sensor.name : sensor.serial}</div>
              <div>{sensor.serial}</div>
              <div>{sensor.description}</div>
            </Col>
            <Col className="settingWrap col-auto">
              <button
                onClick={(e) => editBtn(e, sensor)}
                className="settingBtn"
              >
                <Settings />
              </button>
              {sensor.deviceType === "COT" ? (
                <CheckComponentCOT
                  setSideswitch={setSideswitch}
                  allSelected={allSelected}
                  selects={selects}
                  setSelects={setSelects}
                  id={sensor.id}
                  deviceType={sensor.deviceType}
                  sensor={sensor}
                  setAllSelected={setAllSelected}
                  endDate={endDate}
                  cotIds={cotIds}
                  setCotIds={setCotIds}
                  deviceTypes={deviceTypes}
                  setDeviceTypes={setDeviceTypes}
                  setTopDeviceTypes={setTopDeviceTypes}
                />
              ) : (
                <CheckComponent
                  setSideswitch={setSideswitch}
                  allSelected={allSelected}
                  selects={selects}
                  setSelects={setSelects}
                  id={sensor.id}
                  deviceType={sensor.deviceType}
                  sensor={sensor}
                  setAllSelected={setAllSelected}
                  endDate={endDate}
                  cotIds={cotIds}
                  setCotIds={setCotIds}
                  deviceTypes={deviceTypes}
                  setDeviceTypes={setDeviceTypes}
                  setTopDeviceTypes={setTopDeviceTypes}
                />
              )}
            </Col>
          </Row>
        </td>
      </tr>
    );
  });
};

export default IrrigationRow;
