import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { requestGet } from "../../apis/request";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/ko";
import "./chart.scss";
import YDropdown from "./YDropdown";
import { useTranslation } from "react-i18next";
import SplineArea from "../AllCharts/apex/SplineArea";
import CSVDownloadBtn from "./CSVDownloadBtn";
const COTSensorchart = ({ startDate, endDate, ch, userDevice }) => {
  // const [userDevice, setUserDevice] = useState([]);
  const [respon, setRespon] = useState({});
  const [apiStartDate, setApiStartDate] = useState(
    moment(startDate).format("YYYY-MM-DD")
  );
  const [apiEndDate, setApiEndDate] = useState(
    moment(endDate).format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState({});
  const [min, setMin] = useState({});
  const [max, setMax] = useState({});
  const savedCallback = useRef();
  const [types, setTypes] = useState([]);
  const [resleng, setResleng] = useState(0);
  const { t } = useTranslation("main");

  useEffect(() => {
    setApiStartDate(moment(startDate).format("YYYY-MM-DD HH:mm"));
    setApiEndDate(moment(endDate).format("YYYY-MM-DD HH:mm"));
  }, [startDate, endDate]);

  const useInterval = (callback, delay) => {
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      const executeCallback = () => {
        savedCallback.current();
      };

      const timerId = setInterval(executeCallback, delay);

      return () => clearInterval(timerId);
    }, []);
  };

  // const getLog = async (value) => {
  //   try {
  //     const device = await requestGet(`/api/v1/sensor/controller/${value}`);
  //     if (setting) {
  //       setType(device.deviceType);
  //     }
  //     let arr = [];
  //     for (let i = 0; i < device.channels?.length; i++) {
  //       arr.push(i + 1);
  //     }
  //     setChLength(
  //       (prevState) => (
  //         console.log(prevState),
  //         {
  //           ...prevState,
  //           [`${value}`]: {
  //             ...prevState.value,
  //             chleng: arr,
  //           },
  //         }
  //       )
  //     );
  //     return device;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const callCotapi = async (value) => {
    try {
      setRespon({});
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/cot/${value.id}/ch/${
          ch ? ch : 1
        }/${apiStartDate}/${apiEndDate}`
      );
      for (let i = 0; i < response.length; i++) {
        response[i] = { sensorName: value.name, ...response[i] };
        delete response[i].sensorId;
        delete response[i].modifiedDate;
      }
      setRespon((respon) => ({
        ...respon,
        [value.name]: response,
      }));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    for (let i = 0; i < userDevice.length; i++) {
      callCotapi(userDevice[i]);
    }
  }, [userDevice, apiStartDate, apiEndDate, ch]);

  // const callGetLog = async () => {
  //   const arr = [];
  //   if (selects.length > 0) {
  //     for (let value of selects) {
  //       const result = await getLog(value);
  //       arr.push(result);
  //     }
  //   }
  //   setUserDevice(arr);
  // };

  // useEffect(() => {
  //   if (Array.isArray(selects)) {
  //     callGetLog();
  //   }
  // }, [selects]);

  // useInterval(() => {
  //   if (Array.isArray(selects)) {
  //     callGetLog();
  //   }
  // }, 5 * 60 * 1000);

  useEffect(() => {
    setTypes([...types, "valve"]);
  }, []);

  useEffect(() => {
    let leng = Object.values(respon);
    let num = 0;
    for (let i = 0; i < leng.length; i++) {
      num += leng[i].length;
    }
    setResleng(num);
  }, [respon]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <CSVDownloadBtn res={respon} />
          {[...new Set(types)]?.map((type, i) => {
            return (
              <Card key={i}>
                <CardBody>
                  <CardTitle className="cardtitleline font-size-14 mb-4">
                    {t(type)}
                    <div className="chartHeaderDiv">
                      {menu[type] ? (
                        <>
                          <YDropdown
                            setMin={setMin}
                            setMax={setMax}
                            setMenu={setMenu}
                            typeKey={type}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardTitle>
                  <SplineArea
                    menu={menu}
                    setMenu={setMenu}
                    userDevice={userDevice}
                    min={min[type]}
                    max={max[type]}
                    data={respon}
                    typeKey={type}
                  />
                </CardBody>
              </Card>
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default COTSensorchart;
