import CheckComponent from "./CheckComponent";
import "./sensorrow.scss";
import { useHistory } from "react-router-dom";
import { ReactComponent as Settings } from "../../assets/images/chart/fi-rr-settings.svg";
import { useState } from "react";
import { apiUrl } from "../../apis/request";
import { Col, Row } from "reactstrap";
const SensorRow = ({
  allSelected,
  selects,
  setSelects,
  userInfo,
  setAllSelected,
  endDate,
  usercluster,
  setSideswitch,
  setTopDeviceTypes,
}) => {
  const [deviceTypes, setDeviceTypes] = useState("");
  const history = useHistory();
  const editBtn = (e, data) => {
    e.preventDefault();
    history.push({
      pathname: "/sensors/edit",
      state: {
        editData: data,
        usercluster: usercluster,
      },
    });
  };

  return userInfo.map((data, i) => {
    const imgurl = apiUrl + data.imagePath;
    return (
      <tr key={i} className="sensorsrow">
        <td style={{ width: "100%", border: "none" }}>
          <Row
            className="d-flex align-items-center"
            style={{ height: "100%", width: "100%", margin: "0px" }}
          >
            <Col
              className="col-auto"
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              {!data.imagePath ? (
                <div className="photoImage rounded-circle avatar-md" />
              ) : (
                <img
                  src={imgurl}
                  alt="photo"
                  className="photoImage rounded-circle avatar-md"
                />
              )}
            </Col>
            <Col className="rowWrap col-5">
              <div>{data.name ? data.name : data.serial}</div>
              <div>{data.serial}</div>
              <div>{data.description}</div>
            </Col>

            <Col className="settingWrap col-auto">
              <button onClick={(e) => editBtn(e, data)} className="settingBtn">
                <Settings />
              </button>
              <CheckComponent
                className="settingCheck"
                allSelected={allSelected}
                selects={selects}
                sensorName={data.name}
                deviceType={data.deviceType}
                setSideswitch={setSideswitch}
                setSelects={setSelects}
                id={data.id}
                setAllSelected={setAllSelected}
                endDate={endDate}
                deviceTypes={deviceTypes}
                setDeviceTypes={setDeviceTypes}
                setTopDeviceTypes={setTopDeviceTypes}
              />
            </Col>
          </Row>
        </td>
      </tr>
    );
  });
};
export default SensorRow;
