import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { CardBody } from "reactstrap";
import { requestGet } from "../../apis/request";
import MapSensorRow from "./MapSensorRow";
import Maps from "./Maps";
import SimpleBarReact from "simplebar-react";
import MapTypelist from "./MapTypelist";
import "simplebar/src/simplebar.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import LeftSidebarProfile from "../Utility/Profile/IrrigationEdit";
import { ReactComponent as Left } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-left.svg";
import { ReactComponent as Right } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-right.svg";
import { MetaTags } from "react-meta-tags";
import $ from "jquery";
import GoogleSensorMap from "./GoogleSensorMap";

const MapSensorTypeList = (props) => {
  const [selects, setSelects] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [usercluster, setUserCluster] = useState([]);
  const [type] = useState(props.location.state.deviceType);
  const [sideswicth, setSideswitch] = useState(false);
  const [markers, setMarkers] = useState([]);

  const getsensor = async (value) => {
    try {
      const sensor = await requestGet(`/api/v1/sensor/list/${value}`);
      return sensor;
    } catch (error) {
      alert("데이터 불러오는데 실패하였습니다.");
    }
  };

  const sensors = async () => {
    let arr = [];
    if (typeof type === "string") {
      const sensor = await requestGet(`/api/v1/sensor/list/${type}`);
      return setUserInfo(sensor);
    } else {
      for (let value of type) {
        const result = await getsensor(value);
        arr.push(...result);
      }
    }
    setUserInfo(arr);
  };

  useEffect(() => {
    sensors();
  }, [type]);

  const clu = async () => {
    const clusterList = await requestGet(`/api/v1/cluster/list`);
    setUserCluster(clusterList);
  };

  useEffect(() => {
    clu();
  }, []);

  const headersideswitch = () => {
    setSideswitch(!sideswicth);
  };

  const sidebarheighy = $(".colwrap").height();

  useEffect(() => {
    if (userInfo) {
      let arr = [];
      userInfo.forEach((item) =>
        arr.push({
          lat: item.latitude,
          lng: item.longitude,
        })
      );
      setMarkers(arr);
    }
  }, [userInfo]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Map | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap1">
        <div className="container p-0 m-0" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <Row
              className="d-flex justify-content-center"
              style={{ height: "100%", width: "100%" }}
            >
              <Col
                md={3}
                className={sideswicth ? "switchcardwrap" : "colwrap"}
                style={
                  sideswicth ? { height: sidebarheighy } : { height: "100%" }
                }
              >
                <Card className="cardwrap m-0">
                  <CardBody
                    className="cardBodyWrap"
                    style={{
                      height: "100%",
                      overflow: "hidden",
                      margin: "0px",
                    }}
                  >
                    <div
                      className="table-responsive"
                      style={{ height: "100%", overflow: "hidden" }}
                    >
                      <SimpleBarReact
                        className="listtypesimplebar"
                        autoHide={false}
                        style={{ maxHeight: "100%" }}
                      >
                        <Table className="table mb-0">
                          <tbody className="tbodystyle1">
                            <MapSensorRow
                              setSideswitch={setSideswitch}
                              setSelects={setSelects}
                              userInfo={userInfo}
                              usercluster={usercluster}
                            />
                          </tbody>
                        </Table>
                      </SimpleBarReact>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <div className="sideMark" onClick={() => headersideswitch()}>
                {sideswicth ? (
                  <Right className="sideIcon" />
                ) : (
                  <Left className="sideIcon" />
                )}
              </div>
              <Col md={9} className="chartwrap">
                <Maps markers={markers} />
                {/* <GoogleSensorMap selects={selects} /> */}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MapSensorTypeList;
