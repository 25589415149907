import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { requestDelete, requestPut } from "../../../apis/request";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useHistory } from "react-router-dom";

const IrrigationNameEdit = (props) => {
  const [editData] = useState(props.location.state.editData);
  const [deleteHide, setDeleteHide] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [name, setName] = useState(editData.name);
  const { t } = useTranslation("main");
  const history = useHistory();

  document.addEventListener(
    "keydown",
    function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    },
    true
  );

  useEffect(() => {
    if (editData.name === null) {
      setName("");
    } else if (editData.name !== null) {
      setName(editData.name);
    }
  }, [editData]);

  const onNameHandler = (e) => {
    setName(e.currentTarget.value);
  };

  const hidehandle = () => {
    setDeleteHide(!deleteHide);
  };

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      const params = new URLSearchParams();
      params.append("name", name);
      await requestPut(`/api/v1/cluster/update/${editData.id}`, params);
      alert("수정 성공 하였습니다");
      history.push("/irrigation");
    } catch (error) {
      alert("수정 실패하였습니다");
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Irrigation Edit | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap11 d-flex align-items-center">
        <div className="container">
          <Row className="d-flex justify-content-center">
            <Col className="listAddDisplay">
              <Card className="adminSensorInfoWrap">
                <CardBody>
                  {/* <div>
                    <div className="float-end">
                      <i
                        className="deletehide bx bx-dots-vertical-rounded"
                        onClick={hidehandle}
                      />

                      {deleteHide ? (
                        <button
                          color="danger"
                          className="sensorDeletBtn"
                          onClick={() => {
                            setconfirm_alert(true);
                          }}
                        >
                          <i className="deleticon bx bx-trash" />
                          {t("delete")}
                        </button>
                      ) : (
                        ""
                      )}

                      {confirm_alert ? (
                        <SweetAlert
                          title="정말 삭제하시겠습니까?"
                          warning
                          showCancel
                          confirmButtonText={t("delete")}
                          confirmBtnBsStyle="danger"
                          cancelButtonText={t("cancel")}
                          cancelBtnBsStyle="success"
                          onConfirm={async () => {
                            setconfirm_alert(false);
                            setsuccess_dlg(!success_dlg);
                            await requestDelete(
                              `/api/v1/sensor/delete/${editData.id}`
                            );
                            history.push("/irrigation");
                          }}
                          onCancel={() => setconfirm_alert(false)}
                        ></SweetAlert>
                      ) : null}
                    </div>

                    <div className="clearfix"></div>
                    <div className="sensorInfoTitleWrap"></div>
                  </div> */}
                  <div
                    className="sensorInfoTable table-responsive"
                    style={{ padding: "0 40px 0 50px" }}
                  >
                    <Form onSubmit={onSubmitHandler}>
                      <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("name")}</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="name"
                          value={name}
                          onChange={onNameHandler}
                        />
                      </div>
                      {/* <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">
                          {t("description")}
                        </Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="description"
                          value={description}
                          onChange={onDescriptionHandler}
                        />
                      </div> */}
                      {/* <FormGroup className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("clusterid")}</Label>
                        <Input
                          type="select"
                          className="form-select listAddInput"
                          onChange={handlecluster}
                          value={clusterId}
                        >
                          <option value={0}>없음</option>
                          {usercluster.map((data, i) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                        </Input>
                      </FormGroup> */}
                      <div className="mt-4">
                        <button className="sensorUpdateBtn" type="submit">
                          {t("save")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IrrigationNameEdit;
