import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { requestGet } from "../../../apis/request";
import moment from "moment";
import "moment/locale/ko";
import excel from "../../../assets/icons/xls-file.svg";
import Pencil from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-pencil.svg";
import Hpa from "../../../assets/icons/ph.svg";
import Thermometer from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-thermometer-half.svg";
import Sun from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-sun.svg";
import Raindrops from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import Acc from "../../../assets/icons/acceleration.svg";
import Gyro from "../../../assets/icons/gyro.svg";
import Altitude from "../../../assets/icons/svgs/light/line-height.svg";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../Dashboard/LoadingScreen";

const FMSSplineArea = ({
  setMenu,
  userDevice,
  apistartdate,
  apienddate,
  min,
  max,
  hpamin,
  hpamax,
  altmin,
  altmax,
  tempmin,
  tempmax,
  gyromin,
  gyromax,
  dgyromin,
  dgyromax,
  sunmin,
  sunmax,
  hummin,
  hummax,
  accmin,
  accmax,
  daccmin,
  daccmax,
  setTableData,
  typeKey,
}) => {
  const [respon, setRespon] = useState([]);
  const [series, setSeries] = useState([]);
  const [inMin, setInMin] = useState();
  const [inMax, setInMax] = useState();
  const [hpaInMin, setHpaInMin] = useState();
  const [hpaInMax, setHpaInMax] = useState();
  const [altInMin, setAltInMin] = useState();
  const [altInMax, setAltInMax] = useState();
  const [tempInMin, setTempInMin] = useState();
  const [tempInMax, setTempInMax] = useState();
  const [gyroInMin, setGyroInMin] = useState();
  const [gyroInMax, setGyroInMax] = useState();
  const [dgyroInMin, setDgyroInMin] = useState();
  const [dgyroInMax, setDgyroInMax] = useState();
  const [sunInMin, setSunInMin] = useState();
  const [sunInMax, setSunInMax] = useState();
  const [humInMin, setHumInMin] = useState();
  const [humInMax, setHumInMax] = useState();
  const [accInMin, setAccInMin] = useState();
  const [accInMax, setAccInMax] = useState();
  const [daccInMin, setDaccInMin] = useState();
  const [daccInMax, setDaccInMax] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("main");

  const sensors = [
    "temperature",
    "moisture",
    "acceleration",
    "gyroActivity",
    "altitude",
    "lux",
    "press",
  ];

  const callapi = async () => {
    try {
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/${userDevice[0].id}/${apistartdate}/${apienddate}`
      );
      setRespon(response);
      setTableData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setRespon([]);
      setTableData({});
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   document.addEventListener("mousemove", function (e) {
  //     const tooltip = document.querySelector(".apexcharts-tooltip");
  //     if (tooltip) {
  //       const windowHeight = window.innerHeight;
  //       const tooltipHeight = tooltip.offsetHeight;
  //       const tooltipBottom = e.clientY + tooltipHeight;

  //       // 툴팁이 화면 밖으로 나갈 경우, 바닥에 고정
  //       if (tooltipBottom > windowHeight) {
  //         tooltip.style.top = windowHeight - tooltipHeight + "px";
  //       }
  //     }
  //   });
  // }, []);

  useEffect(() => {
    callapi();
  }, [apistartdate, apienddate, userDevice]);

  const getLog = async (sens) => {
    if (respon.length > 0) {
      if (sens !== "altitude" && sens !== "lux" && sens !== "press") {
        return {
          name: t(sens),
          data: respon.map((sensorItem, j) => {
            return [
              new Date(sensorItem.createdDate.replace(/-/g, "/")).getTime(),
              sensorItem[sens]?.toFixed(1),
            ];
          }),
        };
      }
      // else if (sens === "dacceleration") {
      //   return {
      //     name: t(sens),
      //     data: respon.map((sensorItem, j) => {
      //       return [
      //         new Date(sensorItem.createdDate.replace(/-/g, "/")).getTime(),
      //         respon[j - 1]
      //           ? (
      //               respon[j - 1]["acceleration"].toFixed(1) -
      //               respon[j]["acceleration"].toFixed(1)
      //             ).toFixed(1)
      //           : 0,
      //       ];
      //     }),
      //   };
      // } else if (sens === "dgyro") {
      //   return {
      //     name: t(sens),
      //     data: respon.map((sensorItem, j) => {
      //       return [
      //         new Date(sensorItem.createdDate.replace(/-/g, "/")).getTime(),
      //         respon[j - 1]
      //           ? (
      //               respon[j - 1]["gyroActivity"].toFixed(1) -
      //               respon[j]["gyroActivity"].toFixed(1)
      //             ).toFixed(1)
      //           : 0,
      //       ];
      //     }),
      //   };
      // }
      else if (sens === "altitude" || sens === "lux" || sens === "press") {
        return {
          name: t(sens),
          data: respon.map((sensorItem) => {
            return [
              new Date(sensorItem.createdDate.replace(/-/g, "/")).getTime(),
              sensorItem[sens] ? parseInt(sensorItem[sens]) : 0, // 소수점 없이 정수로 변환
            ];
          }),
        };
      }
    } else {
      return {
        name: t(sens),
        data: [],
      };
    }
  };

  const callChartApi = async () => {
    if (
      Array.isArray(userDevice) &&
      userDevice.length > 0 &&
      respon.length > 0
    ) {
      const arr = [];
      if (userDevice.length > 0) {
        for (let value of sensors) {
          const result = await getLog(value);
          arr.push(result);
        }
        arr.reduce((indices, series, currentIndex) => {
          const maxCurrentValue = Math.max(
            ...series.data.map((data) => data[1])
          );
          const maxPrevValue =
            indices[0] !== undefined
              ? Math.max(...arr[indices[0]].data.map((data) => data[1]))
              : -Infinity;

          if (maxCurrentValue > maxPrevValue) {
            indices.push(currentIndex);
          }
          return indices;
        }, []);
        setSeries(arr);
      }
    } else {
      setSeries([]);
    }
  };

  useEffect(() => {
    callChartApi();
  }, [userDevice, respon]);

  useEffect(() => {
    if (!min && !max) {
      return;
    } else {
      if (parseInt(min, 10) >= 0) {
        setInMin(parseInt(min, 10));
      } else if (parseInt(min, 10) <= 0) {
        setInMin(parseInt(min, 10));
      } else {
        setInMin(0);
      }
      if (parseInt(max, 10) >= 0) {
        setInMax(parseInt(max, 10));
      } else if (parseInt(max, 10) <= 0) {
        setInMax(parseInt(max, 10));
      } else {
        setInMax(0);
      }
    }
  }, [min, max]);

  useEffect(() => {
    if (!tempmin && !tempmax) {
      setTempInMin(undefined);
      setTempInMax(undefined);
    } else {
      if (parseInt(tempmin, 10) >= 0) {
        setTempInMin(parseInt(tempmin, 10));
      } else if (parseInt(tempmin, 10) <= 0) {
        setTempInMin(parseInt(tempmin, 10));
      } else {
        setTempInMin(0);
      }
      if (parseInt(tempmax, 10) >= 0) {
        setTempInMax(parseInt(tempmax, 10));
      } else if (parseInt(tempmax, 10) <= 0) {
        setTempInMax(parseInt(tempmax, 10));
      } else {
        setTempInMax(0);
      }
    }
  }, [tempmin, tempmax]);

  useEffect(() => {
    if (!gyromin && !gyromax) {
      setGyroInMin(undefined);
      setGyroInMax(undefined);
    } else {
      if (parseInt(gyromin, 10) >= 0) {
        setGyroInMin(parseInt(gyromin, 10));
      } else if (parseInt(gyromin, 10) <= 0) {
        setGyroInMin(parseInt(gyromin, 10));
      } else {
        setGyroInMin(0);
      }
      if (parseInt(gyromax, 10) >= 0) {
        setGyroInMax(parseInt(gyromax, 10));
      } else if (parseInt(gyromax, 10) <= 0) {
        setGyroInMax(parseInt(gyromax, 10));
      } else {
        setGyroInMax(0);
      }
    }
  }, [gyromin, gyromax]);

  useEffect(() => {
    if (!dgyromin && !dgyromax) {
      setDgyroInMin(undefined);
      setDgyroInMax(undefined);
    } else {
      if (parseInt(dgyromin, 10) >= 0) {
        setDgyroInMin(parseInt(dgyromin, 10));
      } else if (parseInt(dgyromin, 10) <= 0) {
        setDgyroInMin(parseInt(dgyromin, 10));
      } else {
        setDgyroInMin(0);
      }
      if (parseInt(dgyromax, 10) >= 0) {
        setDgyroInMax(parseInt(dgyromax, 10));
      } else if (parseInt(dgyromax, 10) <= 0) {
        setDgyroInMax(parseInt(dgyromax, 10));
      } else {
        setDgyroInMax(0);
      }
    }
  }, [dgyromin, dgyromax]);

  useEffect(() => {
    if (!hummin && !hummax) {
      setHumInMin(undefined);
      setHumInMax(undefined);
    } else {
      if (parseInt(hummin, 10) >= 0) {
        setHumInMin(parseInt(hummin, 10));
      } else if (parseInt(hummin, 10) <= 0) {
        setHumInMin(parseInt(hummin, 10));
      } else {
        setHumInMin(0);
      }
      if (parseInt(hummax, 10) >= 0) {
        setHumInMax(parseInt(hummax, 10));
      } else if (parseInt(hummax, 10) <= 0) {
        setHumInMax(parseInt(hummax, 10));
      } else {
        setHumInMax(0);
      }
    }
  }, [hummin, hummax]);

  useEffect(() => {
    if (!accmin && !accmax) {
      setAccInMin(undefined);
      setAccInMax(undefined);
    } else {
      if (parseInt(accmin, 10) >= 0) {
        setAccInMin(parseInt(accmin, 10));
      } else if (parseInt(accmin, 10) <= 0) {
        setAccInMin(parseInt(accmin, 10));
      } else {
        setAccInMin(0);
      }
      if (parseInt(accmax, 10) >= 0) {
        setAccInMax(parseInt(accmax, 10));
      } else if (parseInt(accmax, 10) <= 0) {
        setAccInMax(parseInt(accmax, 10));
      } else {
        setAccInMax(0);
      }
    }
  }, [accmin, accmax]);

  useEffect(() => {
    if (!daccmin && !daccmax) {
      setDaccInMin(undefined);
      setDaccInMax(undefined);
    } else {
      if (parseInt(daccmin, 10) >= 0) {
        setDaccInMin(parseInt(daccmin, 10));
      } else if (parseInt(daccmin, 10) <= 0) {
        setDaccInMin(parseInt(daccmin, 10));
      } else {
        setDaccInMin(0);
      }
      if (parseInt(daccmax, 10) >= 0) {
        setDaccInMax(parseInt(daccmax, 10));
      } else if (parseInt(daccmax, 10) <= 0) {
        setDaccInMax(parseInt(daccmax, 10));
      } else {
        setDaccInMax(0);
      }
    }
  }, [daccmin, daccmax]);

  useEffect(() => {
    if (!altmin && !altmax) {
      setAltInMin(undefined);
      setAltInMax(undefined);
    } else {
      if (parseInt(altmin, 10) >= 0) {
        setAltInMin(parseInt(altmin, 10));
      } else if (parseInt(altmin, 10) <= 0) {
        setAltInMin(parseInt(altmin, 10));
      } else {
        setAltInMin(0);
      }
      if (parseInt(altmax, 10) >= 0) {
        setAltInMax(parseInt(altmax, 10));
      } else if (parseInt(altmax, 10) <= 0) {
        setAltInMax(parseInt(altmax, 10));
      } else {
        setAltInMax(0);
      }
    }
  }, [altmin, altmax]);

  useEffect(() => {
    if (!sunmin && !sunmax) {
      setSunInMin(undefined);
      setSunInMax(undefined);
    } else {
      if (parseInt(sunmin, 10) >= 0) {
        setSunInMin(parseInt(sunmin, 10));
      } else if (parseInt(sunmin, 10) <= 0) {
        setSunInMin(parseInt(sunmin, 10));
      } else {
        setSunInMin(0);
      }
      if (parseInt(sunmax, 10) >= 0) {
        setSunInMax(parseInt(sunmax, 10));
      } else if (parseInt(sunmax, 10) <= 0) {
        setSunInMax(parseInt(sunmax, 10));
      } else {
        setSunInMax(0);
      }
    }
  }, [sunmin, sunmax]);

  useEffect(() => {
    if (!hpamin && !hpamax) {
      setHpaInMin(undefined);
      setHpaInMax(undefined);
    } else {
      if (parseInt(hpamin, 10) >= 0) {
        setHpaInMin(parseInt(hpamin, 10));
      } else if (parseInt(hpamin, 10) <= 0) {
        setHpaInMin(parseInt(hpamin, 10));
      } else {
        setHpaInMin(0);
      }
      if (parseInt(hpamax, 10) >= 0) {
        setHpaInMax(parseInt(hpamax, 10));
      } else if (parseInt(hpamax, 10) <= 0) {
        setHpaInMax(parseInt(hpamax, 10));
      } else {
        setHpaInMax(0);
      }
    }
  }, [hpamin, hpamax]);

  const onTempMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSTemp: true,
    }));
  };
  const onGyroMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSGyro: true,
    }));
  };
  const onDgyroMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSDgyro: true,
    }));
  };
  const onHumMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSHum: true,
    }));
  };
  const onAccMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSAcc: true,
    }));
  };
  const onDaccMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSDacc: true,
    }));
  };
  const onAltMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSAlt: true,
    }));
  };
  const onHpaMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSHpa: true,
    }));
  };
  const onSunMenu = () => {
    setMenu((menu) => ({
      ...menu,
      FMSSun: true,
    }));
  };

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
        format: "yyyy/MM/dd",
      },
    },
    yaxis: [
      {
        show: false,
        seriesName: t("temperature"),
        min: tempInMin,
        max: tempInMax,
      },
      {
        show: false,
        seriesName: t("mois"),
        min: humInMin,
        max: humInMax,
      },
      {
        show: false,
        seriesName: t("acceleration"),
        min: accInMin,
        max: accInMax,
      },
      {
        show: false,
        seriesName: t("gyroActivity"),
        min: gyroInMin,
        max: gyroInMax,
      },
      {
        show: false,
        seriesName: t("altitude"),
        min: altInMin,
        max: altInMax,
      },
      {
        show: false,
        seriesName: t("lux"),
        min: sunInMin,
        max: sunInMax,
      },
      {
        show: false,
        seriesName: t("press"),
        min: hpaInMin,
        max: hpaInMax,
      },
    ],
    // yaxis: {
    //   min: inMin,
    //   max: inMax,
    // },
    chart: {
      toolbar: {
        tools: {
          download: `<img src=${excel} width="20" height="20" />`,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          customIcons: [
            {
              icon: `<img src=${Thermometer} width="20" height="20" />`,
              index: 2,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onTempMenu();
              },
            },
            {
              icon: `<img src=${Raindrops} width="20" height="20" />`,
              index: 3,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onHumMenu();
              },
            },
            {
              icon: `<img src=${Acc} width="20" height="20" />`,
              index: 4,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onAccMenu();
              },
            },
            {
              icon: `<img src=${Gyro} width="20" height="20" />`,
              index: 5,
              title: "tooltip of the icon",
              class: "custom-icon custom-span",
              click: function (chart, options, e) {
                onGyroMenu();
              },
            },
            {
              icon: `<img src=${Altitude} width="20" height="20" />`,
              index: 6,
              title: "tooltip of the icon",
              class: "custom-icon custom-span",
              click: function (chart, options, e) {
                onAltMenu();
              },
            },
            {
              icon: `<img src=${Sun} width="20" height="20" />`,
              index: 7,
              title: "tooltip of the icon",
              class: "custom-icon custom-span",
              click: function (chart, options, e) {
                onSunMenu();
              },
            },
            {
              icon: `<img src=${Hpa} width="20" height="20" />`,
              index: 8,
              title: "tooltip of the icon",
              class: "custom-icon custom-span",
              click: function (chart, options, e) {
                onHpaMenu();
              },
            },
          ],
        },
        export: {
          csv: {
            filename: "FMS",
            columnDelimiter: ",",
            headerCategory: "FMS Date",
            headerValue: "value",
            dateFormatter(timestamp) {
              return moment(timestamp).format("YYYY/MM/DD HH:mm:ss");
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      followCursor: true,
      x: {
        format: "yyyy/MM/dd HH:mm",
      },
      y: {
        formatter: function (value, { seriesIndex, w }) {
          // 시리즈 이름을 확인하여 해당 시리즈에 대한 툴팁 포맷 결정
          const seriesName = w.config.series[seriesIndex].name;
          if ([t("altitude"), t("lux"), t("press")].includes(seriesName)) {
            // 값이 정수인 경우, 소수점을 제거하고 문자열로 반환
            return String(Math.round(value));
          }
          // 기타 시리즈는 소수점 한 자리까지 표시
          return value.toFixed(1);
        },
      },
    },
  };

  return loading ? (
    <div
      style={{
        height: "350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "150px" }}>
        <LoadingScreen />
      </div>
    </div>
  ) : (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="400px"
      className="apex-charts"
      dir="ltr"
    />
  );
};

export default FMSSplineArea;
