import PropTypes from "prop-types";
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import { requestPost } from "../../apis/request";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = (props) => {
  const [id] = useState("");
  const [name] = useState("");
  const [serial] = useState("1");
  const [deviceType] = useState("2");
  const [description] = useState("0");
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const history = useHistory();

  return (
    <React.Fragment>
      <MetaTags>
        <title>Profile | FarmCLO</title>
      </MetaTags>
      <Card className="h-100">
        <CardBody>
          <div>
            <div className="float-end">
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                Delete
              </Button>
              {confirm_alert ? (
                <SweetAlert
                  title="정말 삭제하시겠습니까?"
                  warning
                  showCancel
                  confirmButtonText="삭제"
                  confirmBtnBsStyle="danger"
                  cancelButtonText="취소"
                  cancelBtnBsStyle="success"
                  onConfirm={async () => {
                    setconfirm_alert(false);
                    setsuccess_dlg(!success_dlg);
                    await requestPost(`/api/v1/sensor/delete/${id}`);
                    history.push("/tables-datatable");
                  }}
                  onCancel={() => setconfirm_alert(false)}
                ></SweetAlert>
              ) : null}
            </div>

            <div className="clearfix"></div>

            <div className="text-center bg-pattern">
              <h4 className="text-primary mb-2">{name ? name : serial}</h4>
            </div>
          </div>
          <hr className="my-4" />
          <div className="table-responsive">
            <h5 className="font-size-16">Sensor Information</h5>

            <div>
              <p className="mb-1 text-muted">DeviceType :</p>
              <h5 className="font-size-18">{deviceType}</h5>
            </div>
            <div className="mt-3">
              <p className="mb-1 text-muted">Serial :</p>
              <h5 className="font-size-18">{serial}</h5>
            </div>
            <div className="mt-3">
              <p className="mb-1 text-muted">Description :</p>
              <h5 className="font-size-18">{description}</h5>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
);
