// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mode: "en",
  logintitle: "Log in",
  loginbutton: "LOG IN",
  loginsub: "Already have an account? Login below.",
  id: "ID",
  pw: "Password",
  forgot: "Forgot Password?",
  irrigation: "Irrigation Control",
  irrigationheader: "Irrigation",
  irrigationinfo: "Track usage of water in areas",
  irrigationtext: "Select water meter to analyze water consumption",
  sensors: "Sensor Data",
  sensorsheader: "Sensors",
  sensorsinfo:
    "View individual sensors and their data, side by side comparison of sensor",
  sensortext:
    "Select product line and their product list will be displayed and can view individual data of products.",
  map: "Map",
  mapinfo: "View the location of  sensors in areas",
  account: "My Account",
  accountinfo: "View, edit farm’s setting and information",
  soil: "SMS",
  co2: "CO2",
  SOIL: "SMS",
  CO2: "AT",
  setting: "setting",
  email: "Email",
  _email: "E-mail",
  lang: "Language",
  noti: "My\nNotification",
  name: "Name",
  devicetype: "Device Type",
  serial: "Serial",
  description: "Description",
  clusterid: "Cluster Id",
  latitude: "Latitude",
  longitude: "Longitude",
  delete: "Delete",
  save: "Save",
  cancel: "Cancel",
  confirm: "Confirm",
  reset: "Reset",
  farmid: "Cluster Id",
  farmdescription: "Description",
  farmsensors: "Sensor Add",
  notifications: "Notifications",
  message: "Message",
  contactus: "Contact Us",
  send: "SEND",
  nameplaceholder: "Enter your name...",
  emailplaceholder: "Enter your email...",
  messageplaceholder: "Enter message...",
  admin: "Admin",
  WATERFLOW: "WATERFLOW",
  WATERLEVEL: "WATERLEVEL",
  NUTRIENT: "NUTRIENT",
  gdo: "Dissolved Oxygen",
  DRAINAGE: "DRAINAGE",
  FMS: "FMS",
  FMS02: "FMS2",
  td: "TD",
  COMPOST: "COMPOST",
  SMS: "SMS",
  SSH01: "SSH01",
  COT: "Controller",
  SES: "SES",
  stress: "Stress",
  AT: "Environment AT",
  LAT: "Livestock AT",
  timezone: "Time Zone",
  phone: "Phone Number",
  nodata: "No Data.",
  nomapdata: "No Location Data",
  farmadd: "Farm ADD",
  farmdel: "Farm DELETE",
  sensoradd: "Sensor ADD",
  cotadd: "Controller ADD",
  addindex: "ADD,DELETE",
  addindexment: "You can Farm add and Sensor add or Farm delete",
  crops: "Crops",
  nickname: "Nickname",
  ATS02: "ATS02",
  widget: "Widget",
  changepassword: "Change Password",
  widgetHome: "Widget Home",
  minutesago: "Minutes ago",
  hoursago: "Hours ago",
  daysago: "Days ago",
  yearsago: "Years ago",
  justbefore: "Just before",
  lastupdate: "Last Update",
  soilTemperature: "Temperature",
  SoilTemperature: "Soil Temperature",
  ec: "ec",
  moisture: "moisture",
  battery: "battery",
  rssi: "rssi",
  temperature: "temperature",
  humidity: "humidity",
  Co2: "Co2",
  pressure: "pressure",
  press: "Pressure",
  solar: "solar",
  speed: "speed",
  flowmeter: "flowmeter",
  pumpopencount: "pumpopencount",
  ph: "ph",
  waterlevel: "waterlevel",
  t: "Temperature",
  t1: "Temp 1",
  t2: "Temp 2",
  rh: "Humidity",
  t15: "Temperature (15cm)",
  t30: "Temperature (30cm)",
  m15: "Moisture (15cm)",
  m30: "Moisture (30cm)",
  windD: "Wind Direction",
  windS: "Wind Speed",
  rain: "rain",
  illuminance: "illuminance",
  l: "waterlevel",
  pwrq: "Must contain at least 5 characters, English and numbers only.",
  pwrq1: "Must contain at least 8 characters, English and numbers only.",
  currenttemp: "Current Temperature (℃)",
  currenthum: "Humidity (%)",
  weather: "Weather",
  current: "Current",
  valve: "Valve",
  watering: "Watering Time(min) : ",
  lowmoi: "Low Moisture : ",
  highmoi: "High Moisture : ",
  currenttime: "Current Time : ",
  pertime: "Period Time(min) : ",
  repeat: "Repeat : ",
  csvdown: "Download all XLSX",
  co2eng: "CO2",
  ecno: "Conductivity",
  phno: "PH",
  n: "Nitrogen",
  p: "Phosphorus",
  k: "Potassium",
  ch: "Channel",
  lux: "Lux",
  sort: "sort",
  sortname: "Name Descending",
  sortnameRe: "Name Ascending",
  sortserial: "Serial Descending",
  sortserialRe: "Serial Ascending",
  sortdescription: "Description Descending",
  sortdescriptionRe: "Description Ascending",
  activity: "Activity",
  ysetting: "Y-axis Setting",
  mois: "Moisture",
  altitude: "Altitude",
  acceleration: "Acceleration",
  dacceleration: "Different Acceleration",
  gyro: "GyroActivity",
  gyroActivity: "GyroActivity",
  dgyro: "Different GyroActivity",
  remove: "Remove",
  add: "Add",
  sensorlist: "Sensor List",
  delsensor: "You want to delete?",
  delsecces: "Delete successful.",
  delfail: "Delete failed.",
  type: "Type",
  addtrigger: "Would you like to create a trigger?",
  addfail: "create failed.",
  addsuccess: "create successful.",
};
