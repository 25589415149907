import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import {
  CardBody,
  Button,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import IrrigationRow from "./IrrigationRow";
import SimpleBarReact from "simplebar-react";
import Sensorchart from "../Charts/Sensorchart";
import "simplebar/src/simplebar.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ReactComponent as Calendar } from "../../assets/images/chart/fi-rr-calendar.svg";
import { ReactComponent as Smallup } from "../../assets/images/chart/fi-rr-angle-small-up.svg";
import { ReactComponent as Smalldown } from "../../assets/images/chart/fi-rr-angle-small-down.svg";
import { ReactComponent as Left } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-left.svg";
import { ReactComponent as Right } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-right.svg";
import { ReactComponent as Database } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-database.svg";
import { ReactComponent as Thermometer } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-thermometer-half.svg";
import { ReactComponent as Raindrops } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { ReactComponent as Sun } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-sun.svg";
import { ReactComponent as Drainagetemperature } from "../../assets/icons/drainagetemperature.svg";
import { ReactComponent as Co2 } from "../../assets/icons/co2.svg";
import { ReactComponent as Drainagewaterflow } from "../../assets/icons/drainagewaterflow.svg";
import { ReactComponent as Fullwifi } from "../../assets/icons/fullwifi.svg";
import { ReactComponent as Nutrienttemperature } from "../../assets/icons/nutrienttemperature.svg";
import { ReactComponent as Phtemperature } from "../../assets/icons/phtemperature.svg";
import { ReactComponent as Temperature } from "../../assets/icons/temperature.svg";
import { ReactComponent as Speed } from "../../assets/icons/speed.svg";
import { ReactComponent as Flow } from "../../assets/icons/flow.svg";
import { ReactComponent as WindD } from "../../assets/icons/winD.svg";
import { ReactComponent as WindS } from "../../assets/icons/winS.svg";
import { ReactComponent as RainLevel } from "../../assets/icons/rainlevel.svg";
import { ReactComponent as Hpa } from "../../assets/icons/ph.svg";
import { ReactComponent as Acc } from "../../assets/icons/acceleration.svg";
import { ReactComponent as Dacc } from "../../assets/icons/dacceleration.svg";
import { ReactComponent as Gyro } from "../../assets/icons/gyro.svg";
import { ReactComponent as Dgyro } from "../../assets/icons/dgyro.svg";
import { ReactComponent as Altitude } from "../../assets/icons/svgs/light/line-height.svg";
import Battery from "../../assets/icons/001-empty-battery_2021-11-05/001-empty-battery.png";
import Energy from "../../assets/icons/002-renewable-energy_2021-11-05/002-renewable-energy.png";
import Pump from "../../assets/icons/pumpicon.png";
import Temp from "../../assets/icons/temp.png";
import Hum from "../../assets/icons/hum.png";
import DeviceHum from "../../assets/icons/devicehum.png";
import { MetaTags } from "react-meta-tags";
import { requestGet } from "../../apis/request";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import COTsensorWrap from "./COTsensorWrap";
import SensorchartIRR from "../Charts/SensorchartIRR";
import { ko } from "date-fns/esm/locale";

const IrrigationListFarm = (props) => {
  const [startDate, setStartDate] = useState(
    moment().format("YYYY-MM-DD 00:00")
  );
  const [endDate, setEndDate] = useState(moment());
  const [teststartDate, settestStartDate] = useState(new Date());
  const [testendDate, settestEndDate] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const [type, setType] = useState("");
  const [sortName, setSortName] = useState("name");
  const [selects, setSelects] = useState([]);
  const [cotIds, setCotIds] = useState([]);
  const [userInfo] = useState(props.location.state.userInfo);
  const [farmId] = useState(props.location.state.farmId);
  const [farmname] = useState(props.location.state.farmName);
  const [dataListId] = useState(props.location.state.id);
  const [sideswicth, setSideswitch] = useState(false);
  const [sensorLog, setSensorLog] = useState({});
  const [dayOpen, setDayOpen] = useState(false);
  const [activeDay, setActiveDay] = useState("");
  const [farmData, setFarmData] = useState([]);
  const [lastidx, setLastidx] = useState(0);
  const [customDate, setCustomDate] = useState(false);
  const [mqttData, setMqttData] = useState([]);
  const [ch, setCh] = useState({});
  const [nowMoi, setNowMoi] = useState([]);
  const [topDeviceTypes, setTopDeviceTypes] = useState("");
  const [chLength, setChLength] = useState([]);
  const { t } = useTranslation("main");

  useEffect(() => {
    setFarmData(
      userInfo
        .map((data) => data)
        .filter((data) => data.id === farmId)[0]
        .sensors.sort((a, b) => a.name.localeCompare(b.name))
        .reverse()
    );
  }, [userInfo, farmId]);

  const sensors = async () => {
    if (selects[selects.length - 1] !== undefined) {
      const log = await requestGet(
        `/api/v1/sensor/read/log/${selects[selects.length - 1]}/latest`
      );
      setSensorLog(log);
      setEndDate(moment(log.createdDate));
    }
  };

  useEffect(() => {
    setLastidx(selects.length - 1);
    if (selects.length >= 0) {
      sensors();
    }
  }, [selects]);

  const headersideswitch = () => {
    setSideswitch(!sideswicth);
  };

  const isOutsideRange = (day) => {
    return moment().diff(day) < 0;
  };

  const dayBtn = () => {
    setStartDate(moment().subtract(1, "d").format("YYYY-MM-DD 00:00"));
    setEndDate(moment().format("YYYY-MM-DD 23:59"));
    setActiveDay("day");
    setDayOpen(false);
  };

  const weeksBtn = () => {
    setStartDate(moment().subtract(1, "w").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("weeks");
    setDayOpen(false);
  };

  const monthsBtn = () => {
    setStartDate(moment().subtract(1, "M").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("months");
    setDayOpen(false);
  };

  const oneHourBtn = () => {
    setStartDate(moment().subtract(1, "H"));
    setEndDate(moment());
    setActiveDay("onehour");
    setDayOpen(false);
  };
  const threeHourBtn = () => {
    setStartDate(moment().subtract(3, "H"));
    setEndDate(moment());
    setActiveDay("threehour");
    setDayOpen(false);
  };
  const threemonthsBtn = () => {
    setStartDate(moment().subtract(3, "M").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("threemonths");
    setDayOpen(false);
  };
  const halfHourBtn = () => {
    setStartDate(moment().subtract(12, "H"));
    setEndDate(moment());
    setActiveDay("halfhour");
    setDayOpen(false);
  };
  const allmonthsBtn = () => {
    setStartDate(moment("2021-11-01"));
    setEndDate(moment());
    setActiveDay("all");
    setDayOpen(false);
  };
  const customdateBtn = () => {
    setActiveDay("custom");
    setCustomDate(true);
  };

  const dayControl = () => {
    setDayOpen(!dayOpen);
  };

  const closeall = () => {
    setCustomDate(false);
    setDayOpen(!dayOpen);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    settestStartDate(start);
    settestEndDate(end);
    if (start && end) {
      setStartDate(moment(start).format("YYYY-MM-DD 00:00"));
      setEndDate(moment(end).format("YYYY-MM-DD 23:59"));
      setCustomDate(false);
      setDayOpen(!dayOpen);
    }
  };

  const sidebarheighy = $(".colwrap").height();

  const callMqttsensors = async () => {
    if (selects[lastidx] !== undefined) {
      const log = await requestGet(
        `/api/v1/sensor/read/log/${selects[lastidx]}/latest/mqtt`
      );
      setMqttData(log);
    }
  };

  useEffect(() => {
    if (cotIds.length > 0) {
      for (let i = 0; i < cotIds.length; i++) {
        if (!ch[cotIds[i]]) {
          setCh((prevState) => ({
            ...prevState,
            [`${cotIds[i]}`]: {
              ch: "1",
            },
          }));
          console.log(cotIds[i]);
        }
      }
    }
  }, [cotIds]);

  const sortingName = () => {
    setFarmData(farmData.sort((a, b) => a.name.localeCompare(b.name)));
    setSortName("nameRe");
  };

  const sortingSerial = () => {
    setFarmData(farmData.sort((a, b) => Number(a.serial) - Number(b.serial)));
    setSortName("serialRe");
  };

  const sortingDescription = () => {
    setFarmData(
      farmData.sort((a, b) => a.description.localeCompare(b.description))
    );
    setSortName("descriptionRe");
  };

  const sortingNameRe = () => {
    setFarmData(
      farmData.sort((a, b) => a.name.localeCompare(b.name)).reverse()
    );
    setSortName("name");
  };

  const sortingSerialRe = () => {
    setFarmData(farmData.sort((a, b) => Number(b.serial) - Number(a.serial)));
    setSortName("serial");
  };

  const sortingDescriptionRe = () => {
    setFarmData(
      farmData
        .sort((a, b) => a.description.localeCompare(b.description))
        .reverse()
    );
    setSortName("description");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Irrigation List | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap1">
        <div className="container p-0 m-0" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <Row
              className="d-flex justify-content-center"
              style={{ height: "100%", width: "100%", padding: 0, margin: 0 }}
            >
              <Col
                md={3}
                className={sideswicth ? "switchcardwrap" : "colwrap"}
                style={
                  sideswicth ? { height: sidebarheighy } : { height: "100%" }
                }
              >
                <Card className="cardwrap m-0">
                  <span className="irrigationtitle">{t("irrigationtext")}</span>
                  <div style={{ padding: "0px 40px 20px" }}>
                    <UncontrolledDropdown
                      className="m-0 text-end"
                      direction="down"
                    >
                      <DropdownToggle
                        caret
                        color="primary"
                        className="arrow-down"
                      >
                        {t(`sort${sortName}`)}
                      </DropdownToggle>
                      <DropdownMenu id="sortDropMenu">
                        <DropdownItem onClick={sortingNameRe}>
                          {t("sortname")}
                        </DropdownItem>
                        <DropdownItem onClick={sortingName}>
                          {t("sortnameRe")}
                        </DropdownItem>
                        <DropdownItem onClick={sortingSerialRe}>
                          {t("sortserial")}
                        </DropdownItem>
                        <DropdownItem onClick={sortingSerial}>
                          {t("sortserialRe")}
                        </DropdownItem>
                        <DropdownItem onClick={sortingDescriptionRe}>
                          {t("sortdescription")}
                        </DropdownItem>
                        <DropdownItem onClick={sortingDescription}>
                          {t("sortdescriptionRe")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <CardBody
                    className="cardBodyWrap"
                    style={{ height: "100%", overflow: "hidden" }}
                  >
                    <div
                      className="table-responsive"
                      style={{ height: "100%", overflow: "hidden" }}
                    >
                      <SimpleBarReact
                        className="listtypesimplebar"
                        autoHide={false}
                        style={{ maxHeight: "100%" }}
                      >
                        <Table className="table mb-0">
                          <tbody className="tbodystyle1">
                            <IrrigationRow
                              setSideswitch={setSideswitch}
                              allSelected={allSelected}
                              selects={selects}
                              setSelects={setSelects}
                              farmData={farmData}
                              setAllSelected={setAllSelected}
                              farmId={farmId}
                              isOutsideRange={isOutsideRange}
                              endDate={endDate}
                              userInfo={userInfo}
                              cotIds={cotIds}
                              setCotIds={setCotIds}
                              setTopDeviceTypes={setTopDeviceTypes}
                            />
                          </tbody>
                        </Table>
                      </SimpleBarReact>
                    </div>
                  </CardBody>
                  <Button
                    className="selectSuccesBtn"
                    color="success"
                    onClick={() => setSideswitch(true)}
                  >
                    선택완료
                  </Button>
                </Card>
              </Col>
              <div className="sideMark" onClick={() => headersideswitch()}>
                {sideswicth ? (
                  <Right className="sideIcon" />
                ) : (
                  <Left className="sideIcon" />
                )}
              </div>
              <Col
                md={9}
                className="chartwrap"
                style={{ height: "100%", overflow: "auto" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="div354">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "14px 0px",
                      }}
                    >
                      <span className="div354Name">{farmname}</span>
                      <span
                        className="div354dateName"
                        style={{ marginLeft: "70px" }}
                      >
                        {sensorLog.createdDate?.split(" ").map((line) => (
                          <div className="createddate">{line}</div>
                        ))}
                      </span>
                    </div>
                    <div>
                      <div onClick={dayControl}>
                        <Calendar
                          className={
                            dayOpen ? "openCalendarmargin" : "calendarmargin"
                          }
                        />
                        {dayOpen ? <Smallup /> : <Smalldown />}
                      </div>
                      <Card
                        className={dayOpen ? "daycontrol" : "colseday"}
                        style={{ zIndex: 100 }}
                      >
                        <div className="dayrow">
                          <div
                            className={
                              activeDay === "onehour"
                                ? "activedaycol"
                                : "daycol"
                            }
                            onClick={oneHourBtn}
                          >
                            <div className="daymark" />
                            <span>1시간</span>
                          </div>
                          <div
                            className={
                              activeDay === "threehour"
                                ? "activedaycol"
                                : "daycol"
                            }
                            onClick={threeHourBtn}
                          >
                            <div className="daymark" />
                            <span>3시간</span>
                          </div>
                          <div
                            className={
                              activeDay === "halfhour"
                                ? "activedaycol"
                                : "daycol"
                            }
                            onClick={halfHourBtn}
                          >
                            <div className="daymark" />
                            <span>12시간</span>
                          </div>
                        </div>
                        <div className="dayrow">
                          <div
                            className={
                              activeDay === "day" ? "activedaycol" : "daycol"
                            }
                            onClick={dayBtn}
                          >
                            <div className="daymark" />
                            <span>1일</span>
                          </div>
                          <div
                            className={
                              activeDay === "weeks" ? "activedaycol" : "daycol"
                            }
                            onClick={weeksBtn}
                          >
                            <div className="daymark" />
                            <span>1주</span>
                          </div>
                          <div
                            className={
                              activeDay === "months" ? "activedaycol" : "daycol"
                            }
                            onClick={monthsBtn}
                          >
                            <div className="daymark" />
                            <span>1개월</span>
                          </div>
                        </div>
                        <div className="dayrow">
                          <div
                            className={
                              activeDay === "threemonths"
                                ? "activedaycol"
                                : "daycol"
                            }
                            onClick={threemonthsBtn}
                          >
                            <div className="daymark" />
                            <span>3개월</span>
                          </div>
                          <div
                            className={
                              activeDay === "all" ? "activedaycol" : "daycol"
                            }
                            onClick={allmonthsBtn}
                          >
                            <div className="daymark" />
                            <span>전체</span>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            className={
                              activeDay === "custom" ? "activedaycol" : "daycol"
                            }
                            onClick={customdateBtn}
                          >
                            <div className="daymark" />
                            <span>사용자 지정</span>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  {customDate ? (
                    <>
                      <div
                        className="chartOutside"
                        onClick={() => closeall()}
                      />
                      <DatePicker
                        selected={teststartDate}
                        onChange={onChange}
                        startDate={teststartDate}
                        endDate={testendDate}
                        dateFormat="yyyy년 MM월 dd일"
                        selectsRange
                        inline
                        locale={ko}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {selects.length !== 0 ? (
                    <SimpleBarReact style={{ maxHeight: "66vh" }}>
                      <div style={{ display: "flex" }}>
                        <div
                          className="bbadge"
                          style={{ backgroundColor: "#939393" }}
                        >
                          <span
                            className="sensorsListIcon"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            S/N
                          </span>
                          {sensorLog.sensorId
                            ? sensorLog.sensorId
                            : selects[lastidx]}
                        </div>
                        <div className="bbadge">
                          <Database
                            className="sensorsListIcon"
                            style={{ marginRight: "15px" }}
                          />
                          V{sensorLog.fwversion ? sensorLog.fwversion : "1.0"}
                        </div>
                        <div className="bbadge">
                          <Fullwifi className="sensorsListIcon" />
                          {sensorLog.rssi
                            ? sensorLog.rssi
                            : sensorLog.rssi === 0
                            ? "00"
                            : "--"}
                        </div>
                        {topDeviceTypes === "SMS" ? (
                          <>
                            <div className="bbadge">
                              <Thermometer className="sensorsListIcon" />
                              {sensorLog.soilTemperature
                                ? sensorLog.soilTemperature
                                : sensorLog.soilTemperature === 0
                                ? "00"
                                : "--"}
                              °C
                            </div>
                            <div className="bbadge">
                              <Raindrops className="sensorsListIcon" />
                              {sensorLog.moisture
                                ? sensorLog.moisture
                                : sensorLog.moisture === 0
                                ? "00"
                                : "--"}
                              %
                            </div>
                            {/* <div className="bbadge">
                              <img
                                src={Energy}
                                alt="energy"
                                className="sensorsListIcon"
                              />
                              {sensorLog.ec
                                ? sensorLog.ec
                                : sensorLog.ec === 0
                                ? "00"
                                : "--"}
                            </div> */}
                            <div className="bbadge">
                              <img
                                src={Battery}
                                alt="battert"
                                className="sensorsListIcon"
                              />
                              {sensorLog.battery
                                ? sensorLog.battery
                                : sensorLog.battery === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : type === "ATS02" ? (
                          <>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <WindD className="sensorsListIcon" />
                              </span>
                              {sensorLog.windD
                                ? sensorLog.windD
                                : sensorLog.windD === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <WindS className="sensorsListIcon" />
                              </span>
                              {sensorLog.windS
                                ? sensorLog.windS
                                : sensorLog.windS === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <RainLevel className="sensorsListIcon" />
                              </span>
                              {sensorLog.rain
                                ? sensorLog.rain
                                : sensorLog.rain === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <Sun className="sensorsListIcon" />
                              {sensorLog.illuminance
                                ? sensorLog.illuminance
                                : sensorLog.illuminance === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <Drainagetemperature className="sensorsListIcon" />
                              {sensorLog.temperature
                                ? sensorLog.temperature
                                : sensorLog.temperature === 0
                                ? "00"
                                : "--"}
                              °C
                            </div>
                            <div className="bbadge">
                              <Raindrops className="sensorsListIcon" />
                              {sensorLog.humidity
                                ? sensorLog.humidity
                                : sensorLog.humidity === 0
                                ? "00"
                                : "--"}
                              %
                            </div>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Hpa className="sensorsListIcon" />
                              </span>
                              {sensorLog.pressure
                                ? sensorLog.pressure
                                : sensorLog.pressure === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : topDeviceTypes === "AT" ? (
                          <>
                            <div className="bbadge">
                              <Thermometer className="sensorsListIcon" />
                              {sensorLog.temperature
                                ? sensorLog.temperature
                                : sensorLog.temperature === 0
                                ? "00"
                                : "--"}
                              °C
                            </div>
                            <div className="bbadge">
                              <Raindrops className="sensorsListIcon" />
                              {sensorLog.humidity
                                ? sensorLog.humidity
                                : sensorLog.humidity === 0
                                ? "00"
                                : "--"}
                              %
                            </div>
                            <div className="bbadge">
                              <Co2 className="sensorsListIcon" />
                              {sensorLog.co2
                                ? sensorLog.co2
                                : sensorLog.co2 === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <Sun className="sensorsListIcon" />
                              {sensorLog.solar
                                ? sensorLog.solar
                                : sensorLog.solar === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span>VPD </span>
                              {sensorLog.vpd
                                ? (Number(sensorLog.vpd) / 1000).toFixed(1)
                                : sensorLog.vpd === 0
                                ? "00"
                                : "--"}
                            </div>
                            {/* <div className="bbadge">
                              <span>SVP </span>
                              {sensorLog.svp
                                ? (Number(sensorLog.svp) / 1000).toFixed(1)
                                : sensorLog.svp === 0
                                ? "00"
                                : "--"}
                            </div> */}
                          </>
                        ) : type === "WATERFLOW" ? (
                          <>
                            <div className="bbadge">
                              <Flow className="sensorsListIcon" />
                              {sensorLog.flowmeter
                                ? sensorLog.flowmeter
                                : sensorLog.flowmeter === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <Speed className="sensorsListIcon" />
                              {sensorLog.speed
                                ? sensorLog.speed
                                : sensorLog.speed === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <img
                                src={Pump}
                                alt="Pump"
                                className="sensorsListIcon"
                              />
                              {sensorLog.pumpopencount
                                ? sensorLog.pumpopencount
                                : sensorLog.pumpopencount === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : type === "NUTRIENT" ? (
                          <>
                            <div className="bbadge">
                              <Nutrienttemperature className="sensorsListIcon" />
                              {sensorLog.temperature
                                ? sensorLog.temperature
                                : sensorLog.temperature === 0
                                ? "00"
                                : "--"}
                              °C
                            </div>
                            <div className="bbadge">
                              <Phtemperature className="sensorsListIcon" />
                              {sensorLog.ph
                                ? sensorLog.ph
                                : sensorLog.ph === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <img
                                src={Energy}
                                alt="energy"
                                className="sensorsListIcon"
                              />
                              {sensorLog.ec
                                ? sensorLog.ec
                                : sensorLog.ec === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span>DO</span>
                              {sensorLog.gdo
                                ? sensorLog.gdo
                                : sensorLog.gdo === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <img
                                src={Battery}
                                alt="battert"
                                className="sensorsListIcon"
                              />
                              {sensorLog.battery
                                ? sensorLog.battery
                                : sensorLog.battery === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : type === "DRAINAGE" ? (
                          <>
                            <div className="bbadge">
                              <img
                                src={Energy}
                                alt="energy"
                                className="sensorsListIcon"
                              />
                              {/* {sensorLog.ec
                                ? sensorLog.ec
                                : sensorLog.ec === 0
                                ? "00"
                                : "--"} */}
                            </div>
                            <div className="bbadge">
                              <Drainagewaterflow className="sensorsListIcon" />
                              {sensorLog.waterlevel
                                ? sensorLog.waterlevel
                                : sensorLog.waterlevel === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <Drainagetemperature className="sensorsListIcon" />
                              {sensorLog.temperature
                                ? sensorLog.temperature
                                : sensorLog.temperature === 0
                                ? "00"
                                : "--"}
                              °C
                            </div>
                          </>
                        ) : topDeviceTypes === "FMS" ? (
                          <>
                            <div className="bbadge">
                              <span>
                                <Temperature className="sensorsListIcon" />
                              </span>
                              {sensorLog.temperature
                                ? sensorLog.temperature
                                : sensorLog.temperature === 0
                                ? "00"
                                : "--"}
                              °C
                            </div>
                            <div className="bbadge">
                              <Raindrops className="sensorsListIcon" />
                              {sensorLog.moisture
                                ? sensorLog.moisture
                                : sensorLog.moisture === 0
                                ? "00"
                                : "--"}
                              %
                            </div>
                            <div className="bbadge">
                              <Acc className="sensorsListIcon" />
                              {sensorLog.acceleration
                                ? sensorLog.acceleration
                                : sensorLog.acceleration === 0
                                ? "00"
                                : "--"}
                            </div>
                            {/* <div className="bbadge">
                              <Dacc className="sensorsListIcon" />
                              {sensorLog.dacceleration
                                ? sensorLog.dacceleration
                                : sensorLog.dacceleration === 0
                                ? "00"
                                : "--"}
                            </div> */}
                            <div className="bbadge">
                              <Gyro className="sensorsListIcon" />
                              {sensorLog.gyroActivity
                                ? sensorLog.gyroActivity
                                : sensorLog.gyroActivity === 0
                                ? "00"
                                : "--"}
                            </div>
                            {/* <div className="bbadge">
                              <Dgyro className="sensorsListIcon" />
                              {sensorLog.dgyro
                                ? sensorLog.dgyro
                                : sensorLog.dgyro === 0
                                ? "00"
                                : "--"}
                            </div> */}
                            <div className="bbadge">
                              <Altitude className="sensorsListIcon" />
                              {sensorLog.altitude
                                ? sensorLog.altitude
                                : sensorLog.altitude === 0
                                ? "00"
                                : "--"}
                              m
                            </div>
                            <div className="bbadge">
                              <Sun className="sensorsListIcon" />
                              {sensorLog.lux
                                ? sensorLog.lux
                                : sensorLog.lux === 0
                                ? "00"
                                : "--"}
                              lx
                            </div>
                            <div className="bbadge">
                              <Hpa className="sensorsListIcon" />
                              {sensorLog.press
                                ? sensorLog.press
                                : sensorLog.press === 0
                                ? "00"
                                : "--"}
                              hpa
                            </div>
                            <div className="bbadge">
                              <img
                                src={Battery}
                                alt="battert"
                                className="sensorsListIcon"
                              />
                              {sensorLog.battery
                                ? sensorLog.battery
                                : sensorLog.battery === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : topDeviceTypes === "FMS02" ? (
                          <>
                            <div className="bbadge">
                              <span>
                                <Temperature className="sensorsListIcon" />
                              </span>
                              {sensorLog.temperature
                                ? sensorLog.temperature
                                : sensorLog.temperature === 0
                                ? "00"
                                : "--"}
                              °C
                            </div>
                            <div className="bbadge">
                              <Raindrops className="sensorsListIcon" />
                              {sensorLog.moisture
                                ? sensorLog.moisture
                                : sensorLog.moisture === 0
                                ? "00"
                                : "--"}
                              %
                            </div>
                            <div className="bbadge">
                              <Acc className="sensorsListIcon" />
                              {sensorLog.acceleration
                                ? sensorLog.acceleration
                                : sensorLog.acceleration === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <Gyro className="sensorsListIcon" />
                              {sensorLog.gyroActivity
                                ? sensorLog.gyroActivity
                                : sensorLog.gyroActivity === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <Hpa className="sensorsListIcon" />
                              {sensorLog.press
                                ? sensorLog.press
                                : sensorLog.press === 0
                                ? "00"
                                : "--"}
                              hpa
                            </div>
                            <div className="bbadge">
                              <img
                                src={Battery}
                                alt="battert"
                                className="sensorsListIcon"
                              />
                              {sensorLog.battery
                                ? sensorLog.battery
                                : sensorLog.battery === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : type === "COMPOST" ? (
                          <>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={Temp}
                                  alt="temp"
                                  className="sensorsListIcon"
                                />
                                15
                              </span>
                              {sensorLog.t15
                                ? sensorLog.t15
                                : sensorLog.t15 === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={Temp}
                                  alt="temp"
                                  className="sensorsListIcon"
                                />
                                30
                              </span>
                              {sensorLog.t30
                                ? sensorLog.t30
                                : sensorLog.t30 === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={Hum}
                                  alt="hum"
                                  className="sensorsListIcon"
                                />
                                15
                              </span>
                              {sensorLog.m15
                                ? sensorLog.m15
                                : sensorLog.m15 === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={Hum}
                                  alt="hum"
                                  className="sensorsListIcon"
                                />
                                30
                              </span>
                              {sensorLog.m30
                                ? sensorLog.m30
                                : sensorLog.m30 === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span
                                className="sensorsListIcon"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={DeviceHum}
                                  alt="DeviceHum"
                                  className="sensorsListIcon"
                                />
                              </span>
                              {sensorLog.rh
                                ? sensorLog.rh
                                : sensorLog.rh === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : type === "WATERLEVEL" ? (
                          <>
                            <div className="bbadge">
                              <Flow className="sensorsListIcon" />
                              {sensorLog.l
                                ? sensorLog.l
                                : sensorLog.l === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : // : type === "SES" ? (
                        //   <>
                        //     <div className="bbadge">
                        //       <Thermometer className="sensorsListIcon" />
                        //       {sensorLog.temperature
                        //         ? sensorLog.temperature
                        //         : sensorLog.temperature === 0
                        //         ? "00"
                        //         : "--"}
                        //       °C
                        //     </div>
                        //     <div className="bbadge">
                        //       <Raindrops className="sensorsListIcon" />
                        //       {sensorLog.moisture
                        //         ? sensorLog.moisture
                        //         : sensorLog.moisture === 0
                        //         ? "00"
                        //         : "--"}
                        //       %
                        //     </div>
                        //     <div className="bbadge">
                        //       <img
                        //         src={Battery}
                        //         alt="battert"
                        //         className="sensorsListIcon"
                        //       />
                        //       {sensorLog.battery
                        //         ? sensorLog.battery
                        //         : sensorLog.battery === 0
                        //         ? "00"
                        //         : "--"}
                        //     </div>
                        //   </>
                        // )
                        topDeviceTypes === "SSH" ? (
                          <>
                            <div className="bbadge">
                              <Raindrops className="sensorsListIcon" />
                              {sensorLog.moisture
                                ? sensorLog.moisture
                                : sensorLog.moisture === 0
                                ? "00"
                                : "--"}
                              %
                            </div>
                            <div className="bbadge">
                              <Thermometer className="sensorsListIcon" />
                              {sensorLog.temperature
                                ? sensorLog.temperature
                                : sensorLog.temperature === 0
                                ? "00"
                                : "--"}
                              °C
                            </div>
                            <div className="bbadge">
                              <img
                                src={Energy}
                                alt="energy"
                                className="sensorsListIcon"
                              />
                              {sensorLog.ec
                                ? sensorLog.ec
                                : sensorLog.ec === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span>N</span>
                              {sensorLog.n
                                ? sensorLog.n
                                : sensorLog.n === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span>P</span>
                              {sensorLog.p
                                ? sensorLog.p
                                : sensorLog.p === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <span>K</span>
                              {sensorLog.k
                                ? sensorLog.k
                                : sensorLog.k === 0
                                ? "00"
                                : "--"}
                            </div>
                            <div className="bbadge">
                              <img
                                src={Battery}
                                alt="battert"
                                className="sensorsListIcon"
                              />
                              {sensorLog.battery
                                ? sensorLog.battery
                                : sensorLog.battery === 0
                                ? "00"
                                : "--"}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </SimpleBarReact>
                  ) : (
                    ""
                  )}
                  {cotIds?.map((_id) => {
                    return (
                      <div>
                        <COTsensorWrap
                          dataListId={_id}
                          callMqttsensors={callMqttsensors}
                          mqttData={mqttData}
                          sensorLog={sensorLog}
                          setCh={setCh}
                          ch={ch}
                          nowMoi={"pass"}
                          chLength={chLength}
                        />
                        <SensorchartIRR
                          selects={_id}
                          startDate={startDate}
                          endDate={endDate}
                          setting={true}
                          setType={setType}
                          ch={ch[_id]?.ch}
                          editmoi={false}
                          setNowMoi={setNowMoi}
                          setChLength={setChLength}
                          chLength={chLength}
                        />
                      </div>
                    );
                  })}
                  {selects.length !== 0 ? (
                    <div>
                      <Sensorchart
                        selects={selects}
                        startDate={startDate}
                        endDate={endDate}
                        setting={true}
                        setType={setType}
                        setChLength={setChLength}
                      />
                    </div>
                  ) : null}
                  {cotIds.length === 0 && selects.length === 0 ? (
                    <div className="insideIrrText">IRRIGATION</div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IrrigationListFarm;
