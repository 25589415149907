import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Card, Col, Container, Form, Input, Row, CardBody } from "reactstrap";
import backImg from "../../assets/images/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds_2021-10-21/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds.png";
import { requestDelete, requestGet, requestPost } from "../../apis/request";
import { ReactComponent as Minus } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-cross-small.svg";
import { ReactComponent as Info } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-info.svg";
import { ReactComponent as Left } from "../../assets/icons/uicons-solid-rounded/svg/fi-sr-arrow-small-left.svg";
import SimpleBarReact from "simplebar-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import "./farmadd.scss";
const FarmDelete = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [clusterList, setClusterList] = useState([]);
  const [clusterName, setClusterName] = useState([]);
  const [sensorsData, setSensorsData] = useState([]);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [sensorDescription, setSensorDescription] = useState([]);
  const [sensorsId, setSensorsId] = useState([]);
  const [clusterId, setClusterId] = useState(null);
  const history = useHistory();
  const { t } = useTranslation("main");
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handledescription = (e) => {
    setDescription(e.target.value);
  };

  const sensorsDataClick = (data) => {
    setSensorsData([...sensorsData, data]);
    setSensorDescription([...sensorDescription, data.description]);
    setSensorsId([...sensorsId, data.id]);
    setClusterList(clusterList.filter((_data) => _data !== data));
  };

  const sensorsDataRemoveClick = (data) => {
    setClusterList([...clusterList, data]);
    setSensorDescription(
      sensorDescription.filter(
        (_description) => _description !== data.description
      )
    );
    setSensorsId(sensorsId.filter((_id) => _id !== data.id));
    setSensorsData(sensorsData.filter((_data) => _data !== data));
  };

  const OnSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (clusterName.includes(name)) {
        return alert("이미 존재하는 이름 입니다.");
      }
      const params = new URLSearchParams();
      params.append("name", name);
      params.append("description", description);
      params.append("sensors", sensorsId);

      // let body = {
      //   name: name,
      //   description: description,
      //   sensors: sensorsId,
      // };
      await requestPost("/api/v1/cluster/add", params);
      history.push("/dashboard");
    } catch (error) {
      alert("삭제 실패하였습니다.");
    }
  };

  useEffect(() => {
    const clusters = async () => {
      const cluster = await requestGet(`/api/v1/cluster/list`);
      setClusterList(cluster);
    };
    clusters();
  }, [success_dlg]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Farm Delete | FarmCLO</title>
      </MetaTags>
      <img src={backImg} className="backgroundimg" />
      <div className="dashText">DELETE FARM</div>
      <div className="farmAddWrap account-pages">
        <Container className="farmAddContainer">
          <Form onSubmit={OnSubmitHandler}>
            <Row className="farmAddRow justify-content-center">
              <Col className="farmAddCol">
                <Card className="farmDeleteCard overflow-hidden">
                  <Row className="g-0" style={{ height: "100%" }}>
                    <Col style={{ height: "100%" }}>
                      <div style={{ height: "100%" }}>
                        <div style={{ height: "100%" }}>
                          <div style={{ height: "100%" }}>
                            <label
                              htmlFor="_clusterId"
                              className="farmAddNameSensor"
                              style={{
                                padding: "0",
                                height: "10%",
                                color: "#ff6969",
                                fontWeight: "bold",
                              }}
                            >
                              {t("farmdel")}
                            </label>
                            <div
                              className="sensorsAddList"
                              style={{ height: "90%" }}
                            >
                              {clusterList.length > 0 ? (
                                <SimpleBarReact
                                  className="farmDeleteSensorListSimplebar"
                                  style={{ maxHeight: "100%" }}
                                >
                                  {clusterList.map((data, i) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          setconfirm_alert(true);
                                          setClusterId(data.id);
                                        }}
                                        className="farmDeleteSensordiv"
                                      >
                                        <div className="farmSpanWrap">
                                          <span className="farmMinusDescription">
                                            {data.name}
                                          </span>
                                          <span className="farmMinusSerial">
                                            {data.description}
                                          </span>
                                        </div>
                                        <Minus className="farmMinusPlus" />
                                      </div>
                                    );
                                  })}
                                </SimpleBarReact>
                              ) : (
                                <div className="farmAddInfoWrap">
                                  <Info className="farmAddInfo" />
                                  <span className="farmAddInfoSpan">
                                    삭제할 농장이 없습니다.
                                  </span>
                                </div>
                              )}
                              {confirm_alert ? (
                                <SweetAlert
                                  title="정말 삭제하시겠습니까?"
                                  warning
                                  showCancel
                                  confirmButtonText={t("delete")}
                                  confirmBtnBsStyle="danger"
                                  cancelButtonText={t("cancel")}
                                  cancelBtnBsStyle="success"
                                  onConfirm={async () => {
                                    setconfirm_alert(false);
                                    await requestPost(
                                      `/api/v1/cluster/delete/${clusterId}`
                                    );
                                    setsuccess_dlg(!success_dlg);
                                  }}
                                  onCancel={() => setconfirm_alert(false)}
                                ></SweetAlert>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(FarmDelete);
