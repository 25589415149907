import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "reactstrap";
import "../../components/CommonForBoth/TopbarDropdown/notificationdropdown.scss";
import "./chart.scss";
//i18n
import { useTranslation, withTranslation } from "react-i18next";

const SensorYDropdown = ({
  setMin,
  setMax,
  min,
  max,
  setMenu,
  typeKey,
  name,
}) => {
  // Declare a new state variable, which we'll call "menu"
  // const [menu, setMenu] = useState(false);
  const [inMin, setInMin] = useState("0");
  const [inMax, setInMax] = useState("0");
  const { t } = useTranslation("main");
  const handleMin = (e) => {
    setInMin(e.target.value);
  };

  const handleMax = (e) => {
    setInMax(e.target.value);
  };

  const onSubmitHandler = (e) => {
    try {
      e.preventDefault();
      setMin((menu) => ({
        ...menu,
        [typeKey]: inMin,
      }));
      setMax((menu) => ({
        ...menu,
        [typeKey]: inMax,
      }));
      setMenu((menu) => ({
        ...menu,
        [typeKey]: false,
      }));
    } catch (error) {}
  };

  const onClose = () => {
    setMenu((menu) => ({
      ...menu,
      [typeKey]: false,
    }));
  };

  const onReset = (e) => {
    try {
      e.preventDefault();
      setMin((menu) => ({
        ...menu,
        [typeKey]: undefined,
      }));
      setMax((menu) => ({
        ...menu,
        [typeKey]: undefined,
      }));
      setMenu((menu) => ({
        ...menu,
        [typeKey]: false,
      }));
    } catch (error) {}
  };

  useEffect(() => {
    if (min) {
      setInMin(min);
    } else if (!min) {
      setInMin("0");
    }
    if (max) {
      setInMax(max);
    } else if (!max) {
      setInMax("0");
    }
  }, [min, max]);

  return (
    <React.Fragment>
      <Card className="yDropWrap">
        <div className="yDropTitleRow">
          <Row>
            <Col className="yDropTitleCol">
              <span className="yDropTitle">
                {t(`${name}`)} {t("ysetting")}
              </span>
            </Col>
          </Row>
        </div>
        <Row className="m-0 p-0">
          <Col className="yDropInWrap m-0">
            <div className="yDropInputWrap">
              <span className="yDropInputspan">Min</span>
              <input
                type="text"
                className="yDropinput325"
                name="_min"
                value={inMin}
                onChange={handleMin}
              />
            </div>
            <div className="yDropInputWrap">
              <span className="yDropInputspan">Max</span>
              <input
                type="text"
                className="yDropinput325"
                name="_max"
                value={inMax}
                onChange={handleMax}
              />
            </div>
            <div className="yDropBtnWrap">
              <button className="yDropCancelBtn" onClick={() => onClose()}>
                <span>{t("cancel")}</span>
              </button>
              <button
                className="yDropSuccessBtn"
                onClick={(e) => onSubmitHandler(e)}
              >
                <span>{t("confirm")}</span>
              </button>
              <button className="yDropSuccessBtn" onClick={(e) => onReset(e)}>
                <span>{t("reset")}</span>
              </button>
            </div>
          </Col>
        </Row>
      </Card>
      {/* </DropdownMenu>
      </Dropdown> */}
    </React.Fragment>
  );
};

export default withTranslation()(SensorYDropdown);

SensorYDropdown.propTypes = {
  t: PropTypes.any,
};
