import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { requestGet } from "../../apis/request";

import { logoutUser } from "../../store/actions";

const Logout = (props) => {
  const history = useHistory();

  useEffect(() => {
    const logout = async () => {
      try {
        await requestGet(`/api/account/logout`);
        sessionStorage.removeItem("authUser");
        history.push("/login");
        props.logoutUser(props.history);
      } catch (error) {
        sessionStorage.removeItem("authUser");
        history.push("/login");
        props.logoutUser(props.history);
      }
    };
    logout();
  });

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
};

export default withRouter(connect(null, { logoutUser })(Logout));
