import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { CardBody, Button } from "reactstrap";
import {
  adminRequestPost,
  requestDelete,
  requestGet,
} from "../../apis/request";
import { useTranslation } from "react-i18next";
import SimpleBarReact from "simplebar-react";
import Sensorchart from "../Charts/Sensorchart";
import "simplebar/dist/simplebar.min.css";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./sensorrow.scss";
import { ReactComponent as Calendar } from "../../assets/images/chart/fi-rr-calendar.svg";
import { ReactComponent as Smallup } from "../../assets/images/chart/fi-rr-angle-small-up.svg";
import { ReactComponent as Smalldown } from "../../assets/images/chart/fi-rr-angle-small-down.svg";
import { ReactComponent as Left } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-left.svg";
import { ReactComponent as Right } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-right.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-arrow-right.svg";
import { ReactComponent as Database } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-database.svg";
import { ReactComponent as Fullwifi } from "../../assets/icons/fullwifi.svg";
import { ReactComponent as Trash } from "../../assets/icons/svgs/regular/trash-can.svg";
import { useHistory } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import COTsensorWrap from "./COTsensorWrapcopy";
import COTSensorchart from "../Charts/COTSensorchart";
import { ko } from "date-fns/esm/locale";
import CotRowDetail from "./CotRowDetail";
import TriggerModal from "../Listadd/TriggerModal";
import TriggerDetailModal from "../Listadd/TriggerDetailModal";

const SensorListCotDetail = (props) => {
  const [startDate, setStartDate] = useState(
    moment().format("YYYY-MM-DD 00:00")
  );
  const [endDate, setEndDate] = useState(moment());
  const [teststartDate, settestStartDate] = useState(new Date());
  const [testendDate, settestEndDate] = useState(null);
  // const [type] = useState(props.location.state.deviceType);
  const [dataListId] = useState(props.location.state.id);
  const [dataname] = useState(props.location.state.name);
  const [serial] = useState(props.location.state.serial);
  const [selects, setSelects] = useState([]);
  // const [userInfo, setUserInfo] = useState([]);
  const [mqttData, setMqttData] = useState([]);
  const [sensorLog, setSensorLog] = useState({});
  const [usercluster, setUserCluster] = useState([]);
  const [sideswicth, setSideswitch] = useState(false);
  const [dayOpen, setDayOpen] = useState(false);
  const [activeDay, setActiveDay] = useState("");
  const [lastidx, setLastidx] = useState(0);
  const [customDate, setCustomDate] = useState(false);
  const [nowMoi, setNowMoi] = useState("");
  const [ch, setCh] = useState(null);
  const [chLength, setChLength] = useState([]);
  const [nowCh, setNowCh] = useState(null);
  const [userDevice, setUserDevice] = useState([]);
  const [onTrigger, setOnTrigger] = useState(false);
  const [onTriggerDetail, setOnTriggerDetail] = useState(false);
  const [chId, setChId] = useState(null);
  const [chNum, setChNum] = useState(null);
  const [data, setData] = useState(null);

  const savedCallback = useRef();
  const history = useHistory();
  const { t } = useTranslation("main");

  const useInterval = (callback, delay) => {
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      const executeCallback = () => {
        savedCallback.current();
      };

      const timerId = setInterval(executeCallback, delay);

      return () => clearInterval(timerId);
    }, []);
  };

  const getLog = async (value) => {
    try {
      const device = await requestGet(`/api/v1/controller/${value}`);
      let arr = [];
      for (let i = 0; i < device.channels?.length; i++) {
        arr.push(device.channels[i].channelNumber);
      }
      arr.sort((a, b) => a - b);
      console.log(arr);
      device.channels.sort((a, b) => a.channelNumber - b.channelNumber);
      setChLength((prevState) => ({
        ...prevState,
        [`${value}`]: {
          ...prevState.value,
          chleng: arr,
        },
      }));
      return device;
    } catch (error) {
      console.error(error);
    }
  };

  const getMoistureLog = async (value) => {
    try {
      const moisture = await requestGet(
        `/api/v1/sensor/channel/${value}/moisture`
      );
      setNowMoi(String(Number(moisture).toFixed(1)));
    } catch (error) {
      setNowMoi("");
      console.error(error);
    }
  };

  const callGetLog = async () => {
    const arr = [];
    if (selects?.length > 0) {
      for (let value of selects) {
        const result = await getLog(value);
        arr.push(result);
      }
    }
    setUserDevice(arr);
  };

  useInterval(() => {
    if (Array.isArray(selects)) {
      callGetLog();
    }
  }, 5 * 60 * 1000);

  const clu = async () => {
    const clusterList = await requestGet(`/api/v1/cluster/list`);
    setUserCluster(clusterList);
  };

  useEffect(() => {
    clu();
  }, []);

  useEffect(() => {
    if (dataListId !== undefined) {
      setSelects([dataListId]);
    }
  }, [dataListId]);

  const callsensors = async () => {
    if (selects[lastidx] !== undefined) {
      const log = await requestGet(
        `/api/v1/sensor/read/log/${selects[lastidx]}/latest`
      );
      setSensorLog(log);
    }
  };

  useEffect(() => {
    setLastidx(selects.length - 1);
    if (selects.length >= 0) {
      callGetLog();
      callsensors();
    } else {
      return;
    }
  }, [selects, lastidx]);

  const headersideswitch = () => {
    setSideswitch(!sideswicth);
  };

  const dayBtn = () => {
    setStartDate(moment().subtract(1, "d").format("YYYY-MM-DD 00:00"));
    setEndDate(moment().format("YYYY-MM-DD 23:59"));
    setActiveDay("day");
    setDayOpen(false);
  };

  const weeksBtn = () => {
    setStartDate(moment().subtract(1, "w").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("weeks");
    setDayOpen(false);
  };

  const monthsBtn = () => {
    setStartDate(moment().subtract(1, "M").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("months");
    setDayOpen(false);
  };

  const oneHourBtn = () => {
    setStartDate(moment().subtract(1, "H"));
    setEndDate(moment());
    setActiveDay("onehour");
    setDayOpen(false);
  };
  const threeHourBtn = () => {
    setStartDate(moment().subtract(3, "H"));
    setEndDate(moment());
    setActiveDay("threehour");
    setDayOpen(false);
  };
  const threemonthsBtn = () => {
    setStartDate(moment().subtract(3, "M").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("threemonths");
    setDayOpen(false);
  };
  const halfHourBtn = () => {
    setStartDate(moment().subtract(12, "H"));
    setEndDate(moment());
    setActiveDay("halfhour");
    setDayOpen(false);
  };
  const allmonthsBtn = () => {
    setStartDate(moment("2021-11-01"));
    setEndDate(moment());
    setActiveDay("all");
    setDayOpen(false);
  };
  const customdateBtn = () => {
    setActiveDay("custom");
    setCustomDate(true);
  };
  const dayControl = () => {
    setDayOpen(!dayOpen);
  };

  const closeall = () => {
    setCustomDate(false);
    setDayOpen(!dayOpen);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    settestStartDate(start);
    settestEndDate(end);
    if (start && end) {
      setStartDate(moment(start).format("YYYY-MM-DD 00:00"));
      setEndDate(moment(end).format("YYYY-MM-DD 23:59"));
      setCustomDate(false);
      setDayOpen(!dayOpen);
    }
  };

  const sidebarheighy = $(".colwrap").height();

  const fetchData = async () => {
    try {
      const response = await requestGet(
        `/api/v1/controller/${serial}/channel/${nowCh}/refresh`
      );
      setData(response);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (nowCh) {
      fetchData();
    }
  }, [nowCh]);

  useEffect(() => {
    setCh({
      [userDevice[0]?.id]: {
        ch: String(userDevice[0]?.channels[0]?.channelNumber),
      },
    });
    setNowCh(userDevice[0]?.channels[0]?.id);
  }, [userDevice]);

  useEffect(() => {
    setCh({
      [userDevice[0]?.id]: {
        ch: String(userDevice[0]?.channels[0]?.channelNumber),
      },
    });
  }, [userDevice]);

  useEffect(() => {
    if (userDevice.length > 0 && ch !== null) {
      for (let i = 0; i < userDevice[0]?.channels?.length; i++) {
        if (
          String(userDevice[0].channels[i].channelNumber) ===
          ch[userDevice[0]?.id]?.ch
        )
          setNowCh(userDevice[0].channels[i].id);
      }
    }
  }, [userDevice, ch]);

  useEffect(() => {
    if (nowCh !== null) {
      getMoistureLog(nowCh);
    }
  }, [nowCh]);

  const removeSenor = async (id) => {
    if (window.confirm(`${t("delsensor")}`)) {
      try {
        await requestDelete(`/api/v1/sensor/channel/${id}`);
        alert(`${t("delsecces")}`);
        callGetLog();
      } catch (error) {
        alert(`${t("delfail")}`);
      }
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensors List | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap1">
        <div className="container p-0 m-0" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <Row
              className="mobilewidth justify-content-center"
              style={{ height: "100%", width: "100%" }}
            >
              <Col
                md={3}
                className={sideswicth ? "switchcardwrap" : "colwrap"}
                style={
                  sideswicth ? { height: sidebarheighy } : { height: "100%" }
                }
              >
                <Card className="cardwrap m-0">
                  <span className="irrigationtitle">{t("sensortext")}</span>
                  <div className="sensorsListDiv">
                    <span className="sensorsListSpan">Sensor Data List</span>
                  </div>
                  <CardBody
                    className="cardBodyWrap"
                    style={{ height: "100%", overflow: "hidden" }}
                  >
                    <div
                      className="table-responsive"
                      style={{ height: "100%", overflow: "hidden" }}
                    >
                      <SimpleBarReact
                        className="listtypesimplebar"
                        autoHide={false}
                        style={{ maxHeight: "100%" }}
                      >
                        <Table className="table mb-0">
                          <tbody>
                            {userDevice[0]?.channels?.map((item, i) => {
                              // let triggerValue = getTrigger(item.id);
                              // console.log(triggerValue);
                              return (
                                <>
                                  <div
                                    className="sensorsListDiv"
                                    style={{ padding: "10px 40px 0px" }}
                                  >
                                    <span className="sensorsListSpan p-0">
                                      {item.channelNumber}CH {t("sensorlist")}
                                    </span>
                                    <Col className="settingWrap col-auto">
                                      <button className="trashBtn">
                                        <Trash
                                          width={"20px"}
                                          onClick={() => removeSenor(item.id)}
                                        />
                                      </button>
                                    </Col>
                                  </div>
                                  {item?.sensors?.map((el, i) => {
                                    return (
                                      <CotRowDetail
                                        data={el}
                                        chID={item.id}
                                        callGetLog={callGetLog}
                                      />
                                    );
                                  })}
                                </>
                              );
                            })}
                          </tbody>
                        </Table>
                      </SimpleBarReact>
                    </div>
                  </CardBody>
                  <Button
                    className="selectSuccesBtn"
                    color="success"
                    onClick={() => setSideswitch(true)}
                  >
                    선택완료
                  </Button>
                </Card>
              </Col>
              <div className="sideMark" onClick={() => headersideswitch()}>
                {sideswicth ? (
                  <Right className="sideIcon" />
                ) : (
                  <Left className="sideIcon" />
                )}
              </div>
              <Col
                md={9}
                className="chartwrap"
                style={{ height: "100%", overflow: "auto" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="div354">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "14px 0px",
                      }}
                    >
                      <span className="div354Name">{dataname}</span>
                      <span
                        className="div354dateName"
                        style={{ marginLeft: "70px" }}
                      >
                        {sensorLog?.createdDate?.split(" ").map((line) => (
                          <div className="createddate">{line}</div>
                        ))}
                      </span>
                    </div>
                    <div>
                      <div onClick={dayControl}>
                        <Calendar
                          className={
                            dayOpen ? "openCalendarmargin" : "calendarmargin"
                          }
                        />
                        {dayOpen ? <Smallup /> : <Smalldown />}
                      </div>
                      <Card
                        className={dayOpen ? "daycontrol" : "colseday"}
                        style={{ zIndex: 100 }}
                      >
                        <div className="dayrow">
                          <div
                            className={
                              activeDay === "onehour"
                                ? "activedaycol"
                                : "daycol"
                            }
                            onClick={oneHourBtn}
                          >
                            <div className="daymark" />
                            <span>1시간</span>
                          </div>
                          <div
                            className={
                              activeDay === "threehour"
                                ? "activedaycol"
                                : "daycol"
                            }
                            onClick={threeHourBtn}
                          >
                            <div className="daymark" />
                            <span>3시간</span>
                          </div>
                          <div
                            className={
                              activeDay === "halfhour"
                                ? "activedaycol"
                                : "daycol"
                            }
                            onClick={halfHourBtn}
                          >
                            <div className="daymark" />
                            <span>12시간</span>
                          </div>
                        </div>
                        <div className="dayrow">
                          <div
                            className={
                              activeDay === "day" ? "activedaycol" : "daycol"
                            }
                            onClick={dayBtn}
                          >
                            <div className="daymark" />
                            <span>1일</span>
                          </div>
                          <div
                            className={
                              activeDay === "weeks" ? "activedaycol" : "daycol"
                            }
                            onClick={weeksBtn}
                          >
                            <div className="daymark" />
                            <span>1주</span>
                          </div>
                          <div
                            className={
                              activeDay === "months" ? "activedaycol" : "daycol"
                            }
                            onClick={monthsBtn}
                          >
                            <div className="daymark" />
                            <span>1개월</span>
                          </div>
                        </div>
                        <div className="dayrow">
                          <div
                            className={
                              activeDay === "threemonths"
                                ? "activedaycol"
                                : "daycol"
                            }
                            onClick={threemonthsBtn}
                          >
                            <div className="daymark" />
                            <span>3개월</span>
                          </div>
                          <div
                            className={
                              activeDay === "all" ? "activedaycol" : "daycol"
                            }
                            onClick={allmonthsBtn}
                          >
                            <div className="daymark" />
                            <span>전체</span>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            className={
                              activeDay === "custom" ? "activedaycol" : "daycol"
                            }
                            onClick={customdateBtn}
                          >
                            <div className="daymark" />
                            <span>사용자 지정</span>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  {customDate ? (
                    <>
                      <div
                        className="chartOutside"
                        onClick={() => closeall()}
                      />
                      <DatePicker
                        selected={teststartDate}
                        onChange={onChange}
                        startDate={teststartDate}
                        endDate={testendDate}
                        dateFormat="yyyy년 MM월 dd일"
                        selectsRange
                        inline
                        locale={ko}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {selects.length !== 0 ? (
                    <SimpleBarReact style={{ maxHeight: "66vh" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="d-flex">
                          <div
                            className="bbadge"
                            style={{ backgroundColor: "#939393" }}
                          >
                            <span
                              className="sensorsListIcon"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              S/N
                            </span>
                            {serial ? serial : selects[lastidx]}
                          </div>
                          <div className="bbadge">
                            <Fullwifi className="sensorsListIcon" />
                            {sensorLog.rssi
                              ? sensorLog.rssi
                              : sensorLog.rssi === 0
                              ? "00"
                              : "--"}
                          </div>
                        </div>
                        <i
                          className="fas fa-redo"
                          style={{
                            fontSize: "24px",
                            color: "#000",
                            cursor: "pointer",
                          }}
                          onClick={() => fetchData()}
                        ></i>
                      </div>
                    </SimpleBarReact>
                  ) : (
                    ""
                  )}
                  {userDevice?.length > 0 ? (
                    <COTsensorWrap
                      data={data}
                      dataListId={dataListId}
                      serial={serial}
                      mqttData={mqttData}
                      sensorLog={sensorLog}
                      setCh={setCh}
                      ch={ch}
                      channelId={chId}
                      nowMoi={nowMoi}
                      chLength={chLength}
                      userDevice={userDevice}
                      nowCh={nowCh}
                    />
                  ) : (
                    ""
                  )}
                  {selects.length !== 0 ? (
                    <div>
                      <COTSensorchart
                        selects={selects}
                        startDate={startDate}
                        endDate={endDate}
                        ch={ch[selects[0]]?.ch}
                        setChLength={setChLength}
                        userDevice={userDevice}
                        editmoi
                      />
                    </div>
                  ) : (
                    <div className="insideText">SENSORS</div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SensorListCotDetail;
