import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { CardBody } from "reactstrap";
import { requestGet } from "../../apis/request";
import MapSensorRow from "./MapSensorRow";
import Maps from "./Maps";
import SimpleBarReact from "simplebar-react";
import MapTypelist from "./MapTypelist";
import "simplebar/src/simplebar.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import LeftSidebarProfile from "../Utility/Profile/IrrigationEdit";
import { ReactComponent as Left } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-left.svg";
import { ReactComponent as Right } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-right.svg";
import { MetaTags } from "react-meta-tags";
import GoogleSensorMap from "./GoogleSensorMap";

const MapSensorList = () => {
  const [selects, setSelects] = useState([]);
  const [sideswicth, setSideswitch] = useState(false);

  const headersideswitch = () => {
    setSideswitch(!sideswicth);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Map | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap1">
        <div className="container h-100">
          <div className="h-100">
            <Row className="d-flex justify-content-center h-100">
              <Col md={3} className={sideswicth ? "switchcardwrap" : "colwrap"}>
                <MapTypelist className="cardwrap" />
              </Col>
              <div className="sideMark" onClick={() => headersideswitch()}>
                {sideswicth ? (
                  <Right className="sideIcon" />
                ) : (
                  <Left className="sideIcon" />
                )}
              </div>
              <Col md={9} className="chartwrap h-100">
                <Maps />
                {/* <GoogleSensorMap /> */}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MapSensorList;
