import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Card, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import backImg from "../../assets/images/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds_2021-10-21/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds.png";
import { addList } from "../../utils/addList";
import { requestGet, requestPost } from "../../apis/request";
import ListaddMap from "../Maps/ListaddMap";
import { useTranslation } from "react-i18next";
import "./listadd.scss";
import { MetaTags } from "react-meta-tags";
const AdminListadd = () => {
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [deviceType, setDeviceType] = useState("SMS");
  const [channel, setChannel] = useState("2");
  const [serial, setSerial] = useState("");
  const [description, setDescription] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitudeData, setLatitudeData] = useState("37.3614483");
  const [longitudeData, setLongitudeData] = useState("127.1114883");
  const [clusterId, setClusterId] = useState(0);
  const [clusterList, setClusterList] = useState([]);
  const history = useHistory();
  const { t } = useTranslation("main");
  const dispatch = useDispatch();

  const clusters = async () => {
    const cluster = await requestGet(`/api/v1/cluster/list`);
    setClusterList(cluster);
  };

  useEffect(() => {
    clusters();
  }, []);

  const handleId = (e) => {
    setUserId(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handledevice = (e) => {
    setDeviceType(e.target.value);
  };

  const handleChennel = (e) => {
    setChannel(e.target.value);
  };

  const handleserial = (e) => {
    setSerial(e.target.value);
  };
  const handledescription = (e) => {
    setDescription(e.target.value);
  };

  const handlelatitude = (e) => {
    setLatitude(e.target.value);
    setLatitudeData(e.target.value);
  };

  const handlelongitude = (e) => {
    setLongitude(e.target.value);
    setLongitudeData(e.target.value);
  };

  const handleclusterId = (e) => {
    setClusterId(e.target.value);
  };

  const OnSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      const params = new URLSearchParams();
      params.append("userId", userId);
      params.append("name", name);
      params.append("deviceType", deviceType);
      params.append("serial", serial);
      params.append("description", description);
      params.append("latitude", latitude);
      params.append("longitude", longitude);
      params.append("clusterId", clusterId);
      params.append("chNums", channel);
      await requestPost("/api/v1/admin/sensor/add", params);
      history.push("/dashboard");
      setUserId("");
      setName("");
      setDeviceType("SMS");
      setSerial("");
      setDescription("");
      setLatitude("");
      setLongitude("");
      setClusterId(0);
    } catch (error) {
      alert("등록 실패하였습니다");
    }
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin Page Sensor Add | FarmCLO</title>
      </MetaTags>
      <img src={backImg} className="backgroundimg" />
      <div className="dashText">
        ADD
        <br />
        SENSOR
      </div>
      <div className="account-pages">
        <Container>
          <Row>
            <Col className="listAddDisplay">
              <Card className="listAddCard">
                <Row>
                  <Col>
                    <div>
                      <div>
                        <Form onSubmit={OnSubmitHandler}>
                          <div className="listAddRow d-block d-md-flex">
                            <label htmlFor="_name" className="listAddName">
                              userId
                            </label>
                            <Input
                              type="text"
                              name="_name"
                              className="listAddInput"
                              value={userId}
                              onChange={handleId}
                              placeholder="name"
                              required
                            />
                          </div>
                          <div className="listAddRow d-block d-md-flex">
                            <label htmlFor="_name" className="listAddName">
                              {t("name")}
                            </label>
                            <Input
                              type="text"
                              name="_name"
                              className="listAddInput"
                              value={name}
                              onChange={handleName}
                              placeholder="name"
                              required
                            />
                          </div>

                          <FormGroup className="listAddRow d-block d-md-flex">
                            <label htmlFor="_clusterId" className="listAddName">
                              {t("devicetype")}
                            </label>
                            <Input
                              type="select"
                              className="form-select listAddInput"
                              onChange={handledevice}
                            >
                              <option value="SMS">{t("SMS")}</option>
                              <option value="SSH">{t("SSH01")}</option>
                              <option value="AT">{t("AT")}</option>
                              <option value="WATERFLOW">
                                {t("WATERFLOW")}
                              </option>
                              <option value="NUTRIENT">{t("NUTRIENT")}</option>
                              <option value="DRAINAGE">{t("DRAINAGE")}</option>
                              <option value="FMS">{t("FMS")}</option>
                              <option value="FMS02">{t("FMS02")}</option>
                              <option value="COMPOST">{t("COMPOST")}</option>
                              <option value="SOIL">{t("SOIL")}</option>
                              <option value="CO2">{t("CO2")}</option>
                              <option value="ATS02">{t("ATS02")}</option>
                              <option value="LAT">{t("LAT")}</option>
                              <option value="WATERLEVEL">
                                {t("WATERLEVEL")}
                              </option>
                              {/* <option value="SES">{t("SES")}</option> */}
                              <option value="COT">{t("COT")}</option>
                            </Input>
                          </FormGroup>
                          {deviceType === "COT" ? (
                            <FormGroup className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_clusterId"
                                className="listAddName"
                              >
                                {t("ch")}
                              </label>
                              <Input
                                type="select"
                                className="form-select listAddInput"
                                onChange={handleChennel}
                              >
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                              </Input>
                            </FormGroup>
                          ) : null}
                          <div className="listAddRow d-block d-md-flex">
                            <label htmlFor="_serial" className="listAddName">
                              {t("serial")}
                            </label>
                            <Input
                              type="number"
                              name="_serial"
                              className="listAddInput"
                              value={serial}
                              onChange={handleserial}
                              placeholder="serial"
                            />
                          </div>

                          <div className="listAddRow d-block d-md-flex">
                            <label
                              htmlFor="_description"
                              className="listAddName"
                            >
                              {t("description")}
                            </label>
                            <Input
                              type="text"
                              name="_description"
                              className="listAddInput"
                              value={description}
                              onChange={handledescription}
                              placeholder="description"
                            />
                          </div>

                          <div className="listAddRow d-block d-md-flex">
                            <label htmlFor="_latitude" className="listAddName">
                              {t("latitude")}
                            </label>
                            <Input
                              type="text"
                              name="_latitude"
                              className="listAddInput"
                              value={latitude}
                              onChange={handlelatitude}
                              placeholder="latitude"
                            />
                          </div>

                          <div className="listAddRow d-block d-md-flex">
                            <label htmlFor="_longitude" className="listAddName">
                              {t("longitude")}
                            </label>
                            <Input
                              type="text"
                              name="_longitude"
                              className="listAddInput"
                              value={longitude}
                              onChange={handlelongitude}
                              placeholder="longitude"
                            />
                          </div>
                          <div className="listAddRow d-block d-md-flex align-items-start">
                            <label
                              htmlFor="_longitude"
                              className="listAddName mt-2"
                            >
                              {t("map")}
                            </label>
                            <div className="listAddMapWrap">
                              <ListaddMap
                                latitude={latitudeData}
                                longitude={longitudeData}
                                setLatitude={setLatitude}
                                setLongitude={setLongitude}
                              />
                            </div>
                          </div>
                          <Col>
                            <div>
                              <button className="listAddBtn" type="submit">
                                {t("save")}
                              </button>
                            </div>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AdminListadd);
