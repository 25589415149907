// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mode: "kr",
  logintitle: "로그인",
  loginbutton: "로그인",
  loginsub: "계정이 있으신가요? 아래에서 로그인하세요.",
  id: "아이디",
  pw: "비밀번호",
  forgot: "비밀번호를 잊으셨나요?",
  irrigation: "농장시스템",
  irrigationheader: "농장",
  irrigationinfo: "공급된 물의 양과\n토양의 상태를 확인하세요.",
  irrigationtext: "제품을 선택하시고, 물 소비량을 분석하세요.",
  sensors: "센서 측정 데이터",
  sensorsheader: "센서",
  sensorsinfo:
    "설치 센서들의 측정 데이터와\n비교데이터, 센서 설정을 확인하세요.",
  sensortext:
    "센서를 선택하면 해당 센서의 데이터를 볼 수 있고,중복 선택하면 동일기간의 센서별 측정값을 비교할 수 있습니다.",
  map: "지도",
  mapinfo: "설치 센서들의 위치를 확인 하세요.",
  account: "내 계정",
  accountinfo: "내 정보, 농장 정보 등을\n수정하고 확인하세요.",
  soil: "토양",
  co2: "대기",
  SOIL: "토양",
  CO2: "대기",
  setting: "설정",
  email: "이메일",
  _email: "이메일",
  lang: "언어",
  noti: "알람설정",
  name: "이름",
  devicetype: "센서 타입",
  serial: "시리얼",
  description: "설명",
  clusterid: "농장 이름",
  latitude: "위도",
  longitude: "경도",
  delete: "지우기",
  save: "저장",
  cancel: "취소",
  confirm: "확인",
  reset: "초기화",
  farmid: "농장 이름",
  farmdescription: "농장 설명",
  farmsensors: "센서 추가",
  notifications: "알람",
  message: "메시지",
  contactus: "컨택",
  send: "전송",
  nameplaceholder: "이름을 입력하세요.",
  emailplaceholder: "이메일을 입력하세요.",
  messageplaceholder: "메시지를 입력하세요.",
  admin: "관리자",
  WATERFLOW: "유량계",
  WATERLEVEL: "수위센서",
  NUTRIENT: "양액",
  gdo: "용존산소량",
  DRAINAGE: "배액",
  FMS: "분만예측",
  FMS02: "분만예측2",
  COMPOST: "노지용 토양",
  SMS: "토양(SMS)",
  SSH01: "SSH01",
  SES: "토양(EC)",
  td: "이슬점",
  AT: "환경 대기",
  LAT: "축산 대기",
  timezone: "시간",
  phone: "전화번호",
  nodata: "데이터가 없습니다.",
  nomapdata: "위치 정보가 없습니다.",
  farmadd: "농장 추가하기",
  farmdel: "농장 삭제하기",
  sensoradd: "센서 추가하기",
  cotadd: "컨트롤러 추가하기",
  addindex: "추가하기,제거하기",
  addindexment: "농장이나 센서를 추가하거나 농장을 삭제할수 있습니다.",
  crops: "작물 이름",
  nickname: "닉네임",
  ATS02: "복합 대기",
  widget: "위젯",
  changepassword: "비밀번호 변경하기",
  COT: "컨트롤러",
  widgetHome: "위젯 홈",
  minutesago: "분전",
  hoursago: "시간전",
  daysago: "일전",
  yearsago: "년전",
  justbefore: "방금전",
  lastupdate: "최근 업데이트",
  soilTemperature: "토양온도",
  SoilTemperature: "토양온도",
  ec: "전기 전도도",
  stress: "스트레스 지수",
  moisture: "수분",
  battery: "배터리",
  rssi: "신호강도",
  temperature: "온도",
  humidity: "습도",
  Co2: "Co2",
  pressure: "압력",
  press: "압력",
  solar: "광량",
  speed: "유속",
  flowmeter: "유량",
  pumpopencount: "펌프 오픈 횟수",
  ph: "수소 이온 농도",
  waterlevel: "수위",
  t: "온도",
  t1: "온도(t1)",
  t2: "온도(t2)",
  rh: "습도",
  t15: "온도(15cm)",
  t30: "온도(30cm)",
  m15: "수분량(15cm)",
  m30: "수분량(30cm)",
  windD: "풍향",
  windS: "풍속",
  rain: "강수량",
  illuminance: "광량",
  l: "수위",
  pwrq: "5글자 이상 영어와 숫자만 가능합니다.",
  pwrq1: "8글자 이상 영어와 숫자만 가능합니다.",
  currenttemp: "현재 온도 (℃)",
  currenthum: "습도 (%)",
  weather: "날씨",
  current: "현재",
  valve: "밸브",
  watering: "물주는 시간(분) : ",
  lowmoi: "최소수분량 : ",
  highmoi: "최대수분량 : ",
  currenttime: "예약 시간 : ",
  pertime: "물주는 간격(분) : ",
  repeat: "반복 : ",
  csvdown: "전체 XLSX 다운로드",
  co2eng: "CO2",
  ecno: "EC",
  phno: "PH",
  n: "질소",
  p: "인",
  k: "칼륨",
  ch: "채널",
  lux: "조도",
  sort: "정렬",
  sortname: "이름 내림차순",
  sortnameRe: "이름 오름차순",
  sortserial: "시리얼 내림차순",
  sortserialRe: "시리얼 오름차순",
  sortdescription: "설명 내림차순",
  sortdescriptionRe: "설명 오름차순",
  activity: "활동량",
  ysetting: "Y축 설정 변경",
  mois: "수분량",
  altitude: "고도",
  acceleration: "순간 활동량",
  dacceleration: "순간 활동량의 차이",
  gyro: "활동량",
  gyroActivity: "활동량",
  dgyro: "활동량의 차이",
  remove: "지우기",
  add: "추가",
  sensorlist: "센서 목록",
  delsensor: "삭제 하시겠습니까?",
  delsecces: "삭제 하였습니다.",
  delfail: "삭제 실패 하였습니다.",
  type: "타입",
  addtrigger: "트리거를 생성하시겠습니까?",
  addfail: "생성 실패 하였습니다.",
  addsuccess: "생성 하였습니다.",
};
