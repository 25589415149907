import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Typelist from "./Typelist";
import { MetaTags } from "react-meta-tags";
import { ReactComponent as Left } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-left.svg";
import { ReactComponent as Right } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-right.svg";
import { ReactComponent as Sensorlogo } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-chart-histogram.svg";
import { ReactComponent as Weather } from "../../assets/icons/weather.svg";
import SimpleBarReact from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import SensorCard from "../Charts/SensorCard";
import WeatherCard from "../Charts/WeatherCard";
import { requestGet } from "../../apis/request";
import { useTranslation } from "react-i18next";
import { useGeoLocation } from "./useGeoLocation";

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 10,
  maximumAge: 1000 * 3600 * 24,
};

const SensorList = () => {
  const { location } = useGeoLocation(geolocationOptions);
  const [sideswicth, setSideswitch] = useState(false);
  const [widgetData, setWidgetData] = useState([]);
  const [data, setData] = useState({});
  const [lat, setLat] = useState("37.5642135");
  const [lon, setLon] = useState("127.0016985");
  const { t } = useTranslation("main");
  const lang = JSON.parse(localStorage.getItem("languageTitle"));

  useEffect(() => {
    if (location?.latitude) {
      setLat(location.latitude);
    }
    if (location?.longitude) {
      setLon(location.longitude);
    }
  }, [location]);

  const getWeather = async () => {
    const response = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${
        process.env.REACT_APP_OPENAPIKEY
      }&units=metric&lang=${lang === "ko" ? "kr" : "en"}`
    );
    const json = await response.json();
    setData(json);
  };
  const headersideswitch = () => {
    setSideswitch(!sideswicth);
  };

  const callWidget = async () => {
    try {
      let arr = [];
      const response = await requestGet(`/api/v1/sensor/widget/list`);
      if (response && response.length > 0) {
        for (let i = 0; i < response.length; i++) {
          arr.push({
            id: response.id,
            name: response.name,
            description: response.description,
            deviceType: response.deviceType,
            latestLog: response.latestLog,
            serial: response.serial,
          });
        }
      }
      setWidgetData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callWidget();
    getWeather();
  }, [lat, lon]);

  useEffect(() => {}, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensors List | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap1">
        <div className="container p-0 m-0" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <Row
              className="d-flex justify-content-center"
              style={{ height: "100%" }}
            >
              <Col
                md={3}
                className={sideswicth ? "switchcardwrap" : "colwrap"}
                style={{ height: "100%" }}
              >
                <Typelist className="cardwrap" />
              </Col>
              <div className="sideMark" onClick={() => headersideswitch()}>
                {sideswicth ? (
                  <Right className="sideIcon" />
                ) : (
                  <Left className="sideIcon" />
                )}
              </div>
              <Col md={9} className="h-100 p-0 m-0 chartwrap">
                {widgetData && widgetData.length > 0 ? (
                  <SimpleBarReact
                    className="listtypesimplebar"
                    autoHide={false}
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  >
                    <div style={{ padding: "0px 20px" }}>
                      <Row className="w-100 mx-0 mb-2">
                        <Col
                          className="d-flex align-items-center"
                          style={{ color: "#272727" }}
                        >
                          {data?.weather ? (
                            <img
                              src={`https://openweathermap.org/img/wn/${data?.weather[0]?.icon}@2x.png`}
                              alt="weather icon"
                              style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "14px",
                              }}
                            />
                          ) : (
                            <Weather
                              style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "14px",
                              }}
                            />
                          )}
                          <span
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            {`${t("weather")} ${
                              data?.weather ? data?.weather[0]?.description : ""
                            }`}
                          </span>
                        </Col>
                      </Row>
                      <Row
                        className="w-100 mx-0"
                        style={{ marginBottom: "20px" }}
                      >
                        <WeatherCard
                          icon="온도"
                          num={`${Number(data?.main?.temp).toFixed(0)}`}
                          title={t("currenttemp")}
                        />
                        <WeatherCard
                          icon="강수"
                          num={`${data?.main?.humidity}`}
                          title={t("currenthum")}
                        />
                      </Row>
                      <Row className="w-100 mx-0 mb-2">
                        <Col
                          className="d-flex align-items-center"
                          style={{ color: "#272727" }}
                        >
                          <Sensorlogo
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "14px",
                            }}
                          />
                          <span
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            {t("sensorsheader")}
                          </span>
                        </Col>
                      </Row>
                      {widgetData
                        ? widgetData.map((item, i) => {
                            return <SensorCard datas={item} key={i} />;
                          })
                        : null}
                    </div>
                  </SimpleBarReact>
                ) : (
                  <div className="insideText">SENSORS</div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SensorList;
