import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  FormFeedback,
} from "reactstrap";
import { adminRequestPost } from "../../apis/request";
import backImg from "../../assets/images/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds_2021-10-21/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds.png";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { useTranslation } from "react-i18next";
import "./listadd.scss";
import { MetaTags } from "react-meta-tags";
const AdminUseradd = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [nickname, setNickname] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [farmAddress, setFarmAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState("KO");
  const [timezone, setTimezone] = useState("Asia/Seoul");
  const history = useHistory();
  const { t } = useTranslation("main");

  const handleName = (e) => {
    setUsername(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleNickname = (e) => {
    setNickname(e.target.value);
  };

  const handleUserAddress = (e) => {
    setUserAddress(e.target.value);
  };

  const handleFarmAddress = (e) => {
    setFarmAddress(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleLanguage = (e) => {
    setLanguage(e.target.value);
  };

  const handleTimezone = (e) => {
    setTimezone(e);
  };

  const OnSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      // const params = new URLSearchParams();
      // params.append("username", username);
      // params.append("password", password);
      // params.append("nickname", nickname);

      let body = {
        username: username,
        password: password,
        nickname: nickname,
        userAddress: userAddress,
        farmAddress: farmAddress,
        phone: phone,
        language: language,
        timezone: timezone,
      };
      await adminRequestPost("/api/v1/admin/user/register", body);
      history.push("/adminpage/users");
    } catch (error) {
      alert("등록 실패하였습니다");
    }
  };

  const regType1 = /^[A-Za-z0-9_-]{5,16}$/;
  const regType2 = /^[A-Za-z0-9~!@#$%^&*()+_|<>?:{}]{8,16}$/;

  const idLimit = () => {
    if (regType1.test(username) === true) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin Page User Add | FarmCLO</title>
      </MetaTags>
      <img src={backImg} className="backgroundimg" />
      <div className="dashText">
        ADD
        <br />
        USER
      </div>
      <div className="listAddCardTotalWrap">
        <div className="listAddCardWrap">
          <Container className="h-100">
            <Row className="h-100">
              <Col className="listAddDisplay h-100">
                <Card className="listAddCard m-0">
                  <Row>
                    <Col>
                      <div>
                        <div>
                          <Form onSubmit={OnSubmitHandler}>
                            <FormGroup className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                username
                              </label>
                              <div style={{ width: "100%" }}>
                                <Input
                                  type="text"
                                  name="_name"
                                  maxlength="16"
                                  className="listAddInput"
                                  value={username}
                                  onChange={handleName}
                                  invalid={!regType1.test(username)}
                                  placeholder="name"
                                  required
                                />
                                <FormFeedback className="formFeed">
                                  5~16글자 사이 영어와 숫자만 가능합니다.
                                </FormFeedback>
                              </div>
                            </FormGroup>
                            <FormGroup className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                password
                              </label>
                              <div style={{ width: "100%" }}>
                                <Input
                                  type="text"
                                  name="_name"
                                  maxlength="16"
                                  className="listAddInput"
                                  value={password}
                                  onChange={handlePassword}
                                  invalid={!regType2.test(password)}
                                  placeholder="password"
                                  required
                                />
                                <FormFeedback className="formFeed">
                                  8~16글자 사이 영어와 숫자와 특수문자 만
                                  가능합니다.
                                </FormFeedback>
                              </div>
                            </FormGroup>
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                nickname
                              </label>
                              <Input
                                type="text"
                                name="_name"
                                className="listAddInput"
                                value={nickname}
                                onChange={handleNickname}
                                placeholder="nickname"
                                required
                              />
                            </div>
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                userAddress
                              </label>
                              <Input
                                type="text"
                                name="_name"
                                className="listAddInput"
                                value={userAddress}
                                onChange={handleUserAddress}
                                placeholder="user address"
                                required
                              />
                            </div>
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                farmAddress
                              </label>
                              <Input
                                type="text"
                                name="_name"
                                className="listAddInput"
                                value={farmAddress}
                                onChange={handleFarmAddress}
                                placeholder="farm address"
                                required
                              />
                            </div>
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                phone
                              </label>
                              <Input
                                type="text"
                                name="_name"
                                className="listAddInput"
                                value={phone}
                                onChange={handlePhone}
                                placeholder="phone"
                                required
                              />
                            </div>
                            <FormGroup className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_clusterId"
                                className="listAddName"
                              >
                                language
                              </label>
                              <Input
                                type="select"
                                className="listAddInput"
                                onChange={handleLanguage}
                              >
                                <option value="ko">KO</option>
                                <option value="en">EN</option>
                              </Input>
                            </FormGroup>
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                timezone
                              </label>
                              <TimezonePicker
                                onChange={handleTimezone}
                                defaultValue={"Asia/Seoul"}
                                unselectLabel="No Timezone"
                                absolute={true}
                                value={timezone}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                id="userAddButton"
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                등록하기
                              </button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AdminUseradd);
