import React, { useState, useEffect } from "react";
import i18next from "../i18n";
import "./lang.scss";

const Langchange = () => {
  const [languageTitle, setLanguageTitle] = useState(
    () => JSON.parse(window.localStorage.getItem("languageTitle")) || "ko"
  );

  useEffect(() => {
    window.localStorage.setItem("languageTitle", JSON.stringify(languageTitle));
  }, [languageTitle]);

  const changeLanguageKr = () => {
    // 언어변경
    i18next.changeLanguage("ko");

    // 이름변경
    setLanguageTitle("ko");
  };

  const changeLanguageEn = () => {
    // 언어변경
    i18next.changeLanguage("en");

    // 이름변경
    setLanguageTitle("en");
  };
  return (
    <React.Fragment>
      {languageTitle === "ko" ? (
        <div
          className={
            window.location.pathname === "/account"
              ? "accountLangWrap"
              : "langWrap"
          }
        >
          <div
            className={
              window.location.pathname === "/account"
                ? "accountLangdivleft"
                : "langdivleft"
            }
          >
            <div
              className={
                window.location.pathname === "/account"
                  ? "accountChecklangmark"
                  : "checklangmark"
              }
            ></div>
            <div
              className={
                window.location.pathname === "/account"
                  ? "accountChecklang"
                  : "checklang"
              }
            >
              한국어
            </div>
          </div>
          <div
            className={
              window.location.pathname === "/account"
                ? "accountLangdiv"
                : "langdiv"
            }
          >
            <div
              iv
              className={
                window.location.pathname === "/account"
                  ? "accountLangmark"
                  : "langmark"
              }
            ></div>
            <div
              className={
                window.location.pathname === "/account"
                  ? "accountLangfont"
                  : "langfont"
              }
              onClick={changeLanguageEn}
            >
              English
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            window.location.pathname === "/account"
              ? "accountLangWrap"
              : "langWrap"
          }
        >
          <div
            className={
              window.location.pathname === "/account"
                ? "accountLangdivleft"
                : "langdivleft"
            }
          >
            <div
              iv
              className={
                window.location.pathname === "/account"
                  ? "accountLangmark"
                  : "langmark"
              }
            ></div>
            <div
              className={
                window.location.pathname === "/account"
                  ? "accountLangfont"
                  : "langfont"
              }
              onClick={changeLanguageKr}
            >
              한국어
            </div>
          </div>
          <div
            className={
              window.location.pathname === "/account"
                ? "accountLangdiv"
                : "langdiv"
            }
          >
            <div
              className={
                window.location.pathname === "/account"
                  ? "accountChecklangmark"
                  : "checklangmark"
              }
            ></div>
            <div
              className={
                window.location.pathname === "/account"
                  ? "accountChecklang"
                  : "checklang"
              }
            >
              English
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Langchange;
