import React, { useEffect, useState } from "react";
import { NaverMap, Marker } from "react-naver-maps";
import { RenderAfterNavermapsLoaded } from "react-naver-maps";

function ListaddMap({ latitude, longitude, setLatitude, setLongitude }) {
  const [dumMapCenter, setDumMapCenter] = useState({
    lat: 37.3614483,
    lng: 127.1114883,
  });
  const [mapCenter, setMapCenter] = useState({
    lat: null,
    lng: null,
  });

  const [markCenter, setMarkCenter] = useState({});

  const [mark, setMark] = useState(false);
  setTimeout(function () {
    window.dispatchEvent(new Event("resize"));
  }, 600);

  const rightClick = (e) => {
    setLatitude(e.coord._lat);
    setLongitude(e.coord._lng);
    setMapCenter({ lat: e.coord._lat, lng: e.coord._lng });
    setMarkCenter({ lat: e.coord._lat, lng: e.coord._lng });
    setMark(true);
  };

  useEffect(() => {
    setMapCenter({ lat: latitude, lng: longitude });
    setMarkCenter({ lat: latitude, lng: longitude });
  }, [latitude, longitude]);
  return (
    <React.Fragment>
      <RenderAfterNavermapsLoaded // Render후 Navermap로드
        ncpClientId={process.env.REACT_APP_APIKEY} // 자신의 네이버 계정에서 발급받은 Client ID
        error={<p>Maps Load Error</p>}
        loading={<p>Maps Loading...</p>}
      >
        <NaverMap
          mapDivId={"maps-getting-started-uncontrolled"}
          style={{ width: "100%", height: "100%" }}
          onRightclick={(e) => rightClick(e)}
          center={
            mapCenter.lat === null || mapCenter.lng === null
              ? dumMapCenter
              : mapCenter
          }
          onCenterChanged={(center) => {
            mapCenter.lat === null || mapCenter.lng === null
              ? setDumMapCenter(center)
              : setMapCenter(center);
          }}
          zoom={mapCenter.lat === null || mapCenter.lng === null ? 5 : 15}
        >
          {mark ? <Marker position={markCenter} /> : <></>}
        </NaverMap>
      </RenderAfterNavermapsLoaded>
    </React.Fragment>
  );
}

export default ListaddMap;
