import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import "./profileMenu.scss";
import { apiUrl } from "../../../apis/request";

const ProfileMenu = (props) => {
  const [imgurl, setImgurl] = useState("");
  const [userName, setUserName] = useState("");
  const [nickName, setNickName] = useState("");

  useEffect(() => {
    if (props.userinfo) {
      setUserName(props.userinfo.username);
      setNickName(props.userinfo.nickname);
      setImgurl(apiUrl + props.userinfo.imagePath);
    }
  }, [props.userinfo]);

  return (
    <React.Fragment>
      <Link
        to="/account"
        className={props.headerSide ? "profileWrapSide" : "profileWrap"}
      >
        {!props.userinfo?.imagePath ? (
          <div
            className="photoImage"
            style={{ width: "40px", height: "40px", marginRight: "5px" }}
          />
        ) : (
          <img
            src={imgurl}
            alt="userimg"
            className="photoImage"
            style={{ width: "40px", height: "40px", marginRight: "5px" }}
          />
        )}
        <div className="infoWrap">
          <span
            className={
              window.location.pathname === "/login" ||
              window.location.pathname === "/dashboard" ||
              window.location.pathname === "/addpage" ||
              window.location.pathname === "/farmadd" ||
              window.location.pathname === "/listadd" ||
              window.location.pathname === "/cotadd" ||
              window.location.pathname === "/account" ||
              window.location.pathname === "/adminpage" ||
              window.location.pathname === "/farmdelete"
                ? "userinfo"
                : "nodashuserinfo"
            }
          >
            {userName}
          </span>
          <span
            className={
              window.location.pathname === "/login" ||
              window.location.pathname === "/dashboard" ||
              window.location.pathname === "/addpage" ||
              window.location.pathname === "/farmadd" ||
              window.location.pathname === "/listadd" ||
              window.location.pathname === "/cotadd" ||
              window.location.pathname === "/account" ||
              window.location.pathname === "/adminpage" ||
              window.location.pathname === "/farmdelete"
                ? "userfarm"
                : "nodashuserfarm"
            }
          >
            {nickName}
          </span>
        </div>
      </Link>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(ProfileMenu));
