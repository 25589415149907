import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import backImg from "../../assets/images/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds_2021-10-21/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds.png";
import { adminRequestPost } from "../../apis/request";
import { useTranslation } from "react-i18next";
import "./listadd.scss";
import CotaddModal from "./CotaddModal";
import { ReactComponent as Close } from "../../assets/icons/svgs/solid/xmark.svg";

const Cotadd = () => {
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("CON04V");
  const [chs, setChs] = useState([]);
  const [chNum, setChNum] = useState("");
  const [ch, setCh] = useState({});
  const [chName, setChName] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("main");

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleSerial = (e) => {
    setSerial(e.target.value);
  };

  const handledescription = (e) => {
    setDescription(e.target.value);
  };

  const OnSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      let arr = [];
      chs.map((item) => {
        if (ch[item]?.sensorId?.length > 0) {
          arr.push({
            channelNumber: item,
            sensorIds: ch[item].sensorId,
          });
        }
      });
      let body = {
        serial: serial,
        name: name,
        description: description,
        type: type,
      };
      if (arr.length > 0) {
        body.channels = arr;
      }
      await adminRequestPost("/api/v1/controller", body);
      alert("등록 하였습니다.");
      history.goBack();
    } catch (error) {
      alert("등록 실패하였습니다.");
    }
  };

  const onModal = (e, item) => {
    e.preventDefault();
    setChNum(item);
    setOpenModal(!openModal);
  };

  const onRemove = (key) => {
    setCh((prevData) => {
      if (prevData.hasOwnProperty(key)) {
        const updatedData = { ...prevData };
        updatedData[key].sensorId = [];
        updatedData[key].name = [];
        return updatedData;
      }
      return prevData;
    });
  };

  const removeSensorData = (chNum, targetSensorId) => {
    setCh((prevData) => {
      const updatedData = { ...prevData };
      const sensorIdIndex = updatedData[chNum].sensorId.indexOf(targetSensorId);
      if (sensorIdIndex > -1) {
        updatedData[chNum].sensorId.splice(sensorIdIndex, 1);
        updatedData[chNum].name.splice(sensorIdIndex, 1);
      }
      return updatedData;
    });
  };

  const channelCheck = (item) => {
    setChs((prevChs) => {
      const numItem = Number(item);
      const isPresent = prevChs.includes(numItem);
      let newChs;
      if (isPresent) {
        newChs = prevChs.filter((ch) => ch !== numItem);
      } else {
        newChs = [...prevChs, numItem].sort((a, b) => a - b);
      }
      return newChs;
    });
  };

  const channelAll = () => {
    if (chs.length === 8) {
      setChs([]);
    } else {
      setChs([1, 2, 3, 4, 5, 6, 7, 8]);
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensor Add | FarmCLO</title>
      </MetaTags>
      <img src={backImg} className="backgroundimg" />
      <div className="dashText">
        ADD
        <br />
        COT
      </div>
      <div className="listAddCardTotalWrap">
        <div className="listAddCardWrap">
          <Container className="h-100">
            <Row className="h-100">
              <Col className="listAddDisplay h-100">
                <Card className="listAddCard m-0">
                  <Row>
                    <Col>
                      <div>
                        <div>
                          <Form onSubmit={OnSubmitHandler}>
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_name" className="listAddName">
                                {t("name")}
                              </label>
                              <Input
                                type="text"
                                name="_name"
                                className="listAddInput"
                                value={name}
                                onChange={handleName}
                                placeholder="name"
                                required
                              />
                            </div>
                            <div className="listAddRow d-block d-md-flex">
                              <label htmlFor="_serial" className="listAddName">
                                {t("serial")}
                              </label>
                              <Input
                                type="text"
                                name="_serial"
                                className="listAddInput"
                                value={serial}
                                onChange={handleSerial}
                                placeholder="serial"
                                required
                              />
                            </div>
                            <div className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_description"
                                className="listAddName"
                              >
                                {t("description")}
                              </label>
                              <Input
                                type="text"
                                name="_description"
                                className="listAddInput"
                                value={description}
                                onChange={handledescription}
                                placeholder="description"
                              />
                            </div>
                            <FormGroup className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_clusterId"
                                className="listAddName"
                              >
                                {t("type")}
                              </label>
                              <Input
                                type="select"
                                className="listAddInput"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                              >
                                <option value="CON04V">CON04V</option>
                                <option value="CON04E">CON04E</option>
                                <option value="CON01W">CON01W</option>
                              </Input>
                            </FormGroup>
                            <FormGroup className="listAddRow d-block d-md-flex">
                              <label
                                htmlFor="_clusterId"
                                className="listAddName"
                              >
                                {t("ch")}
                              </label>
                              <div className="w-100 d-flex flex-column">
                                <div className="d-flex">
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs.length === 8}
                                      onClick={channelAll}
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      전체
                                    </Label>
                                  </FormGroup>
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs?.includes(1)}
                                      value={1}
                                      onClick={(e) =>
                                        channelCheck(e.target.value)
                                      }
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      1CH
                                    </Label>
                                  </FormGroup>
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs?.includes(2)}
                                      value={2}
                                      onClick={(e) =>
                                        channelCheck(e.target.value)
                                      }
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      2CH
                                    </Label>
                                  </FormGroup>
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs?.includes(3)}
                                      value={3}
                                      onClick={(e) =>
                                        channelCheck(e.target.value)
                                      }
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      3CH
                                    </Label>
                                  </FormGroup>
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs?.includes(4)}
                                      value={4}
                                      onClick={(e) =>
                                        channelCheck(e.target.value)
                                      }
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      4CH
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div className="d-flex">
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs?.includes(5)}
                                      value={5}
                                      onClick={(e) =>
                                        channelCheck(e.target.value)
                                      }
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      5CH
                                    </Label>
                                  </FormGroup>
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs?.includes(6)}
                                      value={6}
                                      onClick={(e) =>
                                        channelCheck(e.target.value)
                                      }
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      6CH
                                    </Label>
                                  </FormGroup>
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs?.includes(7)}
                                      value={7}
                                      onClick={(e) =>
                                        channelCheck(e.target.value)
                                      }
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      7CH
                                    </Label>
                                  </FormGroup>
                                  <FormGroup
                                    check
                                    className="d-flex align-items-center me-3"
                                    style={{ textWrap: "nowrap" }}
                                  >
                                    <Input
                                      type="checkbox"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                      checked={chs?.includes(8)}
                                      value={8}
                                      onClick={(e) =>
                                        channelCheck(e.target.value)
                                      }
                                    />
                                    <Label check style={{ fontSize: "18px" }}>
                                      8CH
                                    </Label>
                                  </FormGroup>
                                </div>
                              </div>
                            </FormGroup>
                            {chs?.map((item, i) => {
                              return (
                                <div key={i} style={{ marginBottom: "20px" }}>
                                  <div
                                    className="listAddRow d-block d-md-flex"
                                    key={i}
                                  >
                                    <label
                                      htmlFor="_description"
                                      className="listAddName"
                                    >
                                      {t("ch")}
                                      {item}
                                    </label>
                                    <div className="w-100 d-flex">
                                      <button
                                        className="listAddBtn"
                                        style={
                                          ch[item]?.name?.length === 3
                                            ? {
                                                marginTop: "0px",
                                                width: "auto",
                                                height: "auto",
                                                borderRadius: "8px",
                                                backgroundColor: "#7e847c",
                                                padding: "5px 10px",
                                                textWrap: "nowrap",
                                              }
                                            : {
                                                marginTop: "0px",
                                                width: "auto",
                                                height: "auto",
                                                borderRadius: "8px",
                                                padding: "5px 10px",
                                                textWrap: "nowrap",
                                              }
                                        }
                                        type="button"
                                        disabled={ch[item]?.name?.length === 3}
                                        onClick={(e) => onModal(e, item)}
                                      >
                                        {t("add")}
                                      </button>
                                      <button
                                        className="listAddBtn"
                                        style={{
                                          marginTop: "0px",
                                          width: "auto",
                                          height: "auto",
                                          borderRadius: "8px",
                                          marginLeft: "20px",
                                          padding: "5px 10px",
                                          backgroundColor: "#ec5454",
                                          textWrap: "nowrap",
                                        }}
                                        type="button"
                                        onClick={(e) => onRemove(item)}
                                      >
                                        {t("remove")}
                                      </button>
                                    </div>
                                  </div>
                                  {ch[item]?.name?.length === 3 ? (
                                    <div
                                      className="w-100 d-flex align-items-end"
                                      style={{
                                        marginTop: "-20px",
                                        color: "#ec5454",
                                      }}
                                    >
                                      채널당 센서는 최대 3개 입니다.
                                    </div>
                                  ) : null}
                                  <div className="d-flex w-100 overflow-auto">
                                    {ch[item]?.name?.map((el, i) => {
                                      return (
                                        <div
                                          className="d-flex align-items-center me-3 chSensorCheap"
                                          key={i}
                                        >
                                          <div className="d-flex flex-column me-2">
                                            <span>{`시리얼 : ${ch[item].sensorId[i]}`}</span>
                                            <span>{`이름 : ${el}`}</span>
                                          </div>
                                          <Close
                                            className="closeSvg"
                                            style={{
                                              cursor: "pointer",
                                              width: "15px",
                                              height: "15px",
                                            }}
                                            onClick={() =>
                                              removeSensorData(
                                                item,
                                                ch[item].sensorId[i]
                                              )
                                            }
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                            <Col>
                              <div>
                                <button className="listAddBtn" type="submit">
                                  {t("save")}
                                </button>
                              </div>
                            </Col>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {openModal ? (
        <CotaddModal
          setModalOpen={setOpenModal}
          chNum={chNum}
          setChNum={setChNum}
          ch={ch}
          setCh={setCh}
          chName={chName}
          setChName={setChName}
          type={type}
        />
      ) : null}
    </React.Fragment>
  );
};
export default withRouter(Cotadd);
