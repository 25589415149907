const CheckComponentCOT = ({
  id,
  deviceType,
  cotIds,
  setCotIds,
  setDeviceTypes,
  setTopDeviceTypes,
}) => {
  return (
    <input
      type="checkbox"
      checked={cotIds?.includes(id)}
      style={{ width: "20px", height: "20px" }}
      value={deviceType}
      onClick={(e) => {
        e.stopPropagation();
        if (cotIds?.includes(id)) {
          setDeviceTypes("");
          setCotIds(cotIds.filter((_id) => _id !== id));
        } else if (!cotIds?.includes(id)) {
          setDeviceTypes("");
          setCotIds([...cotIds, id]);
        }
      }}
      readOnly
    ></input>
  );
};

export default CheckComponentCOT;
