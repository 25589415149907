import React, { useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "./chart.scss";
import { useTranslation } from "react-i18next";

const CSVDownloadBtn = ({ res }) => {
  const { t } = useTranslation("main");
  // const convertToCSV = (objArray) => {
  //   let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  //   let header = Object.keys(array[0]).join(",") + "\r\n";
  //   let str = header;

  //   for (let i = 0; i < array.length; i++) {
  //     let line = "";
  //     for (let index in array[i]) {
  //       if (line != "") line += ",";
  //       line += array[i][index];
  //     }

  //     str += line + "\r\n";
  //   }

  //   return str;
  // };

  // const downloadCSV = () => {
  //   let keys = Object.keys(res);
  //   if (keys.length > 0) {
  //     let csvData = [];
  //     let name = "";
  //     if (keys.length > 1) {
  //       name = keys[0] + "and_" + String(keys.length - 1) + "other";
  //     } else {
  //       name = keys[0];
  //     }
  //     for (let key in res) {
  //       csvData.push(convertToCSV(res[key]));
  //     }
  //     let csvContent = "data:text/csv;charset=utf-8," + csvData.join("\n");
  //     let encodedUri = encodeURI(csvContent);
  //     let link = document.createElement("a");
  //     link.setAttribute("href", encodedUri);
  //     link.setAttribute("download", `${name}.csv`);
  //     document.body.appendChild(link); // Required for FF
  //     link.click(); // This will download the data file named "my_data.csv".
  //   }
  // };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const download = (e) => {
    e.stopPropagation();
    let wb = XLSX.utils.book_new();
    let keys = Object.keys(res);
    let name = "";
    if (keys.length > 1) {
      name = keys[0] + "and_" + String(keys.length - 1) + "other";
    } else {
      name = keys[0];
    }
    for (let key in res) {
      let ws = XLSX.utils.json_to_sheet(res[key]);
      XLSX.utils.book_append_sheet(wb, ws, key);
    }
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    saveAs(blob, `${name}.xlsx`);
  };

  return (
    <div style={{ width: "100%", textAlign: "end" }}>
      <button
        className="excelDownBtn"
        onClick={download}
        style={{ marginBottom: "15px" }}
      >
        {t("csvdown")}
      </button>
    </div>
  );
  //   <button onClick={downloadCSV}>Download CSV</button>;
};

export default CSVDownloadBtn;
