import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import {
  adminRequestPost,
  adminUserPut,
  apiUrl,
  imgPut,
  requestDelete,
  requestGet,
  requestPut,
} from "../../../apis/request";
import SimpleBarReact from "simplebar-react";
import SensorMap from "../../Maps/SensorMap";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { ReactComponent as Photo } from "../../../assets/icons/photo.svg";
import { ReactComponent as Close } from "../../../assets/icons/svgs/solid/xmark.svg";
import CotaddModal from "../../Listadd/CotaddModal";
import CoteditModal from "../../Listadd/CoteditModal";

const ControllerEdit = (props) => {
  const [cotId] = useState(props.location.state.cotId);
  const [editData, setEditData] = useState(null);
  const [imgBase64, setImgBase64] = useState([]);
  const [photoFile, setPhotoFile] = useState(null);
  const [deleteHide, setDeleteHide] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [description, setDescription] = useState("");
  const [chs, setChs] = useState([]);
  const [defaultChs, setDefaultChs] = useState([]);
  const [chNum, setChNum] = useState("");
  const [ch, setCh] = useState({});
  const [chName, setChName] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("main");

  useEffect(() => {
    callApi();
  }, [cotId]);

  const callApi = async () => {
    try {
      const response = await requestGet(`/api/v1/controller/${cotId}`);
      let arr = [];
      let ch = {};
      for (let i = 0; i < response.channels?.length; i++) {
        arr.push(response.channels[i].channelNumber);
      }
      response.channels.forEach((channel) => {
        const chNum = channel.channelNumber;
        const sensorIds = channel.sensors.map((sensor) => sensor.serial);
        const names = channel.sensors.map((sensor) => sensor.name);
        const chIds = channel.id;
        ch[chNum] = {
          sensorId: [
            ...new Set([...(ch[chNum]?.sensorId || []), ...sensorIds]),
          ],
          name: [...new Set([...(ch[chNum]?.name || []), ...names])],
          chId: chIds,
        };
      });
      setEditData(response);
      setCh(ch);
      setChs(arr);
      setDefaultChs(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (editData) {
      setName(editData.name);
      setDescription(editData.description);
      setSerial(editData.serial);
    }
  }, [editData]);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleSerial = (e) => {
    setSerial(e.target.value);
  };

  const handledescription = (e) => {
    setDescription(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      let arr = [];
      chs.map((item) => {
        arr.push({
          channelNumber: item,
          controllerId: cotId,
          sensorIds: ch[item].sensorId,
        });
      });
      let body = {
        serial: serial,
        name: name,
        description: description,
        controllerId: cotId,
        channels: arr,
      };
      await adminUserPut(`/api/v1/controller/${cotId}`, body);
      alert("등록 하였습니다.");
      history.goBack();
    } catch (error) {
      alert("등록 실패하였습니다.");
    }
  };

  const onModal = (e, item) => {
    e.preventDefault();
    setChNum(item);
    setOpenModal(!openModal);
  };

  const removeSenor = async (id, chId) => {
    try {
      await requestDelete(`/api/v1/sensor/channel/${chId}/sensor/${id}`);
    } catch (error) {
      alert(`${t("delfail")}`);
    }
  };
  const onRemove = (key) => {
    setCh((prevData) => {
      if (prevData.hasOwnProperty(key)) {
        const updatedData = { ...prevData };
        updatedData[key].sensorId = [];
        updatedData[key].name = [];
        return updatedData;
      }
      return prevData;
    });
  };

  const removeSensorData = (chNum, targetSensorId, chId) => {
    removeSenor(targetSensorId, chId);
    setCh((prevData) => {
      const updatedData = { ...prevData };
      const sensorIdIndex = updatedData[chNum].sensorId.indexOf(targetSensorId);
      if (sensorIdIndex > -1) {
        updatedData[chNum].sensorId.splice(sensorIdIndex, 1);
        updatedData[chNum].name.splice(sensorIdIndex, 1);
      }
      return updatedData;
    });
  };

  const channelCheck = (item) => {
    setChs((prevChs) => {
      const numItem = Number(item);
      const isPresent = prevChs.includes(numItem);
      let newChs;
      if (isPresent) {
        newChs = prevChs.filter((ch) => ch !== numItem);
      } else {
        newChs = [...prevChs, numItem].sort((a, b) => a - b);
      }
      return newChs;
    });
  };

  const channelAll = () => {
    if (chs.length === 8) {
      setChs(defaultChs);
    } else {
      setChs([1, 2, 3, 4, 5, 6, 7, 8]);
    }
  };

  const onFileHandler = (e) => {
    setPhotoFile(e.target.files[0]);
    setImgBase64(URL.createObjectURL(e.target.files[0]));
  };

  const hidehandle = () => {
    setDeleteHide(!deleteHide);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensor Edit | FarmCLO</title>
      </MetaTags>
      <div className="accountpageTotalWrap11">
        <div className="accountpage-content">
          <div className="accountWrap">
            <div className="accountPageCardWrap">
              <div className="accountPageCard1">
                <CardBody>
                  <div>
                    <div className="float-end" style={{ position: "relative" }}>
                      <i
                        className="deletehide bx bx-dots-vertical-rounded"
                        onClick={hidehandle}
                      />
                      {deleteHide ? (
                        <button
                          color="danger"
                          className="sensorDeletBtn"
                          onClick={() => {
                            setconfirm_alert(true);
                          }}
                          style={{ zIndex: 1 }}
                        >
                          <i className="deleticon bx bx-trash" />
                          {t("delete")}
                        </button>
                      ) : (
                        ""
                      )}
                      {confirm_alert ? (
                        <SweetAlert
                          title="정말 삭제하시겠습니까?"
                          warning
                          showCancel
                          confirmButtonText={t("delete")}
                          confirmBtnBsStyle="danger"
                          cancelButtonText={t("cancel")}
                          cancelBtnBsStyle="success"
                          onConfirm={async () => {
                            setconfirm_alert(false);
                            setsuccess_dlg(!success_dlg);
                            await requestDelete(`/api/v1/controller/${cotId}`);
                            history.push("/sensors");
                          }}
                          onCancel={() => setconfirm_alert(false)}
                        ></SweetAlert>
                      ) : null}
                    </div>
                    <div className="clearfix"></div>
                    <div className="sensorInfoTitleWrap"></div>
                  </div>
                  <div
                    className="sensorInfoTable table-responsive"
                    style={{ padding: "0 40px 0 50px" }}
                  >
                    <Form onSubmit={onSubmitHandler}>
                      <div className="photoAddRow">
                        {!imgBase64 || imgBase64?.length === 0 ? (
                          <div className="photoImage" />
                        ) : (
                          <img
                            src={imgBase64}
                            alt="photo"
                            className="photoImage"
                          />
                        )}
                        <Label className="fileAddName m-0">
                          <Input
                            type="file"
                            id="image"
                            onChange={onFileHandler}
                          />
                          <Photo />
                        </Label>
                        {/* <button
                          onClick={(e) => onFileDelHandler(e)}
                          style={{
                            position: "absolute",
                            right: "5%",
                          }}
                        >
                          사진삭제
                        </button> */}
                      </div>
                      <div className="listAddRow d-block d-md-flex">
                        <label htmlFor="_name" className="listAddName">
                          {t("name")}
                        </label>
                        <Input
                          type="text"
                          name="_name"
                          className="listAddInput"
                          value={name}
                          onChange={handleName}
                          placeholder="name"
                          required
                        />
                      </div>
                      <div className="listAddRow d-block d-md-flex">
                        <label htmlFor="_serial" className="listAddName">
                          {t("serial")}
                        </label>
                        <Input
                          type="text"
                          name="_serial"
                          className="listAddInput"
                          value={serial}
                          onChange={handleSerial}
                          placeholder="serial"
                          required
                        />
                      </div>
                      <div className="listAddRow d-block d-md-flex">
                        <label htmlFor="_description" className="listAddName">
                          {t("description")}
                        </label>
                        <Input
                          type="text"
                          name="_description"
                          className="listAddInput"
                          value={description}
                          onChange={handledescription}
                          placeholder="description"
                        />
                      </div>
                      <FormGroup className="listAddRow d-block d-md-flex">
                        <label htmlFor="_clusterId" className="listAddName">
                          {t("ch")}
                        </label>
                        <div className="w-100 d-flex flex-column">
                          <div className="d-flex">
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                checked={chs.length === 8}
                                onClick={channelAll}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                전체
                              </Label>
                            </FormGroup>
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                disabled={defaultChs?.includes(1)}
                                checked={chs?.includes(1)}
                                value={1}
                                onClick={(e) => channelCheck(e.target.value)}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                1CH
                              </Label>
                            </FormGroup>
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                disabled={defaultChs?.includes(2)}
                                checked={chs?.includes(2)}
                                value={2}
                                onClick={(e) => channelCheck(e.target.value)}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                2CH
                              </Label>
                            </FormGroup>
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                disabled={defaultChs?.includes(3)}
                                checked={chs?.includes(3)}
                                value={3}
                                onClick={(e) => channelCheck(e.target.value)}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                3CH
                              </Label>
                            </FormGroup>
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                disabled={defaultChs?.includes(4)}
                                checked={chs?.includes(4)}
                                value={4}
                                onClick={(e) => channelCheck(e.target.value)}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                4CH
                              </Label>
                            </FormGroup>
                          </div>
                          <div className="d-flex">
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                disabled={defaultChs?.includes(5)}
                                checked={chs?.includes(5)}
                                value={5}
                                onClick={(e) => channelCheck(e.target.value)}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                5CH
                              </Label>
                            </FormGroup>
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                disabled={defaultChs?.includes(6)}
                                checked={chs?.includes(6)}
                                value={6}
                                onClick={(e) => channelCheck(e.target.value)}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                6CH
                              </Label>
                            </FormGroup>
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                disabled={defaultChs?.includes(7)}
                                checked={chs?.includes(7)}
                                value={7}
                                onClick={(e) => channelCheck(e.target.value)}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                7CH
                              </Label>
                            </FormGroup>
                            <FormGroup
                              check
                              className="d-flex align-items-center me-3"
                              style={{ textWrap: "nowrap" }}
                            >
                              <Input
                                type="checkbox"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                                disabled={defaultChs?.includes(8)}
                                checked={chs?.includes(8)}
                                value={8}
                                onClick={(e) => channelCheck(e.target.value)}
                              />
                              <Label check style={{ fontSize: "18px" }}>
                                8CH
                              </Label>
                            </FormGroup>
                          </div>
                        </div>
                      </FormGroup>
                      {chs?.map((item, i) => {
                        return (
                          <div key={i} style={{ marginBottom: "20px" }}>
                            <div
                              className="listAddRow d-block d-md-flex"
                              key={i}
                            >
                              <label
                                htmlFor="_description"
                                className="listAddName"
                              >
                                {t("ch")}
                                {item}
                              </label>
                              <div className="w-100 d-flex">
                                <button
                                  className="listAddBtn"
                                  style={
                                    ch[item]?.name?.length === 3
                                      ? {
                                          marginTop: "0px",
                                          width: "auto",
                                          height: "auto",
                                          borderRadius: "8px",
                                          backgroundColor: "#7e847c",
                                          padding: "5px 10px",
                                          textWrap: "nowrap",
                                        }
                                      : {
                                          marginTop: "0px",
                                          width: "auto",
                                          height: "auto",
                                          borderRadius: "8px",
                                          padding: "5px 10px",
                                          textWrap: "nowrap",
                                        }
                                  }
                                  type="button"
                                  disabled={ch[item]?.name?.length === 3}
                                  onClick={(e) => onModal(e, item)}
                                >
                                  {t("add")}
                                </button>
                                <button
                                  className="listAddBtn"
                                  style={{
                                    marginTop: "0px",
                                    width: "auto",
                                    height: "auto",
                                    borderRadius: "8px",
                                    marginLeft: "20px",
                                    padding: "5px 10px",
                                    backgroundColor: "#ec5454",
                                    textWrap: "nowrap",
                                  }}
                                  type="button"
                                  onClick={(e) => onRemove(item)}
                                >
                                  {t("remove")}
                                </button>
                              </div>
                            </div>
                            {ch[item]?.name?.length === 3 ? (
                              <div
                                className="w-100 d-flex align-items-end"
                                style={{
                                  marginTop: "-20px",
                                  color: "#ec5454",
                                }}
                              >
                                채널당 센서는 최대 3개 입니다.
                              </div>
                            ) : null}
                            <div className="d-flex w-100 overflow-auto">
                              {ch[item]?.name?.map((el, i) => {
                                return (
                                  <div
                                    className="d-flex align-items-center me-3 chSensorCheap"
                                    key={i}
                                  >
                                    <div className="d-flex flex-column me-2">
                                      <span>{`시리얼 : ${ch[item].sensorId[i]}`}</span>
                                      <span>{`이름 : ${el}`}</span>
                                    </div>
                                    <Close
                                      className="closeSvg"
                                      style={{
                                        cursor: "pointer",
                                        width: "15px",
                                        height: "15px",
                                      }}
                                      onClick={() =>
                                        removeSensorData(
                                          item,
                                          ch[item].sensorId[i],
                                          ch[item].chId
                                        )
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                      <Col>
                        <div>
                          <button className="listAddBtn" type="submit">
                            {t("save")}
                          </button>
                        </div>
                      </Col>
                    </Form>
                  </div>
                </CardBody>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal ? (
        <CoteditModal
          setModalOpen={setOpenModal}
          chNum={chNum}
          setChNum={setChNum}
          ch={ch}
          setCh={setCh}
          chName={chName}
          setChName={setChName}
        />
      ) : null}
    </React.Fragment>
  );
};

export default ControllerEdit;
