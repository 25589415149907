import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { CardBody } from "reactstrap";
import PaginationWrap from "./PaginationWrap";
import TableRow from "./TableRow";
import { useHistory } from "react-router-dom";
import { requestDelete, requestGet } from "../../apis/request";
import { MetaTags } from "react-meta-tags";

const AdminSensor = () => {
  const [allSelected, setAllSelected] = useState(false);
  const [selects, setSelects] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [page, setPage] = useState(1);
  const [userId, setUserId] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);

  const sensors = async () => {
    const sensor = await requestGet(`/api/v1/admin/sensors`);
    setUserInfo(sensor);
  };

  useEffect(() => {
    sensors();
  }, []);

  const offset = 5;
  const indexOfLastPost = page * offset;
  const indexOfFirstPost = indexOfLastPost - offset;

  useEffect(() => {
    if (userInfo?.length > 0 && Array.isArray(userInfo)) {
      setUserId(userInfo.map((data) => data.id));
      setCurrentPosts(userInfo.slice(indexOfFirstPost, indexOfLastPost));
    }
  }, [userInfo, indexOfFirstPost, indexOfLastPost]);

  useEffect(() => {
    if (userInfo?.length > 0 && Array.isArray(userInfo)) {
      setCurrentPosts(userInfo.slice(indexOfFirstPost, indexOfLastPost));
    }
  }, [page, userInfo, indexOfFirstPost, indexOfLastPost]);

  const paginate = (pageNumber) => setPage(pageNumber);
  const history = useHistory();
  const removeCheck = async () => {
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      await requestDelete(`/api/v1/admin/sensor/delete/${selects[0]}`);
      setUserInfo(userInfo.filter((user) => !selects.includes(user.id)));
      setSelects(selects.filter((_id) => !_id));
    } else {
      return;
    }
  };

  const allChek = () => {
    setAllSelected(!allSelected);
    if (!allSelected) {
      setSelects(userId);
    } else {
      setSelects([]);
    }
  };

  const goListAdd = () => {
    history.push("/adminpage/sensor/add");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin Page Sensors | FarmCLO</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <Row>
              <Col>
                <Card className="mt-5">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <h4 className="card-title">Sensors List</h4>
                      <div>
                        <Button onClick={goListAdd}>Add</Button>
                        <Button
                          onClick={() => {
                            if (selects.length !== 0) {
                              removeCheck();
                            } else {
                              alert("선택하여 주십시요!");
                            }
                          }}
                          style={{
                            marginLeft: "1em",
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                checked={selects.length === userInfo.length}
                                onClick={() => allChek()}
                                readOnly
                              ></input>
                            </th>
                            <th>uuid</th>
                            <th>Name</th>
                            <th>deviceType</th>
                            <th>serial</th>
                            <th>description</th>
                            <th>latitude</th>
                            <th>longitude</th>
                          </tr>
                        </thead>
                        <tbody>
                          <TableRow
                            allSelected={allSelected}
                            selects={selects}
                            setSelects={setSelects}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            currentPosts={currentPosts}
                            setAllSelected={setAllSelected}
                          />
                        </tbody>
                      </Table>
                      <PaginationWrap
                        pageNumber={pageNumber}
                        setpageNumber={setpageNumber}
                        lastpage={Math.ceil(userInfo.length / 5)}
                        setActiveNum={setPage}
                        activeNum={page}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminSensor;
