import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/logo68.svg";
import nodashlogo from "../../assets/images/logo.svg";
import { ReactComponent as Signout } from "../../assets/images/fi-rr-sign-out-alt.svg";
import { ReactComponent as Add } from "../../assets/images/fi-rr-add.svg";
import { ReactComponent as Irrlogo } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { ReactComponent as Sensorlogo } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-chart-histogram.svg";
import { ReactComponent as Maplogo } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-map-marker.svg";
import { ReactComponent as Lock } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-lock.svg";
//i18n
import { useTranslation } from "react-i18next";

import "./header.scss";
import { norequestGet, requestGet } from "../../apis/request";
import SendMail from "../CommonForBoth/TopbarDropdown/SendMail";
import { Col, Row } from "reactstrap";

const Header = (props) => {
  const [headerSide, setHeaderSide] = useState(false);
  const [userinfo, setUserinfo] = useState(false);
  const { t } = useTranslation("main");
  const history = useHistory();

  const callInfo = async () => {
    try {
      const res = await norequestGet("/api/account/info");
      setUserinfo(res);
    } catch (error) {
      if (error.response?.status === 403) {
        alert("로그인이 만료 되었습니다.");
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    callInfo();
  }, []);

  const headerSideHandle = () => {
    setHeaderSide(!headerSide);
  };

  const logoutbtn = async () => {
    await requestGet(`/api/account/logout`);
    history.push("/login");
  };
  return (
    <React.Fragment>
      <div
        className={
          window.location.pathname === "/login" ||
          window.location.pathname === "/dashboard" ||
          window.location.pathname === "/addpage" ||
          window.location.pathname === "/farmadd" ||
          window.location.pathname === "/listadd" ||
          window.location.pathname === "/cotadd" ||
          window.location.pathname === "/account" ||
          window.location.pathname === "/adminpage" ||
          window.location.pathname === "/farmdelete"
            ? "headerlinelodash"
            : "headerlines"
        }
      />
      <header id="page-topbar">
        <div
          className="navbar-header"
          style={headerSide ? { display: "none" } : { display: "flex" }}
        >
          <div className="navWrap">
            <div className="navbar-brand-box">
              <Link to="/" className="logoclo">
                <img
                  src={
                    window.location.pathname === "/login" ||
                    window.location.pathname === "/dashboard" ||
                    window.location.pathname === "/addpage" ||
                    window.location.pathname === "/farmadd" ||
                    window.location.pathname === "/listadd" ||
                    window.location.pathname === "/cotadd" ||
                    window.location.pathname === "/account" ||
                    window.location.pathname === "/adminpage" ||
                    window.location.pathname === "/farmdelete"
                      ? logo
                      : nodashlogo
                  }
                  alt="logo"
                  className="dashlogo"
                />
              </Link>
              <span className="irrigationspan">
                <Link
                  to="/irrigation"
                  className={
                    window.location.pathname === "/irrigation" ||
                    window.location.pathname === "/irrigation/farm"
                      ? "activeLinkWrap"
                      : window.location.pathname === "/dashboard" ||
                        window.location.pathname === "/addpage" ||
                        window.location.pathname === "/farmadd" ||
                        window.location.pathname === "/listadd" ||
                        window.location.pathname === "/cotadd" ||
                        window.location.pathname === "/account" ||
                        window.location.pathname === "/adminpage" ||
                        window.location.pathname === "/farmdelete"
                      ? "linkWrap"
                      : "nodashlinkWrap"
                  }
                >
                  <Irrlogo className="irrHeaderLogo" />
                  {t("irrigationheader")}
                </Link>
              </span>
              <span className="sensorsspan">
                <Link
                  to="/sensors"
                  className={
                    window.location.pathname === "/sensors" ||
                    window.location.pathname === "/sensors/type"
                      ? "activeLinkWrap"
                      : window.location.pathname === "/dashboard" ||
                        window.location.pathname === "/addpage" ||
                        window.location.pathname === "/farmadd" ||
                        window.location.pathname === "/listadd" ||
                        window.location.pathname === "/cotadd" ||
                        window.location.pathname === "/account" ||
                        window.location.pathname === "/adminpage" ||
                        window.location.pathname === "/farmdelete"
                      ? "linkWrap"
                      : "nodashlinkWrap"
                  }
                >
                  <Sensorlogo className="irrHeaderLogo" />
                  {t("sensorsheader")}
                </Link>
              </span>
              <span className="mapspan">
                <Link
                  to="/maps"
                  className={
                    window.location.pathname === "/maps"
                      ? "activeLinkWrap"
                      : window.location.pathname === "/dashboard" ||
                        window.location.pathname === "/addpage" ||
                        window.location.pathname === "/farmadd" ||
                        window.location.pathname === "/listadd" ||
                        window.location.pathname === "/cotadd" ||
                        window.location.pathname === "/account" ||
                        window.location.pathname === "/adminpage" ||
                        window.location.pathname === "/farmdelete"
                      ? "linkWrap"
                      : "nodashlinkWrap"
                  }
                >
                  <Maplogo className="irrHeaderLogo" />
                  {t("map")}
                </Link>
              </span>
              {userinfo.userType === "ADMIN" ? (
                <span className="mapspan">
                  <Link
                    to="/adminpage"
                    className={
                      window.location.pathname === "/adminpage" ||
                      window.location.pathname === "/adminpage/user" ||
                      window.location.pathname === "/adminpage/users" ||
                      window.location.pathname === "/adminpage/sensor" ||
                      window.location.pathname === "/adminpage/sensors"
                        ? "activeLinkWrap"
                        : window.location.pathname === "/dashboard" ||
                          window.location.pathname === "/addpage" ||
                          window.location.pathname === "/farmadd" ||
                          window.location.pathname === "/listadd" ||
                          window.location.pathname === "/cotadd" ||
                          window.location.pathname === "/account" ||
                          window.location.pathname === "/farmdelete"
                        ? "linkWrap"
                        : "nodashlinkWrap"
                    }
                  >
                    <Lock className="irrHeaderLogo" />
                    {t("admin")}
                  </Link>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Link
              to="/addpage"
              className={
                window.location.pathname === "/addpage" ||
                window.location.pathname === "/farmadd" ||
                window.location.pathname === "/listadd" ||
                window.location.pathname === "/cotadd" ||
                window.location.pathname === "/farmdelete"
                  ? "addPageToggle"
                  : window.location.pathname === "/login" ||
                    window.location.pathname === "/dashboard" ||
                    window.location.pathname === "/account" ||
                    window.location.pathname === "/adminpage"
                  ? "addPagebtntWrap"
                  : "addPagenodashbtntWrap"
              }
            >
              <Add />
            </Link>
            <SendMail />
            <NotificationDropdown className="notification" />
            <Link
              to="/logout"
              className={
                window.location.pathname === "/login" ||
                window.location.pathname === "/dashboard" ||
                window.location.pathname === "/addpage" ||
                window.location.pathname === "/farmadd" ||
                window.location.pathname === "/listadd" ||
                window.location.pathname === "/cotadd" ||
                window.location.pathname === "/account" ||
                window.location.pathname === "/adminpage" ||
                window.location.pathname === "/farmdelete"
                  ? "addPagebtntWrap"
                  : "nodashbtntWrap"
              }
            >
              <Signout />
            </Link>
            <ProfileMenu headerSide={headerSide} userinfo={userinfo} />
            <i
              className="fa fa-fw fa-bars"
              id={
                window.location.pathname === "/login" ||
                window.location.pathname === "/dashboard" ||
                window.location.pathname === "/addpage" ||
                window.location.pathname === "/farmadd" ||
                window.location.pathname === "/listadd" ||
                window.location.pathname === "/cotadd" ||
                window.location.pathname === "/account" ||
                window.location.pathname === "/adminpage" ||
                window.location.pathname === "/farmdelete"
                  ? "mobileHeaderSide"
                  : "nodashmobileHeaderSide"
              }
              onClick={headerSideHandle}
            />
          </div>
        </div>
        <div className={headerSide ? "headerList" : "hideList"}>
          <div className="headerSideClose">
            <span onClick={headerSideHandle} style={{ cursor: "pointer" }}>
              X
            </span>
          </div>
          <div className="profileAndSignout">
            <ProfileMenu headerSide={headerSide} userinfo={userinfo} />
            <button className={"nodashbtntWraps"} onClick={logoutbtn}>
              <Signout />
            </button>
          </div>
          <Row>
            <Col xs="4" className="irrMobile">
              <Link
                to="/irrigation"
                className={
                  window.location.pathname === "/irrigation" ||
                  window.location.pathname === "/irrigation/farm"
                    ? "activeLinkWrap"
                    : "nodashlinkWrap"
                }
              >
                <div className="mobileHeaderDiv">
                  <Irrlogo className="mobileHeaderLogo" />
                  {t("irrigationheader")}
                </div>
              </Link>
            </Col>
            <Col xs="4" className="sensorMobile">
              <Link
                to="/sensors"
                className={
                  window.location.pathname === "/sensors" ||
                  window.location.pathname === "/sensors/type"
                    ? "activeLinkWrap"
                    : "nodashlinkWrap"
                }
              >
                <div className="mobileHeaderDiv">
                  <Sensorlogo className="mobileHeaderLogo" />
                  {t("sensorsheader")}
                </div>
              </Link>
            </Col>
            <Col xs="4" className="mapMobile">
              <Link
                to="/maps"
                className={
                  window.location.pathname === "/maps"
                    ? "activeLinkWrap"
                    : "nodashlinkWrap"
                }
              >
                <div className="mobileHeaderDiv">
                  <Maplogo className="mobileHeaderLogo" />
                  {t("map")}
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(Header);
