import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
//i18n
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import backimg from "../../assets/images/rain-water-on-a-green-leaf-macro_2021-10-13/rain-water-on-a-green-leaf-macro.png";
import irrigationimg from "../../assets/images/1_2021-10-13/1.png";
import sensorimg from "../../assets/images/2_2021-10-13/2.png";
import mapimg from "../../assets/images/3_2021-10-13/3.png";
import accountimg from "../../assets/images/beautiful-shot-of-a-whet-field-with-a-cloudy-sky_2021-10-13/beautiful-shot-of-a-whet-field-with-a-cloudy-sky.png";
import "./dashboard.scss";

const Dashboard = (props) => {
  const [backImgs, setBackImgs] = useState(backimg);
  const [bottom, setBottom] = useState("");
  const { t } = useTranslation("main");
  const history = useHistory();

  const irrigationhandle = () => {
    setBackImgs(irrigationimg);
    setBottom("IRRIGATION");
  };
  const irrigatioouthandle = () => {
    setBackImgs(backimg);
    setBottom("");
  };
  const sensorhandle = () => {
    setBackImgs(sensorimg);
    setBottom("SENSOR DATA");
  };
  const sensorouthandle = () => {
    setBackImgs(backimg);
    setBottom("");
  };
  const maphandle = () => {
    setBackImgs(mapimg);
    setBottom("MAP");
  };
  const mapouthandle = () => {
    setBackImgs(backimg);
    setBottom("");
  };
  const accounthandle = () => {
    setBackImgs(accountimg);
    setBottom("ACCOUNT");
  };
  const accountouthandle = () => {
    setBackImgs(backimg);
    setBottom("");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard | FarmCLO</title>
      </MetaTags>
      <img src={backImgs} className="backgroundimg" />
      <div className="dashText">{bottom}</div>
      <div className="page-content">
        <Container fluid className="container">
          <Row className="mobileRow">
            <Col xs={6} xl={3} className="btnsWrap">
              <button
                onClick={() => {
                  history.push("/irrigation");
                }}
                className="dashbtntWrap"
              >
                <div
                  className="irrigationbtn"
                  onMouseEnter={() => irrigationhandle()}
                  onMouseLeave={() => irrigatioouthandle()}
                >
                  <div className="btninfo">
                    <div className="irrigationlogo" />
                    <div className="infodiv">{t("irrigation")}</div>
                    {t("irrigationinfo")
                      .split("\n")
                      .map((line) => (
                        <div className="infodiv2">{line}</div>
                      ))}
                  </div>
                </div>
              </button>
            </Col>
            <Col xs={6} xl={3} className="btnsWrap">
              <button
                onClick={() => {
                  history.push("/sensors");
                }}
                className="dashbtntWrap"
              >
                <div
                  className="sensorsbtn"
                  onMouseEnter={() => sensorhandle()}
                  onMouseLeave={() => sensorouthandle()}
                >
                  <div className="btninfo">
                    <div className="sensorslogo" />
                    <div className="infodiv">{t("sensors")}</div>
                    {t("sensorsinfo")
                      .split("\n")
                      .map((line) => (
                        <div className="infodiv2">{line}</div>
                      ))}
                  </div>
                </div>
              </button>
            </Col>
            <Col xs={6} xl={3} className="btnsWrap">
              <button
                onClick={() => {
                  history.push("/maps");
                }}
                className="dashbtntWrap"
              >
                <div
                  className="mapbtn"
                  onMouseEnter={() => maphandle()}
                  onMouseLeave={() => mapouthandle()}
                >
                  <div className="btninfo">
                    <div className="maplogo" />
                    <div className="infodiv">{t("map")}</div>
                    <div className="infodiv2">{t("mapinfo")}</div>
                  </div>
                </div>
              </button>
            </Col>
            <Col xs={6} xl={3} className="btnsWrap">
              <button
                onClick={() => {
                  history.push("/account");
                }}
                className="dashbtntWrap"
              >
                <div
                  className="accountbtn"
                  onMouseEnter={() => accounthandle()}
                  onMouseLeave={() => accountouthandle()}
                >
                  <div className="btninfo">
                    <div className="accountlogo" />
                    <div className="infodiv">{t("account")}</div>
                    {t("accountinfo")
                      .split("\n")
                      .map((line) => (
                        <div className="infodiv2">{line}</div>
                      ))}
                  </div>
                </div>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Dashboard);
