const CheckComponent = ({
  setAllSelected,
  selects,
  setSelects,
  id,
  deviceType,
  setDeviceTypes,
  setTopDeviceTypes,
}) => {
  return (
    <input
      type="checkbox"
      checked={selects?.includes(id)}
      style={{ width: "20px", height: "20px" }}
      value={deviceType}
      onClick={(e) => {
        e.stopPropagation();
        setTopDeviceTypes(e.target.value);
        if (
          (e.target.value === "ATS02" ||
            e.target.value === "AT" ||
            e.target.value === "FMS" ||
            e.target.value === "FMS02" ||
            e.target.value === "SSH") &&
          selects?.includes(id)
        ) {
          setDeviceTypes("SSH");
          setSelects([]);
        } else if (
          (e.target.value === "ATS02" ||
            e.target.value === "AT" ||
            e.target.value === "FMS" ||
            e.target.value === "FMS02" ||
            e.target.value === "SSH") &&
          !selects?.includes(id)
        ) {
          setDeviceTypes("SSH");
          setSelects([id]);
        } else if (selects?.includes(id)) {
          setDeviceTypes("");
          setSelects(selects.filter((_id) => _id !== id));
          setAllSelected(false);
        } else if (!selects?.includes(id)) {
          if (e.target.value === "SSH") {
            setDeviceTypes("");
            setSelects([id]);
          } else {
            setDeviceTypes("");
            setSelects([...selects, id]);
          }
        }
      }}
      readOnly
    ></input>
  );
};

export default CheckComponent;
