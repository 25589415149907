import React from "react";
import { withRouter, useHistory } from "react-router-dom";
//i18n
import backImg from "../../assets/images/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds_2021-10-21/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds.png";
import "./farmadd.scss";
import { MetaTags } from "react-meta-tags";
import { useTranslation } from "react-i18next";
const AddPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation("main");
  // farmadd: "Farm ADD",
  // farmdel: "Farm DELETE",
  // sensoradd:"Sensor ADD",

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Page | FarmCLO</title>
      </MetaTags>
      <img src={backImg} className="backgroundimg" />
      <div className="dashText">ADD</div>
      <div className="addpage-content">
        <div className="addWrap">
          <div className="addPageCard">
            <div className="addPageTitle">{t("addindex")}</div>
            <div className="addPageSubTitle">{t("addindexment")}</div>
            <button
              className="addPageBtn"
              onClick={() => history.push("/farmadd")}
            >
              {t("farmadd")}
            </button>
            <button
              className="addPageBtn"
              onClick={() => history.push("/listadd")}
            >
              {t("sensoradd")}
            </button>
            <button
              className="addPageBtn"
              onClick={() => history.push("/cotadd")}
            >
              {t("cotadd")}
            </button>
            <button
              className="deletePageBtn"
              onClick={() => history.push("/farmdelete")}
            >
              {t("farmdel")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddPage);
