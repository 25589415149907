import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, CardBody, Label, Input, Form, FormGroup } from "reactstrap";
import { apiUrl, imgPut, requestPost, requestPut } from "../../apis/request";
import SensorMap from "../Maps/SensorMap";
import { useTranslation } from "react-i18next";
import { ReactComponent as Photo } from "../../assets/icons/photo.svg";
import "./admin.scss";
const AdminSensorEdit = ({ editData }) => {
  const [deleteHide, setDeleteHide] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [imgBase64, setImgBase64] = useState([]);
  const [photoFile, setPhotoFile] = useState(null);
  const [name, setName] = useState(editData.name);
  const [deviceType, setDeviceType] = useState(editData.deviceType);
  const [serial, setSerial] = useState(editData.serial);
  const [description, setDescription] = useState(editData.description);
  const [latitude, setLatitude] = useState(editData.latitude);
  const [longitude, setLongitude] = useState(editData.longitude);
  const [clusterId, setClusterId] = useState("");
  const history = useHistory();
  const { t } = useTranslation("main");

  useEffect(() => {
    if (editData.name === null) {
      setName("");
    } else if (editData.name !== null) {
      setName(editData.name);
    }
    if (editData.deviceType === null) {
      setDeviceType("");
    } else if (editData.deviceType !== null) {
      setDeviceType(editData.deviceType);
    }
    if (editData.serial === null) {
      setSerial("");
    } else if (editData.serial !== null) {
      setSerial(editData.serial);
    }
    if (editData.description === null) {
      setDescription("");
    } else if (editData.description !== null) {
      setDescription(editData.description);
    }
    if (editData.latitude === null) {
      setLatitude("");
    } else if (editData.latitude !== null) {
      setLatitude(editData.latitude);
    }
    if (editData.longitude === null) {
      setLongitude("");
    } else if (editData.longitude !== null) {
      setLongitude(editData.longitude);
    }
    if (editData.imagePath === null) {
      setImgBase64(null);
    } else if (editData.imagePath !== null) {
      setImgBase64(apiUrl + editData.imagePath);
    }
  }, [editData]);
  const onNameHandler = (e) => {
    setName(e.currentTarget.value);
  };

  const handledevice = (e) => {
    setDeviceType(e.target.value);
  };

  const onSerialHandler = (e) => {
    setSerial(e.currentTarget.value);
  };

  const onDescriptionHandler = (e) => {
    setDescription(e.currentTarget.value);
  };

  const handlelatitude = (e) => {
    setLatitude(e.target.value);
  };

  const handlelongitude = (e) => {
    setLongitude(e.target.value);
  };

  const hidehandle = () => {
    setDeleteHide(!deleteHide);
  };

  const onFileHandler = (e) => {
    setPhotoFile(e.target.files[0]);
    setImgBase64(URL.createObjectURL(e.target.files[0]));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append("id", editData.id);
    params.append("name", name);
    params.append("deviceType", deviceType);
    params.append("serial", serial);
    params.append("description", description);
    params.append("latitude", latitude);
    params.append("longitude", longitude);
    params.append("clusterId", clusterId);
    // let body = {
    //   id: editData.id,
    //   name: name,
    //   deviceType: deviceType,
    //   serial: serial,
    //   description: description,
    //   latitude: latitude,
    //   longitude: longitude,
    //   clusterId: clusterId,
    // };
    const formData = new FormData();
    formData.append("image", photoFile, photoFile.name);
    await requestPut(`/api/v1/admin/sensor/update`, params);
    await imgPut(`/api/v1/sensor/update/${editData.id}/image`, formData);
    history.push("/adminpage");
  };
  return (
    <React.Fragment>
      <div className="accountPageCard1 px-0">
        <CardBody>
          <div>
            <div className="float-end">
              <i
                className="deletehide bx bx-dots-vertical-rounded"
                onClick={hidehandle}
              />

              {deleteHide ? (
                <button
                  color="danger"
                  className="sensorDeletBtn"
                  onClick={() => {
                    setconfirm_alert(true);
                  }}
                >
                  <i className="deleticon bx bx-trash" />
                  {t("delete")}
                </button>
              ) : (
                ""
              )}
              {confirm_alert ? (
                <SweetAlert
                  title="정말 삭제하시겠습니까?"
                  warning
                  showCancel
                  confirmButtonText="삭제"
                  confirmBtnBsStyle="danger"
                  cancelButtonText="취소"
                  cancelBtnBsStyle="success"
                  onConfirm={async () => {
                    setconfirm_alert(false);
                    setsuccess_dlg(!success_dlg);
                    await requestPost(`/api/v1/sensor/delete/${editData.id}`);
                    history.push("/sensors-table");
                  }}
                  onCancel={() => setconfirm_alert(false)}
                ></SweetAlert>
              ) : null}
            </div>
            <div className="clearfix"></div>
            <div className="sensorInfoTitleWrap"></div>
          </div>
          <div className="adminSensorInfoTable table-responsive">
            <Form onSubmit={onSubmitHandler}>
              <div className="photoAddRow">
                {!imgBase64 ? (
                  <div className="photoImage" />
                ) : (
                  <img src={imgBase64} alt="sensorimg" className="photoImage" />
                )}
                <Label className="fileAddName m-0">
                  <Input type="file" id="name" onChange={onFileHandler} />
                  <Photo />
                </Label>
              </div>
              <div className="sesorSettingRow mb-3">
                <Label className="sensorfontsize fw-semibold">uuid</Label>
                <Input
                  type="text"
                  className="sensorSettinginput form-control"
                  id="uuid"
                  value={editData.id}
                  disabled
                />
              </div>
              <div className="sesorSettingRow mb-3">
                <Label className="sensorfontsize fw-semibold">
                  {t("name")}
                </Label>
                <Input
                  type="text"
                  className="sensorSettinginput form-control"
                  id="name"
                  value={name}
                  onChange={onNameHandler}
                />
              </div>
              <FormGroup className="listAddRow">
                <Label className="sensorfontsize fw-semibold">
                  {t("devicetype")}
                </Label>
                <Input
                  type="select"
                  className="sensorSettinginput form-control"
                  value={deviceType}
                  onChange={handledevice}
                >
                  <option value="SMS">{t("SMS")}</option>
                  <option value="SSH">{t("SSH01")}</option>
                  <option value="AT">{t("AT")}</option>
                  <option value="WATERFLOW">{t("WATERFLOW")}</option>
                  <option value="NUTRIENT">{t("NUTRIENT")}</option>
                  <option value="DRAINAGE">{t("DRAINAGE")}</option>
                  <option value="FMS">{t("FMS")}</option>
                  <option value="FMS02">{t("FMS02")}</option>
                  <option value="COMPOST">{t("COMPOST")}</option>
                  <option value="SOIL">{t("SOIL")}</option>
                  <option value="CO2">{t("CO2")}</option>
                  <option value="ATS02">{t("ATS02")}</option>
                  <option value="LAT">{t("LAT")}</option>
                  <option value="WATERLEVEL">{t("WATERLEVEL")}</option>
                  {/* <option value="SES">{t("SES")}</option> */}
                  <option value="COT">{t("COT")}</option>
                </Input>
              </FormGroup>
              <div className="sesorSettingRow mb-3">
                <Label className="sensorfontsize fw-semibold">
                  {t("serial")}
                </Label>
                <Input
                  type="number"
                  className="sensorSettinginput form-control"
                  id="serial"
                  value={serial}
                  onChange={onSerialHandler}
                  required
                />
              </div>
              <div className="sesorSettingRow mb-3">
                <Label className="sensorfontsize fw-semibold">
                  {t("description")}
                </Label>
                <Input
                  type="text"
                  className="sensorSettinginput form-control"
                  id="description"
                  value={description}
                  onChange={onDescriptionHandler}
                />
              </div>
              <div className="sesorSettingRow mb-3">
                <Label className="sensorfontsize fw-semibold">
                  {t("latitude")}
                </Label>
                <Input
                  type="text"
                  name="_latitude"
                  className="sensorSettinginput form-control"
                  value={latitude}
                  onChange={handlelatitude}
                />
              </div>
              <div className="sesorSettingRow mb-3">
                <Label className="sensorfontsize fw-semibold">
                  {t("longitude")}
                </Label>
                <Input
                  type="text"
                  name="_longitude"
                  className="sensorSettinginput form-control"
                  value={longitude}
                  onChange={handlelongitude}
                />
              </div>
              <SensorMap
                editData={editData}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
              />
              <div className="mt-4">
                <button className="adminSensorUpdateBtn" type="submit">
                  {t("save")}
                </button>
              </div>
            </Form>
          </div>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

export default AdminSensorEdit;
