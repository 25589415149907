import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { adminUserPut, requestGet } from "../../apis/request";
import { ReactComponent as Close } from "../../assets/icons/168366.svg";
import "../Admin/lineupmodal.scss";

const CotaddModal = ({ setModalOpen, chNum, setChNum, ch, setCh, type }) => {
  const [newPW, setNewPW] = useState("");
  const [sensorList, setSensorList] = useState([]);
  const [sensorId, setSensorId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [serialSuggestions, setSerialSuggestions] = useState([]);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: auto;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const getSensor = async () => {
    const sensor = await requestGet(`/api/v1/sensor/list`);
    if (type === "CON04V") {
      setSensorList(sensor.filter((item) => item.deviceType === "SMS"));
    } else if (type === "CON04E") {
      setSensorList(
        sensor.filter(
          (item) =>
            item.deviceType === "LAT" ||
            item.deviceType === "AT" ||
            item.deviceType === "ATS02" ||
            item.deviceType === "ATS03"
        )
      );
    } else if (type === "CON01W") {
      setSensorList(sensor.filter((item) => item.deviceType === "WATERLEVEL"));
    }
  };

  useEffect(() => {
    getSensor();
  }, []);

  const closeModal = () => {
    setChNum("");
    setModalOpen(false);
  };

  const changeData = async () => {
    const obj = {
      sensorId: ch[chNum]
        ? [...new Set([...ch[chNum].sensorId, sensorId])]
        : [sensorId],
      name: ch[chNum]
        ? [...new Set([...ch[chNum].name, inputValue])]
        : [inputValue],
    };
    ch[chNum] = obj;
    setCh(ch);
    setChNum("");
    setModalOpen(false);
  };

  const onChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value === "") {
      setInputValue("");
      setNewPW("");
      setSensorId("");
      setSuggestions([]);
      setSerialSuggestions([]);
    }
    if (value.length > 0) {
      const filteredSuggestions = sensorList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const onChangeSerial = (e) => {
    const value = e.target.value;
    setNewPW(value);
    if (value === "") {
      setInputValue("");
      setNewPW("");
      setSensorId("");
      setSuggestions([]);
      setSerialSuggestions([]);
    }
    if (value.length > 0) {
      const filteredSuggestions = sensorList.filter((item) =>
        item.serial.includes(value)
      );
      setSerialSuggestions(filteredSuggestions);
    } else {
      setSerialSuggestions([]);
    }
  };

  const onClickName = (item) => {
    setInputValue(item.name);
    setNewPW(item.serial);
    setSensorId(String(item.id));
    setSuggestions([]);
    setSerialSuggestions([]);
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="4">
            <Form>
              <div className="modalOverlay" />
              <Card className="modalCard py-3 ps-3 pe-2">
                <Row
                  className="w-100 m-0"
                  style={{
                    borderBottom: "1px solid #c9c9c9",
                    paddingBottom: "25px",
                  }}
                >
                  <Col
                    xs="6"
                    className="d-flex align-items-center"
                    style={{ fontSize: "22px", fontWeight: "bold" }}
                  >
                    Sensor
                  </Col>
                  <Col xs="6" className="text-end">
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={(e) => closeModal(e)}
                    />
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#B3B3B3",
                          fontSize: "14px",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        <span>시리얼 이나 이름을 입력해주세요</span>
                        <br />
                        <span>{`(채널당 센서는 최대 3개 입니다.)`}</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row
                  className="w-100 m-0"
                  style={{
                    paddingTop: "25px",
                  }}
                >
                  <Col sm="12">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#B3B3B3",
                          fontSize: "14px",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        시리얼
                      </Label>
                      <input
                        className="form-control"
                        type="search"
                        value={newPW}
                        onChange={(e) => onChangeSerial(e)}
                      />
                      {serialSuggestions.length > 0 && (
                        <div
                          className="form-control"
                          style={{
                            padding: "0px",
                            maxHeight: "100px",
                            overflow: "auto",
                          }}
                        >
                          {serialSuggestions.map((item, i) => (
                            <div
                              className="form-control cotModalHoverDiv"
                              style={
                                i !== serialSuggestions?.length - 1
                                  ? {
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      borderBottom: "1px solid #ced4da",
                                      borderRadius: "0px",
                                    }
                                  : {
                                      border: "none",
                                      borderRadius: "0px",
                                    }
                              }
                              key={i}
                              onClick={() => onClickName(item)}
                            >
                              <span>{`시리얼 : ${item.serial}`}</span>
                              <br />
                              <span>{`이름 : ${item.name}`}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row
                  className="w-100 m-0"
                  style={{
                    paddingTop: "25px",
                  }}
                >
                  <Col sm="12">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#B3B3B3",
                          fontSize: "14px",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        이름
                      </Label>
                      <input
                        className="form-control"
                        type="search"
                        value={inputValue}
                        onChange={(e) => onChange(e)}
                      />
                      {suggestions.length > 0 && (
                        <div
                          className="form-control"
                          style={{
                            padding: "0px",
                            maxHeight: "100px",
                            overflow: "auto",
                          }}
                        >
                          {suggestions?.map((item, i) => (
                            <div
                              className="form-control cotModalHoverDiv"
                              style={
                                i !== suggestions?.length - 1
                                  ? {
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      borderBottom: "1px solid #ced4da",
                                      borderRadius: "0px",
                                    }
                                  : {
                                      border: "none",
                                      borderRadius: "0px",
                                    }
                              }
                              key={i}
                              onClick={() => onClickName(item)}
                            >
                              <span>{`이름 : ${item.name}`}</span>
                              <br />
                              <span>{`시리얼 : ${item.serial}`}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-3">
                  <Col className="text-end">
                    <Button
                      id="mastercloseBtn"
                      className="mx-2"
                      onClick={(e) => closeModal(e)}
                    >
                      취소
                    </Button>
                    <Button id="masterAccesBtn" onClick={() => changeData()}>
                      추가
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(CotaddModal);
