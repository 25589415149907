import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { adminRequestPost, adminUserPut, requestGet } from "../../apis/request";
import { ReactComponent as Close } from "../../assets/icons/168366.svg";
import "../Admin/lineupmodal.scss";
import { useTranslation } from "react-i18next";

const TriggerDetailModal = ({ setOnTrigger, channelId, setChId }) => {
  const [upValue, setUpValue] = useState(null);
  const [downValue, setDownValue] = useState(null);
  const [triggerData, setTriggerData] = useState([]);
  const { t } = useTranslation("main");

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: auto;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const closeModal = () => {
    setChId(null);
    setOnTrigger(false);
  };

  const getTrigger = async () => {
    try {
      const response = await requestGet(
        `/api/v1/sensor/channel/${channelId}/trigger`
      );
      if (response.length > 0) {
        setTriggerData(response);
        setUpValue(response[0].upperBound);
        setDownValue(response[0].lowerBound);
      } else {
        alert("데이터가 없습니다.");
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (channelId) {
      getTrigger();
    }
  }, [channelId]);

  const addTrigger = async () => {
    if (!upValue && upValue !== "0" && !downValue && downValue !== "0") {
      return alert("트리거 수정시 한개 이상 입력은 필수 입니다.");
    } else {
      if (window.confirm(t("트리거를 수정하시겠습니까?"))) {
        try {
          let body = {};
          if (upValue || upValue === "0") {
            body.upperBound = Number(upValue);
          }
          if (downValue || downValue === "0") {
            body.lowerBound = Number(downValue);
          }
          await adminUserPut(
            `/api/v1/sensor/channel/${channelId}/trigger/${triggerData[0].id}`,
            body
          );
          alert(t("수정 하였습니다."));
          setChId(null);
          setOnTrigger(false);
        } catch (error) {
          console.log(error);
          alert(t("수정 실패 하였습니다."));
          setChId(null);
          setOnTrigger(false);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {triggerData?.length > 0 ? (
        <Container fluid className="modalWrap">
          <Row className="h-100 d-flex align-items-center justify-content-center">
            <Col xs="12" sm="10" md="8" lg="6" xl="4">
              <Form>
                <div className="modalOverlay" />
                <Card className="modalCard py-3 ps-3 pe-2">
                  <Row
                    className="w-100 m-0"
                    style={{
                      borderBottom: "1px solid #c9c9c9",
                      paddingBottom: "25px",
                    }}
                  >
                    <Col
                      xs="6"
                      className="d-flex align-items-center"
                      style={{ fontSize: "22px", fontWeight: "bold" }}
                    >
                      Trigger
                    </Col>
                    <Col xs="6" className="text-end">
                      <Close
                        style={{ cursor: "pointer" }}
                        onClick={(e) => closeModal(e)}
                      />
                    </Col>
                  </Row>
                  <Row
                    className="w-100 m-0"
                    style={{
                      paddingTop: "25px",
                    }}
                  >
                    <Col sm="12">
                      <FormGroup>
                        <input
                          className="form-control"
                          type="number"
                          value={upValue}
                          onChange={(e) => setUpValue(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                        />
                        <Label
                          style={{
                            color: "#B3B3B3",
                            fontSize: "14px",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          해당 값 보다 높으면 작동함
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row
                    className="w-100 m-0"
                    style={{
                      paddingTop: "25px",
                    }}
                  >
                    <Col sm="12">
                      <FormGroup>
                        <input
                          className="form-control"
                          type="number"
                          value={downValue}
                          onChange={(e) => setDownValue(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                        />
                        <Label
                          style={{
                            color: "#B3B3B3",
                            fontSize: "14px",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          해당 값 보다 낮으면 작동함
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 m-0 mt-3">
                    <Col className="text-end">
                      <Button
                        id="mastercloseBtn"
                        className="mx-2"
                        onClick={(e) => closeModal(e)}
                      >
                        취소
                      </Button>
                      <Button
                        id="masterAccesBtn"
                        disabled={triggerData.length === 0}
                        onClick={() => addTrigger()}
                      >
                        수정
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      ) : null}
    </React.Fragment>
  );
};
export default withRouter(TriggerDetailModal);
