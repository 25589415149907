import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { requestGet } from "../../../apis/request";
import moment from "moment";
import "moment/locale/ko";
import excel from "../../../assets/icons/xls-file.svg";
import Pencil from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-pencil.svg";
import { useTranslation } from "react-i18next";
import LoadingScreen from "../../Dashboard/LoadingScreen";

const WaterBatterySplineArea = ({
  setMenu,
  userDevice,
  apistartdate,
  apienddate,
  min,
  max,
  setTableData,
  typeKey,
}) => {
  const [respon, setRespon] = useState([]);
  const [series, setSeries] = useState([]);
  const [inMin, setInMin] = useState();
  const [inMax, setInMax] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("main");

  const sensors = ["battery"];

  const callapi = async () => {
    try {
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/${userDevice[0].id}/${apistartdate}/${apienddate}`
      );
      setRespon(response);
      setTableData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setRespon([]);
      setTableData({});
      setLoading(false);
    }
  };

  useEffect(() => {
    callapi();
  }, [apistartdate, apienddate, userDevice]);

  const getLog = async (sens) => {
    if (respon.length > 0) {
      return {
        name: userDevice[0]?.name,
        data: respon.map((sensorItem, j) => {
          return [
            new Date(sensorItem.createdDate.replace(/-/g, "/")).getTime(),
            sensorItem[sens].toFixed(1),
          ];
        }),
      };
    } else {
      return {
        name: userDevice[0]?.name,
        data: [],
      };
    }
  };

  const callChartApi = async () => {
    if (
      Array.isArray(userDevice) &&
      userDevice.length > 0 &&
      respon.length > 0
    ) {
      const arr = [];
      if (userDevice.length > 0) {
        for (let value of sensors) {
          const result = await getLog(value);
          arr.push(result);
        }
      }
      setSeries(arr);
    } else {
      setSeries([]);
    }
  };

  useEffect(() => {
    callChartApi();
  }, [userDevice, respon]);

  useEffect(() => {
    if (!min && !max) {
      return;
    } else {
      if (parseInt(min, 10) >= 0) {
        setInMin(parseInt(min, 10));
      } else if (parseInt(min, 10) <= 0) {
        setInMin(parseInt(min, 10));
      } else {
        setInMin(0);
      }
      if (parseInt(max, 10) >= 0) {
        setInMax(parseInt(max, 10));
      } else if (parseInt(max, 10) <= 0) {
        setInMax(parseInt(max, 10));
      } else {
        setInMax(0);
      }
    }
  }, [min, max]);

  const onMenu = () => {
    setMenu((menu) => ({
      ...menu,
      [typeKey]: true,
    }));
  };

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    // colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
        format: "yyyy/MM/dd",
      },
    },
    yaxis: {
      min: inMin,
      max: inMax,
    },
    chart: {
      toolbar: {
        tools: {
          download: `<img src=${excel} width="20" height="20" />`,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          customIcons: [
            {
              icon: `<img src=${Pencil} width="20" height="20" />`,
              index: 2,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
                onMenu();
              },
            },
          ],
        },
        export: {
          csv: {
            filename: "WATERLEVEL Battery",
            columnDelimiter: ",",
            headerCategory: "WATERLEVEL Battery Date",
            headerValue: "value",
            dateFormatter(timestamp) {
              return moment(timestamp).format("YYYY/MM/DD HH:mm:ss");
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      followCursor: true,
      x: {
        format: "yyyy/MM/dd HH:mm",
      },
    },
  };

  return loading ? (
    <div
      style={{
        height: "350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "150px" }}>
        <LoadingScreen />
      </div>
    </div>
  ) : (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
      className="apex-charts"
      dir="ltr"
    />
  );
};

export default WaterBatterySplineArea;
