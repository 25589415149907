import React, { useEffect, useState } from "react";
import { Form, Input, Label } from "reactstrap";
//i18n
import { useTranslation } from "react-i18next";
import { ReactComponent as Mail } from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-envelope.svg";
import backImg from "../../../assets/images/the-farmer-holds-rice-in-hand_2021-10-22/the-farmer-holds-rice-in-hand.png";
import "./account.scss";
import Langchange from "../../../locales/langchange";
import {
  adminUserPut,
  apiUrl,
  imgPut,
  norequestGet,
  requestGet,
} from "../../../apis/request";
import { MetaTags } from "react-meta-tags";
import { ReactComponent as Photo } from "../../../assets/icons/photo.svg";
import { useHistory } from "react-router-dom";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import ChangePWModal from "../../Admin/ChangePWModal";
const Account = (props) => {
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [farmAddress, setFarmAddress] = useState("");
  const [timezone, setTimezone] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [emailCheck, setEmailCheck] = useState(false);
  const [imgBase64, setImgBase64] = useState([]);
  const [photoFile, setPhotoFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation("main");
  const history = useHistory();
  const handleNickname = (e) => {
    setNickname(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleFarmAddress = (e) => {
    setFarmAddress(e.target.value);
  };
  const handleTimezone = (e) => {
    setTimezone(e);
  };
  // email: "Email",
  // _email: "E-mail",
  // lang: "Language",
  // noti: `My${(<br />)}Notification`,
  const getUserInfo = async () => {
    const sensor = await norequestGet(`/api/account/info`);
    setUserInfo(sensor);
    setEmail(sensor.userAddress);
    setFarmAddress(sensor.farmAddress);
    setNickname(sensor.nickname);
    setPhone(sensor.phone);
    setTimezone(sensor.timezone);
    if (sensor.imagePath === null) {
      setImgBase64(null);
    } else if (sensor.imagePath !== null) {
      setImgBase64(apiUrl + sensor.imagePath);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const onFileHandler = (e) => {
    setPhotoFile(e.target.files[0]);
    setImgBase64(URL.createObjectURL(e.target.files[0]));
  };

  const emailClick = () => {
    if (!userInfo.userAddress) {
      alert("이메일 정보가 없습니다.");
      setEmailCheck(false);
    } else {
      setEmailCheck(!emailCheck);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      // const params = new URLSearchParams();
      // params.append("username", username);
      // params.append("password", userpassword);
      // params.append("nickname", nickname);
      // params.append("userAddress", userAddress);
      // params.append("farmAddress", farmAddress);
      // params.append("phone", phone);
      // params.append("language", language);
      // params.append("timezone", timezone);
      let body = {
        nickname: nickname,
        userAddress: email,
        farmAddress: farmAddress,
        phone: phone,
        timezone: timezone,
      };
      await adminUserPut(`/api/account/update/${userInfo.id}`, body);
      if (photoFile) {
        const formData = new FormData();
        formData.append("image", photoFile, photoFile.name);
        await imgPut(`/api/account/update/image`, formData);
        const userinfo = await norequestGet(`/api/account/info`);
      }
      history.push("/dashboard");
    } catch (error) {
      alert("수정 실패하였습니다");
    }
  };

  const onModal = (e) => {
    e.preventDefault();
    setOpenModal(!openModal);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Account | FarmCLO</title>
      </MetaTags>
      <img src={backImg} className="backgroundimg" />
      <div className="dashText">ACCOUNT</div>
      {/* <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "end",
          justifyContent: "center",
        }}
      > */}
      <div className="accountpageTotalWrap">
        <div className="accountpage-content">
          <div className="accountWrap">
            <div className="accountPageCardWrap">
              <div className="accountPageCard1">
                <Form onSubmit={onSubmitHandler}>
                  <div className="accountPageTitle">
                    <div
                      className="photoAddRow"
                      style={{ margin: "0 30px 0 0" }}
                    >
                      {!imgBase64 || imgBase64.length === 0 ? (
                        <div className="photoImage" />
                      ) : (
                        <img
                          src={imgBase64}
                          alt="photo"
                          className="photoImage"
                        />
                      )}
                      <Label className="fileAddName m-0">
                        <Input
                          type="file"
                          id="image"
                          onChange={onFileHandler}
                        />
                        <Photo />
                      </Label>
                    </div>
                    <div className="accountNameWrap">
                      <span className="accountNameTitle">
                        {userInfo.username}
                      </span>
                      <span className="accountNameSub">
                        {userInfo.nickname}
                      </span>
                    </div>
                  </div>
                  <div className="accountEmailWrap">
                    <span className="accountEmail">{t("nickname")}</span>
                    <input
                      type="text"
                      className="input325"
                      name="_id"
                      value={nickname}
                      onChange={handleNickname}
                      placeholder={t("nodata")}
                      required
                    />
                  </div>
                  <div className="accountEmailWrap">
                    <span className="accountEmail">{t("phone")}</span>
                    <input
                      type="text"
                      className="input325"
                      name="_id"
                      value={phone}
                      onChange={handlePhone}
                      placeholder={t("nodata")}
                      required
                    />
                  </div>
                  {userInfo.userType === "ADMIN" ? (
                    <div className="accountEmailWrap">
                      <span className="accountEmail">{t("pw")}</span>
                      <button
                        className="adminChangePWBtn"
                        onClick={(e) => onModal(e)}
                      >
                        {t("changepassword")}
                      </button>
                    </div>
                  ) : null}
                  <div className="accountEmailWrap">
                    <span className="accountEmail">{t("email")}</span>
                    <input
                      type="text"
                      className="input325"
                      name="_id"
                      value={email}
                      onChange={handleEmail}
                      placeholder={t("nodata")}
                      required
                    />
                  </div>
                  <div className="langChange">
                    <span className="langTitle">{t("lang")}</span>
                    <Langchange />
                  </div>
                  <div className="accountEmailWrap">
                    <span className="accountEmail">{t("farmid")}</span>
                    <input
                      type="text"
                      className="input325"
                      name="_id"
                      value={farmAddress}
                      onChange={handleFarmAddress}
                      placeholder={t("nodata")}
                      required
                    />
                  </div>
                  <div className="accountEmailWrap">
                    <span className="accountEmail">{t("timezone")}</span>
                    <TimezonePicker
                      onChange={handleTimezone}
                      defaultValue={timezone}
                      unselectLabel="No Timezone"
                      absolute={true}
                      value={timezone}
                    />
                  </div>
                  <div className="accountCheckWrap">
                    <div className="accountNotiDiv">
                      {t("noti")
                        .split("\n")
                        .map((line) => (
                          <span className="checkTitle">{line}</span>
                        ))}
                    </div>
                    <div>
                      <div>
                        <div className="accountMailDiv">
                          <Mail className="accountMailIcon" />
                          <span className="accountMailTitle">
                            {t("_email")}
                          </span>
                          <Input
                            className="accountMailCheck"
                            type="checkbox"
                            checked={emailCheck}
                            onClick={emailClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button className="sensorUpdateBtn" type="submit">
                      {t("save")}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {openModal ? (
        <ChangePWModal setModalOpen={setOpenModal} id={userInfo.id} />
      ) : null}
    </React.Fragment>
  );
};
export default Account;
