import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Form,
} from "reactstrap";
import { ReactComponent as Bell } from "../../../assets/images/fi-rr-bell.svg";
import Lighting from "../../../assets/icons/002-lighting_2021-10-27/002-lighting.png";
import { ReactComponent as Cloud } from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-cloud-disabled.svg";
import { ReactComponent as Info } from "../../../assets/icons/uicons-regular-rounded/svg/fi-rr-info.svg";
import "./notificationdropdown.scss";
//i18n
import { useTranslation } from "react-i18next";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import { requestGet } from "../../../apis/request";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [noti, setNoti] = useState([]);
  const { t } = useTranslation("main");

  const timeForToday = (value) => {
    const today = new Date();
    const timeValue = new Date(value);

    const betweenTime = Math.floor(
      (today.getTime() - timeValue.getTime()) / 1000 / 60
    );
    if (betweenTime < 1) return "방금전";
    if (betweenTime < 60) {
      return `${betweenTime}분전`;
    }
    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
      return `${betweenTimeHour}시간전`;
    }
    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTimeDay < 365) {
      return `${betweenTimeDay}일전`;
    }
    return `${Math.floor(betweenTimeDay / 365)}년전`;
  };

  const notis = async () => {
    const notinfo = await requestGet(`/api/v1/noti/list`);
    setNoti(notinfo);
  };

  useEffect(() => {
    if (menu) {
      notis();
    }
  }, [menu]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
        id="bellBtnWrap"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id={
            menu
              ? "notiToggle"
              : window.location.pathname === "/login" ||
                window.location.pathname === "/dashboard" ||
                window.location.pathname === "/addpage" ||
                window.location.pathname === "/farmadd" ||
                window.location.pathname === "/listadd" ||
                window.location.pathname === "/cotadd" ||
                window.location.pathname === "/account" ||
                window.location.pathname === "/adminpage" ||
                window.location.pathname === "/farmdelete"
              ? "page-header-notifications-dropdown"
              : "page-header-notifications-dropdown-nodash"
          }
        >
          <Bell className="fi-rr-bell" />
          <span className="badge bg-danger rounded-pill"></span>
        </DropdownToggle>

        <DropdownMenu className="BellWrap p-0">
          <div className="notiTitleRow">
            <Row>
              <Col>
                <span className="notiTitle">{t("notifications")}</span>
              </Col>
            </Row>
          </div>

          <Form>
            <SimpleBarReact style={{ maxHeight: "450px" }}>
              <Row className="m-0 p-0">
                <Col className="m-0 p-0">
                  {noti.map((data) => {
                    return (
                      <div className="notiInside">
                        {data.type === "BATTERY" ? (
                          <div
                            className={
                              data.unread ? "newNotiDiv" : "oldNotiDiv"
                            }
                          >
                            <img
                              src={Lighting}
                              alt="lighting"
                              className="notiIcon"
                            />
                            <div className="notiTitleDIv">
                              <span className="notiTilteSpan">
                                {data.message}
                              </span>
                              <span className="notiNum">
                                {timeForToday(data.createdDate)}
                              </span>
                            </div>
                          </div>
                        ) : data.type === "RSSI" ? (
                          <div
                            className={
                              data.unread ? "newNotiDiv" : "oldNotiDiv"
                            }
                          >
                            <Cloud className="notiIcon" />
                            <div className="notiTitleDIv">
                              <span className="notiTilteSpan">
                                {data.message}
                              </span>
                              <span className="notiNum">
                                {timeForToday(data.createdDate)}
                              </span>
                            </div>
                          </div>
                        ) : data.type === "DEVICE" ? (
                          <div
                            className={
                              data.unread ? "newNotiDiv" : "oldNotiDiv"
                            }
                          >
                            <Info className="notiIcon" />
                            <div className="notiTitleDIv">
                              <span className="notiTilteSpan">
                                {data.message}
                              </span>
                              <span className="notiNum">
                                {timeForToday(data.createdDate)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </SimpleBarReact>
          </Form>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
