import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { adminUserPut } from "../../apis/request";
import { ReactComponent as Close } from "../../assets/icons/168366.svg";
import "./lineupmodal.scss";

const ChangePWModal = ({ setModalOpen, id }) => {
  const [nowPW, setNowPW] = useState("");
  const [newPW, setNewPW] = useState("");
  const [confirmPW, setConfirmPW] = useState("");

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  const changeData = async () => {
    if (newPW === confirmPW) {
      try {
        let body = {
          newPassword: newPW,
          confirmPassword: confirmPW,
        };
        await adminUserPut(`/api/v1/admin/user/changepassword/${id}`, body);
        alert("변경 하였습니다.");
        setModalOpen(false);
      } catch (error) {
        if (error.response) {
          let arr = "";
          error.response.data.map((el) => (arr += `${el.defaultMessage}\n`));
          alert(arr);
        } else {
          alert("변경 실패 하였습니다.");
        }
      }
    } else {
      alert("비밀번호 확인이 다릅니다.");
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="4">
            <Form>
              <div className="modalOverlay" />
              <Card className="modalCard py-3 ps-3 pe-2">
                <Row
                  className="w-100 m-0"
                  style={{
                    borderBottom: "1px solid #c9c9c9",
                    paddingBottom: "25px",
                  }}
                >
                  <Col
                    xs="6"
                    className="d-flex align-items-center"
                    style={{ fontSize: "22px", fontWeight: "bold" }}
                  >
                    비밀번호 변경
                  </Col>
                  <Col xs="6" className="text-end">
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={(e) => closeModal(e)}
                    />
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#B3B3B3",
                          fontSize: "14px",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        비밀번호는 영어와 숫자를 포함해서
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#B3B3B3",
                          fontSize: "14px",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        최소 5자 이상 가능합니다.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row
                  className="w-100 m-0"
                  style={{
                    paddingTop: "25px",
                  }}
                >
                  <Col sm="12">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#B3B3B3",
                          fontSize: "14px",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        변경할 비밀번호
                      </Label>
                      <input
                        className="form-control"
                        type="text"
                        value={newPW}
                        onChange={(e) => setNewPW(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row
                  className="w-100 m-0"
                  style={{
                    paddingTop: "25px",
                  }}
                >
                  <Col sm="12">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#B3B3B3",
                          fontSize: "14px",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        비밀번호 확인
                      </Label>
                      <input
                        className="form-control"
                        type="text"
                        value={confirmPW}
                        onChange={(e) => setConfirmPW(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-3">
                  <Col className="text-end">
                    <Button
                      id="mastercloseBtn"
                      className="mx-2"
                      onClick={(e) => closeModal(e)}
                    >
                      취소
                    </Button>
                    <Button id="masterAccesBtn" onClick={() => changeData()}>
                      확인
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(ChangePWModal);
