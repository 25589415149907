import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { requestGet } from "../../apis/request";
import "simplebar/dist/simplebar.min.css";
import moment from "moment";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./sensorrow.scss";
import { ReactComponent as Calendar } from "../../assets/images/chart/fi-rr-calendar.svg";
import { ReactComponent as Smallup } from "../../assets/images/chart/fi-rr-angle-small-up.svg";
import { ReactComponent as Smalldown } from "../../assets/images/chart/fi-rr-angle-small-down.svg";
import "react-datepicker/dist/react-datepicker.css";

const SensorDate = ({
  setStartDate,
  setEndDate,
  type,
  dataListId,
  selects,
  setSelects,
  setUserInfo,
  setSensorLog,
  setUserCluster,
  dayOpen,
  setDayOpen,
  activeDay,
  setActiveDay,
  lastidx,
  setLastidx,
  setCustomDate,
}) => {
  const getsensor = async (value) => {
    try {
      const sensor = await requestGet(`/api/v1/sensor/list/${value}`);
      return sensor;
    } catch (error) {
      alert("데이터 불러오는데 실패하였습니다.");
    }
  };

  const sensors = async () => {
    try {
      let arr = [];
      if (typeof type === "string") {
        const sensor = await requestGet(`/api/v1/sensor/list/${type}`);
        return setUserInfo(sensor);
      } else {
        for (let value of type) {
          const result = await getsensor(value);
          if (result) {
            arr.push(...result);
          }
        }
      }
      setUserInfo(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sensors();
  }, [type]);

  const clu = async () => {
    const clusterList = await requestGet(`/api/v1/cluster/list`);
    setUserCluster(clusterList);
  };

  useEffect(() => {
    clu();
  }, []);

  useEffect(() => {
    if (dataListId !== undefined) {
      setSelects([dataListId]);
    }
  }, [dataListId]);

  const callsensors = async () => {
    if (selects[lastidx] !== undefined) {
      const log = await requestGet(
        `/api/v1/sensor/read/log/${selects[lastidx]}/latest`
      );
      setSensorLog(log);
      // setEndDate(moment(log.createdDate));
    }
  };

  useEffect(() => {
    setLastidx(selects.length - 1);
    if (selects.length >= 0) {
      callsensors();
    } else {
      return;
    }
  }, [selects, lastidx]);

  const dayBtn = () => {
    setStartDate(moment().subtract(1, "d").format("YYYY-MM-DD 00:00"));
    setEndDate(moment().format("YYYY-MM-DD 23:59"));
    setActiveDay("day");
    setDayOpen(false);
  };

  const weeksBtn = () => {
    setStartDate(moment().subtract(1, "w").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("weeks");
    setDayOpen(false);
  };

  const monthsBtn = () => {
    setStartDate(moment().subtract(1, "M").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("months");
    setDayOpen(false);
  };

  const oneHourBtn = () => {
    setStartDate(moment().subtract(1, "H"));
    setEndDate(moment());
    setActiveDay("onehour");
    setDayOpen(false);
  };
  const threeHourBtn = () => {
    setStartDate(moment().subtract(3, "H"));
    setEndDate(moment());
    setActiveDay("threehour");
    setDayOpen(false);
  };
  const threemonthsBtn = () => {
    setStartDate(moment().subtract(3, "M").format("YYYY-MM-DD 00:00"));
    setEndDate(moment());
    setActiveDay("threemonths");
    setDayOpen(false);
  };
  const halfHourBtn = () => {
    setStartDate(moment().subtract(12, "H"));
    setEndDate(moment());
    setActiveDay("halfhour");
    setDayOpen(false);
  };
  const allmonthsBtn = () => {
    setStartDate(moment("2021-11-01"));
    setEndDate(moment());
    setActiveDay("all");
    setDayOpen(false);
  };
  const customdateBtn = () => {
    setActiveDay("custom");
    setCustomDate(true);
  };
  const dayControl = () => {
    setDayOpen(!dayOpen);
  };

  return (
    <div>
      <div onClick={dayControl}>
        <Calendar
          className={dayOpen ? "openCalendarmargin" : "calendarmargin"}
        />
        {dayOpen ? <Smallup /> : <Smalldown />}
      </div>
      <Card
        className={dayOpen ? "daycontrol" : "colseday"}
        style={{ zIndex: 100 }}
      >
        <div className="dayrow">
          <div
            className={activeDay === "onehour" ? "activedaycol" : "daycol"}
            onClick={oneHourBtn}
          >
            <div className="daymark" />
            <span>1시간</span>
          </div>
          <div
            className={activeDay === "threehour" ? "activedaycol" : "daycol"}
            onClick={threeHourBtn}
          >
            <div className="daymark" />
            <span>3시간</span>
          </div>
          <div
            className={activeDay === "halfhour" ? "activedaycol" : "daycol"}
            onClick={halfHourBtn}
          >
            <div className="daymark" />
            <span>12시간</span>
          </div>
        </div>
        <div className="dayrow">
          <div
            className={activeDay === "day" ? "activedaycol" : "daycol"}
            onClick={dayBtn}
          >
            <div className="daymark" />
            <span>1일</span>
          </div>
          <div
            className={activeDay === "weeks" ? "activedaycol" : "daycol"}
            onClick={weeksBtn}
          >
            <div className="daymark" />
            <span>1주</span>
          </div>
          <div
            className={activeDay === "months" ? "activedaycol" : "daycol"}
            onClick={monthsBtn}
          >
            <div className="daymark" />
            <span>1개월</span>
          </div>
        </div>
        <div className="dayrow">
          <div
            className={activeDay === "threemonths" ? "activedaycol" : "daycol"}
            onClick={threemonthsBtn}
          >
            <div className="daymark" />
            <span>3개월</span>
          </div>
          <div
            className={activeDay === "all" ? "activedaycol" : "daycol"}
            onClick={allmonthsBtn}
          >
            <div className="daymark" />
            <span>전체</span>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className={activeDay === "custom" ? "activedaycol" : "daycol"}
            onClick={customdateBtn}
          >
            <div className="daymark" />
            <span>사용자 지정</span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SensorDate;
