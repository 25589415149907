import { useHistory } from "react-router-dom";
import { ReactComponent as Settings } from "../../assets/images/chart/fi-rr-settings.svg";

const Farmlistrow = ({ userInfo }) => {
  const history = useHistory();
  if (userInfo?.length > 0 && Array.isArray(userInfo)) {
    return userInfo.map((data, i) => {
      const clickHandler = (e) => {
        e.stopPropagation();
        history.push({
          pathname: "/irrigation/farm",
          state: {
            userInfo: userInfo,
            farmId: data.id,
            farmName: data.name,
          },
        });
      };
      const editBtn = (e, data) => {
        e.stopPropagation();
        history.push({
          pathname: "/irrigation/name/edit",
          state: {
            editData: data,
          },
        });
      };
      return (
        <tr
          key={i}
          onClick={(e) => clickHandler(e)}
          className="trstyle"
          style={{ justifyContent: "space-between" }}
        >
          <td className="tdnoneborder">{data.name}</td>
          <button onClick={(e) => editBtn(e, data)} className="settingBtn">
            <Settings />
          </button>
        </tr>
      );
    });
  }
  return (
    <tr>
      <td></td>
    </tr>
  );
};

export default Farmlistrow;
