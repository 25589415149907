import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { requestGet } from "../../apis/request";
import Farmlist from "./farmlist";
import { MetaTags } from "react-meta-tags";
import { ReactComponent as Left } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-left.svg";
import { ReactComponent as Right } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-angle-right.svg";

const IrrigationList = () => {
  const [userInfo, setUserInfo] = useState([]);

  const [sideswicth, setSideswitch] = useState(false);

  const sensors = async () => {
    const sensor = await requestGet(`/api/v1/cluster/list`);
    setUserInfo(sensor);
  };

  useEffect(() => {
    sensors();
  }, []);

  const headersideswitch = () => {
    setSideswitch(!sideswicth);
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Irrigation List | FarmCLO</title>
      </MetaTags>
      <div className="pagewrap1">
        <div className="container p-0 m-0" style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <Row
              className="d-flex justify-content-center"
              style={{ height: "100%" }}
            >
              <Col
                md={3}
                className={sideswicth ? "switchcardwrap" : "colwrap"}
                style={{ height: "100%" }}
              >
                <Farmlist userInfo={userInfo} className="cardwrap" />
              </Col>
              <div className="sideMark" onClick={() => headersideswitch()}>
                {sideswicth ? (
                  <Right className="sideIcon" />
                ) : (
                  <Left className="sideIcon" />
                )}
              </div>
              <Col md={9} className="chartwrap">
                <div className="insideIrrText">IRRIGATION</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IrrigationList;
