import { useHistory } from "react-router-dom";
import CheckComponent from "./CheckComponent";
import UserCheckComponent from "./UserCheckComponent";

const UserRow = ({
  allSelected,
  selects,
  setSelects,
  currentPosts,
  setAllSelected,
}) => {
  const history = useHistory();

  return currentPosts.map((data, i) => {
    const goAdminEdit = (data) => {
      history.push({
        pathname: `/adminpage/user`,
        state: {
          id: data.id,
        },
      });
    };
    return (
      <tr className="tableHover" key={i} onClick={() => goAdminEdit(data)}>
        <td>
          <UserCheckComponent
            allSelected={allSelected}
            selects={selects}
            setSelects={setSelects}
            id={data.id}
            setAllSelected={setAllSelected}
          />
        </td>
        <td>{data.username}</td>
        <td>{data.nickname}</td>
        <td>{data.userType}</td>
      </tr>
    );
  });
};

export default UserRow;
