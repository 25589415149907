import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { requestGet } from "../../apis/request";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/ko";
import "./chart.scss";
import YDropdown from "./YDropdown";
import { useTranslation } from "react-i18next";
import ATS02SplineArea from "../AllCharts/apex/ATS02SplineArea";
import ATSplineArea from "../AllCharts/apex/ATSplineArea";
import SensorTable from "./SensorTable";
import SplineArea from "../AllCharts/apex/SplineArea";
import CSVDownloadBtn from "./CSVDownloadBtn";
import SSH01SplineArea from "../AllCharts/apex/SSH01SplineArea";
const SensorchartIRR = ({
  selects,
  startDate,
  endDate,
  setting,
  setType,
  ch,
  setNowMoi,
  setChLength,
  editmoi,
}) => {
  const [userDevice, setUserDevice] = useState([]);
  const [userDeviceType, setUserDeviceType] = useState([]);
  const [respon, setRespon] = useState({});
  const [apiStartDate, setApiStartDate] = useState(
    moment(startDate).format("YYYY-MM-DD")
  );
  const [apiEndDate, setApiEndDate] = useState(
    moment(endDate).format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState({});
  const [min, setMin] = useState({});
  const [max, setMax] = useState({});
  const [tableData, setTableData] = useState({});
  const savedCallback = useRef();
  const [nowType, setNowType] = useState(null);
  const [types, setTypes] = useState([]);
  const [resleng, setResleng] = useState(0);
  const { t } = useTranslation("main");

  useEffect(() => {
    setApiStartDate(moment(startDate).format("YYYY-MM-DD HH:mm"));
    setApiEndDate(moment(endDate).format("YYYY-MM-DD HH:mm"));
  }, [startDate, endDate]);

  const useInterval = (callback, delay) => {
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      const executeCallback = () => {
        savedCallback.current();
      };

      const timerId = setInterval(executeCallback, delay);

      return () => clearInterval(timerId);
    }, []);
  };

  const getLog = async (value) => {
    try {
      const device = await requestGet(`/api/v1/sensor/read/${value}`);
      if (setting) {
        setType(device.deviceType);
      }
      if (device.deviceType === "COT") {
        let arr = [];
        for (let i = 0; i < device.chNums; i++) {
          arr.push(i + 1);
        }
        setChLength((prevState) => ({
          ...prevState,
          [`${value}`]: {
            ...prevState.value,
            chleng: arr,
          },
        }));
      }
      setNowType(device.deviceType);
      return device;
    } catch (error) {
      console.error(error);
    }
  };

  const callapi = async (value) => {
    try {
      setRespon({});
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/${value.id}/${apiStartDate}/${apiEndDate}`
      );
      for (let i = 0; i < response.length; i++) {
        response[i] = { sensorName: value.name, ...response[i] };
        delete response[i].sensorId;
        delete response[i].modifiedDate;
      }
      setRespon((respon) => ({
        ...respon,
        [value.name]: response,
      }));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const callCotapi = async (value) => {
    try {
      setRespon({});
      if (editmoi) {
        setNowMoi({});
      }
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/cot/${value.id}/ch/${
          ch ? ch : 1
        }/${apiStartDate}/${apiEndDate}`
      );
      for (let i = 0; i < response.length; i++) {
        response[i] = { sensorName: value.name, ...response[i] };
        delete response[i].sensorId;
        delete response[i].modifiedDate;
      }
      setRespon((respon) => ({
        ...respon,
        [value.name]: response,
      }));
      if (setNowMoi) {
        setNowMoi((prevState) => ({
          ...prevState,
          [`${value?.id}`]: {
            ...prevState.value?.id,
            nowMoi:
              response[response?.length - 1]?.moisture === 0
                ? String(response[response?.length - 1]?.moisture)
                : response[response?.length - 1]?.moisture
                ? String(response[response?.length - 1]?.moisture)
                : "--",
          },
        }));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userDeviceType.includes("AT") && !userDeviceType.includes("ATS02")) {
      for (let i = 0; i < userDevice.length; i++) {
        if (userDevice[i]?.deviceType === "COT") {
          callCotapi(userDevice[i]);
        } else {
          callapi(userDevice[i]);
        }
      }
    }
  }, [userDeviceType, userDevice, apiStartDate, apiEndDate, ch]);

  const callGetLog = async () => {
    const arr = [];
    const typearr = [];
    if (selects) {
      const result = await getLog(selects);
      arr.push(result);
      typearr.push(result?.deviceType);
    }
    setUserDevice(arr);
    setUserDeviceType(typearr);
  };

  useEffect(() => {
    callGetLog();
  }, [selects]);

  useInterval(() => {
    callGetLog();
  }, 5 * 60 * 1000);

  useEffect(() => {
    if (nowType) {
      if (nowType === "SMS" || nowType === "SOIL") {
        setTypes([...types, "soilTemperature", "moisture"]);
      } else if (nowType === "CO2") {
        setTypes([
          ...types,
          "temperature",
          "humidity",
          "co2",
          "solar",
          "pressure",
        ]);
      } else if (nowType === "SSH") {
        setTypes([
          ...types,
          "moisture",
          "temperature",
          "ecno",
          "phno",
          "n",
          "p",
          "k",
        ]);
      } else if (nowType === "WATERFLOW") {
        setTypes([...types, "speed", "flowmeter", "pumpopencount"]);
      } else if (nowType === "NUTRIENT") {
        setTypes([...types, "temperature", "ph"]);
      } else if (nowType === "DRAINAGE") {
        setTypes([...types, "temperature", "waterlevel"]);
      } else if (nowType === "FMS") {
        setTypes([...types, "temperature", "pressure", "lux"]);
      } else if (nowType === "COMPOST") {
        setTypes([...types, "t15", "t30", "m15", "m30", "rh"]);
      } else if (nowType === "WATERLEVEL") {
        setTypes([...types, "l"]);
      } else if (nowType === "COT") {
        setTypes([...types, "valve"]);
      }
      // else if (nowType === "SES") {
      //   setTypes([...types, "temperature", "ec", "ph", "gdo"]);
      // }
    }
  }, [nowType]);

  useEffect(() => {
    let leng = Object.values(respon);
    let num = 0;
    for (let i = 0; i < leng.length; i++) {
      num += leng[i].length;
    }
    setResleng(num);
  }, [respon]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          {!userDeviceType.includes("AT") &&
          !userDeviceType.includes("ATS02") &&
          resleng > 0 ? (
            <CSVDownloadBtn res={respon} />
          ) : null}
          {!userDeviceType.includes("AT") &&
          !userDeviceType.includes("ATS02") &&
          !userDeviceType.includes("SSH")
            ? [...new Set(types)].map((type, i) => {
                return (
                  <Card key={i}>
                    <CardBody>
                      <CardTitle className="cardtitleline font-size-14 mb-4">
                        {t(type)}
                        <div className="chartHeaderDiv">
                          {menu[type] ? (
                            <>
                              <YDropdown
                                setMin={setMin}
                                setMax={setMax}
                                setMenu={setMenu}
                                typeKey={type}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </CardTitle>
                      <SplineArea
                        menu={menu}
                        setMenu={setMenu}
                        userDevice={userDevice}
                        min={min[type]}
                        max={max[type]}
                        data={respon}
                        typeKey={
                          type === "ecno" ? "ec" : type === "phno" ? "ph" : type
                        }
                      />
                    </CardBody>
                  </Card>
                );
              })
            : ""}
          {userDeviceType.includes("AT") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["AT"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"AT"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <ATSplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["AT"]}
                  max={max["AT"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"AT"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("ATS02") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["ATS02"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"ATS02"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <ATS02SplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["ATS02"]}
                  max={max["ATS02"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"ATS02"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("SSH") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["SSH"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"SSH"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <SSH01SplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["SSH"]}
                  max={max["SSH"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"SSH"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("ATS02") ? (
            <Card>
              <CardBody>
                <SensorTable type={"ATS02"} tableData={tableData} />
              </CardBody>
            </Card>
          ) : userDeviceType.includes("AT") ? (
            <Card>
              <CardBody>
                <SensorTable type={"AT"} tableData={tableData} />
              </CardBody>
            </Card>
          ) : userDeviceType.includes("SSH") ? (
            <Card>
              <CardBody>
                <SensorTable type={"SSH"} tableData={tableData} />
              </CardBody>
            </Card>
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SensorchartIRR;
