import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, CardBody, Label, Input, Form, Col, Row } from "reactstrap";
import { adminUserPut, requestDelete, requestGet } from "../../apis/request";
import { useTranslation } from "react-i18next";
import "./admin.scss";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { MetaTags } from "react-meta-tags";
import { ReactComponent as Photo } from "../../assets/icons/photo.svg";
import ChangePWModal from "./ChangePWModal";
const AdminUserEdit = (props) => {
  const [id] = useState(props.location.state.id);
  const [username, setUsername] = useState("");
  const [userpassword, setUserpassword] = useState("");
  const [nickname, setNickname] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [farmAddress, setFarmAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState("KO");
  const [timezone, setTimezone] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [photoFile, setPhotoFile] = useState(null);
  const [deleteHide, setDeleteHide] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("main");

  const sensors = async () => {
    const sensor = await requestGet(`/api/v1/admin/user/info/${id}`);
    setUserInfo(sensor);
    setUsername(sensor.username);
    setNickname(sensor.nickname);
    setUserAddress(sensor.userAddress);
    setFarmAddress(sensor.farmAddress);
    setPhone(sensor.phone);
    setLanguage(sensor.language);
    setTimezone(sensor.timezone);
  };

  useEffect(() => {
    sensors();
  }, [id]);
  const handleUsername = (e) => {
    setUsername(e.target.value);
  };

  const handlePassword = (e) => {
    setUserpassword(e.target.value);
  };

  const handleNickname = (e) => {
    setNickname(e.target.value);
  };

  const hidehandle = () => {
    setDeleteHide(!deleteHide);
  };

  const handleUserAddress = (e) => {
    setUserAddress(e.target.value);
  };

  const handleFarmAddress = (e) => {
    setFarmAddress(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleLanguage = (e) => {
    setLanguage(e.target.value);
  };

  const handleTimezone = (e) => {
    setTimezone(e);
  };

  const onFileHandler = (e) => {
    setPhotoFile(URL.createObjectURL(e.target.files[0]));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    // const params = new URLSearchParams();
    // params.append("username", username);
    // params.append("password", userpassword);
    // params.append("nickname", nickname);
    // params.append("userAddress", userAddress);
    // params.append("farmAddress", farmAddress);
    // params.append("phone", phone);
    // params.append("language", language);
    // params.append("timezone", timezone);
    let body = {
      username: username,
      nickname: nickname,
      userAddress: userAddress,
      farmAddress: farmAddress,
      phone: phone,
      language: language,
      timezone: timezone,
    };
    await adminUserPut(`/api/v1/admin/user/update/${id}`, body);
    history.push("/adminpage/users");
  };

  const onModal = (e) => {
    e.preventDefault();
    setOpenModal(!openModal);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin Page User Details | FarmCLO</title>
      </MetaTags>
      <div className="accountpageTotalWrap11">
        <div className="accountpage-content">
          <div className="accountWrap">
            <div className="accountPageCardWrap">
              <div className="accountPageCard1 px-0">
                <CardBody>
                  <div>
                    <div className="float-end">
                      <i
                        className="deletehide bx bx-dots-vertical-rounded"
                        onClick={hidehandle}
                      />

                      {deleteHide ? (
                        <button
                          color="danger"
                          className="sensorDeletBtn"
                          onClick={() => {
                            setconfirm_alert(true);
                          }}
                        >
                          <i className="deleticon bx bx-trash" />
                          {t("delete")}
                        </button>
                      ) : (
                        ""
                      )}
                      {confirm_alert ? (
                        <SweetAlert
                          title="정말 삭제하시겠습니까?"
                          warning
                          showCancel
                          confirmButtonText="삭제"
                          confirmBtnBsStyle="danger"
                          cancelButtonText="취소"
                          cancelBtnBsStyle="success"
                          onConfirm={async () => {
                            setconfirm_alert(false);
                            setsuccess_dlg(!success_dlg);
                            await requestDelete(
                              `/api/v1/admin/user/delete/${id}`
                            );
                            history.push("/adminpage/users");
                          }}
                          onCancel={() => setconfirm_alert(false)}
                        ></SweetAlert>
                      ) : null}
                    </div>
                    <div className="clearfix"></div>
                    <div className="sensorInfoTitleWrap"></div>
                  </div>
                  <div className="adminSensorInfoTable table-responsive">
                    <Form onSubmit={onSubmitHandler}>
                      <div className="photoAddRow">
                        {photoFile === null ? (
                          <div className="photoImage" />
                        ) : (
                          <img
                            src={photoFile}
                            alt="userimg"
                            className="photoImage"
                          />
                        )}
                        <Label className="fileAddName m-0">
                          <Input
                            type="file"
                            id="name"
                            onChange={onFileHandler}
                          />
                          <Photo />
                        </Label>
                      </div>
                      <div className="listAddRow">
                        <Label className="listAddName">uuid</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="name"
                          value={userInfo.id}
                          disabled
                        />
                      </div>
                      <div className="listAddRow">
                        <Label className="listAddName">User Name</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="name"
                          value={username}
                          onChange={handleUsername}
                        />
                      </div>
                      <div className="listAddChangePWRow">
                        <Label className="listAddName">Password</Label>
                        <button
                          className="adminChangePWBtn"
                          onClick={(e) => onModal(e)}
                        >
                          {t("changepassword")}
                        </button>
                      </div>
                      <div className="listAddRow">
                        <Label className="listAddName">Nick Name</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="serial"
                          value={nickname}
                          onChange={handleNickname}
                        />
                      </div>
                      <div className="listAddRow">
                        <Label className="listAddName">UserAddress</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="serial"
                          value={userAddress}
                          onChange={handleUserAddress}
                        />
                      </div>
                      <div className="listAddRow">
                        <Label className="listAddName">FarmAddress</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="serial"
                          value={farmAddress}
                          onChange={handleFarmAddress}
                        />
                      </div>
                      <div className="listAddRow">
                        <Label className="listAddName">Phone</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="serial"
                          value={phone}
                          onChange={handlePhone}
                        />
                      </div>
                      <div className="listAddRow">
                        <Label className="listAddName">Language</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="serial"
                          value={language}
                          onChange={handleLanguage}
                        />
                      </div>
                      <div className="listAddRow">
                        <Label className="listAddName">Time Zone</Label>
                        <TimezonePicker
                          onChange={handleTimezone}
                          defaultValue={timezone}
                          unselectLabel="No Timezone"
                          absolute={true}
                          value={timezone}
                        />
                      </div>
                      <div className="mt-4">
                        <button className="adminSensorUpdateBtn" type="submit">
                          {t("save")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal ? <ChangePWModal setModalOpen={setOpenModal} id={id} /> : null}
    </React.Fragment>
  );
};

export default AdminUserEdit;
