import "./sensorrow.scss";
import { apiUrl, requestDelete } from "../../apis/request";
import { Col, Row } from "reactstrap";
import { ReactComponent as Trash } from "../../assets/icons/svgs/solid/minus.svg";
import { useTranslation } from "react-i18next";
const CotRowDetail = ({ data, chID, callGetLog }) => {
  const { t } = useTranslation("main");
  const imgurl = apiUrl + data?.imagePath;

  const removeSenor = async (id) => {
    if (window.confirm(`${t("delsensor")}`)) {
      try {
        await requestDelete(`/api/v1/sensor/channel/${chID}/sensor/${id}`);
        alert(`${t("delsecces")}`);
        callGetLog();
      } catch (error) {
        alert(`${t("delfail")}`);
      }
    }
  };

  return (
    <div className="sensorsrow" style={{ cursor: "default" }}>
      <Row
        className="d-flex align-items-center"
        style={{ height: "100%", width: "100%", margin: "0px" }}
      >
        <Col
          className="col-auto"
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          {!data.imagePath ? (
            <div className="photoImage rounded-circle avatar-md" />
          ) : (
            <img
              src={imgurl}
              alt="profile"
              className="photoImage rounded-circle avatar-md"
            />
          )}
        </Col>
        <Col className="rowWrap col-5">
          <div>{data.name ? data.name : data.serial}</div>
          <div>{data.serial}</div>
          <div>{data.description}</div>
        </Col>
        <Col className="settingWrap col-auto">
          <button className="trashBtn">
            <Trash width={"20px"} onClick={() => removeSenor(data.id)} />
          </button>
        </Col>
      </Row>
    </div>
  );
};
export default CotRowDetail;
