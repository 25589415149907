import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { ReactComponent as Tem } from "../../assets/icons/tem.svg";
import { ReactComponent as Rain } from "../../assets/icons/rainhum.svg";
import "./sensorCard.scss";
const WeatherCard = (props) => {
  return (
    <React.Fragment>
      <Card className="weatherCardWrap flex-row">
        <Col className="d-flex justify-content-center align-items-center weatherCardDataDiv">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ color: "#000" }}
          >
            {props.icon === "온도" ? (
              <Tem
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "14px",
                }}
              />
            ) : props.icon === "강수" ? (
              <Rain
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "14px",
                }}
              />
            ) : null}
            <div>
              <span
                style={{
                  fontSize: "26px",
                  fontWeight: "bold",
                }}
              >
                {props.num}
              </span>
            </div>
            <div>
              <span
                style={{
                  fontSize: "15px",
                }}
              >
                {props.title}
              </span>
            </div>
          </div>
        </Col>
      </Card>
    </React.Fragment>
  );
};

export default WeatherCard;
