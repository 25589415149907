import { Link, useHistory } from "react-router-dom";
import CheckComponent from "./CheckComponent";
import { useState } from "react";

const TableRow = ({
  allSelected,
  selects,
  setSelects,
  currentPosts,
  setAllSelected,
}) => {
  const [deviceTypes, setDeviceTypes] = useState("");
  const [topDeviceTypes, setTopDeviceTypes] = useState("");
  const history = useHistory();

  return currentPosts.map((data, i) => {
    const goAdminEdit = (data) => {
      history.push({
        pathname: `/adminpage/sensor`,
        state: {
          id: data.id,
          name: data.name,
          deviceType: data.deviceType,
          serial: data.serial,
          description: data.description,
          latitude: data.latitude,
          longitude: data.longitude,
          imagePath: data.imagePath,
        },
      });
    };
    return (
      <tr className="tableHover" key={i} onClick={() => goAdminEdit(data)}>
        <td>
          <CheckComponent
            allSelected={allSelected}
            selects={selects}
            setSelects={setSelects}
            id={data.id}
            setAllSelected={setAllSelected}
            deviceTypes={data.deviceType}
            setDeviceTypes={setDeviceTypes}
            setTopDeviceTypes={setTopDeviceTypes}
          />
        </td>
        <td>{data.id}</td>
        <td>{data.name ? data.name : data.serial}</td>
        <td>{data.deviceType}</td>
        <td>{data.serial}</td>
        <td>{data.description}</td>
        <td>{data.latitude}</td>
        <td>{data.longitude}</td>
      </tr>
    );
  });
};

export default TableRow;
