import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Card, Col, Container, Form, Input, Row, CardBody } from "reactstrap";
import backImg from "../../assets/images/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds_2021-10-21/solar-panels-used-for-renewable-energy-on-the-field-under-the-sky-full-of-clouds.png";
import { requestGet, requestPost } from "../../apis/request";
import { ReactComponent as Plus } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-plus-small.svg";
import { ReactComponent as Info } from "../../assets/icons/uicons-regular-rounded/svg/fi-rr-info.svg";
import { ReactComponent as Left } from "../../assets/icons/uicons-solid-rounded/svg/fi-sr-arrow-small-left.svg";
import SimpleBarReact from "simplebar-react";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import "./farmadd.scss";
const Farmadd = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [clusterList, setClusterList] = useState([]);
  const [clusterName, setClusterName] = useState([]);
  const [sensorsData, setSensorsData] = useState([]);
  const [sensorDescription, setSensorDescription] = useState([]);
  const [sensorsId, setSensorsId] = useState([]);
  const [sensorAdd, setSensorAdd] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("main");
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handledescription = (e) => {
    setDescription(e.target.value);
  };

  const sensorsDataClick = (data) => {
    setSensorsData([...sensorsData, data]);
    setSensorDescription([...sensorDescription, data.description]);
    setSensorsId([...sensorsId, data.id]);
    setClusterList(clusterList.filter((_data) => _data !== data));
  };

  const sensorsDataRemoveClick = (data) => {
    setClusterList([...clusterList, data]);
    setSensorDescription(
      sensorDescription.filter(
        (_description) => _description !== data.description
      )
    );
    setSensorsId(sensorsId.filter((_id) => _id !== data.id));
    setSensorsData(sensorsData.filter((_data) => _data !== data));
  };

  const OnSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (clusterName.includes(name)) {
        return alert("이미 존재하는 이름 입니다.");
      }
      const params = new URLSearchParams();
      params.append("name", name);
      params.append("description", description);
      params.append("sensors", sensorsId);

      // let body = {
      //   name: name,
      //   description: description,
      //   sensors: sensorsId,
      // };
      await requestPost("/api/v1/cluster/add", params);
      history.push("/dashboard");
    } catch (error) {
      alert("등록 실패하였습니다.");
    }
  };

  const clusters = async () => {
    const arr = [];
    const cluster = await requestGet(`/api/v1/sensor/list`);
    const clustername = await requestGet(`/api/v1/cluster/list`);
    clustername.map((data) => arr.push(data.name));
    setClusterName(arr);
    setClusterList(cluster);
  };

  useEffect(() => {
    clusters();
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Farm Add | FarmCLO</title>
      </MetaTags>
      <img src={backImg} className="backgroundimg" />
      <div className="dashText">ADD FARM</div>
      <div className="farmAddWrap account-pages">
        <Container className="farmAddContainer">
          <Form onSubmit={OnSubmitHandler}>
            <Row className="farmAddRow justify-content-center">
              <Col className="farmAddCol">
                <div className="farmAddLeftWrap">
                  <Left className="farmAddLeft" />
                </div>
                <Card className="farmAddCard overflow-hidden">
                  <Row className="g-0">
                    <Col>
                      <div>
                        <div style={{ padding: "0 10px" }}>
                          <div className="addPageRow">
                            <label htmlFor="_name" className="farmAddNames">
                              {t("farmid")}
                            </label>
                            <Input
                              type="text"
                              name="_name"
                              className="farmAddInput"
                              value={name}
                              onChange={handleName}
                              placeholder="name"
                              required
                            />
                          </div>
                          <div className="addPageRow mb-3">
                            <label
                              htmlFor="_description"
                              className="farmAddNames"
                            >
                              {t("farmdescription")}
                            </label>
                            <Input
                              type="text"
                              name="_description"
                              className="farmAddInput"
                              value={description}
                              onChange={handledescription}
                              placeholder="description"
                            />
                          </div>
                          <div className="farmAddListBtnWrap">
                            <label
                              htmlFor="_clusterId"
                              className="farmAddNameSensor"
                            >
                              {t("farmsensors")}
                            </label>
                            <div className="sensorsAddList">
                              <SimpleBarReact
                                className="farmaddSimplebar"
                                style={{ maxHeight: "277px" }}
                              >
                                {sensorsData.map((data, i) => {
                                  return (
                                    <div
                                      onClick={() =>
                                        sensorsDataRemoveClick(data)
                                      }
                                      className="farmAddSensordiv"
                                    >
                                      <div className="farmSpanWrap">
                                        <span className="farmAddDescription">
                                          {data.description}
                                        </span>
                                        <span className="farmAddSerial">
                                          {data.serial}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </SimpleBarReact>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col className="farmAddCol">
                <Card
                  className={
                    sensorAdd ? "farmAddSensorList" : "hideFarmAddSensorList"
                  }
                >
                  <CardBody className="farmAddSensorListCard">
                    <button
                      className="farmSensorAddEndButton"
                      onClick={() => setSensorAdd(false)}
                    >
                      추가완료
                    </button>
                    {clusterList.length > 0 ? (
                      <SimpleBarReact
                        className="farmaddSensorListSimplebar"
                        style={{ maxHeight: "555px" }}
                      >
                        {clusterList.map((data, i) => {
                          return (
                            <div
                              onClick={() => sensorsDataClick(data)}
                              className="farmAddSensordiv"
                            >
                              <div className="farmSpanWrap">
                                <span className="farmAddDescription">
                                  {data.description}
                                </span>
                                <span className="farmAddSerial">
                                  {data.serial}
                                </span>
                              </div>
                              <Plus className="farmAddPlus" />
                            </div>
                          );
                        })}
                      </SimpleBarReact>
                    ) : (
                      <div className="farmAddInfoWrap">
                        <Info className="farmAddInfo" />
                        <span className="farmAddInfoSpan">
                          추가할 센서가 없습니다.
                        </span>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <button
                className="farmSensorAddButton"
                onClick={() => setSensorAdd(true)}
              >
                추가하기
              </button>
              <div className="farmAddBtnDiv">
                <button
                  id="farmAddButton"
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                >
                  {t("save")}
                </button>
              </div>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(Farmadd);
