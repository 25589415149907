import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { requestGet } from "../../apis/request";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/ko";
import "./chart.scss";
import YDropdown from "./YDropdown";
import { useTranslation } from "react-i18next";
import ATS02SplineArea from "../AllCharts/apex/ATS02SplineArea";
import ATSplineArea from "../AllCharts/apex/ATSplineArea";
import SensorTable from "./SensorTable";
import SplineArea from "../AllCharts/apex/SplineArea";
import CSVDownloadBtn from "./CSVDownloadBtn";
import SSH01SplineArea from "../AllCharts/apex/SSH01SplineArea";
import SSH01BatterySplineArea from "../AllCharts/apex/SSH01BatterySplineArea";
import LATSplineArea from "../AllCharts/apex/LATSplineArea";
import SensorYDropdown from "./SensorYDropdown";
import FMSSplineArea from "../AllCharts/apex/FMSSplineArea";
import FMS02SplineArea from "../AllCharts/apex/FMS02SplineArea";
import WaterBatterySplineArea from "../AllCharts/apex/WaterBatterySplineArea";
import WATERLEVELSplineArea from "../AllCharts/apex/WATERLEVELSplineArea";
const Sensorchart = ({
  selects,
  startDate,
  endDate,
  setting,
  setType,
  ch,
  setNowMoi,
  setChLength,
  editmoi,
}) => {
  const [userDevice, setUserDevice] = useState([]);
  const [userDeviceType, setUserDeviceType] = useState([]);
  const [respon, setRespon] = useState({});
  const [apiStartDate, setApiStartDate] = useState(
    moment(startDate).format("YYYY-MM-DD")
  );
  const [apiEndDate, setApiEndDate] = useState(
    moment(endDate).format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState({});
  const [min, setMin] = useState({});
  const [max, setMax] = useState({});
  const [tableData, setTableData] = useState({});
  const savedCallback = useRef();
  const [nowType, setNowType] = useState(null);
  const [types, setTypes] = useState([]);
  const [resleng, setResleng] = useState(0);
  const { t } = useTranslation("main");

  useEffect(() => {
    setApiStartDate(moment(startDate).format("YYYY-MM-DD HH:mm"));
    setApiEndDate(moment(endDate).format("YYYY-MM-DD HH:mm"));
  }, [startDate, endDate]);

  const useInterval = (callback, delay) => {
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      const executeCallback = () => {
        savedCallback.current();
      };

      const timerId = setInterval(executeCallback, delay);

      return () => clearInterval(timerId);
    }, []);
  };

  const getLog = async (value) => {
    try {
      const device = await requestGet(`/api/v1/sensor/read/${value}`);
      if (setting) {
        setType(device.deviceType);
      }
      if (device.deviceType === "COT") {
        let arr = [];
        for (let i = 0; i < device.chNums; i++) {
          arr.push(i + 1);
        }
        setChLength((prevState) => ({
          ...prevState,
          [`${value}`]: {
            ...prevState.value,
            chleng: arr,
          },
        }));
      }
      setNowType(device.deviceType);
      return device;
    } catch (error) {
      console.error(error);
    }
  };

  const callapi = async (value) => {
    try {
      setRespon({});
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/${value.id}/${apiStartDate}/${apiEndDate}`
      );
      for (let i = 0; i < response.length; i++) {
        response[i] = { sensorName: value.name, ...response[i] };
        delete response[i].sensorId;
        delete response[i].modifiedDate;
      }
      setRespon((respon) => ({
        ...respon,
        [value.name]: response,
      }));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const callCotapi = async (value) => {
    try {
      setRespon({});
      if (editmoi) {
        setNowMoi({});
      }
      setLoading(true);
      const response = await requestGet(
        `/api/v1/sensor/read/log/cot/${value.id}/ch/${
          ch ? ch : 1
        }/${apiStartDate}/${apiEndDate}`
      );
      for (let i = 0; i < response.length; i++) {
        response[i] = { sensorName: value.name, ...response[i] };
        delete response[i].sensorId;
        delete response[i].modifiedDate;
      }
      setRespon((respon) => ({
        ...respon,
        [value.name]: response,
      }));
      if (setNowMoi) {
        setNowMoi((prevState) => ({
          ...prevState,
          [`${value?.id}`]: {
            ...prevState.value?.id,
            nowMoi:
              response[response?.length - 1]?.moisture === 0
                ? String(response[response?.length - 1]?.moisture)
                : response[response?.length - 1]?.moisture
                ? String(response[response?.length - 1]?.moisture)
                : "--",
          },
        }));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      !userDeviceType.includes("AT") &&
      !userDeviceType.includes("ATS02") &&
      !userDeviceType.includes("LAT")
    ) {
      for (let i = 0; i < userDevice.length; i++) {
        if (userDevice[i]?.deviceType === "COT") {
          callCotapi(userDevice[i]);
        } else {
          callapi(userDevice[i]);
        }
      }
    }
  }, [userDeviceType, userDevice, apiStartDate, apiEndDate, ch]);

  const callGetLog = async () => {
    const arr = [];
    const typearr = [];
    if (selects.length > 0) {
      for (let value of selects) {
        const result = await getLog(value);
        arr.push(result);
        typearr.push(result?.deviceType);
      }
    }
    setUserDevice(arr);
    setUserDeviceType(typearr);
  };

  useEffect(() => {
    if (Array.isArray(selects)) {
      callGetLog();
    }
  }, [selects]);

  useInterval(() => {
    if (Array.isArray(selects)) {
      callGetLog();
    }
  }, 5 * 60 * 1000);

  useEffect(() => {
    if (nowType) {
      if (nowType === "SMS" || nowType === "SOIL") {
        setTypes([...types, "soilTemperature", "moisture", "battery"]);
      } else if (nowType === "CO2") {
        setTypes([
          ...types,
          "temperature",
          "humidity",
          "co2",
          "solar",
          "pressure",
        ]);
      } else if (nowType === "SSH") {
        setTypes([
          ...types,
          "moisture",
          "temperature",
          "ecno",
          "phno",
          "n",
          "p",
          "k",
        ]);
      } else if (nowType === "WATERFLOW") {
        setTypes([...types, "speed", "flowmeter", "pumpopencount"]);
      } else if (nowType === "NUTRIENT") {
        setTypes([...types, "temperature", "ph", "ec", "gdo"]);
      } else if (nowType === "DRAINAGE") {
        setTypes([...types, "temperature", "waterlevel"]);
      } else if (nowType === "FMS") {
        setTypes([...types, "temperature", "pressure", "lux"]);
      } else if (nowType === "COMPOST") {
        setTypes([...types, "t15", "t30", "m15", "m30", "rh"]);
      } else if (nowType === "WATERLEVEL") {
        setTypes([...types, "l"]);
      } else if (nowType === "COT") {
        setTypes([...types, "valve"]);
      }
      // else if (nowType === "SES") {
      //   setTypes([...types, "temperature", "ecno", "phno", "gdo"]);
      // }
    }
  }, [nowType]);

  useEffect(() => {
    let leng = Object.values(respon);
    let num = 0;
    for (let i = 0; i < leng.length; i++) {
      num += leng[i].length;
    }
    setResleng(num);
  }, [respon]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          {!userDeviceType.includes("AT") &&
          !userDeviceType.includes("ATS02") &&
          !userDeviceType.includes("LAT") &&
          !userDeviceType.includes("FMS") &&
          !userDeviceType.includes("FMS02") &&
          !userDeviceType.includes("WATERLEVEL") &&
          !userDeviceType.includes("SSH") &&
          resleng > 0 ? (
            <CSVDownloadBtn res={respon} />
          ) : null}
          {!userDeviceType.includes("AT") &&
          !userDeviceType.includes("ATS02") &&
          !userDeviceType.includes("LAT") &&
          !userDeviceType.includes("FMS") &&
          !userDeviceType.includes("FMS02") &&
          !userDeviceType.includes("WATERLEVEL") &&
          !userDeviceType.includes("SSH")
            ? [...new Set(types)].map((type, i) => {
                return (
                  <Card key={i}>
                    <CardBody>
                      <CardTitle className="cardtitleline font-size-14 mb-4">
                        {t(type)}
                        <div className="chartHeaderDiv">
                          {menu[type] ? (
                            <>
                              <YDropdown
                                setMin={setMin}
                                setMax={setMax}
                                setMenu={setMenu}
                                typeKey={type}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </CardTitle>
                      <SplineArea
                        menu={menu}
                        setMenu={setMenu}
                        userDevice={userDevice}
                        min={min[type]}
                        max={max[type]}
                        data={respon}
                        typeKey={
                          type === "ecno" ? "ec" : type === "phno" ? "ph" : type
                        }
                      />
                    </CardBody>
                  </Card>
                );
              })
            : ""}
          {userDeviceType.includes("AT") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["AT"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"AT"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATHpa"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATHpa"]}
                          max={max["ATHpa"]}
                          setMenu={setMenu}
                          typeKey={"ATHpa"}
                          name={"pressure"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATTemp"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATTemp"]}
                          max={max["ATTemp"]}
                          setMenu={setMenu}
                          typeKey={"ATTemp"}
                          name={"temperature"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATVpd"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATVpd"]}
                          max={max["ATVpd"]}
                          setMenu={setMenu}
                          typeKey={"ATVpd"}
                          name={"vpd"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATSun"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATSun"]}
                          max={max["ATSun"]}
                          setMenu={setMenu}
                          typeKey={"ATSun"}
                          name={"solar"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATHum"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATHum"]}
                          max={max["ATHum"]}
                          setMenu={setMenu}
                          typeKey={"ATHum"}
                          name={"humidity"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATCo2"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATCo2"]}
                          max={max["ATCo2"]}
                          setMenu={setMenu}
                          typeKey={"ATCo2"}
                          name={"co2"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATTd"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATTd"]}
                          max={max["ATTd"]}
                          setMenu={setMenu}
                          typeKey={"ATTd"}
                          name={"td"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <ATSplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["AT"]}
                  max={max["AT"]}
                  hpamin={min["ATHpa"]}
                  hpamax={max["ATHpa"]}
                  tempmin={min["ATTemp"]}
                  tempmax={max["ATTemp"]}
                  vpdmin={min["ATVpd"]}
                  vpdmax={max["ATVpd"]}
                  sunmin={min["ATSun"]}
                  sunmax={max["ATSun"]}
                  hummin={min["ATHum"]}
                  hummax={max["ATHum"]}
                  co2min={min["ATCo2"]}
                  co2max={max["ATCo2"]}
                  tdmin={min["ATTd"]}
                  tdmax={max["ATTd"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"AT"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("WATERLEVEL")
            ? [...new Set(types)].map((type, i) => {
                return (
                  <Card key={i}>
                    <CardBody>
                      <CardTitle className="cardtitleline font-size-14 mb-4">
                        {t(type)}
                        <div className="chartHeaderDiv">
                          {menu[type] ? (
                            <>
                              <YDropdown
                                setMin={setMin}
                                setMax={setMax}
                                setMenu={setMenu}
                                typeKey={type}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </CardTitle>
                      <WATERLEVELSplineArea
                        menu={menu}
                        setMenu={setMenu}
                        userDevice={userDevice}
                        min={min[type]}
                        max={max[type]}
                        data={respon}
                        typeKey={
                          type === "ecno" ? "ec" : type === "phno" ? "ph" : type
                        }
                      />
                    </CardBody>
                  </Card>
                );
              })
            : ""}
          {userDeviceType.includes("FMS") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["FMS"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"FMS"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSTemp"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSTemp"]}
                          max={max["FMSTemp"]}
                          setMenu={setMenu}
                          typeKey={"FMSTemp"}
                          name={"temperature"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSHum"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSHum"]}
                          max={max["FMSHum"]}
                          setMenu={setMenu}
                          typeKey={"FMSHum"}
                          name={"mois"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSAcc"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSAcc"]}
                          max={max["FMSAcc"]}
                          setMenu={setMenu}
                          typeKey={"FMSAcc"}
                          name={"acceleration"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSDacc"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSDacc"]}
                          max={max["FMSDacc"]}
                          setMenu={setMenu}
                          typeKey={"FMSDacc"}
                          name={"dacceleration"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSGyro"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSGyro"]}
                          max={max["FMSGyro"]}
                          setMenu={setMenu}
                          typeKey={"FMSGyro"}
                          name={"gyroActivity"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSDgyro"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSDgyro"]}
                          max={max["FMSDgyro"]}
                          setMenu={setMenu}
                          typeKey={"FMSDgyro"}
                          name={"dgyro"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSAlt"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSAlt"]}
                          max={max["FMSAlt"]}
                          setMenu={setMenu}
                          typeKey={"FMSAlt"}
                          name={"altitude"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSSun"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSSun"]}
                          max={max["FMSSun"]}
                          setMenu={setMenu}
                          typeKey={"FMSSun"}
                          name={"lux"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMSHpa"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMSHpa"]}
                          max={max["FMSHpa"]}
                          setMenu={setMenu}
                          typeKey={"FMSHpa"}
                          name={"press"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <FMSSplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["FMS"]}
                  max={max["FMS"]}
                  tempmin={min["FMSTemp"]}
                  tempmax={max["FMSTemp"]}
                  hummin={min["FMSHum"]}
                  hummax={max["FMSHum"]}
                  gyromin={min["FMSGyro"]}
                  gyromax={max["FMSGyro"]}
                  dgyromin={min["FMSDgyro"]}
                  dgyromax={max["FMSDgyro"]}
                  accmin={min["FMSAcc"]}
                  accmax={max["FMSAcc"]}
                  daccmin={min["FMSDacc"]}
                  daccmax={max["FMSDacc"]}
                  altmin={min["FMSAlt"]}
                  altmax={max["FMSAlt"]}
                  sunmin={min["FMSSun"]}
                  sunmax={max["FMSSun"]}
                  hpamin={min["FMSHpa"]}
                  hpamax={max["FMSHpa"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"FMS"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("FMS02") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["FMS02"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"FMS02"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMS02Temp"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMS02Temp"]}
                          max={max["FMS02Temp"]}
                          setMenu={setMenu}
                          typeKey={"FMS02Temp"}
                          name={"temperature"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMS02Hum"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMS02Hum"]}
                          max={max["FMS02Hum"]}
                          setMenu={setMenu}
                          typeKey={"FMS02Hum"}
                          name={"mois"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMS02Acc"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMS02Acc"]}
                          max={max["FMS02Acc"]}
                          setMenu={setMenu}
                          typeKey={"FMS02Acc"}
                          name={"acceleration"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMS02Dacc"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMS02Dacc"]}
                          max={max["FMS02Dacc"]}
                          setMenu={setMenu}
                          typeKey={"FMS02Dacc"}
                          name={"dacceleration"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMS02Gyro"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMS02Gyro"]}
                          max={max["FMS02Gyro"]}
                          setMenu={setMenu}
                          typeKey={"FMS02Gyro"}
                          name={"gyroActivity"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMS02Dgyro"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMS02Dgyro"]}
                          max={max["FMS02Dgyro"]}
                          setMenu={setMenu}
                          typeKey={"FMS02Dgyro"}
                          name={"dgyro"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["FMS02Hpa"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["FMS02Hpa"]}
                          max={max["FMS02Hpa"]}
                          setMenu={setMenu}
                          typeKey={"FMS02Hpa"}
                          name={"press"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <FMS02SplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["FMS02"]}
                  max={max["FMS02"]}
                  tempmin={min["FMS02Temp"]}
                  tempmax={max["FMS02Temp"]}
                  hummin={min["FMS02Hum"]}
                  hummax={max["FMS02Hum"]}
                  gyromin={min["FMS02Gyro"]}
                  gyromax={max["FMS02Gyro"]}
                  dgyromin={min["FMS02Dgyro"]}
                  dgyromax={max["FMS02Dgyro"]}
                  accmin={min["FMS02Acc"]}
                  accmax={max["FMS02Acc"]}
                  daccmin={min["FMS02Dacc"]}
                  daccmax={max["FMS02Dacc"]}
                  hpamin={min["FMS02Hpa"]}
                  hpamax={max["FMS02Hpa"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"FMS02"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("ATS02") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["ATS02"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"ATS02"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATS02Hum"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATS02Hum"]}
                          max={max["ATS02Hum"]}
                          setMenu={setMenu}
                          typeKey={"ATS02Hum"}
                          name={"humidity"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATS02Sun"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATS02Sun"]}
                          max={max["ATS02Sun"]}
                          setMenu={setMenu}
                          typeKey={"ATS02Sun"}
                          name={"solar"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATS02Hpa"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATS02Hpa"]}
                          max={max["ATS02Hpa"]}
                          setMenu={setMenu}
                          typeKey={"ATS02Hpa"}
                          name={"pressure"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATS02Rain"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATS02Rain"]}
                          max={max["ATS02Rain"]}
                          setMenu={setMenu}
                          typeKey={"ATS02Rain"}
                          name={"rain"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATS02Temp"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATS02Temp"]}
                          max={max["ATS02Temp"]}
                          setMenu={setMenu}
                          typeKey={"ATS02Temp"}
                          name={"temperature"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATS02WindD"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATS02WindD"]}
                          max={max["ATS02WindD"]}
                          setMenu={setMenu}
                          typeKey={"ATS02WindD"}
                          name={"windD"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["ATS02WindS"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["ATS02WindS"]}
                          max={max["ATS02WindS"]}
                          setMenu={setMenu}
                          typeKey={"ATS02WindS"}
                          name={"windS"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <ATS02SplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["ATS02"]}
                  max={max["ATS02"]}
                  hummin={min["ATS02Hum"]}
                  hummax={max["ATS02Hum"]}
                  sunmin={min["ATS02Sun"]}
                  sunmax={max["ATS02Sun"]}
                  hpamin={min["ATS02Hpa"]}
                  hpamax={max["ATS02Hpa"]}
                  rainmin={min["ATS02Rain"]}
                  rainmax={max["ATS02Rain"]}
                  tempmin={min["ATS02Temp"]}
                  tempmax={max["ATS02Temp"]}
                  winddmin={min["ATS02WindD"]}
                  winddmax={max["ATS02WindD"]}
                  windsmin={min["ATS02WindS"]}
                  windsmax={max["ATS02WindS"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"ATS02"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("LAT") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["LAT"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"LAT"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["LATHum"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["LATHum"]}
                          max={max["LATHum"]}
                          setMenu={setMenu}
                          typeKey={"LATHum"}
                          name={"humidity"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["LATHpa"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["LATHpa"]}
                          max={max["LATHpa"]}
                          setMenu={setMenu}
                          typeKey={"LATHpa"}
                          name={"pressure"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["LATTemp"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["LATTemp"]}
                          max={max["LATTemp"]}
                          setMenu={setMenu}
                          typeKey={"LATTemp"}
                          name={"temperature"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["LATStress"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["LATStress"]}
                          max={max["LATStress"]}
                          setMenu={setMenu}
                          typeKey={"LATStress"}
                          name={"stress"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["LATCo2"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["LATCo2"]}
                          max={max["LATCo2"]}
                          setMenu={setMenu}
                          typeKey={"LATCo2"}
                          name={"co2eng"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {menu["LATSolar"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <SensorYDropdown
                          setMin={setMin}
                          setMax={setMax}
                          min={min["LATSolar"]}
                          max={max["LATSolar"]}
                          setMenu={setMenu}
                          typeKey={"LATSolar"}
                          name={"solar"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <LATSplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["LAT"]}
                  max={max["LAT"]}
                  hummin={min["LATHum"]}
                  hummax={max["LATHum"]}
                  hpamin={min["LATHpa"]}
                  hpamax={max["LATHpa"]}
                  tempmin={min["LATTemp"]}
                  tempmax={max["LATTemp"]}
                  stressmin={min["LATStress"]}
                  stressmax={max["LATStress"]}
                  co2min={min["LATCo2"]}
                  co2max={max["LATCo2"]}
                  solarmin={min["LATSolar"]}
                  solarmax={max["LATSolar"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"LAT"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("SSH") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {userDevice[0].name}
                  <div className="chartHeaderDiv">
                    {menu["SSH"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"SSH"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <SSH01SplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["SSH"]}
                  max={max["SSH"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"SSH"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("ATS02") ? (
            <Card>
              <CardBody>
                <SensorTable type={"ATS02"} tableData={tableData} />
              </CardBody>
            </Card>
          ) : //  : userDeviceType.includes("AT") ? (
          //   <Card>
          //     <CardBody>
          //       <SensorTable type={"AT"} tableData={tableData} />
          //     </CardBody>
          //   </Card>
          // )
          userDeviceType.includes("SSH") ? (
            <Card>
              <CardBody>
                <SensorTable type={"SSH"} tableData={tableData} />
              </CardBody>
            </Card>
          ) : null}
          {userDeviceType.includes("SSH") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {t("battery")}
                  <div className="chartHeaderDiv">
                    {menu["battery"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"battery"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <SSH01BatterySplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["battery"]}
                  max={max["battery"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"battery"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          {userDeviceType.includes("WATERLEVEL") ? (
            <Card>
              <CardBody>
                <CardTitle className="cardtitleline font-size-14 mb-4">
                  {t("battery")}
                  <div className="chartHeaderDiv">
                    {menu["battery"] ? (
                      <>
                        <div
                          className="chartOutside"
                          onClick={() => setMenu(false)}
                        />
                        <YDropdown
                          setMin={setMin}
                          setMax={setMax}
                          setMenu={setMenu}
                          typeKey={"battery"}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </CardTitle>
                <WaterBatterySplineArea
                  setMenu={setMenu}
                  userDevice={userDevice}
                  min={min["battery"]}
                  max={max["battery"]}
                  apistartdate={
                    startDate
                      ? moment(startDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  apienddate={
                    endDate
                      ? moment(endDate).format("YYYY-MM-DD HH:mm")
                      : moment().format("YYYY-MM-DD HH:mm")
                  }
                  typeKey={"battery"}
                  setTableData={setTableData}
                />
              </CardBody>
            </Card>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Sensorchart;
