import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "reactstrap";
import "../../components/CommonForBoth/TopbarDropdown/notificationdropdown.scss";
import "./chart.scss";
//i18n
import { withTranslation } from "react-i18next";

const YDropdown = ({ setMin, setMax, setMenu, typeKey }) => {
  // Declare a new state variable, which we'll call "menu"
  // const [menu, setMenu] = useState(false);
  const [inMin, setInMin] = useState("0");
  const [inMax, setInMax] = useState("0");
  const handleMin = (e) => {
    setInMin(e.target.value);
  };

  const handleMax = (e) => {
    setInMax(e.target.value);
  };

  const onSubmitHandler = (e) => {
    try {
      e.preventDefault();
      setMin((menu) => ({
        ...menu,
        [typeKey]: inMin,
      }));
      setMax((menu) => ({
        ...menu,
        [typeKey]: inMax,
      }));
      setMenu((menu) => ({
        ...menu,
        [typeKey]: false,
      }));
    } catch (error) {}
  };

  const onClose = () => {
    setMenu((menu) => ({
      ...menu,
      [typeKey]: false,
    }));
  };

  return (
    <React.Fragment>
      <Card className="yDropWrap">
        <div className="yDropTitleRow">
          <Row>
            <Col className="yDropTitleCol">
              <span className="yDropTitle"> Y축 설정 변경 </span>
            </Col>
          </Row>
        </div>
        <Row className="m-0 p-0">
          <Col className="yDropInWrap m-0">
            <div className="yDropInputWrap">
              <span className="yDropInputspan">Min</span>
              <input
                type="text"
                className="yDropinput325"
                name="_min"
                value={inMin}
                onChange={handleMin}
              />
            </div>
            <div className="yDropInputWrap">
              <span className="yDropInputspan">Max</span>
              <input
                type="text"
                className="yDropinput325"
                name="_max"
                value={inMax}
                onChange={handleMax}
              />
            </div>
            <div className="yDropBtnWrap">
              <button className="yDropCancelBtn" onClick={() => onClose()}>
                <span>취소</span>
              </button>
              <button
                className="yDropSuccessBtn"
                onClick={(e) => onSubmitHandler(e)}
              >
                <span>적용</span>
              </button>
            </div>
          </Col>
        </Row>
      </Card>
      {/* </DropdownMenu>
      </Dropdown> */}
    </React.Fragment>
  );
};

export default withTranslation()(YDropdown);

YDropdown.propTypes = {
  t: PropTypes.any,
};
