import { ReactComponent as Settings } from "../../assets/images/chart/fi-rr-settings.svg";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { apiUrl } from "../../apis/request";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { Col, Row } from "reactstrap";
const MapSensorRow = ({ usercluster, setSelects, userInfo, setSideswitch }) => {
  const [imgBase64, setImgBase64] = useState([]);
  const history = useHistory();
  const { t } = useTranslation("main");

  var windowWidth = $(window).width();

  const mapBtnHandle = (e, data) => {
    e.preventDefault();
    if (windowWidth <= 767) {
      setSelects(data.id);
      setSideswitch(true);
    } else {
      setSelects(data.id);
    }
  };

  const editBtn = (e, data) => {
    e.stopPropagation();
    history.push({
      pathname: "/maps/edit",
      state: {
        editData: data,
        usercluster: usercluster,
      },
    });
  };

  return userInfo.map((data, i) => {
    const imgurl = apiUrl + data.imagePath;
    if (data.latitude > 0 && data.longitude > 0) {
      return (
        <div
          key={i}
          className="sensorsrow"
          style={{ cursor: "pointer" }}
          onClick={(e) => mapBtnHandle(e, data)}
        >
          <Row
            className="d-flex align-items-center"
            style={{ height: "100%", width: "100%", margin: "0px" }}
          >
            <Col
              className="col-auto"
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              {!data.imagePath ? (
                <div className="photoImage rounded-circle avatar-md" />
              ) : (
                <img
                  src={imgurl}
                  alt="photo"
                  className="photoImage rounded-circle avatar-md"
                />
              )}
            </Col>
            <Col className="rowWrap col-5">
              <div>{data.name ? data.name : data.serial}</div>
              <div>{data.serial}</div>
              <div>{data.description}</div>
            </Col>
            <Col className="settingWrap col-auto">
              <button onClick={(e) => editBtn(e, data)} className="settingBtn">
                <Settings />
              </button>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div key={i} className="sensorsrow" style={{ cursor: "pointer" }}>
          <Row
            className="d-flex align-items-center"
            style={{ height: "100%", width: "100%", margin: "0px" }}
          >
            <Col
              className="col-auto"
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              {!data.imagePath ? (
                <div className="photoImage rounded-circle avatar-md" />
              ) : (
                <img
                  src={imgurl}
                  alt="photo"
                  className="photoImage rounded-circle avatar-md"
                />
              )}
            </Col>
            <Col className="rowWrap col-5">
              <div>{data.name ? data.name : data.serial}</div>
              <div>{data.serial}</div>
              <div>{data.description}</div>
            </Col>

            <Col className="settingWrap col-auto">
              <span className="noMapData">{t("nomapdata")}</span>
              <button onClick={(e) => editBtn(e, data)} className="settingBtn">
                <Settings />
              </button>
            </Col>
          </Row>
        </div>
      );
    }
  });
};
export default MapSensorRow;
