import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import {
  apiUrl,
  imgPut,
  requestDelete,
  requestPut,
} from "../../../apis/request";
import SimpleBarReact from "simplebar-react";
import SensorMap from "../../Maps/SensorMap";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { ReactComponent as Photo } from "../../../assets/icons/photo.svg";

const MapEdit = (props) => {
  const [editData] = useState(props.location.state.editData);
  const [usercluster] = useState(props.location.state.usercluster);
  const [imgBase64, setImgBase64] = useState([]);
  const [photoFile, setPhotoFile] = useState(null);
  const [deleteHide, setDeleteHide] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [name, setName] = useState(editData.name);
  const [deviceType, setDeviceType] = useState(editData.deviceType);
  const [channel, setChannel] = useState(editData.chNums);
  const [serial, setSerial] = useState(editData.serial);
  const [description, setDescription] = useState(editData.description);
  const [crops, setCrops] = useState(editData.crops);
  const [latitude, setLatitude] = useState(editData.latitude);
  const [check, setCheck] = useState(editData.widget);
  const [longitude, setLongitude] = useState(editData.longitude);
  const [clusterId, setClusterId] = useState(
    props.location.state.editData.clusters.length > 0
      ? props.location.state.editData.clusters[0].id
      : 0
  );
  const { t } = useTranslation("main");
  const history = useHistory();

  useEffect(() => {
    if (editData.name === null) {
      setName("");
    } else if (editData.name !== null) {
      setName(editData.name);
    }
    if (editData.deviceType === null) {
      setDeviceType("");
    } else if (editData.deviceType !== null) {
      setDeviceType(editData.deviceType);
    }
    if (editData.serial === null) {
      setSerial("");
    } else if (editData.serial !== null) {
      setSerial(editData.serial);
    }
    if (editData.chNums === null) {
      setChannel("");
    } else if (editData.chNums !== null) {
      setChannel(editData.chNums);
    }
    if (editData.description === null) {
      setDescription("");
    } else if (editData.description !== null) {
      setDescription(editData.description);
    }
    if (editData.crops === null) {
      setCrops("");
    } else if (editData.crops !== null) {
      setCrops(editData.crops);
    }
    if (editData.latitude === null) {
      setLatitude("");
    } else if (editData.latitude !== null) {
      setLatitude(editData.latitude);
    }
    if (editData.longitude === null) {
      setLongitude("");
    } else if (editData.longitude !== null) {
      setLongitude(editData.longitude);
    }
    if (editData.imagePath === null) {
      setImgBase64(null);
    } else if (editData.imagePath !== null) {
      setImgBase64(apiUrl + props.location.state.editData.imagePath);
    }
  }, [editData]);
  const onFileHandler = (e) => {
    setPhotoFile(e.target.files[0]);
    setImgBase64(URL.createObjectURL(e.target.files[0]));
  };
  const onNameHandler = (e) => {
    setName(e.currentTarget.value);
  };

  const handledevice = (e) => {
    setDeviceType(e.target.value);
  };

  const onSerialHandler = (e) => {
    setSerial(e.currentTarget.value);
  };

  const onDescriptionHandler = (e) => {
    setDescription(e.currentTarget.value);
  };

  const handleCrops = (e) => {
    setCrops(e.target.value);
  };

  const handleChennel = (e) => {
    setChannel(e.target.value);
  };

  const handlelatitude = (e) => {
    setLatitude(e.target.value);
  };

  const handlelongitude = (e) => {
    setLongitude(e.target.value);
  };

  const hidehandle = () => {
    setDeleteHide(!deleteHide);
  };
  const handlecluster = (e) => {
    setClusterId(e.target.value);
  };
  const handleCheck = () => {
    setCheck(!check);
  };

  const onSubmitHandler = async (e) => {
    if (deviceType) {
      try {
        e.preventDefault();
        const params = new URLSearchParams();
        params.append("id", editData.id);
        params.append("name", name);
        params.append("deviceType", deviceType);
        params.append("serial", serial);
        params.append("description", description);
        params.append("crops", crops);
        params.append("latitude", latitude);
        params.append("longitude", longitude);
        params.append("widget", check);
        params.append("clusterId", clusterId);
        params.append("chNums", channel);

        // let body = {
        //   id: editData.id,
        //   name: name,
        //   deviceType: deviceType,
        //   serial: serial,
        //   description: description,
        //   latitude: latitude,
        //   longitude: longitude,
        //   clusterId: clusterId,
        // };
        await requestPut(`/api/v1/sensor/update/${editData.id}`, params);
        if (photoFile) {
          const formData = new FormData();
          formData.append("image", photoFile, photoFile.name);
          await imgPut(`/api/v1/sensor/update/${editData.id}/image`, formData);
        }
        history.push("/maps");
        alert("수정 성공 하였습니다");
      } catch (error) {
        alert("수정 실패하였습니다");
      }
    } else {
      alert("디바이스타입 필수 입니다.");
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensor Edit | FarmCLO</title>
      </MetaTags>
      <div className="accountpageTotalWrap11">
        <div className="accountpage-content">
          <div className="accountWrap">
            <div className="accountPageCardWrap">
              <div className="accountPageCard1">
                <CardBody>
                  <div>
                    <div className="float-end">
                      <i
                        className="deletehide bx bx-dots-vertical-rounded"
                        onClick={hidehandle}
                      />

                      {deleteHide ? (
                        <button
                          color="danger"
                          className="sensorDeletBtn"
                          onClick={() => {
                            setconfirm_alert(true);
                          }}
                        >
                          <i className="deleticon bx bx-trash" />
                          {t("delete")}
                        </button>
                      ) : (
                        ""
                      )}

                      {confirm_alert ? (
                        <SweetAlert
                          title="정말 삭제하시겠습니까?"
                          warning
                          showCancel
                          confirmButtonText={t("delete")}
                          confirmBtnBsStyle="danger"
                          cancelButtonText={t("cancel")}
                          cancelBtnBsStyle="success"
                          onConfirm={async () => {
                            setconfirm_alert(false);
                            setsuccess_dlg(!success_dlg);
                            await requestDelete(
                              `/api/v1/sensor/delete/${editData.id}`
                            );
                            history.push("/maps");
                          }}
                          onCancel={() => setconfirm_alert(false)}
                        ></SweetAlert>
                      ) : null}
                    </div>

                    <div className="clearfix"></div>
                    <div className="sensorInfoTitleWrap"></div>
                  </div>
                  <div
                    className="sensorInfoTable table-responsive"
                    style={{ padding: "0 40px 0 50px" }}
                  >
                    <Form onSubmit={onSubmitHandler}>
                      <div className="photoAddRow">
                        {!imgBase64 || imgBase64.length === 0 ? (
                          <div className="photoImage" />
                        ) : (
                          <img
                            src={imgBase64}
                            alt="photo"
                            className="photoImage"
                          />
                        )}
                        <Label className="fileAddName m-0">
                          <Input
                            type="file"
                            id="image"
                            onChange={onFileHandler}
                          />
                          <Photo />
                        </Label>
                      </div>
                      <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("name")}</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="name"
                          value={name}
                          onChange={onNameHandler}
                        />
                      </div>
                      <FormGroup className="listAddRow d-block d-md-flex">
                        <label htmlFor="_clusterId" className="listAddName">
                          {t("devicetype")}
                        </label>
                        <Input
                          type="select"
                          className="form-select listAddInput"
                          value={deviceType}
                          onChange={handledevice}
                        >
                          <option value="SMS">{t("SMS")}</option>
                          <option value="SSH">{t("SSH01")}</option>
                          <option value="AT">{t("AT")}</option>
                          <option value="WATERFLOW">{t("WATERFLOW")}</option>
                          <option value="NUTRIENT">{t("NUTRIENT")}</option>
                          <option value="DRAINAGE">{t("DRAINAGE")}</option>
                          <option value="FMS">{t("FMS")}</option>
                          <option value="FMS02">{t("FMS02")}</option>
                          <option value="COMPOST">{t("COMPOST")}</option>
                          <option value="SOIL">{t("SOIL")}</option>
                          <option value="CO2">{t("CO2")}</option>
                          <option value="ATS02">{t("ATS02")}</option>
                          <option value="LAT">{t("LAT")}</option>
                          <option value="WATERLEVEL">{t("WATERLEVEL")}</option>
                          {/* <option value="SES">{t("SES")}</option> */}
                          <option value="COT">{t("COT")}</option>
                        </Input>
                      </FormGroup>
                      {deviceType === "COT" ? (
                        <FormGroup className="listAddRow d-block d-md-flex">
                          <label htmlFor="_clusterId" className="listAddName">
                            {t("ch")}
                          </label>
                          <Input
                            type="select"
                            className="form-select listAddInput"
                            value={channel}
                            onChange={handleChennel}
                          >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                          </Input>
                        </FormGroup>
                      ) : null}
                      <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("serial")}</Label>
                        <Input
                          type="number"
                          className="listAddInput"
                          id="serial"
                          value={serial}
                          onChange={onSerialHandler}
                          disabled
                        />
                      </div>
                      <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("crops")}</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="description"
                          value={crops}
                          onChange={handleCrops}
                        />
                      </div>
                      <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">
                          {t("description")}
                        </Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="description"
                          value={description}
                          onChange={onDescriptionHandler}
                        />
                      </div>
                      {/* <div className="listAddRow">
                        <Label className="listAddName">최저 지습설정</Label>
                        <Input
                          type="text"
                          className="listAddInput"
                          id="description"
                          placeholder={
                            deviceType !== "SOIL"
                              ? "토양센서 일때만 활성화됩니다."
                              : ""
                          }
                          disabled={deviceType !== "SOIL"}
                        />
                      </div> */}
                      <FormGroup className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("clusterid")}</Label>
                        <Input
                          type="select"
                          className="form-select listAddInput"
                          onChange={handlecluster}
                          value={clusterId}
                        >
                          <option value={0}>없음</option>
                          {usercluster.map((data, i) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("latitude")} </Label>
                        <Input
                          type="text"
                          name="_latitude"
                          className="listAddInput"
                          value={latitude}
                          onChange={handlelatitude}
                        />
                      </div>
                      <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("longitude")}</Label>
                        <Input
                          type="text"
                          name="_longitude"
                          className="listAddInput"
                          value={longitude}
                          onChange={handlelongitude}
                        />
                      </div>
                      <div className="listAddRow d-block d-md-flex">
                        <Label className="listAddName">{t("widget")}</Label>
                        <Input
                          type="checkbox"
                          name="_longitude"
                          style={{ width: "30px", height: "30px" }}
                          checked={check}
                          onClick={() => handleCheck()}
                        />
                      </div>
                      <SensorMap
                        editData={editData}
                        setLatitude={setLatitude}
                        setLongitude={setLongitude}
                      />
                      <div className="mt-4">
                        <button className="sensorUpdateBtn" type="submit">
                          {t("save")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MapEdit;
